import React from 'react'
import useLocalization from '../../../hooks/useLocalization'
import Box from '../../Box'
import Heading from '../../Heading'
import ReactMarkdown from 'react-markdown'

interface IProps extends React.PropsWithChildren {
  description: string
}

const ContractAbout: React.FC<IProps> = ({description}) => {
  const {translate} = useLocalization()

  if (!description) {
    return null
  }

  return (
    <Box>
      <Heading>{translate('About the contract')}</Heading>
      <ReactMarkdown>{description}</ReactMarkdown>
    </Box>
  )
}

export default ContractAbout
