import IHttpClient from './IHttpClient'
import ISmartContractService from './ISmartEnergyContractService'
import ISmartContract from '../domain/ISmartEnergyContract'
import IToken from '../domain/IToken'

interface ITokenResult {
  id: string
  deliveryStart: number
  deliveryEnd: number
  minted: {
    date: number
    gas: number
    blockHash: string
    blockNumber: number
    transactionHash: string
  }
  states: Array<{
    blockHash: string
    blockNumber: number
    transactionHash: string
    date: number
    gas: number
    offtakers?: Array<{
      offtaker: string
      share: string
      energyHash?: string
    }>
    owners?: Array<{
      owner: string
      share: string
      energyHash?: string
    }>
  }>
}

interface ICollectionResult {
  tokens: ITokenResult[]
}

export default class SmartEnergyContractService implements ISmartContractService {
  private readonly httpClient: IHttpClient

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient
  }

  public async getSmartContractById(id: string): Promise<ISmartContract> {
    const {data} = await this.httpClient.get<ICollectionResult>(`/${id}`)

    if (!data) {
      throw new Error(`Could not retrieve collection ${id}`)
    }

    return {
      id,
      tokens: data.tokens.map(token => SmartEnergyContractService.parseToken(token)),
      owners: data.tokens.map(token => SmartEnergyContractService.parseOwners(token)),
    }
  }

  private static parseToken(result: ITokenResult): IToken {
    return {
      id: result.id,
      minted: {
        date: new Date(result.minted.date * 1000),
        gas: result.minted.gas,
        blockHash: result.minted.blockHash,
        blockNumber: result.minted.blockNumber,
        transactionHash: result.minted.transactionHash,
      },
      deliveryStart: new Date(result.deliveryStart * 1000),
      deliveryEnd: new Date(result.deliveryEnd * 1000),
      states: result.states.map(state => ({
        blockHash: state.blockHash,
        blockNumber: state.blockNumber,
        transactionHash: state.transactionHash,
        holderAddress: null, // TODO
        gas: state.gas,
        date: new Date(state.date * 1000),
        offtakers: state.offtakers?.map(({offtaker, share, energyHash}) => ({
          offtaker,
          share: +share,
          energyHash,
        })),
      })),
    }
  }
  private static parseOwners(result: any): any {
    return {
      id: result.id,
      minted: {
        date: new Date(result.minted.date * 1000),
        gas: result.minted.gas,
        blockHash: result.minted.blockHash,
        blockNumber: result.minted.blockNumber,
        transactionHash: result.minted.transactionHash,
      },
      deliveryStart: new Date(result.deliveryStart * 1000),
      deliveryEnd: new Date(result.deliveryEnd * 1000),
      states: result.states.map(state => ({
        blockHash: state.blockHash,
        blockNumber: state.blockNumber,
        transactionHash: state.transactionHash,
        holderAddress: null, // TODO
        gas: state.gas,
        date: new Date(state.date * 1000),
        owners: state.owners?.map(({owner, share, energyHash}) => ({
          owner,
          share: +share,
          energyHash,
        })),
      })),
    }
  }
}
