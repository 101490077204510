import React, {useState} from 'react'
import Box from '../../../../Box'
import FormInput from '../../../../Form/FormInput'
import Button, {IProps as IButtonProps} from '../../../../Button'
import useLocalization from '../../../../../hooks/useLocalization'
import useFormContext from '../../../../../hooks/useFormContext'
import FormFileDrop from '../../../../Form/FormFileDrop'
import ISite, {SiteType} from '../../../../../domain/ISite'
import styled from 'styled-components'
import ITheme from '../../../../../theme/ITheme'
import DownloadExampleLink from './DownloadExampleLink'
import Label from '../../../../Label'
import ProductionChart from '../../../Chart/ProductionChart'
import useResource from '../../../../../hooks/useResource'
import FormSelect from '../../../../Form/FormSelect'

const UnderlinedButton = styled(Button)<IButtonProps & {selected: boolean}>`
  border-bottom: 2px solid rgba(0, 0, 0, 0);

  ${props =>
    props.selected &&
    `
      border-bottom-color: ${(props.theme as ITheme).colors.yellow2};
      border-radius: 4px 4px 0 0;
  `}
`

enum ProfileSourceType {
  Upload = 1,
  Build,
}

interface IProps extends React.PropsWithChildren {
  isConsumptionSite: boolean
  site?: ISite
  siteType?: SiteType
}

const SiteProfileSourceData: React.FC<IProps> = ({site, siteType, isConsumptionSite}) => {
  const [profileSourceType, setConsumptionType] = useState<ProfileSourceType>(
    site?.avgYearlyProductionMwh ? null : ProfileSourceType.Upload,
  )
  const {translate} = useLocalization()
  const {setValue, clearError} = useFormContext()
  const allowMwhEdit = profileSourceType === ProfileSourceType.Build
  const productionSites = useResource(
    store => (isConsumptionSite ? store.siteStore.consumptionTemplates : store.siteStore.productionTemplates),
    store =>
      isConsumptionSite ? store.siteStore.loadConsumptionTemplates() : store.siteStore.loadProductionTemplates(),
  )

  return (
    <Box direction={'column'}>
      <FormInput
        type="number"
        defaultValue={''}
        disabled={!allowMwhEdit}
        required={allowMwhEdit}
        placeholder={!allowMwhEdit && translate('Will be aggregated from profile')}
        min={0}
        max={9999999.9}
        name={isConsumptionSite ? 'avgYearlyConsumptionMwh' : 'avgYearlyProductionMwh'}
        label={translate(isConsumptionSite ? 'Est. annual consumption, MWh' : 'Est. annual production, MWh')}
      />
      <Box>
        <Label text={translate('Load profile source')} />
        <Box gap={1} margin={{bottom: 2}}>
          <UnderlinedButton
            selected={profileSourceType === ProfileSourceType.Upload}
            variant="clear"
            type="button"
            onClick={e => {
              e.preventDefault()
              setValue(
                isConsumptionSite ? 'avgYearlyConsumptionMwh' : 'avgYearlyProductionMwh',
                (site && site[isConsumptionSite ? 'avgYearlyConsumptionMwh' : 'avgYearlyProductionMwh']) || null,
              )
              setConsumptionType(ProfileSourceType.Upload)
            }}
          >
            {translate('Upload')}
          </UnderlinedButton>
        </Box>
      </Box>

      {profileSourceType === ProfileSourceType.Upload && (
        <>
          <FormFileDrop label={translate('Upload profile')} name="file" required={!site} />
          <Box margin={{vertical: 2}}>
            <DownloadExampleLink siteType={SiteType.CONSUMPTION} />
          </Box>
        </>
      )}

      {profileSourceType === ProfileSourceType.Build && (
        <Box>
          <FormSelect
            name="loadType"
            required
            options={[
              {
                label: translate('Select load type...'),
                value: null,
              },
            ].concat(
              productionSites.map((site, i) => ({
                key: 100 + i,
                label: site.name,
                value: site.id,
              })),
            )}
          />
        </Box>
      )}

      {!profileSourceType && site?.avgYearlyProductionMwh && (
        <>
          <Box margin={{top: 2}}>
            {translate('Current profile')}: <strong>{site && site.name}</strong>
          </Box>
          <Box margin={{top: 2}}>
            <ProductionChart site={site} label={translate('Production')} />
          </Box>
        </>
      )}
    </Box>
  )
}

export default SiteProfileSourceData
