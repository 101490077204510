import React, {useEffect, useState} from 'react'
import Box from '../../../Box'
import FormInput from '../../../Form/FormInput'
import Button from '../../../Button'
import TextLink from '../../../TextLink'
import useLocalization from '../../../../hooks/useLocalization'
import Heading from '../../../Heading'
import useFormContext from '../../../../hooks/useFormContext'
import ConsumptionChart from '../../Chart/ConsumptionChart'
import IParty from '../../../../domain/IParty'
import FormFileDrop from '../../../Form/FormFileDrop'
import ProfileSelector from './ProfileSelector'

enum ConsumptionType {
  Upload = 1,
  Build,
}

interface IProps extends React.PropsWithChildren {
  party?: IParty
  setPricingParameterGroupId?: any
  setShouldUpdateParameterGroup?: any
  shouldUpdateParameterGroup?: any
}

const CustomerConsumptionBuilder: React.FC<IProps> = ({
  party,
  setPricingParameterGroupId,
  setShouldUpdateParameterGroup,
  shouldUpdateParameterGroup,
}) => {
  const [consumptionType, setConsumptionType] = useState<ConsumptionType>(
    party?.totalAvgYearlyConsumptionMwh ? null : ConsumptionType.Upload,
  )
  const {translate} = useLocalization()
  const {setValue, clearError, getValues} = useFormContext()

  const allowConsumptionMwhEdit = consumptionType === ConsumptionType.Build
  const formValues = getValues()

  useEffect(() => {
    if (shouldUpdateParameterGroup) {
      if (allowConsumptionMwhEdit) {
        setPricingParameterGroupId(formValues.pricingParameterGroupId)
      } else if (formValues.newSiteId) {
        const site = JSON.parse(formValues.newSiteId)

        if (site.meta) {
          setPricingParameterGroupId(site.meta)
        } else {
          setPricingParameterGroupId(null)
        }
      }
    }
    setShouldUpdateParameterGroup(false)
    // eslint-disable-next-line
  }, [formValues.newSiteId, formValues.pricingParameterGroupId])

  return (
    <div>
      <Heading size="small">{translate('Consumption data')}</Heading>

      {/* TODO: Implement build properly later */}
      {/* <Box gap={1} margin={{vertical: 2}}>
        <Button
          variant="clear"
          type="button"
          onClick={() => {
            setValue('totalAvgYearlyConsumptionMwh', party?.totalAvgYearlyConsumptionMwh || null) // Reset value
            setConsumptionType(ConsumptionType.Upload)
          }}
        >
          {translate('Upload')}
        </Button>
        <Button
          variant="clear"
          type="button"
          onClick={() => {
            setValue('consumptionFile', null)
            clearError('consumptionFile')
            setConsumptionType(ConsumptionType.Build)
          }}
        >
          {translate('Build')}
        </Button>
      </Box> */}

      <FormInput
        label={translate('Estimated annual consumption volume, Mwh')}
        name="totalAvgYearlyConsumptionMwh"
        disabled={!allowConsumptionMwhEdit}
        required={allowConsumptionMwhEdit}
        max={9999999.9}
        min={0}
        placeholder={!allowConsumptionMwhEdit && translate('Upload consumption profile to fill this field')}
        pattern={allowConsumptionMwhEdit && /^\d+(\.\d{1,2})?$/}
      />

      {consumptionType === ConsumptionType.Upload && (
        <Box margin={{top: 2}}>
          <Heading size="small" margin={{bottom: 2}}>
            {translate('Upload consumption profile file')}
            &nbsp; (
            <TextLink to="/files/consumption_example.csv" download>
              {translate('Download example file')}
            </TextLink>
            )
          </Heading>
          <FormFileDrop name="consumptionFile" required />
          <Box margin={{top: 2}}>
            <ProfileSelector setShouldUpdateParameterGroup={setShouldUpdateParameterGroup} />
          </Box>
        </Box>
      )}

      {consumptionType === ConsumptionType.Build && (
        <Box margin={{top: 2}}>
          <ProfileSelector splitSite setShouldUpdateParameterGroup={setShouldUpdateParameterGroup} />
        </Box>
      )}

      {!consumptionType && party?.totalAvgYearlyConsumptionMwh && (
        <>
          <Box margin={{top: 2}}>{translate('Current profile')}</Box>
          <Box margin={{top: 2}}>
            <ConsumptionChart party={party} label={translate('Consumption')} />
          </Box>
        </>
      )}
    </div>
  )
}

export default CustomerConsumptionBuilder
