import IAdminCreatePartyMember from 'domain/IAdminCreateUser'
import {IPartyMemberUpdateRequest} from 'domain/IPartyMemberUpdateRequest'
import IUser from 'domain/IUser'
import IAttachment from '../domain/IAttachment'
import IContract, {ContractState, IContractSnapshot} from '../domain/IContract'
import IContractType from '../domain/IContractType'
import IOffer, {IOfferInvite, OfferState} from '../domain/IOffer'
import IOfferTextGroup from '../domain/IOfferTextGroup'
import IParty, {PartyRole} from '../domain/IParty'
import IPartyMember from '../domain/IPartyMember'
import IProduct from '../domain/IProduct'
import IProductType from '../domain/IProductType'
import ISite, {SiteProductionTechnology} from '../domain/ISite'
import ITimeseriesItem from '../domain/ITimeseriesItem'
import {SellExcessPayload} from '../domain/INewTimeseriesItem'
import {isProductUserSelectable} from '../helpers/product'
import IContentService, {Interval} from './IContentService'
import IHttpClient from './IHttpClient'
import IPurchaseGroup, {IPurchaseGroupBase} from 'domain/IPurchaseGroup'
import IOfferRequest from '../domain/IOfferRequest'
import {IShoppingCart} from '../store/ShoppingCartStore'
import IPurchaseContract from 'domain/IPurchaseContract'
import {TextDocumentType} from 'domain/IDocumentType'
import ITextDocument from 'domain/ITextDocument'
import {EnergyDataType} from 'domain/EnergyDataType'
import IOfferPriceCalculationResult from 'domain/IOfferPriceCalculationResult'
import {ISignedContractFile} from 'domain/ISignedContractFile'
import {IPaginateResult, IPaginatedExtendedResult} from 'domain/IPaginateResult'
import {IPasswordFromInvite} from '../domain/IPasswordFromInvite'
import {ITimeseriesOverview} from 'domain/Portfolio'
import {ResourceId} from 'types'
import {IProposalDetailsBody} from 'domain/IProposalDetails'
import SellExcessRequest, {IRecommendedExcessPrice} from 'domain/ISellExcessRequest'
import ISellExcessRequest from 'domain/ISellExcessRequest'

export default class LederhosenContentService implements IContentService {
  private readonly httpClient: IHttpClient

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient
  }

  public async getPorfolioOverview(
    partyId: any,
    interval: Interval,
    start: Date,
    end: Date,
  ): Promise<ITimeseriesOverview> {
    const {data} = await this.httpClient.get<ITimeseriesOverview>(
      `/public/parties/${partyId}/portfolio-overview/${interval}`,
      {
        params: {
          start: start,
          end: end,
        },
      },
    )

    return data
  }

  public async updateProfile(updatedUser: any) {
    const {data} = await this.httpClient.put<IUser>(`/public/currentuser/profile`, updatedUser)

    return data
  }

  public async createPartyForCurrentUser(partyMember: Omit<IPartyMember, 'id'>): Promise<IPartyMember> {
    const {data} = await this.httpClient.post<IPartyMember>(`/public/currentuser/party-memberships`, partyMember)

    return data
  }

  public async savePartyForCurrentUser(partyMember: IPartyMember): Promise<IPartyMember> {
    const {data} = await this.httpClient.put<IPartyMember>(`/public/currentuser/party-memberships`, partyMember)

    return data
  }

  public async uploadConsumptionProfile(partyId: any, csv: File, name?: string): Promise<void> {
    const formData = new FormData()
    formData.append('file', csv)

    if (name) {
      formData.append('name', name)
    }

    await this.httpClient.post(`/public/parties/${partyId}/consumption`, formData)
  }

  public async getTextDocument(documentType: TextDocumentType, locale: string = 'en'): Promise<ITextDocument> {
    const {data} = await this.httpClient.get<ITextDocument>(`/public/text-document`, {params: {documentType, locale}})

    return data
  }

  public async getPurchaseGroupConsumptionData(
    partyId: any,
    purchaseGroupId: any,
    interval: Interval,
  ): Promise<ITimeseriesItem[]> {
    if (interval === Interval.YEARLY) {
      throw new Error('Yearly is currently not supported')
    }

    const {data} = await this.httpClient.get<ITimeseriesItem[]>(
      `/public/parties/${partyId}/purchase-groups/${purchaseGroupId}/consumption/${interval}`,
    )

    return data
  }

  public async getConsumptionData(partyId: any, interval: Interval): Promise<ITimeseriesItem[]> {
    if (interval === Interval.YEARLY) {
      throw new Error('Yearly is currently not supported')
    }

    const {data} = await this.httpClient.get<ITimeseriesItem[]>(`/public/parties/${partyId}/consumption/${interval}`)

    return data
  }

  public async getYearlyConsumptionData(partyId: any, start: Date, end: Date): Promise<ITimeseriesItem[]> {
    const {data} = await this.httpClient.get<ITimeseriesItem[]>(
      `/public/parties/${partyId}/consumption/${Interval.YEARLY}`,
      {
        params: {
          start,
          end,
        },
      },
    )

    return data
  }

  public async getYearlyTimeseriesData(siteId: any, start: Date, end: Date): Promise<ITimeseriesItem[]> {
    const {data} = await this.httpClient.get<ITimeseriesItem[]>(
      `/public/sites/${siteId}/timeseries/${Interval.YEARLY}`,
      {
        params: {
          start,
          end,
        },
      },
    )

    return data
  }

  public async getPurchaseGroupYearlyConsumptionData(
    purchaseGroupId: any,
    partyId: any,
    start: Date,
    end: Date,
  ): Promise<ITimeseriesItem[]> {
    const {data} = await this.httpClient.get<ITimeseriesItem[]>(
      `/public/parties/${partyId}/purchase-groups/${purchaseGroupId}/consumption/${Interval.YEARLY}`,
      {
        params: {
          start,
          end,
        },
      },
    )

    return data
  }

  public async getTimeseriesData(
    siteId: any,
    interval: Interval,
    energyDataType = EnergyDataType.PREDICTED,
  ): Promise<ITimeseriesItem[]> {
    const {data} = await this.httpClient.get<ITimeseriesItem[]>(`/public/sites/${siteId}/timeseries/${interval}`, {
      params: {
        energyDataType,
      },
    })

    return data
  }

  public async sellCertExcessRequest(partyId: ResourceId, payload: SellExcessPayload): Promise<SellExcessRequest> {
    const {data} = await this.httpClient.post<SellExcessRequest>(`/public/party/${partyId}/sell-requests`, payload)

    return data
  }

  public async getYearlyPurchaseGroupConsumptionData(
    partyId: any,
    purchaseGroupId: any,
    start: number,
    end: number,
  ): Promise<ITimeseriesItem[]> {
    const {data} = await this.httpClient.get<ITimeseriesItem[]>(
      `/public/parties/${partyId}/purchase-groups/${purchaseGroupId}/consumption/${Interval.YEARLY}`,
      {
        params: {
          start,
          end,
        },
      },
    )

    return data
  }

  public async uploadTimeseriesData(siteId: any, csv: File): Promise<ISite> {
    const formData = new FormData()
    formData.append('file', csv)

    const {data} = await this.httpClient.post(`/public/sites/${siteId}/timeseries`, formData)

    return data
  }

  public async cloneTimeseriesData(siteId: any, cloneSiteId: any): Promise<ISite> {
    const {data} = await this.httpClient.post(`/public/sites/${siteId}/timeseries/clone/${cloneSiteId}`)

    return data
  }

  public async getProductTypes(partyId: any): Promise<IProductType[]> {
    const {data} = await this.httpClient.get<IProductType[]>(`/public/parties/${partyId}/product-types`)

    return data
  }

  public async getProductVisibleToParty(partyId: any, id: any): Promise<IProduct> {
    const {data} = await this.httpClient.get<IProduct>(`/public/parties/${partyId}/visible-products/${id}`)

    return data
  }

  public async getProductManagedByParty(partyId: any, id: any): Promise<IProduct> {
    const {data} = await this.httpClient.get<IProduct>(`/public/parties/${partyId}/managed-products/${id}`)

    return data
  }

  public async getProductsManagedByParty(partyId: any): Promise<IProduct[]> {
    const {data} = await this.httpClient.get<IProduct[]>(`/public/parties/${partyId}/managed-products`)

    return data
  }

  public async getProductsVisibleToParty(partyId: any): Promise<IProduct[]> {
    const {data} = await this.httpClient.get<IProduct[]>(`/public/parties/${partyId}/visible-products`)

    return data
  }

  public async editCertificateOffer(partyId: any, offerId: any, body: IProposalDetailsBody): Promise<IOffer> {
    const {data} = await this.httpClient.put<IOffer>(
      `/public/parties/${partyId}/offers/${offerId}/purchase-designer`,
      body,
    )

    return data
  }

  public async sendCertificateReview(partyId: any, offerId: any): Promise<void> {
    await this.httpClient.post(`/public/parties/${partyId}/offers/${offerId}/purchase-designer/indicative-to-retailer`)
  }

  public async approveCertificateOffer(partyId: any, offerId: any): Promise<void> {
    await this.httpClient.post(`/public/parties/${partyId}/offers/${offerId}/purchase-designer/approve`)
  }

  public async declineCertificateOffer(partyId: any, offerId: any): Promise<void> {
    await this.httpClient.post(`/public/parties/${partyId}/offers/${offerId}/purchase-designer/decline`)
  }

  public async getPublicExcessSellRequests(partyId: any): Promise<IPaginatedExtendedResult<ISellExcessRequest>> {
    const {data} = await this.httpClient.get<IPaginatedExtendedResult<ISellExcessRequest>>(
      `/public/party/${partyId}/sell-requests`,
    )

    return data
  }

  public async getRecommendedExcessPrice(
    partyId: any,
    technologyKind: SiteProductionTechnology,
  ): Promise<IRecommendedExcessPrice> {
    const {data} = await this.httpClient.get<IRecommendedExcessPrice>(
      `/public/party/${partyId}/sell-requests/price-recommendation`,
      {
        params: {
          technologyKind,
        },
      },
    )

    return data
  }

  public async acceptExcessSellRequest(sellRequestId: any): Promise<void> {
    await this.httpClient.put<ISellExcessRequest>(`admin/green-certs/sell-requests/${sellRequestId}/accept`)
  }

  public async declineExcexsSellRequest(sellRequestId: any): Promise<void> {
    await this.httpClient.put<ISellExcessRequest>(`admin/green-certs/sell-requests/${sellRequestId}/decline`)
  }

  public async createProduct(partyId: any, product: IProduct): Promise<IProduct> {
    const {data} = await this.httpClient.post<IProduct>(`/public/parties/${partyId}/products`, product)

    return data
  }

  public async updateProduct(partyId: any, product: IProduct): Promise<IProduct> {
    const {data} = await this.httpClient.put<IProduct>(`/public/parties/${partyId}/products`, product)

    return data
  }

  public async getConsumptionSiteTemplates(partyId: any): Promise<ISite[]> {
    const {data} = await this.httpClient.get<ISite[]>(`/public/parties/${partyId}/sites/consumption-templates`)

    return data
  }

  public async getProductionSiteTemplates(partyId: any): Promise<ISite[]> {
    const {data} = await this.httpClient.get<ISite[]>(`/public/parties/${partyId}/sites/production-templates`)

    return data
  }

  public async getSites(partyId: any): Promise<ISite[]> {
    const params = {withProducts: '1', 'state.notEquals': 'DELETED'}
    const {data} = await this.httpClient.get<ISite[]>(`/public/parties/${partyId}/sites`, {params})

    return data
  }

  public async createSite(partyId: any, site: ISite): Promise<ISite> {
    const {data} = await this.httpClient.post<ISite>(`/public/parties/${partyId}/sites`, site)

    return data
  }

  public async updateSite(partyId: any, site: ISite): Promise<ISite> {
    const {data} = await this.httpClient.put<ISite>(`/public/parties/${partyId}/sites`, site)

    return data
  }

  public async associatePurchaseGroupToSites(
    partyId: any,
    purchaseGroup: IPurchaseGroupBase,
    siteIds: number[],
  ): Promise<void> {
    await this.httpClient.post(`/public/parties/${partyId}/sites/purchase-group`, {purchaseGroup, siteIds})
  }

  public async softDeleteSite(partyId: any, siteId: any): Promise<ISite> {
    const {data} = await this.httpClient.delete<ISite>(`public/parties/${partyId}/sites/${siteId}`)

    return data
  }

  public async getPartyContracts(partyId: any): Promise<IContract[]> {
    const {data} = await this.httpClient.get<IContract[]>(`/public/parties/${partyId}/contracts`)

    return data
  }

  public async getDraftContractsWithoutOffer(partyId: any): Promise<IContract[]> {
    const params = {'offerId.specified': false, 'state.equals': ContractState.DRAFT}
    const {data} = await this.httpClient.get<IContract[]>(`/public/parties/${partyId}/contracts`, {params})
    return data
  }

  public async reserveProduction(partyId: any, reserve: IShoppingCart[]): Promise<void> {
    await this.httpClient.post(`/public/parties/${partyId}/products/reserve-production`, {reserve})
  }

  public async updateContract(partyId: any, contract: IContract): Promise<void> {
    await this.httpClient.patch(`/public/parties/${partyId}/contracts/${contract.id}`, {
      contractItems: contract.contractItems
        .filter(contractItem => isProductUserSelectable(contractItem.product))
        .map(({id, volumeMwh}) => ({id, volumeMwh})),
    })
  }

  public async getContractPdf(contractId: any): Promise<Blob> {
    const {data} = await this.httpClient.get(`/public/contracts/${contractId}`, {
      headers: {
        accept: 'application/pdf',
      },
      responseType: 'blob',
    })

    return new Blob([data])
  }

  public async getContractContractTemplatePdf(contractId: any, templateId: any, partyId: any): Promise<Blob> {
    const {data} = await this.httpClient.get(
      `/public/${partyId}/contract-templates/${templateId}/contracts/${contractId}`,
      {
        headers: {
          accept: 'application/pdf',
        },
        responseType: 'blob',
      },
    )

    return new Blob([data])
  }

  public async getContractContractTemplates(contractId: any, partyId: any): Promise<any> {
    const {data} = await this.httpClient.get<IContractType>(
      `/public/${partyId}/contract-templates/contracts/${contractId}`,
    )

    return data
  }

  public async getContractHtml(contractId: any): Promise<string> {
    const {data} = await this.httpClient.get(`/public/contracts/${contractId}`, {
      headers: {
        accept: 'text/html',
      },
    })

    return data
  }

  public async getDefaultContractType(asPartyId: any): Promise<IContractType> {
    const {data} = await this.httpClient.get<IContractType>(`/public/parties/${asPartyId}/contract-types/default`)

    return data
  }

  public async getContractTypes(asPartyId: any): Promise<IContractType[]> {
    const {data} = await this.httpClient.get<IContractType[]>(`/public/parties/${asPartyId}/contract-types`)

    return data
  }

  public async getOffer(partyId: any, offerId: any): Promise<IOffer> {
    const {data: offer} = await this.httpClient.get<IOffer>(`/public/parties/${partyId}/offers/${offerId}`)

    return offer
  }

  public async getPagedOffers(partyId: any, size: any, page: any): Promise<IPaginateResult<IOffer>> {
    const params = {'state.notEquals': 'DELETED', size, page}
    const {data} = await this.httpClient.get<IPaginateResult<IOffer>>(`/public/parties/${partyId}/offers`, {
      params,
    })

    return data
  }

  public async getAllOffers(partyId: any): Promise<IOffer[]> {
    const params = {'state.notEquals': 'DELETED'}

    const {data} = await this.httpClient.get<IOffer[]>(`/public/parties/${partyId}/offers/all`, {
      params,
    })

    return data
  }

  public async sendDraftToCustomer(partyId: any, offerId: any): Promise<any> {
    return await this.httpClient.post(`/public/parties/${partyId}/offers/${offerId}/indicative-to-customer`)
  }

  public async sendBindingToCustomer(partyId: any, offerId: any): Promise<any> {
    return await this.httpClient.post(`/public/parties/${partyId}/offers/${offerId}/binding-to-customer`)
  }

  public async toBindingDraft(partyId: any, offerId: any): Promise<any> {
    return await this.httpClient.post(`/public/parties/${partyId}/offers/${offerId}/binding-draft`)
  }

  public async userInterestedInOffer(partyId: any, offerId: any): Promise<any> {
    return await this.httpClient.post(`/public/parties/${partyId}/offers/${offerId}/customer-interested`)
  }

  public async userNotInterestedInOffer(partyId: any, offerId: any): Promise<any> {
    return await this.httpClient.post(`/public/parties/${partyId}/offers/${offerId}/not-interested`)
  }

  public async getPagedCustomerOffers(partyId: any, size: any, page: any): Promise<IPaginateResult<IOffer>> {
    const states = [
      OfferState.INDICATIVE_TO_CUSTOMER,
      OfferState.BINDING_TO_CUSTOMER,
      OfferState.CUSTOMER_INTERESTED,
      OfferState.PURCHASE_CONFIG_IN_RETAILER_REVIEW,
      OfferState.PURCHASE_CONFIG_DRAFT,
      OfferState.PURCHASE_CONFIG_RETAILER_DECLINED,
    ]

    const params = {
      'state.in': states.join(', '),
      size,
      page,
    }
    const {data} = await this.httpClient.get<IPaginateResult<IOffer>>(`/public/parties/${partyId}/offers`, {
      params,
    })

    return data
  }

  public async createOffer(partyId: any, offer: IOffer): Promise<IOffer> {
    const {data} = await this.httpClient.post<IOffer>(`/public/parties/${partyId}/offers`, offer)

    return data
  }

  public async confirmUserPhoneNumber(code: string): Promise<void> {
    const {data} = await this.httpClient.post<void>(`/public/phone-verification/confirm?verification_code=${code}`)

    return data
  }

  public async sendUserPhoneVerification(phone: string): Promise<void> {
    const {data} = await this.httpClient.post<void>(`/public/phone-verification`, {phone})

    return data
  }

  public async sendContractVerificationCode(contractId: number): Promise<void> {
    const {data} = await this.httpClient.post<void>(`/public/contract-verification?contract=${contractId}`)

    return data
  }

  public async confirmContractVerificationCode(contractId: number, code: string): Promise<void> {
    const {data} = await this.httpClient.post<void>(
      `/public/contract-verification/confirm?contract=${contractId}&code=${code}`,
    )

    return data
  }

  public async updateOffer(partyId: any, offer: IOffer): Promise<IOffer> {
    const {data} = await this.httpClient.put<IOffer>(`/public/parties/${partyId}/offers`, offer)

    return data
  }

  public async getOfferTextGroups(partyId: any, offerId: any): Promise<IOfferTextGroup[]> {
    const {data} = await this.httpClient.get<IOfferTextGroup[]>(
      `/public/parties/${partyId}/offers/${offerId}/text-groups`,
    )

    return data
  }

  public async getManagedParties(asPartyId: any, partyRole: PartyRole = PartyRole.BUYER): Promise<IParty[]> {
    const params = {'partyRole.equals': partyRole, 'state.notEquals': 'DELETED'}

    const {data} = await this.httpClient.get<IParty[]>(`/public/parties/${asPartyId}/managed-parties`, {
      params,
    })

    return data.map(d => ({...d, partyRole}))
  }

  public async getPartyMembers(asPartyId: number): Promise<IPartyMember[]> {
    const {data} = await this.httpClient.get<IPartyMember[]>(`/public/parties/${asPartyId}/members`)

    return data
  }

  public async getUserFromInvite(accessCode: string): Promise<IUser> {
    const {data} = await this.httpClient.get(`/public/user-invites/${accessCode}`)

    return data as IUser
  }

  public async getUserFromOfferInvite(accessCode: string): Promise<IOfferInvite> {
    const {data} = await this.httpClient.get(`/public/offer-invites/${accessCode}`)

    return data
  }

  public async setPasswordFromInvite(accessCode: string, password: IPasswordFromInvite): Promise<void> {
    await this.httpClient.post(`/public/user-invites/${accessCode}/confirm`, password)
  }

  public async setPasswordFromOfferInvite(
    accessCode: string,
    user: {firstName: string; lastName: string; password: string},
  ): Promise<IOffer> {
    const {data} = await this.httpClient.post(`/public/offer-invites/${accessCode}/confirm`, {
      user,
    })

    return data.offer
  }

  public async resendUserInvite(asPartyId: number, partyMemberId: number): Promise<void> {
    await this.httpClient.post(`/public/parties/${asPartyId}/members/${partyMemberId}/resend-invite`)
  }

  public async getPartyMember(asPartyId: number, partyMemberId: number): Promise<IPartyMember> {
    const {data} = await this.httpClient.get(`/public/parties/${asPartyId}/members/${partyMemberId}`)

    return data
  }

  public async createPartyMemberUser(
    asPartyId: number,
    createPartyMember: IAdminCreatePartyMember,
  ): Promise<IPartyMember> {
    const {data} = await this.httpClient.post<IPartyMember>(`/public/parties/${asPartyId}/members`, createPartyMember)

    return data
  }

  public async updatePartyMember(asPartyId: number, partyMember: IPartyMemberUpdateRequest): Promise<IPartyMember> {
    const {data} = await this.httpClient.put<IPartyMember>(
      `/public/parties/${asPartyId}/members/${partyMember.id}`,
      partyMember,
    )

    return data
  }

  public async disablePartyMember(asPartyId: number, partyMemberId: number): Promise<IPartyMember> {
    const {data} = await this.httpClient.post<IPartyMember>(
      `/public/parties/${asPartyId}/members/${partyMemberId}/disable`,
    )

    return data
  }

  public async enablePartyMember(asPartyId: number, partyMemberId: number): Promise<IPartyMember> {
    const {data} = await this.httpClient.post<IPartyMember>(
      `/public/parties/${asPartyId}/members/${partyMemberId}/enable`,
    )

    return data
  }

  public async getManagedParty(asPartyId: any, partyId: any): Promise<IParty> {
    const {data} = await this.httpClient.get<IParty>(`/public/parties/${asPartyId}/managed-parties/${partyId}`)

    return data
  }

  public async createManagedParty(asPartyId: any, party: IParty): Promise<IParty> {
    const {data} = await this.httpClient.post<IParty>(`/public/parties/${asPartyId}/managed-parties`, party)

    return data
  }

  public async updateManagedParty(asPartyId: any, party: IParty): Promise<IParty> {
    const {data} = await this.httpClient.put<IParty>(`/public/parties/${asPartyId}/managed-parties`, party)

    return data
  }

  public async softDeleteManagedParty(asPartyId: any): Promise<IParty> {
    const {data} = await this.httpClient.delete<IParty>(`/public/parties/${asPartyId}/managed-parties`)

    return data
  }

  public async softDeleteManagedPartyOffer(partyId: any, offerId: any): Promise<IOffer> {
    const {data} = await this.httpClient.delete<IOffer>(`/public/parties/${partyId}/offers/${offerId}`)

    return data
  }

  public async uploadAttachment(file: File, name?: string): Promise<IAttachment> {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('name', name || file.name)

    const {data} = await this.httpClient.post<IAttachment>(`/public/attachments`, formData)

    return data
  }

  public getAttachmentURL(attachment: IAttachment): string {
    if (!attachment) {
      return ''
    }

    return `${this.httpClient.defaults.baseURL}/public/attachments/download/${attachment.id}-${attachment.fileName}`
  }

  public getSignedContractFileURL(file: ISignedContractFile): string {
    if (!file) {
      return ''
    }

    return `${this.httpClient.defaults.baseURL}/public/contracts/external/download/${file.id}`
  }

  public async loadMandatoryProducts(contractId: number, partyId: any): Promise<IProduct[]> {
    const {data} = await this.httpClient.get<IProduct[]>(
      `/public/parties/${partyId}/contract-types/${contractId}/mandatory-products`,
    )
    return data
  }

  public async createPurchaseContract(partyId: any, contract: IPurchaseContract): Promise<IPurchaseContract> {
    const {data} = await this.httpClient.post<IPurchaseContract>(
      `/public/parties/${partyId}/purchase-contracts`,
      contract,
    )

    return data
  }

  public async getPurchaseContracts(partyId: any): Promise<IPurchaseContract[]> {
    const {data} = await this.httpClient.get<IPurchaseContract[]>(`/public/parties/${partyId}/purchase-contracts`)

    return data
  }

  public async getSalesContracts(partyId: any): Promise<IPurchaseContract[]> {
    const {data} = await this.httpClient.get<IPurchaseContract[]>(`/public/parties/${partyId}/sales-contracts`)

    return data
  }

  public async updatePurchaseContract(partyId: any, contract: IPurchaseContract): Promise<IPurchaseContract> {
    const {data} = await this.httpClient.put<IPurchaseContract>(
      `/public/parties/${partyId}/purchase-contracts`,
      contract,
    )

    return data
  }

  public async getPartyPurchaseGroups(partyId: number): Promise<IPurchaseGroup[]> {
    const {data} = await this.httpClient.get<IPurchaseGroup[]>(`/public/parties/${partyId}/purchase-groups`)

    return data
  }

  public async getPurchaseGroup(partyId: number, purchaseGroupId: number): Promise<IPurchaseGroup> {
    const {data} = await this.httpClient.get<IPurchaseGroup>(
      `/public/parties/${partyId}/purchase-groups/${purchaseGroupId}`,
    )

    return data
  }

  public async getPartyOfferRequests(partyId: number): Promise<IOfferRequest[]> {
    const {data} = await this.httpClient.get<IOfferRequest[]>(`/public/parties/${partyId}/offer-requests`)

    return data
  }

  public async getContractSnapshot(contractId: number, partyId: any): Promise<IContract> {
    const {data} = await this.httpClient.get<IContractSnapshot>(
      `public/snapshot/party/${partyId}/contract/${contractId}`,
    )

    return data.contract
  }

  public async defaultCalculateOfferPrice(offer: IOffer, partyId?: any): Promise<IOfferPriceCalculationResult> {
    const {data} = await this.httpClient.post<IOfferPriceCalculationResult>(
      `/public/parties/${partyId ? partyId : offer?.managedByParty?.id}/offers/calculate-price`,
      offer,
    )

    return data
  }

  public async simpleCalculateOfferPrice(offer: IOffer, partyId?: any): Promise<IOfferPriceCalculationResult> {
    const {data} = await this.httpClient.post<IOfferPriceCalculationResult>(
      `/public/parties/${partyId ? partyId : offer?.managedByParty?.id}/offers/simple-price-calculation`,
      offer,
    )

    return data
  }

  public async uploadSignedContractFiles(contractId: number, files: File[]): Promise<void> {
    const formData = new FormData()
    files.forEach(file => {
      formData.append('files', file)
    })

    await this.httpClient.post<void>(`/public/contracts/external/upload/${contractId}`, formData)
  }

  public async deleteSignedContractFile(contractId: number, fileId: number): Promise<void> {
    await this.httpClient.delete(`/public/contracts/external/${contractId}/delete/${fileId}`)
  }

  public async getSignedContractFiles(contractId: number): Promise<ISignedContractFile[]> {
    const {data} = await this.httpClient.get<ISignedContractFile[]>(`/public/contracts/external/${contractId}`)

    return data
  }
}
