import Link from 'components/Link'
import IPurchaseContract from 'domain/IPurchaseContract'
import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import ITheme from 'theme/ITheme'
import IContract from '../../../domain/IContract'
import {getPartyLink} from '../../../helpers/party'
import useLocalization from '../../../hooks/useLocalization'
import useProfile from '../../../hooks/useProfile'
import useStore from '../../../hooks/useStore'
import Box from '../../Box'
import Modal from '../../Modal'
import ModalHeader from '../../Modal/ModalHeader'
import Text from '../../Text'
import ContractVerificationChallengeModal from './ContractVerificationChallengeModal'
import ContractModalForm from './ContractModalForm'
import {PartyRole} from '../../../domain/IParty'

const StyledLink = styled(Link)`
  background-color: ${props => (props.theme as ITheme).colors.primaryDark};
  font-size: 14px;
  border: 1px solid #004ad7;
  font-family: DMSans, sans-serif;
  font-weight: 300;
  background-color: #004ad7;
  color: #fff;
  text-align: center;
  line-height: 38px;
  width: 100%;
  padding: 10px 16px 10px 16px;
  border-radius: 4px;
`

interface IProps extends React.PropsWithChildren {
  contract: IContract | IPurchaseContract
  onClose: () => void
}

const ContractModal: React.FC<IProps> = ({contract, onClose}) => {
  const [confirming, setConfirming] = useState(false)
  const {party, user} = useProfile()
  const {translate} = useLocalization()
  const {contractStore, alertStore, profileStore} = useStore()
  const {partyRole} = profileStore.party
  const history = useHistory()
  const [showPhoneVerificationModal, setShowPhoneVerificationModal] = useState(false)
  const [verificationChallengeCompleted, setVerificationChallengeCompleted] = useState(false)
  const isBuyer = party?.partyRole === PartyRole.BUYER

  const signContract = async () => {
    try {
      await contractStore.signContract(contract.id)

      history.push(`/${getPartyLink(partyRole)}/portfolio/current-contracts/${contract.id}`)
      alertStore.addSuccess(translate('Congratulations! Your contract is now signed'))
    } catch (e) {
      onClose()
      console.error(e)
      alertStore.addError(translate('Unable to sign contract'), e?.correlationId, e?.message)
    }
  }

  const handleVerificationSuccess = async () => {
    await signContract()
    setVerificationChallengeCompleted(true)
  }

  const onSubmit = () => {
    setConfirming(true)
    setVerificationChallengeCompleted(false)
    setShowPhoneVerificationModal(true)
  }

  if (confirming) {
    if (!user.phoneNumberVerified && showPhoneVerificationModal) {
      return (
        <Modal onClose={onClose}>
          <ModalHeader title={translate('Verify your phone number')} />
          <Box style={{minWidth: '500px'}}>
            <Text margin={{top: 2, bottom: 4}} size="xxlarge" align="center">
              To sign the contract, you need to <br></br> verify your phone number from Personal Profile page.
            </Text>
            <Box justify="center">
              <StyledLink to={`/${getPartyLink(party?.partyRole)}/company/personal-profile`}>
                Go to Personal Profile
              </StyledLink>
            </Box>
          </Box>
        </Modal>
      )
    }

    if (!verificationChallengeCompleted) {
      return (
        <ContractVerificationChallengeModal
          //TODO: remove this once the feature is considered stable
          onClose={() => {
            setVerificationChallengeCompleted(true)
            //skip the password verification as well
            setConfirming(false)
          }}
          contract={contract}
          onSuccess={() => handleVerificationSuccess()}
        ></ContractVerificationChallengeModal>
      )
    }
  }

  return (
    <Modal full onClose={onClose}>
      <ContractModalForm contract={contract} onClose={onClose} onSubmit={onSubmit} signable={isBuyer} />
    </Modal>
  )
}

export default ContractModal
