import React, {ComponentType} from 'react'
import {OptionProps} from 'react-select'
import {SecondaryButton} from '../Button'
import ModalCloser from '../Modal/ModalCloser'
import FormSubmitButton from '../Form/FormSubmitButton'
import {OptionTypeBase, StylesConfig} from 'react-select'
import styled from 'styled-components'

export interface IComponentOption extends OptionTypeBase {
  label: string
  value: string
  cancel?: boolean
  submit?: boolean
  color?: string
  styles?: StylesConfig<OptionTypeBase, boolean>
}

const StyledButton = styled(SecondaryButton)`
  width: 100%;
  margin-bottom: 10px;
`

const ComponentOption: ComponentType<OptionProps<IComponentOption, boolean>> = ({...props}) => {
  const {cancel, submit, label} = props.data

  if (cancel) {
    return (
      <ModalCloser>
        <StyledButton type="button">{label}</StyledButton>
      </ModalCloser>
    )
  }

  if (submit) {
    return <FormSubmitButton>{label}</FormSubmitButton>
  }
}

export default ComponentOption
