import React from 'react'
import CustomerEditPage from 'components/Platform/Portfolio/Customer/CustomerEditPage'
import PartyResolver from 'components/Platform/Resolver/PartyResolver'
import ResourceGateway from 'components/ResourceGateway'
import CustomerListPage from 'components/Platform/Portfolio/Customer/CustomerListPage'

const Customer = () => {
  return (
    <ResourceGateway
      create={() => <CustomerEditPage />}
      single={routeProps => <PartyResolver component={CustomerEditPage} id={routeProps.match.params.id} />}
      list={() => <CustomerListPage />}
    />
  )
}

export default Customer
