import React, {useContext, useEffect, useState} from 'react'
import Loader from '../../../Loader'
import Form from '../../../Form'
import ModalFormHeader from '../../../Modal/ModalFormHeader'
import OfferPriceParameterFormContent from './OfferPriceParameterFormContent'
import {RouterModalContext} from '../../../Modal/RouterModal'
import useLocalization from '../../../../hooks/useLocalization'
import useServices from '../../../../hooks/useServices'
import IOfferPriceParameterGroup from '../../../../domain/IOfferPriceParameterGroup'
import useStore from '../../../../hooks/useStore'
import Error from '../../../Error'

interface IProps extends React.PropsWithChildren {
  onCreate?: (parameterGroup: IOfferPriceParameterGroup) => void
  name: string
}

enum State {
  IDLE,
  PENDING,
  ERROR,
  SUCCESS,
}

const OfferPriceParameterForm: React.FC<IProps> = ({onCreate, name}) => {
  const {translate} = useLocalization()
  const {offerPriceParameterService} = useServices()
  const {alertStore, offerDesignerStore} = useStore()
  const [parameterGroup, setParameterGroup] = useState(null)
  const [state, setState] = useState(State.IDLE)
  const {value: groupId, handleClose} = useContext(RouterModalContext)

  useEffect(() => {
    setState(State.PENDING)
    ;(groupId
      ? offerPriceParameterService.getParameterGroup(groupId)
      : offerPriceParameterService.getDefaultParameterGroup()
    )
      .then(value => {
        setParameterGroup(value)
        setState(State.SUCCESS)
      })
      .catch(error => {
        setState(State.ERROR)
      })
  }, [groupId, offerPriceParameterService])

  const handleSubmit = async (values: IOfferPriceParameterGroup) => {
    try {
      const result = await offerPriceParameterService.createParameterGroup({
        ...values,
        name,
      })

      onCreate && onCreate(result)
      offerDesignerStore.changeToDirty()
      handleClose()
    } catch (e) {
      console.error(e)
      alertStore.addError(translate('Failed to save parameters'), e?.correlationId, e?.message)
    }
  }

  return (
    <>
      {state === State.ERROR && <Error title={translate('Error')}>{translate('Failed to retrieve parameters')}</Error>}
      {!parameterGroup && state === State.PENDING && <Loader />}
      {parameterGroup && (
        <Form onSubmit={handleSubmit} defaultValues={parameterGroup} submissionFeedback={null}>
          <ModalFormHeader
            title={translate('Pricing parameters')}
            description={translate('Configure quarterly pricing parameters')}
          />
          <OfferPriceParameterFormContent />
        </Form>
      )}
    </>
  )
}

export default OfferPriceParameterForm
