import React from 'react'
import Box from '../Box'
import Text from '../Text'
import useLocalization from '../../hooks/useLocalization'
import useTheme from '../../hooks/useTheme'
import ReactMarkdown from 'react-markdown'

const LegalDisclaimer: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const theme = useTheme()

  return (
    <Text color={theme.colors.blue7}>
      <Box margin={{bottom: 1}}>{translate('Legal disclaimer')}</Box>

      <Text size="msmall">
        <ReactMarkdown>{translate('__LEGAL_DISCLAIMER__')}</ReactMarkdown>
      </Text>
    </Text>
  )
}

export default LegalDisclaimer
