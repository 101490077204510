import React from 'react'
import Box, {IProps as IBoxProps} from '../Box'
import SelectTimescale, {IProps as ISelectorProps} from './SelectTimescale'
import Legend, {IProps as ILegendProps} from './Legend'

export interface IProps extends React.PropsWithChildren, ILegendProps, IBoxProps {
  vertical?: boolean
  timescale?: boolean | ISelectorProps
}

const Toolbar: React.FC<IProps> = ({lines, vertical, timescale, selectedLegend, onClick, ...rest}) => {
  const boxProps: IBoxProps = vertical
    ? {
        direction: 'column',
        gap: 2,
      }
    : {
        gap: 3,
      }

  return (
    <Box pad={{right: 0.1}} style={{minWidth: 120}} {...boxProps} {...rest}>
      {!!timescale && <SelectTimescale {...(typeof timescale === 'object' ? timescale : {})} />}
      <Legend vertical={vertical} lines={lines} selectedLegend={selectedLegend} />
    </Box>
  )
}

export default Toolbar
