import React from 'react'
import useTheme from '../../hooks/useTheme'
import Box from '../Box'
import Text, {ISize} from '../Text'
import {isFiniteNumber} from 'helpers/misc'

interface IProps extends React.PropsWithChildren {
  title: string
  color?: string
  value?: number
  valueParser?: any
  fallbackValueText?: string
  icon?: any
  formatFn?: (num: number) => string
  unit?: string
  unitSize?: ISize
  small?: boolean
  smallValue?: boolean
  semiBoldValue?: boolean
  highlighted?: boolean
  autoHide?: boolean
  currency?: any
}

const InverseKeyFeature: React.FC<IProps> = ({
  title,
  color,
  value,
  valueParser,
  fallbackValueText,
  unit,
  unitSize,
  icon,
  small,
  formatFn = v => v,
  smallValue,
  semiBoldValue = true,
  highlighted,
  autoHide,
  currency,
  children,
}) => {
  const theme = useTheme()
  const isValueValidNum = isFiniteNumber(value)

  if (autoHide && !value) {
    return null
  }

  return (
    <Box pad={1} margin={{horizontal: -1}} round color={highlighted ? theme.colors.light3 : null}>
      <Box align="center">
        {icon && <Box margin={{right: 1}}>{icon}</Box>}
        {isValueValidNum && currency && (
          <Text color={color} size={smallValue || small ? 'medium' : 'xlarge'} semibold={semiBoldValue}>
            {currency}
          </Text>
        )}
        {isValueValidNum ? (
          <Text
            color={color}
            size={smallValue || small ? 'medium' : 'xlarge'}
            semibold={semiBoldValue}
            font={theme.font.numbers}
            pad={{top: 0.5, left: 0.5}}
          >
            {formatFn(valueParser ? valueParser(value) : value)}
          </Text>
        ) : (
          <Box grow={true} justify="center">
            {fallbackValueText && (
              <Text color={color} size={smallValue || small ? 'medium' : 'xlarge'} semibold={semiBoldValue}>
                {fallbackValueText}
              </Text>
            )}
          </Box>
        )}
        {unit && (
          <Text
            color={color}
            size={unitSize ? unitSize : small ? 'small' : 'medium'}
            margin={{left: 1}}
            semibold={!small}
          >
            {unit}
          </Text>
        )}
      </Box>
      {children}
      <Text size="msmall" uppercase color={'#7e95ac'} semibold>
        {title}
      </Text>
    </Box>
  )
}

export default InverseKeyFeature
