import ILocation from './ILocation'
import IAttachment from './IAttachment'
import IPartyMember from './IPartyMember'
import IOffer from './IOffer'
import ISite from './ISite'

export enum PartyType {
  ORGANIZATION = 'ORGANIZATION',
}

export enum PartyRole {
  BUYER = 'BUYER',
  //TODO: rename to Seller
  BROKER = 'BROKER',
  //TODO: remove these below and use only BUYER and BROKER(which should be renamed to seller)
  PRODUCER = 'PRODUCER',
  BALANCING_PARTY = 'BALANCING_PARTY',
  INSURANCE_PROVIDER = 'INSURANCE_PROVIDER',
}

export enum PartyState {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export enum OnboardingStatus {
  NOT_STARTED = 'NOT_STARTED',
  SELLER_STARTED = 'SELLER_STARTED',
  SELLER_SITE_CREATED = 'SELLER_SITE_CREATED',
  SELLER_PRODUCT_CREATED = 'SELLER_PRODUCT_CREATED',
  BUYER_STARTED = 'BUYER_STARTED',
  BUYER_SITE_CREATED = 'BUYER_SITE_CREATED',
  BUYER_PROFILE_UPLOADED = 'BUYER_PROFILE_UPLOADED',
  COMPLETED = 'COMPLETED',
  SELLER_SKIPPED = 'SELLER_SKIPPED',
}

export const progress = (status: OnboardingStatus) => {
  switch (status) {
    case OnboardingStatus.NOT_STARTED:
    case OnboardingStatus.SELLER_SKIPPED:
      return 0
    case OnboardingStatus.SELLER_STARTED:
    case OnboardingStatus.BUYER_STARTED:
      return 1
    case OnboardingStatus.SELLER_SITE_CREATED:
    case OnboardingStatus.BUYER_SITE_CREATED:
      return 2
    case OnboardingStatus.SELLER_PRODUCT_CREATED:
    case OnboardingStatus.BUYER_PROFILE_UPLOADED:
      return 3
    case OnboardingStatus.COMPLETED:
      return 4
  }
}

export enum PriceCalculationMethod {
  SIMPLE = 'SIMPLE',
  DEFAULT = 'DEFAULT',
}

export interface IRepresentative {
  firstName: string
  lastName: string
  email: string
}

export default interface IParty {
  id: number
  partyRole: PartyRole
  name: string
  description: string
  registrationCode: string
  contactPerson?: string
  contactEmail?: string
  vatCode: string
  totalAvgYearlyConsumptionMwh?: number
  meteringPointCount?: number
  location?: ILocation
  image: IAttachment
  partyMembers?: IPartyMember[]
  offers?: IOffer[]
  sites?: ISite[]
  meta?: {
    pricingParameterGroupId?: number
    walletId?: string
  }
  state?: PartyState
  priceCalculationMethod?: PriceCalculationMethod
  representative?: IRepresentative
  onboardingStatus: OnboardingStatus
  timeseriesId: string
}
