import React from 'react'
import EnergySummaryItem from '../../../components/Platform/Portfolio/Summary/EnergySummaryItem'
import ResourceSummary from '../../../components/Platform/Portfolio/Summary/ResourceSummary'
import useLocalization from '../../../hooks/useLocalization'
import useTheme from '../../../hooks/useTheme'
import Table from '../../../components/Table'
import {DateFormat, formatDate} from '../../../helpers/date'
import TextLink from '../../../components/TextLink'
import OverviewSkeleton from 'components/Platform/Chart/OverviewSkeleton'
import {useAtom} from 'jotai'
import {contractedVolumesAtom, contractedVolumesDateFilterAtom} from 'atoms/portfolioOverview'
import {IContractedVolumeContract} from 'domain/Portfolio'
import ResourceSummaryItem from 'components/Platform/Portfolio/Summary/ResourceSummaryItem'
import ContractedVolumesChart from 'components/Platform/Portfolio/Overview/ContractedVolumesChart'
import Box from 'components/Box'
import Heading from 'components/Heading'
import Select from 'components/Select'
import {getChangeEventValue} from 'helpers/misc'
import moment from 'moment'
import {isEmpty} from 'lodash'

interface IContractedVolumeContractWithExternal extends IContractedVolumeContract {
  external?: boolean
}

const ContractedVolume: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const [dateFilter, setDateFilter] = useAtom(contractedVolumesDateFilterAtom)
  const [{data: contractedVolumes, loading, error}] = useAtom(contractedVolumesAtom)
  const theme = useTheme()
  const {
    contracts = [],
    externalContracts,
    summary,
    contractsTimeseries = [],
    consumption,
    externalContractsTimeseries,
  } = contractedVolumes || {}

  const extContractsWithFlag = externalContracts?.map(contract => ({...contract, external: true})) || []
  const allContracts = [...contracts, ...extContractsWithFlag]

  const extTimeseriesWithFlag = externalContractsTimeseries?.map(item => ({...item, external: true})) || []
  const allTimeseries = [...contractsTimeseries, ...extTimeseriesWithFlag]

  const years = Array.from({length: 5}, (v, k) => moment().year() - k)
  const months = moment.months()
  const noData = isEmpty(allContracts)

  const handleDateChange = (event, key) => {
    const value = getChangeEventValue(event)

    setDateFilter({...dateFilter, [key]: value})
  }

  if (loading) {
    return <OverviewSkeleton></OverviewSkeleton>
  }

  if (error) {
    return (
      <Box justify="center">
        <Heading margin={{top: 4}}>{translate('Failed to load contracted volumes chart')}</Heading>
      </Box>
    )
  }

  return (
    <>
      <ResourceSummary>
        <EnergySummaryItem
          title={translate('Consumption in selected period')}
          value={summary.consumptionMwh}
          color={theme.colors.blue2}
          showFilledProgress
        />
        <EnergySummaryItem
          title={translate('Direct purchase volume')}
          value={summary.electricityMwh}
          color={theme.colors.yellow1}
          showFilledProgress
        />
        <ResourceSummaryItem
          title={translate('EAC quantity')}
          value={summary.certificatesQuantity}
          unit={translate('EACs')}
          color={theme.colors.green1}
          showFilledProgress
        />
      </ResourceSummary>
      <Box direction="row" gap={2}>
        <Select
          value={dateFilter.month}
          label="Month"
          options={months.map(month => ({value: month, label: month}))}
          onChange={e => handleDateChange(e, 'month')}
        />
        <Select
          value={dateFilter.year}
          label="Year"
          options={years.map(year => ({value: year, label: `${year}`}))}
          onChange={e => handleDateChange(e, 'year')}
        />
      </Box>
      {noData ? (
        <Box justify="center">
          <Heading margin={{top: 4}}>{translate('No data for this interval')}</Heading>
        </Box>
      ) : (
        <>
          <ContractedVolumesChart consumption={consumption} contractsTimeseries={allTimeseries} />

          <Table
            data={allContracts}
            isLoading={loading}
            columns={[
              {
                title: translate('Contract'),
                accessor: (item: IContractedVolumeContractWithExternal) => item,
                render: (item: IContractedVolumeContractWithExternal) =>
                  item.external ? (
                    <TextLink to={`/consumer/portfolio/external-contracts/${item.id}`}>EXTERNAL-#{item.id}</TextLink>
                  ) : (
                    <TextLink to={`/consumer/portfolio/current-contracts/${item.id}`}>#{item.id}</TextLink>
                  ),
              },
              {
                title: translate('Start'),
                accessor: (item: IContractedVolumeContractWithExternal) =>
                  `${item.validFrom ? formatDate(item.validFrom, DateFormat.DAY_MONTH_YEAR) : ''}`,
              },
              {
                title: translate('End'),
                accessor: (item: IContractedVolumeContractWithExternal) =>
                  `${item.validTo ? formatDate(item.validTo, DateFormat.DAY_MONTH_YEAR) : ''}`,
              },
              {
                title: translate('Renewable energy volume'),
                accessor: (item: IContractedVolumeContractWithExternal) => {
                  return `${item.volumeMwh} ${translate('EACs')}`
                },
              },
            ]}
          />
        </>
      )}
    </>
  )
}

export default ContractedVolume
