import React from 'react'
import useLocalization from '../../../../hooks/useLocalization'
import Page from '../../../Page'
import Button from '../../../Button'
import SiteMap from '../../Map/SiteMap'
import InnerScroll from '../../../InnerScroll'
import Link from '../../../Link'
import Box from '../../../Box'
import {useRouteMatch} from 'react-router'
import {SiteType} from '../../../../domain/ISite'
import IParty, {PartyRole} from '../../../../domain/IParty'
import {useAtomValue, useAtom} from 'jotai'
import {partyAtom} from 'atoms/party'
import {dashboardAtom, pageAtom, pageSizeAtom, passedPartyAtom, sitesAtom} from 'atoms/portfolioSites'
import {PaginateWithSelection} from 'components/Paginate/Paginate'
import SiteListNew from './SiteListNew'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import {useLocation} from 'react-router-dom'
import DashboardChart from './DashboardChart'
import DashboardLoadingSkeleton from './DashboardLoadingSkeleton'
import ErrorInfo from 'components/ErrorInfo'

const RESULT_PER_PAGE_OPTIONS = [25, 50, 100]
const TAB_SLUG = 'sites'
const LIST_TAB_SLUG = 'list'

const SiteListPage: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const currentParty = useAtomValue(partyAtom)
  const passedParty = useAtomValue(passedPartyAtom)
  const party = passedParty || currentParty
  const [page, setPage] = useAtom(pageAtom)
  const [pageSize, setPageSize] = useAtom(pageSizeAtom)
  const {data: sitesResult, loading: sitesLoading, error: sitesError} = useAtomValue(sitesAtom)
  const {data: dashboardResult, loading: dashboardLoading, error: dashboardError} = useAtomValue(dashboardAtom)
  const siteType = party?.partyRole === PartyRole.BUYER ? SiteType.CONSUMPTION : SiteType.PRODUCTION
  const {url: urlPrefix} = useRouteMatch()
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  const tabSlug = urlSearchParams.get(TAB_SLUG)
  const isListTab = tabSlug === LIST_TAB_SLUG
  const error = sitesError || dashboardError

  return (
    <Page
      title={siteType === SiteType.CONSUMPTION ? translate('Consumption') : translate('Sites')}
      description={translate('Manage your sites')}
      corner={
        <Box gap={1}>
          <Link to={`${urlPrefix}/add`}>
            <Button>{translate('Add new site')}</Button>
          </Link>
        </Box>
      }
      aside={
        isListTab && (
          <SiteMap
            title={siteType === SiteType.CONSUMPTION ? translate('Consumption') : translate('Sites')}
            description={translate('Overview of your sites locations')}
            sites={sitesResult?.data || []}
            urlPrefix={urlPrefix}
          />
        )
      }
      error={error && <ErrorInfo error={error} title={translate('Failed to load dashboard')} />}
    >
      <InnerScroll noRightPad>
        <Box margin={{top: -2}}>
          <Tabs name={TAB_SLUG}>
            <Tab label={translate('Dashboard')} slug="">
              <Box pad={{top: 1}}>
                {dashboardLoading ? <DashboardLoadingSkeleton /> : <DashboardChart timeseries={dashboardResult} />}
              </Box>
            </Tab>
            <Tab label={translate('Sites list')} slug={LIST_TAB_SLUG}>
              <SiteListNew sites={sitesResult?.data} siteType={siteType} isLoading={sitesLoading} />
            </Tab>
          </Tabs>
        </Box>
      </InnerScroll>
      {isListTab && (
        <Box align="flex-end" justify="center">
          <PaginateWithSelection
            activePage={page}
            resultsPerPage={pageSize}
            resultsPerPageOptions={RESULT_PER_PAGE_OPTIONS}
            totalPages={sitesResult?.totalPages || 1}
            handlePageChange={value => setPage(value)}
            handleResultsPerPageChange={value => {
              setPageSize(value)
              setPage(1)
            }}
          />
        </Box>
      )}
    </Page>
  )
}

export default SiteListPage
