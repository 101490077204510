import IConfig from '../config/IConfig'
import IConfigurationService from './IConfigurationService'

export default class RuntimeConfigurationService implements IConfigurationService {
  getConfiguration(): IConfig {
    return (window as any).__CONFIG__ || {}
  }

  setConfiguration(config: IConfig) {
    throw new Error('Could not override runtime configuration')
  }
}
