import React from 'react'
import GenericLayout, {Spacer} from '../../Layout'
import TopNavigation, {TopNavigationLink} from '../../Layout/TopNavigation'
import UserBadge from './UserBadge'
import AsideNavigation, {AsideNavigationLink} from './AsideNavigation'
import IRoute from '../../../interfaces/IRoute'
import PartySelect from './PartySelect'
import Box from 'components/Box'
import UserMenu from './UserMenu'

interface IProps extends React.PropsWithChildren {
  topNavigation: IRoute[]
  asideNavigation: IRoute[]
}

const Layout: React.FC<IProps> = ({topNavigation, asideNavigation, children}) => {
  return (
    <GenericLayout
      top={
        <TopNavigation>
          {topNavigation.map(item => (
            <TopNavigationLink key={item.path} to={item.path}>
              {item.title}
            </TopNavigationLink>
          ))}
          <Spacer />
          <PartySelect />
          <UserMenu />
        </TopNavigation>
      }
      aside={
        <>
          <Box pad={{top: 4}}>
            <UserBadge />
          </Box>
          <AsideNavigation>
            {asideNavigation.map(item => (
              <AsideNavigationLink key={item.path} to={item.path}>
                {item.title}
              </AsideNavigationLink>
            ))}
          </AsideNavigation>
        </>
      }
    >
      {children}
    </GenericLayout>
  )
}

export default Layout
