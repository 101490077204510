import React, {useEffect, useMemo, useRef, useState} from 'react'
import useStore from '../../../../hooks/useStore'
import useLocalization from '../../../../hooks/useLocalization'
import {useAtomValue, useSetAtom} from 'jotai'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import Page from 'components/Page'
import Form from 'components/Form'
import Box from 'components/Box'
import {partyAtom} from 'atoms/party'
import InnerScroll from 'components/InnerScroll'
import {useLocation} from 'react-router-dom'
import Button from 'components/Button'
import {buyerSiteAtom, refetchSitesAtom, siteIdAtom} from 'atoms/portfolioSites'
import SiteMap from 'components/Platform/Map/SiteMap'
import {TimeseriesKind} from 'domain/ISite'
import BuyerGeneral from './Form/BuyerGeneral'
import CenteredLoader from 'components/Platform/ProductDetails/ReservationBox/components/CenteredLoader'
import {rootServiceAtom} from 'atoms/general'
import {omit} from 'lodash'
import BuyerConsumption from './Form/BuyerConsumption'
import {MeterRefKind, ProfileUploadKind} from 'services/V2ApiErrorMapper'

const TAB_SLUG = 'site'
const CONSUMPTION_PROFILE_TAB = 'consumption'

export interface IProps extends React.PropsWithChildren {
  siteId?: number
}

const BuyerSiteEditPage: React.FC<IProps> = ({siteId}) => {
  const {translate} = useLocalization()
  const [uploadType, setUploadType] = useState(TimeseriesKind.BUILDER)
  const [siteLocation, setSiteLocation] = useState(null)
  const [saveLoading, setSaveLoading] = useState(false)
  const generalFormRef = useRef<HTMLFormElement>(null)
  const consumptionFormRef = useRef<HTMLFormElement>(null)
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  const tabSlug = urlSearchParams.get(TAB_SLUG)
  const {alertStore} = useStore()
  const party = useAtomValue(partyAtom)
  const rootService = useAtomValue(rootServiceAtom)
  const {data: site, loading, error: error} = useAtomValue(buyerSiteAtom)
  const refetchSites = useSetAtom(refetchSitesAtom)
  const setSiteId = useSetAtom(siteIdAtom)
  const consumptionTab = tabSlug === CONSUMPTION_PROFILE_TAB
  const mapLocation = siteLocation || site?.location
  const mapSites = useMemo(() => (site ? [{...site, location: mapLocation}] : []), [site, mapLocation])

  useEffect(() => {
    if (siteId) {
      setSiteId(siteId)
    }
  }, [siteId])

  const handleSave = () => {
    if (consumptionTab) {
      consumptionFormRef.current.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}))
    } else {
      generalFormRef.current.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}))
    }
  }

  const handleGeneralSubmit = async (data, {setError}) => {
    const {location, ...rest} = data
    try {
      setSaveLoading(true)
      await rootService.v2ContentService.updateBuyerSiteGeneral(party.id, site.id, {
        ...rest,
        placeId: mapLocation.placeId,
      })
      refetchSites()
      alertStore.addSuccess(translate('Successfully updated %s', data?.name))
    } catch (e) {
      const kind = e?.error?.kind
      if (kind in MeterRefKind) {
        setError('meterReference', 'meterReference', e.message)
        alertStore.addError(e.message)
      } else {
        alertStore.addError(translate('Failed to save marketplace listing'), e?.correlationId, e?.message)
      }
    } finally {
      setSaveLoading(false)
    }
  }

  const handleUploadSubmit = async (data, {setError}) => {
    const {file, interval, builder} = data

    try {
      setSaveLoading(true)
      await rootService.v2ContentService.uploadBuyerSiteConsumption(party.id, site.id, {
        interval,
        timeseries: {
          kind: uploadType,
          payload: {
            hourlyVolumes: uploadType === TimeseriesKind.BUILDER ? builder.hourlyVolumes : undefined,
            attachmentId: uploadType === TimeseriesKind.FILE_UPLOAD ? file.id : undefined,
          },
        },
      })
      alertStore.addSuccess(translate('Successfully uploaded consumption profile'))
    } catch (e) {
      const kind = e?.error?.kind
      if (kind in ProfileUploadKind) {
        setError('file', 'file', e.message)
        alertStore.addError(e.message)
      } else {
        alertStore.addError(translate('Failed to save marketplace listing'), e?.correlationId, e?.message)
      }
    } finally {
      setSaveLoading(false)
    }
  }

  if (loading) {
    return <CenteredLoader />
  }

  return (
    <Page
      title={translate('Edit site')}
      description={translate('Manage information about the site')}
      corner={<Button onClick={handleSave}>{saveLoading ? translate('Saving...') : translate('Save')}</Button>}
      aside={<SiteMap title={translate('Site address')} sites={mapSites} />}
    >
      <InnerScroll noRightPad>
        <Box margin={{top: -2}}>
          <Tabs name={TAB_SLUG}>
            <Tab label={translate('General')} slug="">
              <Form
                onSubmit={handleGeneralSubmit}
                ref={generalFormRef}
                fillHeight
                defaultValues={{...omit(site, 'location'), locationDetails: site?.location?.details}}
                submissionFeedback={null}
              >
                <BuyerGeneral location={siteLocation || site?.location} setLocation={setSiteLocation} />
              </Form>
            </Tab>
            <Tab label={translate('Upload consumption')} slug={CONSUMPTION_PROFILE_TAB}>
              <Form ref={consumptionFormRef} onSubmit={handleUploadSubmit} fillHeight submissionFeedback={null}>
                <BuyerConsumption uploadType={uploadType} setUploadType={setUploadType} />
              </Form>
            </Tab>
          </Tabs>
        </Box>
      </InnerScroll>
    </Page>
  )
}

export default BuyerSiteEditPage
