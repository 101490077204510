import React from 'react'
import useResource from '../../../../hooks/useResource'
import Box from '../../../Box'
import useTheme from '../../../../hooks/useTheme'
import useLocalization from '../../../../hooks/useLocalization'
import Text from '../../../Text'
import {ResourceId} from '../../../../types'
import AutocompleteSelect from '../../../AutocompleteSelect'

interface IProps extends React.PropsWithChildren {
  excludedIds?: ResourceId[]
  onAdd: (productId: ResourceId) => void
}

const ProductAdder: React.FC<IProps> = ({excludedIds = [], onAdd}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const products = useResource(
    store => store.productStore.managedProductsOnSale,
    store => store.productStore.loadManagedProducts(),
  )

  return (
    <Box
      round
      color={theme.colors.light3}
      align="center"
      justify="space-between"
      pad={{vertical: 3, horizontal: 2}}
      margin={{vertical: 4}}
      data-c="ProductAdder"
      overflow="visible"
    >
      <Text semibold>{translate('Add products to offer')}</Text>

      <Box justify="space-between" gap={4}>
        <Box width={40} overflow="visible">
          <AutocompleteSelect
            margin={0}
            styles={{
              container: provided => ({
                ...provided,
                margin: 0,
              }),
            }}
            color={theme.colors.common.white}
            options={[
              ...(products || [])
                .filter(product => !excludedIds.includes(product.id))
                .map(product => ({value: product.id, label: product.name})),
            ]}
            onChange={value => {
              onAdd(value)
            }}
            suggestAt={0}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ProductAdder
