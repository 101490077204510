import React from 'react'
import {IProps as IAutocompleteProps} from '../../../Form/FormAutocompleteSelect'
import useResource from '../../../../hooks/useResource'
import IParty from '../../../../domain/IParty'
import useLocalization from '../../../../hooks/useLocalization'
import FormAutocompleteSelect from '../../../Form/FormAutocompleteSelect'
import TextLink from '../../../TextLink'

type IProps = IAutocompleteProps

const PartySelect: React.FC<IProps> = props => {
  const parties = useResource<IParty[]>(
    store => store.partyStore.items,
    store => store.partyStore.loadManagedParties(),
  )
  const {translate} = useLocalization()

  return (
    <FormAutocompleteSelect
      noOptionsMessage={({inputValue}) => (
        <div>
          {translate('No customer found.')}{' '}
          <TextLink to={`/broker/portfolio/customers/add?name=${inputValue}`}>{translate('Create')}</TextLink>
        </div>
      )}
      {...props}
      options={
        parties.length > 0
          ? parties.map(party => ({
              value: party.id,
              label: party.name,
            }))
          : []
      }
    />
  )
}

export default PartySelect
