import React, {ReactElement, useEffect} from 'react'
import styled from 'styled-components'
import ITheme from '../theme/ITheme'
import {useHistory, useLocation} from 'react-router'
import {ISpacingProps} from './styleProps/spacing'
import {ReactSelect} from './MultiSelect'
import isEmpty from 'lodash/isEmpty'
import {components} from 'react-select'
import useLocalization from '../hooks/useLocalization'
import filterIcon from '../assets/icons/filter.svg'
import {capitalize} from 'lodash'

interface IProps extends ISpacingProps {
  label?: string
  title?: string
  choices: IChoice[]
  selected?: IChoice | IChoice[]
  multiselect: boolean
  customLabel?: string
  transparent?: boolean
  name?: string
  children?: (choice: IChoice | IChoice[]) => any
  technology?: boolean
  colored?: boolean
  choiceMaxWidth?: string
  suffixComponent?: ReactElement
  filterListTargetId?: string
  setActiveChoice?: (choice: IChoice | IChoice[]) => any
}

export interface IChoice {
  slug: string
  name: string
}

const StyledSelect = styled(ReactSelect)`
  padding-left: 10px;
  width: ${props => (props.choiceMaxWidth ? props.choiceMaxWidth : '120px')};
  z-index: 1001;
  margin-bottom: 0;
  color: #222222;
  .wp {
    &__control {
      border: none;
      box-shadow: none;
      &:hover {
        cursor: pointer;
        color: inherit;
        border: none;
      }
      &--menu-is-open {
        border: none;
      }
    }

    &__indicators {
      display: none;
    }

    &__input {
      caret-color: transparent;
    }

    &__input input {
      font-size: 12px;
      font-weight: 300;
    }

    &__menu {
      min-width: 80px;
    }
  }
`

const StyledOption = styled.div`
  font-size: 10px;
  color: ${props => (props.theme as ITheme).colors.secondary};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  input {
    margin-right: 10px;
  }
`

const StyledPlaceholder = styled.div`
  display: flex;
  align-items: center;
  color: #222222;
  img {
    margin-left: 10px;
  }
`

const SelectFilter = ({
  label,
  title,
  choices,
  selected,
  customLabel,
  transparent,
  name,
  multiselect,
  children,
  technology,
  colored,
  choiceMaxWidth,
  suffixComponent,
  filterListTargetId,
  setActiveChoice,
  ...props
}: IProps) => {
  const {translate} = useLocalization()
  const location = useLocation()
  const history = useHistory()
  const urlSearchParams = new URLSearchParams(location.search)
  const activeSlug: string | string[] = multiselect
    ? urlSearchParams.getAll(name) || []
    : urlSearchParams.get(name) || ''

  let selectedFilter: IChoice[] = null

  useEffect(() => {
    if (selectedFilter && setActiveChoice) {
      setActiveChoice(selectedFilter as IChoice[])
    }
  }, [activeSlug, setActiveChoice, selectedFilter])

  if (multiselect) {
    selectedFilter = activeSlug && choices?.filter(p => activeSlug.includes(p.slug))
  }

  const handleOnChange = items => {
    const urlSearchParams = new URLSearchParams(location.search)

    if (!isEmpty(selectedFilter)) {
      urlSearchParams.delete(name)
      items.forEach(item => {
        urlSearchParams.append(name, item.value)
      })
    } else {
      urlSearchParams.set(name, items[0].value)
    }

    history.push(`?${urlSearchParams.toString()}`)
  }

  const displayName = () => {
    if (!selectedFilter.length) {
      return translate(`Any ${name}`)
    }

    const capitalizedName = capitalize(name)

    if (selectedFilter.length === 1) {
      return `${selectedFilter.length} ${translate(capitalizedName).toLowerCase()}`
    }

    const nameEndsWithS = name.endsWith('s')

    return `${selectedFilter.length} ${translate(capitalizedName + (nameEndsWithS ? 'es' : 's')).toLowerCase()}`
  }

  const Option = props => {
    return (
      <>
        <components.Option {...props}>
          <StyledOption>
            <input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
          </StyledOption>
        </components.Option>{' '}
      </>
    )
  }

  return (
    <>
      <StyledSelect
        classNamePrefix="wp"
        components={{Option}}
        isMulti
        closeMenuOnSelect={false}
        value={selectedFilter?.map(x => {
          return {value: x.slug, label: x.name}
        })}
        onChange={handleOnChange}
        hideSelectedOptions={false}
        options={choices?.map(x => {
          return {value: x.slug, label: x.name}
        })}
        controlShouldRenderValue={false}
        placeholder={
          <StyledPlaceholder>
            {displayName()} <img src={filterIcon} alt="filter" width={14} height={14} />
          </StyledPlaceholder>
        }
        noOptionsMessage={() => translate('No options')}
        choiceMaxWidth={choiceMaxWidth}
        {...props}
      />
    </>
  )
}

SelectFilter.defaultProps = {
  name: 'filter',
  multiselect: true,
  colored: false,
}

export default SelectFilter
