import React, {useEffect, useState} from 'react'
import IOffer, {OfferState} from '../../../../domain/IOffer'
import useStore from '../../../../hooks/useStore'
import {getReasonWhyPriceCouldNotBeCalculated} from '../../../../helpers/offer'
import {getPriceUnitSymbol, getSlashEnergyUnit} from '../../../../helpers/price'
import KeyFeature from '../../KeyFeature'
import useTheme from '../../../../hooks/useTheme'
import useLocalization from '../../../../hooks/useLocalization'
import Box from '../../../Box'
import Text from '../../../Text'
import Loader from '../../../Loader'
import useDebounce from '../../../../hooks/useDebounce'
import SeamlessInput from '../../../SeamlessInput'
import Link from '../../../Link'
import {MODAL_NAME} from '../OfferPriceParameter/OfferPriceParameterModal'
import SettingsButton from '../../../IconButton/SettingsButton'
import ValueWithUnit from '../../../ValueWithUnit'
import {
  getContractItemsByVolumeType,
  getElectricityItemsAndFirmingItems,
  getWeightAverageForContractItems,
} from '../../../../helpers/contract'
import {ProductTypeBehaviour} from '../../../../domain/IProductType'
import {formatMoney} from '../../../../helpers/format'
import {OfferPriceRange} from '../../../../theme/ITheme'

interface IProps extends React.PropsWithChildren {
  offer: IOffer
  isDirty: boolean
}
interface IIProps {
  defaultValue: number | string
  prefix?: string
  suffix?: string
  name?: string
  onBlur: any
}

const PriceInput: React.FC<IIProps> = props => {
  return (
    <Text size="large" semibold>
      <SeamlessInput
        type="number"
        defaultValue={props.defaultValue}
        onBlur={value => props.onBlur(value)}
        prefix={props.prefix ? props.prefix : ''}
        suffix={props.suffix ? props.suffix : ''}
        name={props.name}
      />
    </Text>
  )
}

const OfferDynamicPrice: React.FC<IProps> = props => {
  const {offer, isDirty}: {offer: IOffer; isDirty: boolean} = useDebounce(props, 250)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>(null)
  const theme = useTheme()
  const {translate} = useLocalization()
  const {offerDesignerStore} = useStore()
  const isOfferPriceRangeFixed = theme.offerPriceRange === OfferPriceRange.FIXED

  const calculatePrice = async () => {
    const reason = getReasonWhyPriceCouldNotBeCalculated(offer, translate)

    if (reason) {
      setError(translate('Unable to calculate price: %s', reason))
      return
    }

    setLoading(true)
    try {
      await offerDesignerStore.calculatePrice(offer)
      setError(null)
    } catch (e) {
      setError(translate('Unable to calculate price'))
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const toFixedNumber = (value, fixPosition) => {
    return parseFloat(parseFloat(value.toString()).toFixed(fixPosition))
  }

  useEffect(() => {
    if (isDirty) {
      calculatePrice()
    }
    /* eslint-disable */
  }, [
    isDirty,
    offer.id,
    offer.meta?.pricingParameterGroupId,
    offer.numberOfNmis,
    offer.customer?.id,
    offer.contract.volumeMwh,
    offer.contract.volumeProfile,
    offer.contract.validTo,
    offer.contract.validFrom,
    offer.contract.contractType?.id,
    offer.contract.contractItems
      .map(
        i =>
          i.product.productType.behaviour !== ProductTypeBehaviour.BALANCING &&
          [i.id, i.volumeMwh, i.productPriceValue].join(),
      )
      .join(),
  ])
  /* eslint-enable */

  return (
    <Box align="center" gap={2}>
      {!isOfferPriceRangeFixed &&
      ![OfferState.BINDING_DRAFT, OfferState.BINDING_TO_CUSTOMER, OfferState.ACCEPTED].includes(offer.state) ? (
        <>
          <Box noShrink margin={{right: 3}}>
            <KeyFeature title={translate('Est. electricity price (range)')} key={offer.minPrice && offer.maxPrice}>
              <Text size={'medium'} semibold style={{display: 'flex'}}>
                <PriceInput
                  name="minPrice"
                  defaultValue={offer.minPrice ? formatMoney(offer.minPrice, theme) : 0}
                  prefix={getPriceUnitSymbol(theme.priceUnit)}
                  suffix={getSlashEnergyUnit(theme.priceUnit)}
                  onBlur={value => {
                    offerDesignerStore.setMinPrice(toFixedNumber(value, theme.numberDecimals))
                  }}
                />
                <Box gap={0.5} align="center">
                  &nbsp;-&nbsp;
                </Box>
                <PriceInput
                  name="maxPrice"
                  defaultValue={offer.maxPrice ? formatMoney(offer.maxPrice, theme) : 0}
                  prefix={getPriceUnitSymbol(theme.priceUnit)}
                  suffix={getSlashEnergyUnit(theme.priceUnit)}
                  onBlur={value => {
                    offerDesignerStore.setMaxPrice(toFixedNumber(value, theme.numberDecimals))
                  }}
                />
              </Text>
            </KeyFeature>
          </Box>
          <Box noShrink>
            <KeyFeature title={translate('Est. LGC price (range)')} key={offer.lgcMinPrice && offer.lgcMaxPrice}>
              <Text size={'medium'} semibold style={{display: 'flex'}}>
                <PriceInput
                  defaultValue={offer.lgcMinPrice ? formatMoney(offer.lgcMinPrice, theme) : 0}
                  prefix={getPriceUnitSymbol(theme.priceUnit)}
                  suffix={getSlashEnergyUnit(theme.priceUnit)}
                  onBlur={value => {
                    offerDesignerStore.setLgcMinPrice(toFixedNumber(value, theme.numberDecimals))
                  }}
                />
                <Box gap={0.5} align="center">
                  &nbsp;-&nbsp;
                </Box>
                <PriceInput
                  defaultValue={offer.lgcMaxPrice ? formatMoney(offer.lgcMaxPrice, theme) : 0}
                  prefix={getPriceUnitSymbol(theme.priceUnit)}
                  suffix={getSlashEnergyUnit(theme.priceUnit)}
                  onBlur={value => {
                    offerDesignerStore.setLgcMaxPrice(toFixedNumber(value, theme.numberDecimals))
                  }}
                />
              </Text>
            </KeyFeature>
          </Box>
        </>
      ) : (
        <>
          <Box noShrink justify="space-between" gap={3}>
            <KeyFeature title={translate('Electricity price')} key={offer.minPrice && offer.maxPrice}>
              <Text size={'medium'} semibold style={{display: 'flex'}}>
                <ValueWithUnit
                  size="large"
                  value={formatMoney(
                    getWeightAverageForContractItems(getElectricityItemsAndFirmingItems(offer.contract.contractItems)),
                  )}
                  unit={getSlashEnergyUnit(theme.priceUnit)}
                  currency={getPriceUnitSymbol(theme.priceUnit)}
                />
              </Text>
            </KeyFeature>

            <KeyFeature title={translate('LGC price')} key={offer.lgcMinPrice && offer.lgcMaxPrice}>
              <Text size={'medium'} semibold style={{display: 'flex'}}>
                <ValueWithUnit
                  size="large"
                  value={formatMoney(
                    getWeightAverageForContractItems(
                      getContractItemsByVolumeType(offer.contract.contractItems, ProductTypeBehaviour.LGC),
                    ),
                  )}
                  unit={getSlashEnergyUnit(theme.priceUnit)}
                  currency={getPriceUnitSymbol(theme.priceUnit)}
                />
              </Text>
            </KeyFeature>
            {theme.offer.dutchCooAlternative && (
              <KeyFeature
                title={translate('%s price', theme.offer.dutchCooAlternative)}
                key={offer.lgcMinPrice && offer.lgcMaxPrice}
              >
                <Text size={'medium'} semibold style={{display: 'flex'}}>
                  <ValueWithUnit
                    size="large"
                    value={formatMoney(
                      getWeightAverageForContractItems(
                        getContractItemsByVolumeType(offer.contract.contractItems, ProductTypeBehaviour.DUTCH_COO),
                      ),
                    )}
                    unit={getSlashEnergyUnit(theme.priceUnit)}
                    currency={getPriceUnitSymbol(theme.priceUnit)}
                  />
                </Text>
              </KeyFeature>
            )}
          </Box>
        </>
      )}
      <Box align="center" grow>
        {error && (
          <Text size="small" color={theme.colors.error}>
            {error}
          </Text>
        )}
        {loading && <Loader margin={{left: 1}} />}
      </Box>
      {theme.offer.hasConfigurablePriceParameters && (
        <Link to={`?${MODAL_NAME}=${offer.meta?.pricingParameterGroupId || ''}`}>
          <SettingsButton />
        </Link>
      )}
    </Box>
  )
}

export default OfferDynamicPrice
