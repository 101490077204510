import React from 'react'
import Box from '../../../../Box'
import greenEnergySvg from '../../../../../assets/icons/green-energy.svg'

const GreenEnergy: React.FC<React.PropsWithChildren> = () => (
  <Box justify="center" margin={{vertical: 3}}>
    <img src={greenEnergySvg} alt="Green energy icon" />
  </Box>
)

export default GreenEnergy
