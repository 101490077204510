import {useEffect, useRef} from 'react'

const useOutsideComponentListener = triggerFn => {
  const ref = useRef(null)

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        triggerFn()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [triggerFn])

  return {ref}
}

export default useOutsideComponentListener
