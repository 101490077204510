import styled from 'styled-components'
import Button from '../../../../Button'
import {IProps} from '../../../../Button'
import React from 'react'

const StyledButton: React.FC<IProps> = styled(Button)<IProps>`
  background-color: ${props => props.theme.colors.primary};
  border-color: ${props => props.theme.colors.primary};
  border-radius: 8px;
  height: 28px;
  font-size: 12px;
  font-weight: 700;
  color: ${props => props.theme.colors.secondary};
`

const MicroButton: React.FC<IProps> = ({children, ...props}) => (
  <StyledButton height={1} {...props}>
    {children}
  </StyledButton>
)

export default MicroButton
