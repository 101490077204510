import React, {useState} from 'react'
import useLocalization from 'hooks/useLocalization'
import Page from 'components/Page'
import Box from 'components/Box'
import EnergySummaryItem from 'components/Platform/Portfolio/Summary/EnergySummaryItem'
import useResource from 'hooks/useResource'
import WarehouseCertificatesChart from 'components/Platform/Admin/WarehouseCertificatesChart'
import TechnologyIcon from 'components/Platform/TechnologyIcon'
import {SiteProductionTechnology} from 'domain/ISite'
import useTheme from 'hooks/useTheme'
import ResourceSummary from 'components/Platform/Portfolio/Summary/ResourceSummary'
import Filter, {IChoice} from 'components/Filter'
import capitalize from 'lodash/capitalize'

const ALL_TECHNOLOGIES_SLUG = 'ALL'

const CertificateWarehouse: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const allTechnologies: IChoice = {
    name: translate('All'),
    slug: ALL_TECHNOLOGIES_SLUG,
  }
  const [technology, setTechnology] = useState<IChoice>(allTechnologies)
  const theme = useTheme()
  const {certData, loading} = useResource(
    store => ({
      certData: store.adminStore.certificatesData,
      loading: store.adminStore.loading,
    }),
    store => {
      store.adminStore.loadCertificates()
    },
  )
  const stockData = certData?.certificateStock

  const technologies: IChoice[] = [
    allTechnologies,
    ...[SiteProductionTechnology.SOLAR, SiteProductionTechnology.WIND, SiteProductionTechnology.HYDRO].map(
      technology => ({
        name: translate(capitalize(SiteProductionTechnology[technology])).toUpperCase(),
        slug: technology,
      }),
    ),
  ]

  return (
    <Page title={translate('Marketplace overview')} description={translate('Manage certificates')} isLoading={loading}>
      <Box direction="column" gap={5}>
        <ResourceSummary>
          <EnergySummaryItem
            title={translate('Solar Certificate Stock')}
            color={theme.colors.technology.SOLAR}
            value={stockData?.solar}
            icon={<TechnologyIcon technology={SiteProductionTechnology.SOLAR} />}
          />
          <EnergySummaryItem
            title={translate('Wind Certificate Stock')}
            icon={<TechnologyIcon technology={SiteProductionTechnology.WIND} />}
            color={theme.colors.technology.WIND}
            value={stockData?.wind}
          />
          <EnergySummaryItem
            title={translate('Hydro Certificate Stock')}
            icon={<TechnologyIcon technology={SiteProductionTechnology.HYDRO} />}
            color={theme.colors.technology.HYDRO}
            value={stockData?.hydro}
          />
          <EnergySummaryItem
            title={translate('Mixed Certificate Stock')}
            icon={
              <Box>
                <TechnologyIcon technology={SiteProductionTechnology.SOLAR} />
                <TechnologyIcon technology={SiteProductionTechnology.WIND} />
                <TechnologyIcon technology={SiteProductionTechnology.HYDRO} />
              </Box>
            }
            color={theme.colors.technology.GAS}
            value={stockData?.mixed}
          />
        </ResourceSummary>

        <Filter
          selected={technology}
          choices={technologies}
          colored
          transparent
          skipDate
          technology
          setActiveChoice={choice => setTechnology(choice as IChoice)}
        />

        <WarehouseCertificatesChart certs={certData} filter={technology} />
      </Box>
    </Page>
  )
}

export default CertificateWarehouse
