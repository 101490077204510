import React, {useEffect, useState} from 'react'
import {Animate} from 'react-move'
import {easeQuadInOut} from 'd3-ease'

interface IProps {
  valueStart?: number
  valueEnd: number
  duration?: number
  repeat?: boolean
  children: (value: number) => any
}

const AnimatedValue: React.FC<IProps> = ({children, valueStart, valueEnd, duration, repeat}) => {
  const [animated, setAnimated] = useState<boolean>(false)

  useEffect(() => {
    let interval: number

    if (repeat) {
      interval = window.setInterval(() => {
        setAnimated(!animated)
      }, duration)
    } else {
      setAnimated(!animated)
    }

    return () => window.clearInterval(interval)
    // eslint-disable-next-line
  }, [repeat])

  return (
    <Animate
      start={() => ({
        value: valueStart,
      })}
      update={() => ({
        value: [animated ? valueEnd : valueStart],
        timing: {
          duration: duration,
          ease: easeQuadInOut,
        },
      })}
    >
      {({value}) => children(value)}
    </Animate>
  )
}

AnimatedValue.defaultProps = {
  valueStart: 0,
  duration: 1000,
}

export default AnimatedValue
