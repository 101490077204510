import {EnergyDataType} from 'domain/EnergyDataType'
import {toDateRange} from 'helpers/date'
import {action, observable} from 'mobx'
import ITimeseriesItem from '../domain/ITimeseriesItem'
import IContentService, {Interval} from '../services/IContentService'
import {ResourceId} from '../types'
import RootStore from './index'

enum Type {
  PARTY_PREDICTED_CONSUMPTION,
  PARTY_ACTUAL_CONSUMPTION,
  SITE_PREDICTED_TIMESERIES,
  SITE_ACTUAL_TIMESERIES,
  PURCHASE_GROUP_CONSUMPTION,
}

export default class TimeseriesStore {
  private contentService: IContentService
  private rootStore: RootStore

  @observable public data: {[key: string]: ITimeseriesItem[]} = {}
  @observable public errors: {[key: string]: string} = {}
  @observable public interval: Interval = Interval.MONTHLY
  @observable public yearlyPeriod?: [number, number] = [null, null]

  public constructor(rootStore: RootStore, contentService: IContentService) {
    this.rootStore = rootStore
    this.contentService = contentService
  }

  public getData(key: string): ITimeseriesItem[] {
    return this.data[key]
  }

  @action
  public setData(key: string, data: ITimeseriesItem[]): void {
    this.data[key] = data
  }

  @action
  public setYearlyPeriod(start: number, end: number): void {
    this.yearlyPeriod = [start, end]
  }

  public async loadActualPartyConsumption(partyId: ResourceId) {
    const [startDate, endDate] = toDateRange(...this.yearlyPeriod)

    await this.setEventually(
      this.getKey(Type.PARTY_ACTUAL_CONSUMPTION, partyId),
      this.interval === Interval.YEARLY
        ? this.contentService.getYearlyConsumptionData(partyId, startDate, endDate, EnergyDataType.ACTUAL)
        : this.contentService.getConsumptionData(partyId, this.interval || Interval.MONTHLY, EnergyDataType.ACTUAL),
    )
  }

  public async loadPredictedPartyConsumption(partyId: ResourceId) {
    const [startDate, endDate] = toDateRange(...this.yearlyPeriod)

    await this.setEventually(
      this.getKey(Type.PARTY_PREDICTED_CONSUMPTION, partyId),
      this.interval === Interval.YEARLY
        ? this.contentService.getYearlyConsumptionData(partyId, startDate, endDate, EnergyDataType.PREDICTED)
        : this.contentService.getConsumptionData(partyId, this.interval || Interval.MONTHLY, EnergyDataType.PREDICTED),
    )
  }

  public async loadActualSiteTimeseries(siteId: ResourceId) {
    const [startDate, endDate] = toDateRange(...this.yearlyPeriod)

    await this.setEventually(
      this.getKey(Type.SITE_ACTUAL_TIMESERIES, siteId),
      this.interval === Interval.YEARLY
        ? this.contentService.getYearlyTimeseriesData(siteId, startDate, endDate, EnergyDataType.ACTUAL)
        : this.contentService.getTimeseriesData(siteId, this.interval || Interval.MONTHLY, EnergyDataType.ACTUAL),
    )
  }

  public async loadPredictedSiteTimeseries(siteId: ResourceId) {
    const [startDate, endDate] = toDateRange(...this.yearlyPeriod)

    await this.setEventually(
      this.getKey(Type.SITE_PREDICTED_TIMESERIES, siteId),
      this.interval === Interval.YEARLY
        ? this.contentService.getYearlyTimeseriesData(siteId, startDate, endDate, EnergyDataType.PREDICTED)
        : this.contentService.getTimeseriesData(siteId, this.interval || Interval.MONTHLY, EnergyDataType.PREDICTED),
    )
  }

  public async loadPurchaseGroupConsumption(partyId: ResourceId, purchaseGroupId: ResourceId) {
    const [startDate, endDate] = toDateRange(...this.yearlyPeriod)

    await this.setEventually(
      this.getKey(Type.PURCHASE_GROUP_CONSUMPTION, purchaseGroupId),
      this.interval === Interval.YEARLY
        ? this.contentService.getPurchaseGroupYearlyConsumptionData(partyId, purchaseGroupId, startDate, endDate)
        : this.contentService.getPurchaseGroupConsumptionData(
            partyId,
            purchaseGroupId,
            this.interval || Interval.MONTHLY,
          ),
    )
  }

  public getPartyConsumption(partyId: ResourceId) {
    return this.getData(this.getKey(Type.PARTY_PREDICTED_CONSUMPTION, partyId))
  }

  public getPurchaseGroupConsumption(purchaseGroupId: ResourceId) {
    return this.getData(this.getKey(Type.PURCHASE_GROUP_CONSUMPTION, purchaseGroupId))
  }

  public getSiteTimeseries(siteId: ResourceId) {
    return this.getData(this.getKey(Type.SITE_PREDICTED_TIMESERIES, siteId))
  }

  public getSiteActualTimeseries(siteId: ResourceId) {
    return this.getData(this.getKey(Type.SITE_ACTUAL_TIMESERIES, siteId))
  }

  public getPartyConsumptionError(partyId: ResourceId) {
    return this.errors[this.getKey(Type.PARTY_PREDICTED_CONSUMPTION, partyId)] || null
  }

  public getSiteTimeseriesError(siteId: ResourceId) {
    return this.errors[this.getKey(Type.SITE_PREDICTED_TIMESERIES, siteId)] || null
  }

  public getPurchaseGroupConsumptionError(purchaseGroupId: ResourceId) {
    return this.errors[this.getKey(Type.PURCHASE_GROUP_CONSUMPTION, purchaseGroupId)] || null
  }

  private async setEventually(key: string, promise: Promise<ITimeseriesItem[]>) {
    try {
      const data = await promise

      this.errors[key] = null
      this.setData(key, data)
    } catch (e) {
      this.errors[key] = e.message
    }
  }

  private getKey(type: Type, resourceId: ResourceId): string {
    return [type, resourceId, this.interval, this.interval === Interval.YEARLY ? this.yearlyPeriod.join() : ''].join(
      '/',
    )
  }
}
