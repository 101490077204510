import {generalProfileContractSubject, IContractSubject, purchaseGroupToContractSubject} from 'domain/IContractSubject'
import IParty from 'domain/IParty'
import IPurchaseGroup, {PurchaseGroupState} from 'domain/IPurchaseGroup'
import useServices from 'hooks/useServices'
import React, {useEffect, useState} from 'react'
import useLocalization from '../../../../hooks/useLocalization'
import FormSelect, {IProps as ISelectProps} from '../../../Form/FormSelect'

interface IProps extends ISelectProps {
  party: IParty
  activePurchaseGroup: IPurchaseGroup
}

const ContractSubjectSelect: React.FC<IProps> = props => {
  const {translate} = useLocalization()
  const {contentService} = useServices()

  const [contractSubjects, setContractSubjects] = useState<IContractSubject[]>([])
  const partyId = props.party?.id || -1

  useEffect(() => {
    if (props.party) {
      // TODO: move this to store
      contentService.getPartyPurchaseGroups(props.party.id).then(purchaseGroups => {
        const purchaseGroupSubjects = purchaseGroups
          .filter(purchGrp => purchGrp.state !== PurchaseGroupState.DELETED)
          .map(purchGrp => {
            return purchaseGroupToContractSubject(purchGrp)
          })

        const contractSubjects: IContractSubject[] = [
          generalProfileContractSubject(props.party.id),
          ...purchaseGroupSubjects,
        ]
        setContractSubjects(contractSubjects)
      })
    }

    // eslint-disable-next-line
  }, [partyId])

  //TODO use a store instead of JSON.stringify to pass the data
  const defaultSubject = props.activePurchaseGroup
    ? purchaseGroupToContractSubject(props.activePurchaseGroup)
    : generalProfileContractSubject(props.party?.id)

  return (
    <FormSelect
      label={translate('Proposal subject(s)')}
      {...props}
      options={contractSubjects.map(subject => ({
        value: JSON.stringify(subject),
        label: subject.name,
      }))}
      defaultValue={JSON.stringify(defaultSubject)}
    />
  )
}

export default ContractSubjectSelect
