import IAuthenticationService from './IAuthenticationService'
import LocalStorageDataService from './LocalStorageDataService'
import IUser from '../domain/IUser'

export default class LocalAuthenticationService implements IAuthenticationService {
  private static EMAIL_CODE = '123456'

  private readonly authenticated = new LocalStorageDataService<{
    id: any
    email: string
  }>('authenticated')
  private readonly users = new LocalStorageDataService<IUser>('users')

  public async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    const user = await this.getAuthenticatedUser()

    if (user.password !== oldPassword) {
      throw new Error('Invalid old password')
    }

    this.users.updateItem(user)
  }

  public async confirmSignUp(email: string, code: string): Promise<void> {
    this.verifyEmailCode(email, code)

    this.users.getItems()

    const user = this.users.getItems().find(u => u.email === email)
    user.emailVerified = true

    this.users.updateItem(user)
  }

  public async forgotPassword(email: string): Promise<void> {
    if (!this.users.getItems().find(u => u.email === email)) {
      throw new Error('User does not exist')
    }
  }

  public async forgotPasswordSubmit(email: string, code: string, password: string): Promise<void> {
    this.verifyEmailCode(email, code)

    const users = this.users.getItems()
    const user = users.find(u => u.email === email)

    if (!user) {
      throw new Error('Invalid user')
    }

    user.password = password

    this.users.setItems(users)
  }

  public async getAuthenticatedUser(): Promise<IUser> {
    const [au] = this.authenticated.getItems()

    if (!au) {
      return null
    }

    return this.users.getItems().find(u => u.email === au.email) || null
  }

  public async getToken(): Promise<string> {
    return null
  }

  public async resendSignUp(email: string): Promise<void> {
    // Do nothing
  }

  public async signIn(email: string, password: string): Promise<void> {
    const users = this.users.getItems()

    if (!users.find(u => u.email === email && u.password === password)) {
      throw new Error('Invalid username or password')
    }

    this.authenticated.setItems([])
    this.authenticated.addItem({email})
  }

  public async signOut(): Promise<void> {
    this.authenticated.setItems([])
  }

  public async signUp(email: string, password: string, firstName: string, lastName: string): Promise<void> {
    this.users.addItem({
      email,
      phoneNumber: '+12345678',
      firstName,
      lastName,
      password,
      emailVerified: false,
      phoneNumberVerified: true,
    })
  }

  public async updateInfo(user: IUser): Promise<void> {
    this.users.updateItem(user)
  }

  private verifyEmailCode(email: string, code: string) {
    if (code !== LocalAuthenticationService.EMAIL_CODE) {
      throw new Error('Invalid code')
    }
  }
}
