import React, {useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import Page, {IProps as IPageProps} from '../../Page'
import ContractList from './ContractList'
import InnerScroll from '../../InnerScroll'
import NoSignedContract from './NoContract/NoSignedContract'
import NoDraftContract from './NoContract/NoDraftContract'
import useResource from '../../../hooks/useResource'
import useStore from '../../../hooks/useStore'
import {getPartyLink} from '../../../helpers/party'
import FullscreenLoader from 'components/FullscreenLoader'

type IProps = IPageProps

const ContractListPage: React.FC<IProps> = ({...pageProps}) => {
  const {profileStore, contractStore} = useStore()
  const {partyRole} = profileStore?.party || {}
  const {signedContracts, draftContracts, partyLoaded, isLoading} = useResource(store => ({
    signedContracts: store.contractStore.partySignedContracts,
    draftContracts: store.contractStore.partyDraftContracts,
    partyLoaded: store.profileStore.isLoaded,
    isLoading: store.contractStore.isLoading,
  }))

  useEffect(() => {
    if (partyLoaded) {
      contractStore.loadPartyContracts()
    }
  }, [partyLoaded])

  if (!partyLoaded || isLoading) {
    return <FullscreenLoader />
  }

  if (signedContracts.length === 1) {
    return <Redirect to={`/${getPartyLink(partyRole)}/portfolio/current-contracts/${signedContracts[0].id}`} />
  }

  if (signedContracts.length <= 0) {
    return draftContracts.length > 0 ? <NoSignedContract /> : <NoDraftContract />
  }

  return (
    <Page {...pageProps}>
      <InnerScroll padded>
        <ContractList contracts={signedContracts} />
      </InnerScroll>
    </Page>
  )
}

export default ContractListPage
