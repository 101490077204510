export enum PriceUnit {
  EUR_MWH = 'EUR_MWH',
  GBP_MWH = 'GBP_MWH',
  AUD_MWH = 'AUD_MWH',
  USD_MWH = 'USD_MWH',
  ZAR_MWH = 'ZAR_MWH',
  EUR_KWH = 'EUR_KWH',
  AUD_KWH = 'AUD_KWH',
  GBP_KWH = 'GBP_KWH',
  USD_KWH = 'USD_KWH',
  ZAR_KWH = 'ZAR_KWH',
}

export default interface IPrice {
  id?: number
  value: number
  unit: PriceUnit
}

export enum CurrencyCode {
  EUR = 'EUR',
  GBP = 'GBP',
}

export enum IUnit {
  TON = 'tCO2',
}
