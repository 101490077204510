import React, {useState} from 'react'
import useStoreData from '../../hooks/useStoreData'
import styled from 'styled-components'
import Text from '../Text'
import copyIcon from './content_copy.svg'
import copyBlackIcon from './content_copy_black.svg'
import Box from 'components/Box'
import {AlertTitle, Alert} from '@mui/material'
import useLocalization from 'hooks/useLocalization'
import capitalize from 'lodash/capitalize'
import useProfile from 'hooks/useProfile'
import {showAdminMessage} from 'helpers/user'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  align-items: center;
  z-index: 15;
`

export const VerticallyAlignedImage = styled.img`
  vertical-align: text-bottom;
`

function copyCorrelationId(correlationId) {
  navigator.clipboard.writeText(correlationId)
}

const Alerts: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const {user} = useProfile()
  const alerts = useStoreData(store => store.alertStore.alerts)

  return (
    <Container>
      {alerts.map((alert, i) => (
        <Alert severity={alert.type} key={i} data-cy={`alert-${i}`}>
          <AlertTitle>{translate(capitalize(alert.type))}</AlertTitle>
          <Text> {alert.message} </Text>
          {showAdminMessage(user) && alert.details && <Text>Admin: {alert.details} </Text>}
          {alert.correlationId && <CorrelationIdMessage correlationId={alert.correlationId} />}
        </Alert>
      ))}
    </Container>
  )
}

export const CorrelationIdMessage: React.FC<{correlationId: string; black?: boolean}> = ({correlationId, black}) => {
  const [isContentCopied, setIsContentCopied] = useState(false)

  return (
    <Text
      size={'msmall'}
      onClick={e => {
        e.stopPropagation()
        copyCorrelationId(correlationId)
        setIsContentCopied(true)
      }}
      style={{cursor: 'pointer'}}
    >
      <Box direction="row" gap={0.5} justify="center">
        Request ID: {correlationId} <VerticallyAlignedImage src={black ? copyBlackIcon : copyIcon} alt="Copy icon" />
        {isContentCopied ? 'Copied!' : 'Copy'}
      </Box>
    </Text>
  )
}

export default Alerts
