import React from 'react'
import CircularProgress from '../CircularProgress'
import natureImage from './nature.svg'
import Box from '../Box'
import Text from '../Text'
import AnimatedValue from '../AnimatedValue'
import useTheme from 'hooks/useTheme'

const FixedSizeContainer = ({fixed, dynamic}) => {
  return (
    <div style={{position: 'relative'}}>
      <div style={{visibility: 'hidden'}}>{fixed}</div>
      <div style={{position: 'absolute', top: 0, left: 0}}>{dynamic}</div>
    </div>
  )
}

interface IProps extends React.PropsWithChildren {
  value: number
  text?: string
}

const GreenIndicator: React.FC<IProps> = ({value: valueEnd, text}) => {
  const theme = useTheme()

  return (
    <AnimatedValue valueEnd={valueEnd}>
      {value => (
        <Box align="center">
          <CircularProgress value={value} color={theme.colors.primaryDark} circleRatio={value / valueEnd} noAnimation>
            <img src={natureImage} alt="" width={32} />
          </CircularProgress>
          <Box margin={{left: 3}} gap={1} align="center">
            <Text color={theme.colors.common.white} size="xxlarge" inline semibold>
              <FixedSizeContainer dynamic={`${Math.round(value)}%`} fixed={`${Math.round(valueEnd)}%`} />
            </Text>
            <Text color={theme.colors.common.white} inline semibold size="xlarge">
              {text}
            </Text>
          </Box>
        </Box>
      )}
    </AnimatedValue>
  )
}

interface IIProps {
  value: number
  title: string
  subtitle: string
  hoverText?: string
  trailColor?: string
  noImage?: boolean
  circularSize?: number
  justify?: 'start' | 'end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'flex-end' | 'flex-start'
  dataCy?: string
}

export const GreenIndicatorWithSubTitle: React.FC<IIProps> = ({
  value: valueEnd,
  title,
  subtitle,
  hoverText,
  trailColor,
  noImage,
  circularSize,
  justify,
  dataCy,
}) => {
  const theme = useTheme()

  return (
    <div title={hoverText || ''}>
      <AnimatedValue valueEnd={valueEnd}>
        {value => (
          <Box align="center" justify={justify} pad={1}>
            <Box align="center">
              {!noImage && (
                <CircularProgress
                  value={value}
                  color={theme.colors.primaryDark}
                  trailColor={trailColor || theme.colors.common.white}
                  circleRatio={1}
                  width={circularSize ? circularSize : 6}
                  height={circularSize ? circularSize : 6}
                  noAnimation
                >
                  <img src={natureImage} alt="" width={24} />
                </CircularProgress>
              )}
              <Box margin={{left: !noImage ? 1 : 0}} align="start" direction="column">
                <Text color={theme.colors.primaryDark} inline semibold size="xlarge" lineHeight="small">
                  {title}
                </Text>
                <Text inline size="msmall">
                  {subtitle}
                </Text>
              </Box>
            </Box>
            <Box margin={{left: 3}} gap={1} align="center" data-cy={dataCy}>
              <Text color={theme.colors.primaryDark} size="xmlarge" inline font={theme.font.numbers}>
                <FixedSizeContainer dynamic={`${Math.round(value)}%`} fixed={`${Math.round(valueEnd)}%`} />
              </Text>
            </Box>
          </Box>
        )}
      </AnimatedValue>
    </div>
  )
}

export default GreenIndicator
