import React from 'react'
import Page, {IProps as IPageProps} from '../../Page'
import Loader from '../../Loader'
import PageWithFixedLeftSide from '../../PageWithFixedLeftSide'
import ContractSummary from './ContractSummary'
import ContractDetails from './ContractDetails'
import IContract from '../../../domain/IContract'
import NotFound from '../../NotFound'
import IOffer from '../../../domain/IOffer'
import IPurchaseContract, {IPurchaseParty} from 'domain/IPurchaseContract'
import IParty from '../../../domain/IParty'

interface IProps extends React.PropsWithChildren, IPageProps {
  contract: IContract | IPurchaseContract
  loading?: boolean
  editable?: boolean
  signable?: boolean
  chart?: any
  offer?: IOffer
  linkable?: boolean
  party?: IParty | IPurchaseParty
}

const ContractPage: React.FC<IProps> = ({
  contract,
  loading,
  signable,
  editable,
  chart,
  offer,
  linkable,
  party,
  ...pageProps
}) => {
  if (loading) {
    return (
      <Page {...pageProps}>
        <div>
          <Loader />
        </div>
      </Page>
    )
  }

  if (!contract) {
    return <NotFound />
  }

  return (
    <PageWithFixedLeftSide
      {...pageProps}
      aside={<ContractSummary contract={contract} editable={editable} signable={signable} offer={offer} />}
    >
      <ContractDetails
        contract={contract}
        editable={editable}
        chart={chart}
        offer={offer}
        linkable={linkable}
        passedParty={party}
      />
    </PageWithFixedLeftSide>
  )
}

export default ContractPage
