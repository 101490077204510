import React from 'react'
import Box from '../Box'
import ModalCloser from './ModalCloser'
import ModalHeader, {IProps as IHeaderProps} from './ModalHeader'
import {SecondaryButton} from '../Button'
import FormSubmitButton from '../Form/FormSubmitButton'
import useLocalization from '../../hooks/useLocalization'

interface IProps extends React.PropsWithChildren, IHeaderProps {
  buttonText?: string
}

const ModalFormHeader: React.FC<IProps> = ({buttonText, ...props}) => {
  const {translate} = useLocalization()

  return (
    <ModalHeader {...props}>
      <Box direction="row" gap={2}>
        <ModalCloser>
          <SecondaryButton type="button">{translate('Cancel')}</SecondaryButton>
        </ModalCloser>
        <FormSubmitButton>{buttonText || translate('Save')}</FormSubmitButton>
      </Box>
    </ModalHeader>
  )
}

export default ModalFormHeader
