import Box from 'components/Box'
import Button, {SecondaryButton} from 'components/Button'
import Form from 'components/Form'
import FormInput from 'components/Form/FormInput'
import FormSubmitButton from 'components/Form/FormSubmitButton'
import Text from 'components/Text'
import useInterval from 'hooks/useInterval'
import useLocalization from 'hooks/useLocalization'
import useTheme from 'hooks/useTheme'
import {toInteger} from 'lodash'
import React, {useState} from 'react'

interface IProps extends React.PropsWithChildren {
  phoneNumber: string
  errorMessage: string
  remainingMinutes: number
  handleSubmit: (data: any) => void
  handleCancel: () => void
  resendCode: () => Promise<void>
  onChange?: () => void
}

const SECOND_TO_MILISEC = 1000
const MINUTE_TO_SECOND = 60

const SmsVerificationForm: React.FC<IProps> = ({
  phoneNumber,
  handleSubmit,
  handleCancel,
  onChange,
  resendCode,
  remainingMinutes,
  errorMessage,
}) => {
  const theme = useTheme()
  const {translate} = useLocalization()
  const remainingTimeMs = remainingMinutes * MINUTE_TO_SECOND * SECOND_TO_MILISEC
  const [remainingTime, setRemainingTime] = useState(remainingTimeMs)
  const isCodeExpired = remainingTime <= 0

  useInterval(
    () => {
      const updated = remainingTime - SECOND_TO_MILISEC
      const remaining = updated >= 0 ? updated : 0
      setRemainingTime(remaining)
    },
    remainingTime >= 0 ? SECOND_TO_MILISEC : null,
  )

  const totalSeconds = remainingTime / SECOND_TO_MILISEC
  const seconds = toInteger(totalSeconds % 60)
  const minutes = toInteger(totalSeconds / 60)

  return (
    <Form onSubmit={handleSubmit} submissionFeedback={null}>
      <Box direction="column" align="center">
        {!isCodeExpired && (
          <>
            <Text size="small" uppercase>
              Time until your code expires
            </Text>
            <Box direction="row" gap={1}>
              <Box>
                <Text size="xlarge" align="center">
                  {minutes > 9 ? minutes : '0' + minutes}
                </Text>
                <Text>minutes</Text>
              </Box>
              <Text size="xlarge">:</Text>
              <Box>
                <Text size="xlarge" align="center">
                  {seconds > 9 ? seconds : '0' + seconds}
                </Text>
                <Text>seconds</Text>
              </Box>
            </Box>
          </>
        )}
        {isCodeExpired && <Text size="xxlarge">Your verification code has expired.</Text>}
        <Box width="70%">
          <Text margin={{bottom: 2, top: 4}} align="center">
            {translate('Please enter the 8-digit verification code sent to ') + phoneNumber + '.'}
          </Text>
        </Box>
        <Box>
          <FormInput
            name="pin"
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            required
            onChange={onChange}
            minLength={8}
            maxLength={8}
            validate={value => {
              if (!/^\d+$/.test(value)) {
                return translate('Must contain only digits')
              }

              return true
            }}
          />
        </Box>

        <Box>
          <Button variant="clear" type="button" onClick={resendCode} height={3}>
            {translate('Resend code')}
          </Button>
        </Box>
        {errorMessage && (
          <Text size="xlarge" color={theme.colors.error}>
            {errorMessage}
          </Text>
        )}
        <Box gap={1} margin={{top: 3}}>
          <Button variant="secondary" type="button" onClick={handleCancel}>
            {translate('Cancel')}
          </Button>
          <FormSubmitButton disabled={isCodeExpired}>{translate('Confirm')}</FormSubmitButton>
        </Box>
      </Box>
    </Form>
  )
}

export default SmsVerificationForm
