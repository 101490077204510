import React from 'react'
import Chart, {ChartType, ILine, StackType} from '../../../Chart'
import useTheme from '../../../../hooks/useTheme'
import {DateFormat, formatDate} from '../../../../helpers/date'
import useLocalization from '../../../../hooks/useLocalization'
import {getSmartContractTokenSharePercentage} from '../../../../helpers/smartContract'
import IToken from '../../../../domain/IToken'
import {formatPercentage} from '../../../../helpers/format'

interface IProps extends React.PropsWithChildren {
  tokens: IToken[]
  walletId: string
}

const ProductionOwnershipChart: React.FC<IProps> = ({tokens, walletId}) => {
  const theme = useTheme()
  const {translate} = useLocalization()

  const lines: ILine[] = [
    {
      key: 's1',
      label: translate('Your share'),
      color: theme.colors.grey1,
      data: tokens.map(token => formatPercentage(getSmartContractTokenSharePercentage(token, walletId))),
      type: ChartType.BAR,
      stackType: StackType.STACKED,
    },
    {
      key: 's2',
      label: translate("Others' share"),
      color: theme.colors.primaryDark,
      data: tokens.map(token => formatPercentage(1 - getSmartContractTokenSharePercentage(token, walletId))),
      type: ChartType.BAR,
      stackType: StackType.STACKED,
    },
  ]

  return (
    <Chart
      dark
      toolbar={{vertical: true}}
      lines={lines}
      labels={tokens.map(token => formatDate(token.deliveryStart, DateFormat.MONTH_YEAR))}
      typeLabels={{
        [StackType.DEFAULT]: translate('% of production capacity'),
      }}
      maxValue={100}
    />
  )
}

export default ProductionOwnershipChart
