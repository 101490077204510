import IParty from './IParty'
import ISite from './ISite'
import {formatEnergy} from '../helpers/format'

export enum PurchaseGroupState {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export interface IPurchaseGroupBase {
  name: string
  color: string
}

export default interface PurchaseGroup extends IPurchaseGroupBase {
  id: number
  party: IParty
  state: PurchaseGroupState
  sites: ISite[]
}

export const getAvgYearlyConsumptionMwh = (purchaseGroup: PurchaseGroup): number => {
  if (!purchaseGroup.sites) {
    return 0
  }

  const avg = purchaseGroup.sites.map(site => site.avgYearlyConsumptionMwh).reduce((a, b) => a + b, 0)

  return formatEnergy(avg)
}
