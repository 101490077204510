import ISite, {ISiteWithTimeseries, SiteModel, SiteProductionTechnology, SiteShape, SiteState} from '../domain/ISite'
import ITranslate from '../interfaces/ITranslate'
import {getHumanReadableCountry} from './location'
import groupBy from 'lodash/groupBy'
import zipWith from 'lodash/zipWith'
import ITimeseriesItem from '../domain/ITimeseriesItem'
import IContract from '../domain/IContract'
import ILocation from '../domain/ILocation'
import {ISiteTimeseriesResult} from 'domain/Portfolio'

export function getHumanReadableTechnologyName(technology: SiteProductionTechnology, translate: ITranslate): string {
  switch (technology) {
    case SiteProductionTechnology.HYDRO:
      return translate('Hydro')
    case SiteProductionTechnology.WIND:
      return translate('Wind')
    case SiteProductionTechnology.SOLAR:
      return translate('Solar')
    case SiteProductionTechnology.BIOENERGY:
      return translate('Bioenergy')
    case SiteProductionTechnology.GEOTHERMAL:
      return translate('Geothermal')
    case SiteProductionTechnology.HYDROGEN:
      return translate('Hydrogen')
    case SiteProductionTechnology.WAVE:
      return translate('Wave')
    case SiteProductionTechnology.GAS:
      return translate('Gas')
    case SiteProductionTechnology.BATTERY:
      return translate('Battery')
  }

  return technology
}

export function getHumanReadableShape(shape: SiteShape, translate: ITranslate): string {
  switch (shape) {
    case SiteShape.COMMERCIAL:
      return translate('Commercial')
    case SiteShape.MINING:
      return translate('Mining')
    case SiteShape.INDUSTRIAL:
      return translate('Industrial')
    case SiteShape.DATA_WAREHOUSE:
      return translate('Data warehouse')
    case SiteShape.PORT:
      return translate('Port')
    case SiteShape.REAL_ESTATE:
      return translate('Real estate')
  }

  return shape
}

export function getHumanReadableState(siteOrState: ISite | SiteState, translate: ITranslate): string {
  const state: SiteState = typeof siteOrState === 'string' ? siteOrState : siteOrState.state
  switch (state) {
    case SiteState.IN_CONSTRUCTION:
      return translate('Under construction')
    case SiteState.IN_OPERATION:
      return translate('In operation')
    // case SiteState.PLANNING:
    //   return translate('Planning')
    // case SiteState.RETIRED:
    //   return translate('Retired')
  }

  return null
}

export function getStringifiedSiteLocation(site: ISite, translate: ITranslate): string {
  const location = site?.location

  if (!location) {
    return null
  }

  return [
    location.addressStreet,
    location.addressCity,
    location.addressSubdivision,
    getHumanReadableCountry(location.addressCountry, translate),
  ]
    .filter(Boolean)
    .join(', ')
}

export function getStringifiedLocation(location: ILocation, translate: ITranslate): string {
  if (!location) {
    return null
  }

  return [
    location.addressStreet,
    location.addressCity,
    location.addressSubdivision,
    getHumanReadableCountry(location.addressCountry, translate),
  ]
    .filter(Boolean)
    .join(', ')
}

export function getHoverKey(site: ISite) {
  return `SITE_${site.id}`
}

export function getHumanReadableSiteModel(siteModel: SiteModel, translate: ITranslate): string {
  switch (siteModel) {
    case SiteModel.PHYSICAL:
      return translate('Physical')
    case SiteModel.VIRTUAL:
      return translate('Virtual')
    default:
      return siteModel
  }
}

export function getSiteId(site: string) {
  if (!site) return null
  const parsedSite = JSON.parse(site)
  if (typeof parsedSite === 'number') {
    return parsedSite
  } else {
    return parsedSite.id
  }
}

export function matchSiteWithParameterGroup(sites: Array<ISite>, groups) {
  const list = []
  groups.forEach(group => {
    const item = sites.find(site => site.meta?.pricingParameterGroupId === group.id)

    if (item) {
      list.push({meta: group.id, site: item.id, name: group.name})
    }
  })

  return list
}

export function groupSitesByTechnology(sites: ISite[]): {[key in SiteProductionTechnology]?: ISite[]} {
  return groupBy(
    sites.filter(s => !!s.productionTechnology),
    s => s.productionTechnology,
  )
}

export function groupSitesByShape(sites: ISite[]): {[key in SiteShape]?: ISite[]} {
  return groupBy(
    sites.filter(s => !!s.siteShape),
    s => s.siteShape,
  )
}

export function zipSitesWithTimeseries(sites: ISite[], timeseries: ITimeseriesItem[][]): ISiteWithTimeseries[] {
  return zipWith(sites, timeseries, (site, timeseries) => ({...site, timeseries}))
}

export function zipSitesWithTimeseriesById(sites: ISite[], results: ISiteTimeseriesResult[]): ISiteWithTimeseries[] {
  return zipWith(sites, results, (site: ISite, siteTsResult: ISiteTimeseriesResult) => ({
    ...site,
    timeseries: siteTsResult?.timeseries,
  }))
}

export function getContractedCapacityMwac(site: ISite, contract: IContract) {
  return (contract.volumeMwh / site.avgYearlyProductionMwh) * site.productionNameplateCapacityMwac
}

export function getCapacityUnitBasedOnTechnology(technology: SiteProductionTechnology, defaultUnit: string): string {
  switch (technology) {
    case SiteProductionTechnology.SOLAR:
      return 'MWp'
    case SiteProductionTechnology.WIND:
      return 'MW'

    default:
      return defaultUnit
  }
}
