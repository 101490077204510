import React from 'react'
import RouterModal from '../../Modal/RouterModal'
import {ModalType} from '../../Modal/IModal'
import IOffer from '../../../domain/IOffer'
import ContractModalForm from './ContractModalForm'

interface IProps extends React.PropsWithChildren {
  offer: IOffer
}

const ContractPreviewModal: React.FC<IProps> = ({offer}) => {
  if (!offer?.id || !offer?.contract) {
    return null
  }

  return (
    <RouterModal style={{width: 1200}} name={ModalType.CONTRACT}>
      <ContractModalForm contract={offer.contract} />
    </RouterModal>
  )
}

export default ContractPreviewModal
