import React from 'react'
import RouterModal from '../../../Modal/RouterModal'
import OfferPriceParameterForm from './OfferPriceParameterForm'
import IOfferPriceParameterGroup from '../../../../domain/IOfferPriceParameterGroup'

export const MODAL_NAME = 'modal.priceParameters'

interface IProps extends React.PropsWithChildren {
  parameterGroupName: string
  onCreate?: (parameterGroup: IOfferPriceParameterGroup) => void
}

const OfferPriceParameterModal: React.FC<IProps> = ({parameterGroupName, onCreate}) => {
  return (
    <RouterModal name={MODAL_NAME} full>
      <OfferPriceParameterForm name={parameterGroupName} onCreate={onCreate} />
    </RouterModal>
  )
}

export default OfferPriceParameterModal
