import IPriceCalculationService from './IPriceCalculationService'
import IOffer from '../domain/IOffer'
import IOfferPriceCalculationResult from '../domain/IOfferPriceCalculationResult'
import {SiteProductionTechnology} from '../domain/ISite'
import {VolumeType} from '../domain/IProductType'

export default class LocalPriceCalculationService implements IPriceCalculationService {
  public async calculateOfferPrice(offer: IOffer): Promise<IOfferPriceCalculationResult> {
    const solarItems = offer.contract?.contractItems?.filter(
      contractItem => contractItem.product?.site?.productionTechnology === SiteProductionTechnology.SOLAR,
    )
    const windItems = offer.contract?.contractItems?.filter(
      contractItem => contractItem.product?.site?.productionTechnology === SiteProductionTechnology.WIND,
    )
    const firmingItem = offer.contract?.contractItems?.find(
      contractItem => contractItem.product?.productType?.volumeType === VolumeType.AUTO_FILL,
    )
    const unbundledLgcItem = offer.contract?.contractItems?.find(
      contractItem => contractItem.product?.productType?.volumeType === VolumeType.STANDALONE_USER_SELECT,
    )
    const bundledLgcItem = offer.contract?.contractItems?.find(
      contractItem => contractItem.product?.productType?.volumeType === VolumeType.NONE,
    )

    const getPercentage = item => (item?.volumeMwh || 0) / (offer?.contract?.volumeMwh || 1)

    let result = 0

    for (const i of [...solarItems, ...windItems]) {
      result += getPercentage(i) * (i.price?.value || 0)
    }

    if (firmingItem) {
      result += (1 - [...solarItems, ...windItems].map(i => getPercentage(i)).reduce((a, b) => a + b, 0)) * 57.6
    }

    if (unbundledLgcItem) {
      result +=
        unbundledLgcItem.price?.value *
        Math.max(
          0,
          getPercentage(unbundledLgcItem) -
            [...solarItems, ...windItems].map(i => getPercentage(i)).reduce((a, b) => a + b, 0),
        )
    }

    if (bundledLgcItem) {
      result += bundledLgcItem.price?.value
    }

    if (result < 0 || result > 100) {
      throw new Error(`Expected something value between 10 and 100 but received ${result}`)
    }

    const lgcPrice = unbundledLgcItem ? 12 : bundledLgcItem ? 2.85 : 0

    return {
      minPrice: result * 0.99,
      maxPrice: result * 1.025,
      price: result,
      lgcMinPrice: lgcPrice * 0.99,
      lgcMaxPrice: lgcPrice * 1.025,
      lgcPrice: lgcPrice,
    }
  }
}
