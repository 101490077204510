import React from 'react'
import {isPartyAdmin} from 'helpers/partyMember'
import useProfile from 'hooks/useProfile'
import {PartyRole} from '../../domain/IParty'
import useLocalization from '../../hooks/useLocalization'
import IRoute from '../../interfaces/IRoute'
import CompanyInformation from './Account/CompanyInformation'
import PersonalProfile from './Account/PersonalProfile'
import Platform from './index'
import Offers from './Portfolio/Offers'
import Products from './Portfolio/Products'
import Sites from './Portfolio/Sites'
import CompanyUsers from './Account/CompanyUsers'
import SalesContracts from './Portfolio/SalesContracts'
import {PartyRoleContext} from 'App'
import MarketplaceOverview from './Admin/MarketplaceOverview'
import {isAdmin} from 'helpers/user'
import SellRequests from './Admin/SellRequests'
import Customers from './Portfolio/Customers'
import CustomersSites from './Portfolio/CustomersSites'
import SellerOnboardingWizard from 'components/Platform/Onboarding/SellerOnboardingWizard'
import {useAtomValue} from 'jotai'
import {partyAtom} from 'atoms/party'
import MarketplaceListings from './Portfolio/MarketplaceListings'
import {needsOnboarding} from 'helpers/party'
import TenderRespond from './Sell/TenderRespond'

const Broker = () => {
  const {translate} = useLocalization()
  const {isLoaded, partyMembership, user} = useProfile()
  const party = useAtomValue(partyAtom)

  const routes: IRoute[] = [
    {
      title: translate('Portfolio'),
      path: '/portfolio',
      children: [
        {
          title: translate('Marketplace listings'),
          path: '/portfolio/marketplace-listings',
          component: MarketplaceListings,
        },
        {
          title: translate('Customers'),
          path: '/portfolio/customers',
          component: Customers,
        },
        {
          title: translate('Customers sites'),
          path: '/portfolio/customers-sites',
          component: CustomersSites,
        },
        {
          title: translate('Sales contracts'),
          path: '/portfolio/sales-contracts',
          component: SalesContracts,
        },
        {
          title: translate('Products'),
          path: '/portfolio/products',
          component: Products,
        },
        {
          title: translate('Sites'),
          path: '/portfolio/sites',
          component: Sites,
        },
      ].filter(Boolean),
    },
    {
      title: translate('Welcome to Renewabl'),
      path: '/onboarding',
      hidden: true,
      children: [
        {
          title: translate('Onboarding wizard'),
          path: '/',
          component: SellerOnboardingWizard,
          hidden: true,
        },
      ],
    },
    {
      title: translate('Sell'),
      path: '/sell',
      default: true,
      children: [
        {
          title: translate('Offers'),
          path: '/sell/offers',
          component: Offers,
        },
        {
          title: translate('Tender responses'),
          path: '/sell/tender-responses',
          component: TenderRespond,
        },
      ],
    },
    {
      title: translate('Company'),
      path: '/company',
      children: [
        {
          title: translate('Personal profile'),
          path: '/company/personal-profile',
          component: PersonalProfile,
          hidden: true,
        },
        {
          title: translate('General'),
          path: '/company/general',
          component: CompanyInformation,
        },
        {
          title: translate('Members'),
          path: '/company/members',
          component: CompanyUsers,
          hidden: isLoaded && !isPartyAdmin(partyMembership),
        },
      ],
    },
  ]

  if (isAdmin(user)) {
    routes.push({
      title: translate('Admin'),
      path: '/admin',
      children: [
        {
          title: translate('Marketplace overview'),
          path: '/admin/marketplace-overview',
          component: MarketplaceOverview,
        },
        {
          title: translate('Sell requests'),
          path: '/admin/sell-requests',
          component: SellRequests,
        },
      ],
    })
  }

  if (needsOnboarding(party)) {
    routes.push({
      title: translate('Onboarding'),
      path: '/onboarding',
      component: SellerOnboardingWizard,
    })
  }

  return (
    <PartyRoleContext.Provider value={PartyRole.BROKER}>
      <Platform routes={routes} routePrefix="/broker" />
    </PartyRoleContext.Provider>
  )
}

export default Broker
