import IAdminCreatePartyMember from 'domain/IAdminCreateUser'
import {IPartyMemberUpdateRequest} from 'domain/IPartyMemberUpdateRequest'
import IUser from 'domain/IUser'
import IAttachment from '../domain/IAttachment'
import IContract, {ContractState} from '../domain/IContract'
import IContractType from '../domain/IContractType'
import IOffer, {IOfferInvite} from '../domain/IOffer'
import IOfferTextGroup from '../domain/IOfferTextGroup'
import IPurchaseGroup, {IPurchaseGroupBase} from 'domain/IPurchaseGroup'
import IParty from '../domain/IParty'
import IPartyMember from '../domain/IPartyMember'
import IProduct from '../domain/IProduct'
import IProductType from '../domain/IProductType'
import ISite, {SiteProductionTechnology} from '../domain/ISite'
import ITimeseriesItem from '../domain/ITimeseriesItem'
import {SellExcessPayload} from '../domain/INewTimeseriesItem'
import IOfferRequest from '../domain/IOfferRequest'
import {IShoppingCart} from '../store/ShoppingCartStore'
import IPurchaseContract from 'domain/IPurchaseContract'
import ITextDocument from 'domain/ITextDocument'
import {TextDocumentType} from 'domain/IDocumentType'
import {IUserProfile} from 'domain/IUserProfile'
import {EnergyDataType} from '../domain/EnergyDataType'
import IOfferPriceCalculationResult from 'domain/IOfferPriceCalculationResult'
import {ISignedContractFile} from 'domain/ISignedContractFile'
import {IPaginateResult, IPaginatedExtendedResult} from 'domain/IPaginateResult'
import {IPasswordFromInvite} from '../domain/IPasswordFromInvite'
import {IPasswordFromOfferInvite} from '../domain/IPasswordFromOfferInvite'
import {ITimeseriesOverview} from 'domain/Portfolio'
import {IProposalDetailsBody} from 'domain/IProposalDetails'
import SellExcessRequest, {IRecommendedExcessPrice} from 'domain/ISellExcessRequest'
import ISellExcessRequest from 'domain/ISellExcessRequest'

export enum Interval {
  HOURLY = 'hourly',
  DAILY = 'daily',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export default interface IContentService {
  getTextDocument(documentType: TextDocumentType, locale: string): Promise<ITextDocument>

  sendContractVerificationCode(contractId: number)

  confirmContractVerificationCode(contractId: number, code: string)

  updateProfile(updatedUser: IUser)

  confirmUserPhoneNumber(code: string): Promise<void>

  sendUserPhoneVerification(phone: string): Promise<void>

  createPartyForCurrentUser(partyMember: Omit<IPartyMember, 'id'>): Promise<IPartyMember>

  savePartyForCurrentUser(partyMember: IPartyMember): Promise<IPartyMember>

  uploadConsumptionProfile(partyId: any, csv: File, name?: string): Promise<void>

  getPorfolioOverview(partyId: number, interval: Interval, start: Date, end: Date): Promise<ITimeseriesOverview>

  getConsumptionData(partyId: any, interval: Interval, energyDataType: EnergyDataType): Promise<ITimeseriesItem[]>

  getTimeseriesData(siteId: any, interval: Interval, energyDataType: EnergyDataType): Promise<ITimeseriesItem[]>

  sellCertExcessRequest(partyId: any, payload: SellExcessPayload): Promise<SellExcessRequest>

  getPurchaseGroupConsumptionData(partyId: any, purchaseGroup: any, interval: Interval): Promise<ITimeseriesItem[]>

  getYearlyConsumptionData(
    partyId: any,
    start: Date,
    end: Date,
    energyDataType: EnergyDataType,
  ): Promise<ITimeseriesItem[]>

  getYearlyTimeseriesData(
    siteId: any,
    start: Date,
    end: Date,
    energyDataType: EnergyDataType,
  ): Promise<ITimeseriesItem[]>

  getPurchaseGroupYearlyConsumptionData(
    partyId: any,
    purchaseGroup: any,
    start: Date,
    end: Date,
  ): Promise<ITimeseriesItem[]>

  uploadTimeseriesData(siteId: any, csv: File): Promise<ISite>

  cloneTimeseriesData(siteId: any, cloneSiteId: any): Promise<ISite>

  getProductsVisibleToParty(partyId: any): Promise<IProduct[]>

  getProductVisibleToParty(partyId: any, id: any): Promise<IProduct>

  getProductsManagedByParty(partyId: any): Promise<IProduct[]>

  getProductManagedByParty(partyId: any, id: any): Promise<IProduct>

  editCertificateOffer(partyId: any, offerId: any, payload: IProposalDetailsBody): Promise<IOffer>

  sendCertificateReview(partyId: any, offerId: any): Promise<void>

  approveCertificateOffer(partyId: any, offerId: any): Promise<void>

  declineCertificateOffer(partyId: any, offerId: any): Promise<void>

  getPublicExcessSellRequests(partyId: any): Promise<IPaginatedExtendedResult<ISellExcessRequest>>

  getRecommendedExcessPrice(partyId: any, technologyKind: SiteProductionTechnology): Promise<IRecommendedExcessPrice>

  acceptExcessSellRequest(sellRequestId: any): Promise<void>

  declineExcexsSellRequest(sellRequestId: any): Promise<void>

  getProductTypes(partyId: any): Promise<IProductType[]>

  createProduct(partyId: any, product: IProduct): Promise<IProduct>

  updateProduct(partyId: any, product: IProduct): Promise<IProduct>

  getConsumptionSiteTemplates(partyId: any): Promise<ISite[]>

  getProductionSiteTemplates(partyId: any): Promise<ISite[]>

  getSites(partyId: any): Promise<ISite[]>

  createSite(partyId: any, site: ISite): Promise<ISite>

  updateSite(partyId: any, site: ISite): Promise<ISite>

  associatePurchaseGroupToSites(partyId: any, purchaseGroup: IPurchaseGroupBase, siteIds: number[]): Promise<void>

  softDeleteSite(partyId: any, siteId: any): Promise<ISite>

  reserveProduction(partyId: any, reserve: IShoppingCart[]): Promise<void>

  updateContract(partyId: any, contract: IContract): Promise<void>

  getPartyContracts(partyId: any, state?: ContractState): Promise<IContract[]>

  getDraftContractsWithoutOffer(partyId: any, state?: ContractState): Promise<IContract[]>

  getContractPdf(contractId: any): Promise<Blob>

  getContractContractTemplatePdf(contractId: any, templateId: any, partyId: any): Promise<Blob>

  getContractContractTemplates(contractId: any, partyId: any): Promise<any>

  getContractHtml(contractId: any): Promise<string>

  getDefaultContractType(asPartyId: any): Promise<IContractType>

  getContractTypes(asPartyId: any): Promise<IContractType[]>

  getManagedParties(asPartyId: any): Promise<IParty[]>

  getManagedParty(asPartyId: any, partyId: any): Promise<IParty>

  getPartyMembers(asPartyId: any): Promise<IPartyMember[]>

  getPartyMember(asPartyId: number, partyMemberId: number): Promise<IPartyMember>

  resendUserInvite(asPartyId: number, partyMemberId: number): Promise<void>

  setPasswordFromInvite(accessCode: string, password: IPasswordFromInvite): Promise<void>

  setPasswordFromOfferInvite(accessCode: string, user: IPasswordFromOfferInvite): Promise<IOffer>

  getUserFromInvite(accessCode: string): Promise<IUser>

  getUserFromOfferInvite(accessCode: string): Promise<IOfferInvite>

  createPartyMemberUser(asPartyId: number, partyMember: IAdminCreatePartyMember): Promise<IPartyMember>

  updatePartyMember(asPartyId: any, partyMember: IPartyMemberUpdateRequest): Promise<IPartyMember>

  disablePartyMember(asPartyId: number, partyMemberId: number): Promise<IPartyMember>

  enablePartyMember(asPartyId: number, partyMemberId: number): Promise<IPartyMember>

  createManagedParty(asPartyId: any, party: IParty): Promise<IParty>

  updateManagedParty(asPartyId: any, party: IParty): Promise<IParty>

  softDeleteManagedParty(partyId: any): Promise<IParty>

  softDeleteManagedPartyOffer(partyId: any, offerId: any): Promise<IOffer>

  getPagedOffers(partyId: any, size?: any, page?: any): Promise<IPaginateResult<IOffer>>

  getPagedCustomerOffers(partyId: any, size?: any, page?: any): Promise<IPaginateResult<IOffer>>

  getAllOffers(partyId: any): Promise<IOffer[]>

  getOffer(partyId: any, offerId: any): Promise<IOffer>

  sendDraftToCustomer(partyId: any, offerId: any): Promise<any>

  sendBindingToCustomer(partyId: any, offerId: any): Promise<any>

  toBindingDraft(partyId: any, offerId: any): Promise<any>

  userInterestedInOffer(partyId: any, offerId: any): Promise<any>

  userNotInterestedInOffer(partyId: any, offerId: any): Promise<any>

  createOffer(partyId: any, offer: IOffer): Promise<IOffer>

  updateOffer(partyId: any, offer: IOffer): Promise<IOffer>

  getOfferTextGroups(partyId: any, offerId: any): Promise<IOfferTextGroup[]>

  uploadAttachment(file: File, name?: string): Promise<IAttachment>

  getAttachmentURL(attachment: IAttachment): string

  getSignedContractFileURL(attachment: ISignedContractFile): string

  loadMandatoryProducts(contractTypeId: number, partyId: any): Promise<IProduct[]>

  getPurchaseContracts(partyId: any): Promise<IPurchaseContract[]>

  createPurchaseContract(partyId: any, contract: IPurchaseContract): Promise<IPurchaseContract>

  getSalesContracts(partyId: any): Promise<IPurchaseContract[]>

  updatePurchaseContract(partyId: any, contract: IPurchaseContract): Promise<IPurchaseContract>

  getPartyPurchaseGroups(partyId: number): Promise<IPurchaseGroup[]>

  getPurchaseGroup(partyId: number, purchaseGroupId: number): Promise<IPurchaseGroup>

  getPartyOfferRequests(partyId: number): Promise<IOfferRequest[]>

  getContractSnapshot(contractId: number, partyId: any): Promise<IContract>

  defaultCalculateOfferPrice(offer: IOffer, partyId?: any): Promise<IOfferPriceCalculationResult>

  simpleCalculateOfferPrice(offer: IOffer, partyId?: any): Promise<IOfferPriceCalculationResult>

  uploadSignedContractFiles(contractId: number, files: File[]): Promise<void>

  deleteSignedContractFile(contractId: number, fileId: number): Promise<void>

  getSignedContractFiles(contractId: number): Promise<ISignedContractFile[]>
}
