import {action, computed, observable} from 'mobx'
import config from '../config'
import ITheme from '../theme/ITheme'

interface ITranslations {
  [key: string]: string
}

const DEFAULT_LOCALE = config.language || 'en'

export default class LocalizationStore {
  @observable
  private _translations: ITranslations = {}

  @observable
  private _locale: string

  public constructor() {
    this.changeLocale(DEFAULT_LOCALE)
  }

  public translate(text: string, theme: ITheme, ...modifiers: Array<string | number>): string {
    let newText = text

    if (this.translations[`[${config.theme}] ` + text]) {
      newText = this.translations[`[${config.theme}] ` + text]
    } else if (this.translations[text]) {
      newText = this.translations[text]
    }

    modifiers.forEach(modifier => {
      newText = newText.replace('%s', modifier ? modifier.toString() : '')
    })

    if (theme.lgcEquivalent && newText.match(/\bLGC/i)) {
      newText = newText.replace(/LGC/i, theme.lgcEquivalent)
    }

    if (theme.nmiEquivalent && newText.match(/\bNMI/i)) {
      newText = newText.replace(/NMI/i, theme.nmiEquivalent)
    }

    if (theme.firmingEquivalent && newText.match(/\bFirming/i)) {
      newText = newText.replace(/Firming/i, theme.firmingEquivalent)
    }

    return newText
  }

  @action
  private setLocale(locale: string, translations: ITranslations): void {
    this._locale = locale
    this._translations = translations
  }

  public async changeLocale(locale: string) {
    const translations = await import(`../localization/${locale}.json`)

    this.setLocale(locale, translations)
  }

  @computed
  public get locale(): string {
    return this._locale
  }

  @computed
  public get translations(): ITranslations {
    return this._translations
  }
}
