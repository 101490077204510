import moment, {unitOfTime} from 'moment'

export enum DateFormat {
  MONTH_DAY_YEAR = 'MMM D, YYYY', // Jul 30, 2008
  DAY_MONTH_YEAR_HOURS_MINUTES = 'DD.MM.YYYY HH:mm', // 12.10.1999 12:15
  MONTH_YEAR = 'MMM YYYY', // Jun 2024
  DAY_MONTH_YEAR = 'DD.MM.YYYY', // 12.01.2020
  YEAR_MONTH_DAY = 'YYYY-MM-DD', // 12-01-2020
  MONTH_SHORT = 'MMM',
  MONTH_LONG = 'MMMM',
  HOURS_MINUTES = 'HH:mm',
}

export function formatDate(
  date: string | Date,
  format: DateFormat | string = DateFormat.DAY_MONTH_YEAR_HOURS_MINUTES,
): string {
  const momentDate = moment(date)

  return momentDate.isValid() ? momentDate.format(format) : '-'
}

export function toDateRange(startYear: number, endYear: number): [Date, Date] {
  const startDate = startYear ? moment().set('year', startYear).startOf('year').toDate() : null
  const endDate = endYear ? moment().set('year', endYear).endOf('year').toDate() : null

  return [startDate, endDate]
}

export function getPeriod(
  date1: string | Date,
  date2: string | Date,
  format: DateFormat = DateFormat.DAY_MONTH_YEAR,
): string {
  return `${date1 ? formatDate(date1, format) : '...'} - ${date2 ? formatDate(date2, format) : '...'}`
}

export function toDate(date: string | Date, format?: string): Date | null {
  if (!date) {
    return null
  }

  return moment(date, format).toDate()
}

export function getYear(date: string | Date): number {
  if (!date) {
    return null
  }

  return moment(date).get('year')
}

export function getMonth(date: string | Date): number {
  if (!date) {
    return null
  }

  return moment(date).get('month')
}

export function getPercentageOfYear(date: string | Date): number {
  const dayOfYear = moment(date).dayOfYear()

  return dayOfYear / 365
}

export function getMinimumDate(...dates: Array<string | Date>): string | Date {
  let minDate: string | Date = null

  for (const date of dates) {
    if (date && (!minDate || moment(date).isBefore(minDate))) {
      minDate = date
    }
  }

  return minDate
}

export function getMaximumDate(...dates: Array<string | Date>): string | Date {
  let maxDate: string | Date = null

  for (const date of dates) {
    if (date && (!maxDate || moment(date).isAfter(maxDate))) {
      maxDate = date
    }
  }

  return maxDate
}

export function previousDate(date: string | Date, timeUnit: unitOfTime.DurationConstructor, amount: number) {
  return moment(date).subtract(amount, timeUnit).toDate()
}

export function dateStartOf(date: string | Date, timeUnit: unitOfTime.DurationConstructor) {
  return moment(date).startOf(timeUnit).toDate()
}

export function dateEndOf(date: string | Date, timeUnit: unitOfTime.DurationConstructor) {
  return moment(date).endOf(timeUnit).toDate()
}

export function futureDate(date: string | Date, timeUnit: unitOfTime.DurationConstructor, amount: number) {
  return moment(date).add(amount, timeUnit).toDate()
}

export function getInvolvedYears(date1: string | Date, date2: string | Date): number[] {
  const year1 = getYear(date1)
  const year2 = getYear(date2)

  if (!year1 && !year2) {
    return []
  }

  if (!year1) {
    return [year2]
  }

  if (!year2) {
    return [year1]
  }

  const years: number[] = []

  for (let i = year1; i <= year2; i++) {
    years.push(i)
  }

  return years
}

export function isDateBetween(date: string | Date, start: string | Date, end: string | Date): boolean {
  return moment(date).isBetween(start, end, undefined, '[]')
}

export function isDateAfter(date: string | Date, other: string | Date): boolean {
  return moment(date).isAfter(other)
}

export function getAllMonthsOfYear(): Date[] {
  return Array(12)
    .fill(0)
    .map((value, monthIndex) => {
      const date = new Date()

      date.setUTCMonth(monthIndex)

      return date
    })
}

export function timeUntilDate(oldDate: string | Date, newDate: string | Date): number {
  return moment(oldDate).diff(moment(newDate), 'hours')
}

export function getCurrentYear(): number {
  return getYear(moment().toDate())
}

export function getCurrentMonth(): number {
  return getMonth(moment().toDate())
}

export function getPrevMonth(): string {
  return moment().subtract(1, 'month').format('MMMM')
}

export function dateByMonthAndYear(month: string, year: number): Date {
  if (!month || !year) {
    return null
  }

  return moment().month(month).set('year', year).toDate()
}

export function lastDayOfMonth(month: number, year: number): number {
  const date = new Date()
  date.setFullYear(year)
  date.setMonth(month - 1)

  return moment(date).endOf('month').get('date')
}

export function getMonthFromString(mon) {
  return new Date(Date.parse(mon + ' 1, 2024')).getMonth() + 1
}

export function getMonthDays(month: number, year: number): number {
  return moment()
    .month(month - 1)
    .set('year', year)
    .daysInMonth()
}

export function getMonths(): string[] {
  return moment.months()
}

export function getPastYears(amount: number): number[] {
  return Array.from({length: amount + 1}, (v, k) => moment().year() - k)
}

export function getHourlyInterval(date: string): string {
  const momentDate = moment(date)
  momentDate.add(1, 'hour')

  return `${formatDate(date, DateFormat.HOURS_MINUTES)} - ${formatDate(momentDate.toDate(), DateFormat.HOURS_MINUTES)}`
}

export function generateYears(startingYear: number, amount: number): number[] {
  return Array.from({length: amount}, (v, k) => startingYear - k)
}
