import ResourceGateway from '../../../components/ResourceGateway'
import React from 'react'
import OfferContractDesigner from './OfferContractDesigner'
import NotFound from '../../../components/NotFound'

const ContractDesignerGateway = () => {
  return (
    <ResourceGateway
      single={routeProps => <OfferContractDesigner id={routeProps.match.params.id} />}
      list={() => <NotFound />}
    />
  )
}

export default ContractDesignerGateway
