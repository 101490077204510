import React, {useEffect} from 'react'
import useStore from '../../../hooks/useStore'
import useStoreData from '../../../hooks/useStoreData'
import IParty from '../../../domain/IParty'
import NotFound from '../../NotFound'
import {useSetAtom} from 'jotai'
import {passedPartyAtom} from 'atoms/portfolioSites'
import FullscreenLoader from 'components/FullscreenLoader'

interface IProps extends React.PropsWithChildren {
  id: any
  component: React.FC<{party: IParty}>
}

const PartyResolver: React.FC<IProps> = ({id, component: Component, ...props}) => {
  const {partyStore, profileStore} = useStore()
  const partyLoaded = profileStore.isLoaded
  const setParty = useSetAtom(passedPartyAtom)
  const {party, isLoading} = useStoreData(store => ({
    party: store.partyStore.getItem(id),
    isLoading: store.partyStore.isLoading,
  }))

  useEffect(() => {
    if (partyLoaded) {
      partyStore.loadManagedParty(id)
    }
  }, [partyLoaded])

  useEffect(() => {
    setParty(party)

    return () => setParty(null)
  }, [party])

  if (!partyLoaded || isLoading) {
    return <FullscreenLoader />
  }

  if (id && !party) {
    return <NotFound />
  }

  return <Component party={party} {...props} />
}

export default PartyResolver
