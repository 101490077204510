import React from 'react'
import ISite from '../../../../domain/ISite'
import useResource from '../../../../hooks/useResource'
import Heading from '../../../Heading'
import useLocalization from '../../../../hooks/useLocalization'
import Table from '../../../Table'
import IToken from '../../../../domain/IToken'
import {DateFormat, formatDate} from '../../../../helpers/date'
import Loader from '../../../Loader'
import Error from '../../../Error'
import {
  filterByWalletId,
  getHumanReadableTokenStatus,
  getSmartContractTokenSharePercentage,
  getSmartContractTokenStatus,
} from '../../../../helpers/smartContract'
import {formatPercentage} from '../../../../helpers/format'
import ProductionOwnershipChart from './ProductionOwnershipChart'
import Box from '../../../Box'
import {DownloadLink1} from '../../../DownloadLink'
import Link from '../../../Link'
import TokenDetailsModal from './TokenDetailsModal'
import Truncated from '../../../Truncated'
import useProfile from '../../../../hooks/useProfile'
import {TokenType} from './TabbedTokenDetails'

interface IProps extends React.PropsWithChildren {
  site: ISite
}

const ContractSiteProductionOwnership: React.FC<IProps> = ({site}) => {
  const {translate} = useLocalization()
  const {party} = useProfile()

  const smartContractId = site.meta?.smartContractId
  const walletId = party?.meta?.walletId

  const {smartContract, isLoading} = useResource(
    store => ({
      smartContract: store.smartEnergyContractStore.getItem(smartContractId),
      isLoading: store.smartEnergyContractStore.isLoading,
    }),
    store => smartContractId && store.smartEnergyContractStore.loadById(smartContractId),
    smartContractId,
  )

  if (!smartContract && isLoading) {
    return <Loader />
  }

  if (!smartContractId) {
    return <Error>{translate('Site is missing SET token')}</Error>
  }

  if (!smartContract) {
    return <Error>{translate('Unable to load smart contract')}</Error>
  }

  const filteredTokens = filterByWalletId(smartContract.tokens, walletId)

  return (
    <div>
      <Box margin={{vertical: 4}}>
        <ProductionOwnershipChart tokens={filteredTokens} walletId={walletId} />
      </Box>
      <Heading margin={{bottom: 2}}>{translate('Ownership tokens')}</Heading>
      <Table<IToken>
        search={{placeholder: translate('Search tokens')}}
        columns={[
          {
            title: translate('Token Id'),
            accessor: item => item.id,
            render: value => <Truncated maxWidth={10}>{value}</Truncated>,
          },
          {
            title: translate('Token month'),
            accessor: item => item.deliveryStart,
            render: (value: Date) => formatDate(value, DateFormat.MONTH_YEAR),
          },
          {
            title: translate('Origin site'),
            accessor: () => site.name,
          },
          {
            title: translate('Share'),
            accessor: token => `${formatPercentage(getSmartContractTokenSharePercentage(token, walletId))}%`,
          },
          {
            title: translate('Token status'),
            accessor: token => getHumanReadableTokenStatus(getSmartContractTokenStatus(token), translate),
          },
          {
            title: translate('Token details'),
            accessor: token => token.id,
            render: id => (
              <Link to={`?tab.contract=production-ownership&modal.token-details=${id}`}>
                <DownloadLink1 iconHeight={4} noText />
              </Link>
            ),
          },
        ]}
        data={filteredTokens}
      />
      <TokenDetailsModal
        smartContract={smartContract}
        type={TokenType.SET}
        tokens={filteredTokens}
        site={site}
        walletId={walletId}
      />
    </div>
  )
}

export default ContractSiteProductionOwnership
