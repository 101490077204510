import React from 'react'
import IContract from '../../../../../domain/IContract'
import Chart, {ChartType, getMaxValue, ILine, StackType} from '../../../../Chart'
import useLocalization from '../../../../../hooks/useLocalization'
import useTheme from '../../../../../hooks/useTheme'
import IParty from '../../../../../domain/IParty'
import {autoCeilNumber, HexToRGBWithOpacity, sumArrayOfArrays} from '../../../../../helpers/misc'
import {
  getActivePeriodContractDurationInfo,
  getContractDurationInMonthsForYear,
  getSitesOfContracts,
  volumeTypeExistsInContract,
  volumeTypeExistsInContracts,
} from '../../../../../helpers/contract'
import {getContractItemChartValues} from '../../../../../helpers/contractsChart'
import ISite, {ISiteWithTimeseries} from '../../../../../domain/ISite'
import {zipSitesWithTimeseriesById} from '../../../../../helpers/site'
import {getCurrentMonth, getCurrentYear} from '../../../../../helpers/date'
import {VolumeType} from '../../../../../domain/IProductType'
import {Interval} from '../../../../../services/IContentService'
import ITimeseriesItem from 'domain/ITimeseriesItem'
import {ITimeseriesOverview} from 'domain/Portfolio'
import useStore from 'hooks/useStore'
import useNewChartColors from 'hooks/useNewChartColors'

export enum GroupBy {
  Contract,
  Site,
}

interface IProps extends React.PropsWithChildren {
  contracts: IContract[]
  party?: IParty
  partySites?: ISite[]
  groupBy?: GroupBy
  type?: ChartType
  activePeriod?: any
  timeseriesOverview: ITimeseriesOverview
  isLoading: boolean
}

export const reduceIntervals = (items: ITimeseriesItem[][]): ITimeseriesItem[] => {
  return items.filter(Boolean).reduce((prev, current) => {
    const copyCurrent = current.map(item => ({...item}))

    // Intervals are sorted in the same order, so we are safe to iterate them.
    for (let idx = 0; idx < copyCurrent.length; ++idx) {
      copyCurrent[idx].value += prev[idx]?.value || 0
    }

    return copyCurrent
  }, [])
}

const getLinesByContractActual = (
  contracts: IContract[],
  sites: ISiteWithTimeseries[],
  consumptionLine: ILine | null,
  partyConsumptionMwh: number,
  options: (contract: IContract) => Pick<ILine, 'color' | 'label'>,
  activePeriod: any,
  timeseriesInterval: Interval = Interval.MONTHLY,
): ILine[] => {
  return contracts.map(contract => {
    const currentMonth = getCurrentMonth()
    const {length, isContractStartYear, isContractEndYear, numberOfMonthsWithNoContract} =
      getActivePeriodContractDurationInfo(contract, activePeriod?.slug)

    const line: ILine = {
      key: `contract-${contract.id}`,
      label: null,
      color: null,
      data: sumArrayOfArrays(
        contract.contractItems
          ?.map(contractItem => {
            const site = sites.find(site => site.id === contractItem.product?.site?.id)

            const currentYear = getCurrentYear()
            if (!site) {
              return null
            }
            if (!site?.timeseries) {
              console.warn(`Missing timeseries for site with ID: ${site.id}`)
              return null
            }

            if (currentYear < activePeriod?.slug) {
              console.warn(`Invalid year ${currentYear}`)
              return null
            }

            // actual volumes are based on the percent of the product when the contract was signed, ie:
            // contract volumes = sum(% of actual production of each product's site)
            // const productionRatio = getRatioOfProduction(contractItem)
            //TODO Check this logic again
            const productionRatio = 1

            const updatedSiteTimeseries = [...site.timeseries]

            if (length && isContractStartYear && timeseriesInterval === Interval.MONTHLY) {
              updatedSiteTimeseries.splice(0, numberOfMonthsWithNoContract)

              for (let i = 0; i < numberOfMonthsWithNoContract; i++) {
                updatedSiteTimeseries.unshift({id: 0, name: '0', value: null})
              }
            }

            if (currentYear.toString() === activePeriod?.slug && timeseriesInterval === Interval.MONTHLY) {
              const slicedTimeseries = updatedSiteTimeseries.slice(0, currentMonth)

              for (let i = 0; i < 10 - currentMonth; i++) {
                slicedTimeseries.push({id: 0, name: '0', value: null})
              }

              return getContractItemChartValues(slicedTimeseries, contractItem).map(value => value * productionRatio)
            }

            return getContractItemChartValues(updatedSiteTimeseries, contractItem).map(value => value * productionRatio)
          })
          .filter(Boolean),
      ),
      type: ChartType.BAR,
      stack: 'actual',
      stackType: StackType.TERTIARY,
      ...options(contract),
    }

    // Checks whether AUTO_FILL product exists in a contract and increases volumes to consumption volumes when needed
    if (consumptionLine && volumeTypeExistsInContract(contract, VolumeType.AUTO_FILL)) {
      const percentageOfConsumption = contract.volumeMwh / partyConsumptionMwh

      line.data.forEach((value, index) => {
        if (length && length !== 12 && isContractStartYear && index < numberOfMonthsWithNoContract) {
          return
        }

        if (length && length !== 12 && isContractEndYear && index > numberOfMonthsWithNoContract) {
          return
        }

        const computedConsumptionValue = percentageOfConsumption * consumptionLine.data[index]
        if (computedConsumptionValue > value && value !== null) {
          line.data[index] = computedConsumptionValue
        }
      })
    }

    return line
  })
}

const getLinesByContract = (
  contracts: IContract[],
  sites: ISiteWithTimeseries[],
  consumptionLine: ILine | null,
  partyConsumptionMwh: number,
  options: (contract: IContract) => Pick<ILine, 'color' | 'label'>,
  activePeriod: any,
  timeseriesInterval: Interval = Interval.MONTHLY,
): ILine[] => {
  return contracts.map(contract => {
    const {length, isContractStartYear, isContractEndYear, numberOfMonthsWithNoContract} =
      getActivePeriodContractDurationInfo(contract, activePeriod?.slug)

    const line: ILine = {
      key: `contract-${contract.id}`,
      label: null,
      color: null,
      data: sumArrayOfArrays(
        contract.contractItems
          ?.map(contractItem => {
            const site = sites.find(site => site.id === contractItem.product?.site?.id)

            if (!site?.timeseries) {
              return null
            }

            if (length && isContractStartYear && timeseriesInterval === Interval.MONTHLY) {
              const siteTimeseries = [...site.timeseries]
              siteTimeseries.splice(0, numberOfMonthsWithNoContract)

              for (let i = 0; i < numberOfMonthsWithNoContract; i++) {
                siteTimeseries.unshift({id: 0, name: '0', value: 0})
              }

              return getContractItemChartValues(siteTimeseries, contractItem)
            }

            if (length && isContractEndYear && timeseriesInterval === Interval.MONTHLY) {
              const slicedTimeseries = site.timeseries.slice(0, length)

              for (let i = 0; i < numberOfMonthsWithNoContract; i++) {
                slicedTimeseries.push({id: 0, name: '0', value: 0})
              }

              return getContractItemChartValues(slicedTimeseries, contractItem)
            }

            return getContractItemChartValues(site.timeseries, contractItem)
          })
          .filter(Boolean),
      ),
      type: ChartType.BAR,
      stackType: StackType.STACKED,
      stack: 'predicted',
      ...options(contract),
    }

    // Checks whether AUTO_FILL product exists in a contract and increases volumes to consumption volumes when needed
    if (consumptionLine && volumeTypeExistsInContract(contract, VolumeType.AUTO_FILL)) {
      const percentageOfConsumption = contract.volumeMwh / partyConsumptionMwh

      line.data.forEach((value, index) => {
        if (length && length !== 12 && isContractStartYear && index < numberOfMonthsWithNoContract) {
          return
        }

        if (length && length !== 12 && isContractEndYear && index > numberOfMonthsWithNoContract) {
          return
        }

        const computedConsumptionValue = percentageOfConsumption * consumptionLine.data[index]
        if (computedConsumptionValue > value) {
          line.data[index] = computedConsumptionValue
        }
      })
    }

    return line
  })
}

const getLinesBySite = (
  contracts: IContract[],
  sites: ISiteWithTimeseries[],
  options: (site: ISite) => Pick<ILine, 'color' | 'label'>,
  activePeriod: any,
  timeseriesInterval: Interval = Interval.MONTHLY,
): ILine[] => {
  const lines: ILine[] = sites.map(site => {
    return {
      key: `site-${site.id}`,
      label: null,
      color: null,
      data: sumArrayOfArrays(
        contracts.map(contract => {
          const {length, numberOfMonthsWithNoContract, isContractStartYear, isContractEndYear} =
            getActivePeriodContractDurationInfo(contract, activePeriod?.slug)

          return sumArrayOfArrays(
            contract.contractItems
              ?.map(contractItem => {
                if (!site?.timeseries || contractItem.product?.site?.id !== site.id) {
                  return null
                }

                if (length && isContractStartYear && timeseriesInterval === Interval.MONTHLY) {
                  const siteTimeseries = [...site.timeseries]
                  siteTimeseries.splice(0, numberOfMonthsWithNoContract)

                  for (let i = 0; i < numberOfMonthsWithNoContract; i++) {
                    siteTimeseries.unshift({id: 0, name: '0', value: 0})
                  }

                  return getContractItemChartValues(siteTimeseries, contractItem)
                }

                if (length && isContractEndYear && timeseriesInterval === Interval.MONTHLY) {
                  const slicedTimeseries = site.timeseries.slice(0, length)

                  for (let i = 0; i < numberOfMonthsWithNoContract; i++) {
                    slicedTimeseries.push({id: 0, name: '0', value: 0})
                  }

                  return getContractItemChartValues(slicedTimeseries, contractItem)
                }

                return getContractItemChartValues(site.timeseries, contractItem)
              })
              .filter(Boolean),
          )
        }),
      ),
      type: ChartType.BAR,
      ...options(site),
    }
  })

  return lines
}

const ContractsChart: React.FC<IProps> = ({
  party,
  contracts,
  partySites,
  timeseriesOverview,
  groupBy,
  type,
  activePeriod,
  isLoading,
}) => {
  const chartColors = useNewChartColors()
  const {translate} = useLocalization()
  const theme = useTheme()
  const {portfolioStore} = useStore()
  const sites = getSitesOfContracts(contracts)

  const interval = portfolioStore.interval

  const timeseries = {
    parties: timeseriesOverview.party.predicted.sites.filter(site => site.timeseries?.length > 0),
    sites: timeseriesOverview.contracts
      .flatMap(summary => summary.predicted.sites)
      .filter(summary => summary.timeseries?.length > 0),
    contractSitesActual: timeseriesOverview.contracts.flatMap(summary => summary.actual.sites),
    partySitesActual: timeseriesOverview.party.actual.sites.filter(site => site.timeseries?.length > 0),
    //TODO
    // purchaseGroups: purchaseGroups?.map(purchaseGroup =>
    //   store.timeseriesStore.getPurchaseGroupConsumption(purchaseGroup.id),
    // ),
  }

  const sitesWithTimeseries = zipSitesWithTimeseriesById(sites, timeseries.sites)
  const contractSitesActualTs = zipSitesWithTimeseriesById(sites, timeseries.contractSitesActual)

  const partySitesActualTs = zipSitesWithTimeseriesById(partySites, timeseries.partySitesActual)

  const arr =
    [...timeseries.parties, ...timeseries.sites, ...timeseries.contractSitesActual, ...timeseries.partySitesActual] ||
    []
  const uniqLabels = new Set(
    arr.filter(res => res.timeseries?.length > 0).flatMap(res => res.timeseries?.map(interval => interval.name)),
  )
  const labels = Array.from(uniqLabels)
  const lines: ILine[] = []

  const actualData = reduceIntervals(partySitesActualTs.map(s => s.timeseries))
  const hasActualConsumption = actualData?.length > 0
  let consumptionLineActual: ILine = null

  if (hasActualConsumption) {
    consumptionLineActual = {
      key: 'consumption',
      label: translate('My consumption'),
      color: theme.colors.blue7,
      data: actualData.map(item => item.value),
      type: ChartType.LINE,
    }
    lines.push(consumptionLineActual)
  }

  let consumptionLinePredicted: ILine = null

  if (timeseries?.parties[0]) {
    consumptionLinePredicted = timeseries.parties[0] && {
      key: 'consumption-predicted',
      label: translate('My consumption prediction'),
      color: `rgba(${HexToRGBWithOpacity(theme.colors.blue7, 0.2).toString()})`,
      data: timeseries.parties[0].timeseries.map(({value}) => value),
      type: ChartType.LINE,
    }

    lines.push(consumptionLinePredicted)
  }

  if (groupBy === GroupBy.Site) {
    const productionLines: ILine[] = getLinesBySite(
      contracts,
      sitesWithTimeseries,
      site => ({
        label: `${site.name} `,
        color: theme.colors.technology[site.productionTechnology],
        type,
      }),
      activePeriod,
      interval,
    )

    if (
      groupBy === GroupBy.Site &&
      consumptionLineActual &&
      volumeTypeExistsInContracts(contracts, VolumeType.AUTO_FILL)
    ) {
      const contractLines = getLinesByContract(
        contracts,
        sitesWithTimeseries,
        consumptionLineActual,
        party.totalAvgYearlyConsumptionMwh,
        contract => ({
          label: translate('#%s volume', contract.id),
          color: chartColors.getNext(),
          type,
        }),
        activePeriod,
        interval,
      )
      const allContractDataMerged = contractLines.reduce((r, a) => a.data.map((b, i) => (r[i] || 0) + b), [])
      productionLines.push({
        key: 'firming',
        label: translate('Firming'),
        color: theme.colors.light1,
        data: sumArrayOfArrays(
          contracts.map(contract => {
            const length = getContractDurationInMonthsForYear(contract, parseInt(activePeriod?.slug))

            const percentageOfConsumption = contract.volumeMwh / party.totalAvgYearlyConsumptionMwh
            const productsVolumes = sumArrayOfArrays(
              contract.contractItems
                ?.map(contractItem => {
                  const site = sitesWithTimeseries.find(site => site.id === contractItem.product?.site?.id)

                  if (!site?.timeseries) {
                    return null
                  }

                  return getContractItemChartValues(
                    length ? site.timeseries.slice(0, length) : site.timeseries,
                    contractItem,
                  )
                })
                .filter(Boolean),
            )

            return allContractDataMerged.map((value, index) => {
              const data = value * percentageOfConsumption - productsVolumes[index]
              if (!data) {
                return 0
              }
              return Math.max(0, value * percentageOfConsumption - productsVolumes[index])
            })
          }),
        ),
        type: ChartType.BAR,
      })
    }

    lines.push(...productionLines)
  }

  if (groupBy === GroupBy.Contract) {
    const productionLines: ILine[] = getLinesByContract(
      contracts,
      sitesWithTimeseries,
      consumptionLinePredicted,
      party.totalAvgYearlyConsumptionMwh,
      contract => ({
        label: translate('#%s predicted volume', contract.id),
        color: `rgba(${HexToRGBWithOpacity(chartColors.getNext(), 0.2).toString()})`,
        type,
      }),
      activePeriod,
      interval,
    )

    lines.push(...productionLines)
  }

  if (groupBy === GroupBy.Contract) {
    const productionLines: ILine[] = getLinesByContractActual(
      contracts,
      contractSitesActualTs,
      consumptionLineActual ? consumptionLineActual : consumptionLinePredicted,
      party.totalAvgYearlyConsumptionMwh,
      contract => ({
        label: translate('#%s actual volume', contract.id),
        color: chartColors.getNext(),
        type,
      }),
      activePeriod,
      interval,
    )

    lines.push(...productionLines)
  }

  const nonEmptyLines = lines.filter(line => line.data?.length > 0)

  return (
    <Chart
      toolbar={{vertical: true, timescale: {hideYearly: true}}}
      lines={nonEmptyLines}
      labels={labels}
      dark
      maxValue={autoCeilNumber(getMaxValue(lines))}
    />
  )
}

ContractsChart.defaultProps = {
  groupBy: GroupBy.Contract,
  type: ChartType.BAR,
}

export default ContractsChart
