import React from 'react'
import Form from 'components/Form'
import Text from 'components/Text'
import {useHistory} from 'react-router-dom'
import useLocalization from 'hooks/useLocalization'
import HorizStack from 'components/HorizStack'
import Box from 'components/Box'
import {SiteShape, SiteState} from 'domain/ISite'
import useTheme from 'hooks/useTheme'
import {getHumanReadableState} from 'helpers/site'
import {Container, FormContainer, StyledFormSelect} from '../Layout'
import useServices from 'hooks/useServices'
import useStore from 'hooks/useStore'
import {partyAtom} from 'atoms/party'
import {useAtom, useAtomValue} from 'jotai'
import {ICreateOnbBuyerSiteReq} from 'domain/ICreateOnbSiteReq'
import {LocationBox} from 'components/Platform/Portfolio/Site/LocationSearch'
import ILocation from 'domain/ILocation'
import {buyerOnboardingAtom} from 'atoms/onboarding'
import {Heading} from '../Heading'
import {getOnboardSite} from 'domain/IBuyerOnboardState'
import {StyledFormButton, StyledFormInput, StyledLocationSearch} from '../Layout/FormComponents'
import CenteredLoader from 'components/Platform/ProductDetails/ReservationBox/components/CenteredLoader'

interface IProps extends React.PropsWithChildren {
  onSuccess?: () => void
}

const SetupSitesForm: React.FC<IProps> = ({...props}) => {
  const theme = useTheme()
  const {translate} = useLocalization()
  const {v2ContentService} = useServices()
  const [party, setParty] = useAtom(partyAtom)
  const {data: onboardResult, loading: onbLoading, error: onbError} = useAtomValue(buyerOnboardingAtom)
  const {alertStore} = useStore()
  const history = useHistory()

  const handleSubmit = async formData => {
    const location: ILocation = formData.location

    const req: ICreateOnbBuyerSiteReq = {
      name: formData.name,
      description: formData.description,
      state: formData.state,
      shape: SiteShape.COMMERCIAL,
      placeId: location.placeId,
    }

    try {
      const res = await v2ContentService.createBuyerOnbSite(party.id, req)
      const updated = {
        ...party,
        onboardingStatus: res.onboardingStatus,
      }
      setParty(updated)
      history.push('/consumer/onboarding/upload-consumption')
    } catch (ex) {
      alertStore.addError('Failed to save site', ex?.correlationId, ex?.message)
    }
  }

  if (onbError) {
    return (
      <Container>
        <Heading size="large" color={theme.colors.common.white}>
          {translate('Something went wrong')}
        </Heading>
      </Container>
    )
  }

  if (onbLoading) {
    return (
      <Container>
        <CenteredLoader size="medium" color={theme.colors.common.white} />
      </Container>
    )
  }

  const site = getOnboardSite(onboardResult)
  const isEdit = site !== null

  return (
    <Box>
      <FormContainer>
        <Form onSubmit={handleSubmit} fillWidth={true} submissionFeedback={null} defaultValues={site}>
          <Text size="xxxlarge" color={theme.colors.common.black} margin={{bottom: 2}} data-cy="site-step-title">
            1. {translate('Set up site')}
          </Text>
          <StyledFormInput
            $small
            defaultValue={''}
            name="name"
            label={translate('Site name')}
            placeholder={translate('Insert site name')}
            required
            data-cy="site-name"
          />
          <StyledFormInput
            $small
            name="description"
            defaultValue={''}
            label={translate('Site description')}
            placeholder={translate('Insert site description')}
            required
            data-cy="site-description"
          />
          <StyledFormSelect
            name="state"
            label={translate('Status')}
            required
            placeholder={translate('Select one..')}
            options={Object.keys(SiteState).map(state => ({
              value: state,
              label: getHumanReadableState(state as SiteState, translate),
            }))}
            data-cy="site-state"
          />
          <LocationBox data-cy="location-select">
            <Text size="small" uppercase margin={{bottom: 0.5}}>
              {translate('Location')}
            </Text>
            <StyledLocationSearch
              $small
              location={site?.location}
              variant="contrast"
              placeholder={translate('Start typing the address(based on Google Map)')}
              required
              data-cy="site-location"
            />
          </LocationBox>
          <HorizStack justify="flex-end" margin={{top: 1}}>
            {/* <CancelButton type="button" onClick={() => history.push('/consumer/onboarding/welcome')}>
              <Text uppercase semibold>
                {translate('Back')}
              </Text>
            </CancelButton> */}
            <StyledFormButton>{isEdit ? translate('Update') : translate('Continue')}</StyledFormButton>
          </HorizStack>
        </Form>
      </FormContainer>
    </Box>
  )
}

export default SetupSitesForm
