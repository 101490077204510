import IContract, {ContractVolumeProfile} from '../domain/IContract'
import {ILine, StackType} from '../components/Chart'
import IParty from '../domain/IParty'
import {getMaximumDate, getMinimumDate, getPercentageOfYear, getYear} from './date'
import IContractItem from '../domain/IContractItem'
import ITimeseriesItem from '../domain/ITimeseriesItem'
import IPurchaseGroup, {getAvgYearlyConsumptionMwh} from 'domain/IPurchaseGroup'
import IPurchaseContract, {IPurchaseParty} from 'domain/IPurchaseContract'

export function getMultipliedLine(line: ILine, multiplier: number): number[] {
  return line.data.map(v => multiplier * v)
}

export function getSumOfStackedLines(lines: ILine[]): number[] {
  const stackedLines = lines.filter(line => line.stackType === StackType.STACKED)

  if (!lines.length) {
    return []
  }

  if (!stackedLines.length) {
    return Array(lines[0].data.length).fill(0)
  }

  return stackedLines.map(line => line.data).reduce((a, b) => a.map((c, i) => c + b[i]))
}

export function flattenLineData(lineData: number[]): number[] {
  const total = lineData.reduce((a, b) => a + b, 0)

  return Array(lineData.length).fill(total / lineData.length)
}

export function getPurchasedEnergyData(
  contract: IContract | IPurchaseContract,
  lines: ILine[],
  party: IParty | IPurchaseParty,
  purchaseGroup: IPurchaseGroup,
): number[] {
  let aggregatedData: number[] = []

  if (contract.volumeProfile === ContractVolumeProfile.GENERATION_FOLLOWING) {
    // Sums all stacked lines values together to get purchased energy
    aggregatedData = getSumOfStackedLines(lines)
  } else if (purchaseGroup) {
    const consumptionMultiplier = contract.volumeMwh / getAvgYearlyConsumptionMwh(purchaseGroup)
    const line = lines.find(line => line.stackType !== StackType.STACKED)

    if (line) {
      aggregatedData = getMultipliedLine(line, consumptionMultiplier)
    }
  } else if (party) {
    const consumptionMultiplier = contract.volumeMwh / party.totalAvgYearlyConsumptionMwh
    const line = lines.find(line => line.stackType !== StackType.STACKED)

    if (line) {
      aggregatedData = getMultipliedLine(line, consumptionMultiplier)
    }
  }

  if (contract.volumeProfile === ContractVolumeProfile.FLAT) {
    aggregatedData = flattenLineData(aggregatedData)
  }

  return aggregatedData
}

export function reduceFirstAndLastYearValues(
  lineData: any,
  labels: string[],
  operationsStart,
  operationsEnd,
  validFrom,
  validTo,
): number[] {
  const minDate = getMaximumDate(operationsStart, validFrom)
  const maxDate = getMinimumDate(operationsEnd, validTo)
  const minYear = getYear(minDate)
  const maxYear = getYear(maxDate)
  return labels.map((label, i) => {
    let result = lineData.data[i]
    const year = +label

    if (minDate && year < minYear) {
      return 0
    }

    if (minYear && year === minYear) {
      result = result * (1 - getPercentageOfYear(minDate))
    }

    if (maxYear && year === maxYear) {
      result = result * getPercentageOfYear(maxDate)
    }

    if (maxYear && year > maxYear) {
      return 0
    }

    return result
  })
}

export function getContractItemChartValue(timeseriesValue: number, contractItem: IContractItem): number {
  const {product, volumeMwh} = contractItem

  if (!product.site?.avgYearlyProductionMwh || !volumeMwh || !timeseriesValue) {
    return 0
  }

  return (timeseriesValue * volumeMwh) / product.site.avgYearlyProductionMwh
}

export function getContractItemChartValues(timeseriesItems: ITimeseriesItem[], contractItem: IContractItem): number[] {
  if (!timeseriesItems) {
    return []
  }
  return timeseriesItems.map(({value}) => {
    return getContractItemChartValue(value, contractItem)
  })
}
