import React from 'react'
import Text from '../../Text'
import Box from '../../Box'
import {DownloadLink2} from '../../DownloadLink'

interface IFeatureProps extends React.PropsWithChildren {
  title: string
  value?: any
  unit?: string
  currency?: string
  smallValue?: string
}

export const Feature: React.FC<IFeatureProps> = ({title, value, unit, currency, smallValue, children}) => (
  <Box margin={{bottom: 1}}>
    <Text size="small" uppercase>
      {title}
    </Text>
    {currency && (
      <Text inline size="xlarge">
        {currency}
      </Text>
    )}
    {value && (
      <Text inline size="xlarge" semibold margin={{right: 1}}>
        {value}
      </Text>
    )}
    {unit && (
      <Text inline semibold>
        {unit}
      </Text>
    )}
    {smallValue && (
      <Text size="medium" semibold>
        {smallValue}
      </Text>
    )}
    {children}
  </Box>
)

interface IFeaturesProps {
  features: IFeatureProps[]
}

const Features: React.FC<IFeaturesProps> = ({features}) => (
  <Box align="start" wrap gap={3}>
    {features.map(feature => (
      <Feature key={feature.title} {...feature} />
    ))}
  </Box>
)

interface IFeaturesWithFactSheetProps {
  features: IFeatureProps[]
  termsTitle: string
  url: string
}

export const FeaturesWithProductFactSheet: React.FC<IFeaturesWithFactSheetProps> = ({features, termsTitle, url}) => (
  <Box align="start" wrap gap={3}>
    {features.map(feature => (
      <Feature key={feature.title} {...feature} />
    ))}
    {url && (
      <Box pad={{left: 3}}>
        <DownloadLink2 title={termsTitle} url={url} iconHeight={5} />
      </Box>
    )}
  </Box>
)

export default Features
