import React from 'react'
import useLocalization from '../../hooks/useLocalization'
import Label from '../Label'
import Box from '../Box'
import LegendItem from './LegendItem'

export interface IProps extends React.PropsWithChildren {
  lines: Array<{key: string; color: string; label: string; onClick?: () => void}>
  vertical?: boolean
  selectedLegend?: string
}

const Legend: React.FC<IProps> = ({lines, vertical, selectedLegend}) => {
  const {translate} = useLocalization()

  return (
    <Label text={translate('Legend')}>
      <Box
        align={vertical ? 'start' : 'center'}
        direction={vertical ? 'column' : 'row'}
        gap={vertical ? 0.5 : 2}
        margin={{top: 1}}
      >
        {lines.map(line => (
          <LegendItem
            onClick={line?.onClick}
            selected={selectedLegend === line.key}
            key={line.color}
            color={line.color}
          >
            {line.label}
          </LegendItem>
        ))}
      </Box>
    </Label>
  )
}

export default Legend
