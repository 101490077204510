import React, {useEffect} from 'react'
import Box from 'components/Box'
import useTheme from 'hooks/useTheme'
import useStore from 'hooks/useStore'
import useStoreData from 'hooks/useStoreData'
import CloseButton from 'components/CloseButton'
import IPurchaseGroup from 'domain/IPurchaseGroup'
import ISite from 'domain/ISite'
import {InitialIcon} from 'components/Filter'
import Text from 'components/Text'
import FormMultiSelect from 'components/Form/FormMultiSelect'
import useLocalization from 'hooks/useLocalization'
import PurchaseGroupSelect from './PurchaseGroupSelect'
import styled from 'styled-components'

const LimitedBox = styled(Box)`
  max-width: 250px;
`

interface ISitePurchaseGroups {
  site: ISite
  editable?: boolean
  height?: number
}

interface IOption {
  label: string
  value: any
}

const SitePurchaseGroups: React.FC<ISitePurchaseGroups> = ({site, editable, height}) => {
  const {translate} = useLocalization()
  const {siteStore, purchaseGroupStore} = useStore()
  const {allPurchaseGroups, isLoadingGroups} = useStoreData(store => ({
    allPurchaseGroups: store.purchaseGroupStore.purchaseGroups,
    isLoadingGroups: store.purchaseGroupStore.isLoading,
  }))
  const theme = useTheme()
  const newSite = editable && !site

  useEffect(() => {
    if (newSite) {
      purchaseGroupStore.loadCurrentPartyPurchaseGroups()
    }
  }, [newSite, purchaseGroupStore])

  const handleSubmit = async (purchaseGroup: IPurchaseGroup) => {
    const newPurchaseGroups = site.purchaseGroups.filter(group => group.id !== purchaseGroup.id)

    await siteStore.saveSite({
      ...site,
      purchaseGroups: newPurchaseGroups,
    })
  }

  const handleSave = async ({value}: IOption) => {
    const newSitePurchaseGroups = [...site.purchaseGroups, allPurchaseGroups.find(pg => pg.id === value.id)]

    await siteStore.saveSite({
      ...site,
      purchaseGroups: newSitePurchaseGroups,
    })
  }

  const sitePgIds = (site && site.purchaseGroups && site.purchaseGroups.map(pg => pg.id)) || []
  const options: IOption[] = allPurchaseGroups
    .filter(pg => !sitePgIds.includes(pg.id))
    .map(group => ({
      label: group.name,
      value: group,
    }))

  const customStyles = {
    control: provided => ({
      ...provided,
      minWidth: '300px',
    }),
  }

  const targetId = 'purchaseGroupList'

  return (
    !isLoadingGroups && (
      <Box id={targetId} justify="flex-start" height={height && height} gap={1} margin={{top: 2}} wrap>
        {site?.purchaseGroups?.map((group: IPurchaseGroup) => (
          <LimitedBox
            key={group.name}
            align="center"
            height={6}
            round={3}
            color={theme.colors.light3}
            pad={{
              vertical: 1,
              left: 1,
              right: 2,
            }}
            margin={{bottom: 2}}
          >
            <InitialIcon color={group.color} name={group.name} />
            <Text
              size="medium"
              uppercase
              color={theme.colors.secondary}
              semibold
              nowrap
              overflowEllipsis
              title={group.name}
            >
              {group.name}
            </Text>
            {editable && (
              <Box align="end">
                <CloseButton
                  style={{
                    transform: 'scale(0.65)',
                  }}
                  color={theme.colors.secondary}
                  onClick={() => handleSubmit(group)}
                />
              </Box>
            )}
          </LimitedBox>
        ))}
        {editable && !newSite && <PurchaseGroupSelect targetId={targetId} options={options} onSubmit={handleSave} />}
        {newSite && (
          <>
            <FormMultiSelect
              name="purchaseGroups"
              data={allPurchaseGroups}
              parse={(option: IPurchaseGroup) => ({
                value: option.id,
                label: option.name,
              })}
              format={value => ({
                id: value.value,
                name: value.label,
              })}
              styles={customStyles}
              placeholder={translate('Purchase groups')}
            />
            {/* TODO Replace upper component with this <PurchaseGroupSelect targetId={targetId} options={options as IOption[]} onSubmit={handleSave} /> */}
          </>
        )}
      </Box>
    )
  )
}

export default SitePurchaseGroups
