import React from 'react'
import FormInput from 'components/Form/FormInput'
import FormTextEditor from 'components/Form/FormTextEditor'
import FormTextArea from 'components/Form/FormTextArea'
import Label from 'components/Label'
import useLocalization from 'hooks/useLocalization'
import InnerScroll from 'components/InnerScroll'
import LocationSearch, {ILocationSearch} from '../LocationSearch'
import SiteStateSelect from './SiteStateSelect'
import ILocation from 'domain/ILocation'

interface IProps extends React.PropsWithChildren {
  location: ILocation
  setLocation: (location: ILocationSearch) => void
}

const BuyerGeneral: React.FC<IProps> = ({location, setLocation}) => {
  const {translate} = useLocalization()

  return (
    <InnerScroll noRightPad>
      <FormInput name="name" label={translate('Name')} required defaultValue={null} />
      <FormTextEditor name="description" label={translate('Description')} />
      <Label text={translate('Location')}>
        <LocationSearch
          setLocation={setLocation}
          location={location}
          title="Location"
          variant="primary"
          placeholder={translate('Start typing the address')}
        />
      </Label>
      <FormTextArea name="locationDetails" label={translate('Location details')} />
      <FormInput
        name="meterReference"
        minLength={6}
        maxLength={11}
        placeholder={translate('Insert meter reference number')}
        label={translate('Meter reference number')}
        // meter ref cannot be updated as it would cause problems in backend when resolving the timeseries profile
        disabled
        required
        defaultValue={null}
      />
      <SiteStateSelect name="state" required />
      <FormInput type="date" name="operationsStart" label={translate('Operations start')} defaultValue={null} />
      <FormInput type="date" name="operationsEnd" label={translate('Operations end')} defaultValue={null} />
    </InnerScroll>
  )
}

export default BuyerGeneral
