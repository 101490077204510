import ResourceStore from './ResourceStore'
import RootStore from './index'
import ISmartEnergyContractService from '../services/ISmartEnergyContractService'
import ISmartEnergyContract from '../domain/ISmartEnergyContract'

export default class SmartEnergyContractStore extends ResourceStore<ISmartEnergyContract> {
  private readonly rootStore: RootStore
  private readonly smartEnergyContractService: ISmartEnergyContractService

  public constructor(rootStore: RootStore, smartEnergyContractService: ISmartEnergyContractService) {
    super()
    this.rootStore = rootStore
    this.smartEnergyContractService = smartEnergyContractService
  }

  public async loadById(id: string): Promise<void> {
    this.setOneEventually(this.smartEnergyContractService.getSmartContractById(id))
  }

  public async loadByIds(ids: string[]): Promise<void> {
    const promises = Promise.all(ids.map(id => id && this.smartEnergyContractService.getSmartContractById(id)))
    this.setManyEventually(promises)
  }
}
