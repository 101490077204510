import React from 'react'
import FormInputWrapper, {IProps as IWrapperProps} from './FormInputWrapper'
import RangedInput, {IProps as IRangedInputProps} from '../RangedInput'

type IProps = IWrapperProps<IRangedInputProps>

const FormRangedInput: React.FC<IProps> = props => {
  const {min, max} = props

  return (
    <FormInputWrapper {...props}>
      <RangedInput min={min} max={max} />
    </FormInputWrapper>
  )
}

export default FormRangedInput
