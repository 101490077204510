import React from 'react'
import useLocalization from '../../../../hooks/useLocalization'
import Table, {TableValue} from '../../../Table'
import {DateFormat, dateByMonthAndYear, formatDate, getPeriod} from '../../../../helpers/date'
import {brokerOfferStateToReadableName} from '../../../../helpers/offer'
import useTheme from '../../../../hooks/useTheme'
import {getCurrencyCodeByPriceUnit} from '../../../../helpers/price'
import useStore from '../../../../hooks/useStore'
import DottedIconButtonSelect from '../../../IconButton/DottedIconButtonSelect'
import SmartOption, {ISmartOption} from '../../../MultiSelect/SmartOption'
import {OptionsType} from 'react-select'
import styled from 'styled-components'
import {INewOffer, OfferState} from 'domain/IOffer'
import {LinkButton} from 'components/Button'

interface IProps extends React.PropsWithChildren {
  offers: INewOffer[]
  loading?: boolean
  updateTable: () => void
}

const DropdownDiv = styled.div`
  width: 60px;
`

const OfferTable: React.FC<IProps> = ({offers, loading, updateTable}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const {offerStore, alertStore} = useStore()

  const handleDelete = async (e, offer) => {
    if (window.confirm(translate('Do you want to delete offer?'))) {
      await offerStore.deleteOffer(offer.id)
      updateTable()
    }
  }

  const handleConvertToContract = async (e, offer): Promise<void> => {
    if (window.confirm(translate('Do you want to convert to contract draft?'))) {
      const response = await offerStore.toBindingDraft(offer.id)
      if (response.status === 204) {
        alertStore.addSuccess(translate('Successfully changed to binding draft'))
        updateTable()
      }
    }
  }

  const handleSendingToCustomer = async (offer: INewOffer) => {
    if (window.confirm(translate('Do you want to send to customer?'))) {
      if (offer.state === OfferState.BINDING_DRAFT) {
        const response = await offerStore.sendBindingToCustomer(offer.id)
        if (response.status === 204) {
          alertStore.addSuccess(translate('A binding contract sent to the customer'))
          updateTable()
        }
      } else if (offer.state === OfferState.INDICATIVE_DRAFT) {
        const response = await offerStore.sendDraftToCustomer(offer.id)
        if (response.status === 204) {
          alertStore.addSuccess(translate('Offer successfully sent to the customer'))
          updateTable()
        }
      }
    }
  }

  const getSelectOptions = (offer: INewOffer): OptionsType<ISmartOption> => {
    if (offer.state === OfferState.CUSTOMER_INTERESTED) {
      return [
        {
          value: 'View',
          to: `/broker/sell/offers/${offer.id}?`,
          label: translate('View'),
        },
        {
          value: 'Convert',
          label: translate('Convert to contract'),
          onClick: (e: any) => handleConvertToContract(e, offer),
        },
      ]
    } else if (offer.state === OfferState.ACCEPTED) {
      return [
        {
          value: 'View',
          label: translate('View'),
          to: `/broker/sell/offers/${offer.id}?`,
        },
      ]
    } else if ([OfferState.INDICATIVE_DRAFT || OfferState.BINDING_DRAFT].includes(offer.state)) {
      return [
        {
          value: 'View',
          label: translate('View'),
          to: `/broker/sell/offers/${offer.id}?`,
        },
        {
          value: 'Send to customer',
          label: translate('Send to customer'),
          onClick: () => handleSendingToCustomer(offer),
        },
      ]
    } else {
      return [
        {
          value: 'View',
          label: translate('View'),
          to: `/broker/sell/offers/${offer.id}?`,
        },
        {
          value: 'Delete',
          label: translate('Delete'),
          color: theme.colors.error,
          onClick: (e: any) => handleDelete(e, offer),
        },
      ]
    }
  }

  return (
    <Table<INewOffer>
      tableName={translate('Offers')}
      data={offers}
      isLoading={loading}
      linkTo={offer => `/broker/sell/offers/${offer.id}?`}
      highlightOnHover
      columns={[
        {
          title: translate('Name'),
          accessor: (offer: INewOffer) => offer.name,
          render: (name: string) => (
            <LinkButton style={{justifyContent: 'left', whiteSpace: 'nowrap'}} fullWidth>
              {name}
            </LinkButton>
          ),
        },
        {
          title: translate('Customer'),
          accessor: (offer: INewOffer) => offer.customer?.name,
          // eslint-disable-next-line react/display-name
          render: (value: string) => <strong>{value}</strong>,
        },
        {
          title: translate('Certificates'),
          accessor: (offer: INewOffer) => offer.contract?.volumeMwh,
          render: (volume: number) => <TableValue rounded value={volume} />,
        },
        {
          title: translate('Offer expires'),
          accessor: (offer: INewOffer) => formatDate(offer.validTo, DateFormat.MONTH_DAY_YEAR),
          render: (date: string) => <TableValue text={date} />,
        },
        {
          title: translate('Contract Term'),
          accessor: (offer: INewOffer) => getPeriod(offer.contract?.validFrom, offer.contract?.validTo),
          render: (term: string) => <TableValue text={term} />,
        },
        {
          title: translate('Purchase interval'),
          accessor: (offer: INewOffer) => {
            return (
              formatDate(
                dateByMonthAndYear(offer?.purchaseInterval?.month, offer?.purchaseInterval?.year),
                DateFormat.MONTH_YEAR,
              ) || '-'
            )
          },
          render: (interval: string) => <TableValue text={interval} />,
        },
        {
          title: translate('Certificate price'),
          accessor: (offer: INewOffer) => offer.certPrice,
          render: (price: number, offer: INewOffer) => (
            <TableValue value={price} unit={getCurrencyCodeByPriceUnit(offer.priceUnit)} />
          ),
        },
        {
          title: translate('Total price'),
          accessor: (offer: INewOffer) => offer.totalPrice,
          render: (price: number, offer: INewOffer) => (
            <TableValue nowrap value={price} unit={getCurrencyCodeByPriceUnit(offer.priceUnit)} />
          ),
        },
        {
          title: translate('Status'),
          accessor: (offer: INewOffer) => brokerOfferStateToReadableName(offer.state, translate),
        },
        {
          title: null,
          disableLink: true,
          accessor: (offer: any) => offer,
          // eslint-disable-next-line react/display-name
          render: (offer: INewOffer) => (
            <DropdownDiv>
              <DottedIconButtonSelect
                hideIndicators
                components={{Option: SmartOption}}
                options={getSelectOptions(offer)}
              />
            </DropdownDiv>
          ),
        },
      ]}
    />
  )
}

export default OfferTable
