import React from 'react'
import ResourceGateway from '../../../components/ResourceGateway'
import OfferListPage from '../../../components/Platform/Portfolio/Offer/OfferListPage'
import OfferDesignerPage from '../../../components/Platform/Portfolio/Offer/OfferDesignerPage'
import OfferResolver from '../../../components/Platform/Resolver/OfferResolver'

const Offers = () => {
  return (
    <ResourceGateway
      create={() => <OfferDesignerPage />}
      single={routeProps => <OfferResolver component={OfferDesignerPage} id={routeProps.match.params.id} />}
      list={() => <OfferListPage />}
    />
  )
}

export default Offers
