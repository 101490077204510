import React from 'react'
import Box, {IProps as IBoxProps} from '../../../../Box'
import styled from 'styled-components'
import Button, {IProps as IButtonProps} from '../../../../Button'
import {getBubbleStyles} from './bubbleStyle'

const StyledButton: React.FC<IButtonProps> = styled(Button)<IButtonProps>`
  ${props => getBubbleStyles(props)}
  border: 0;
  padding-right: 20px;

  &:disabled {
    opacity: 0.5;
  }
`

const Container: React.FC<IBoxProps> = styled(Box)`
  min-width: 100px;
  height: 28px;
`
const CoverText = styled.div`
  line-height: 28px;
`

interface IBubbleButtonProps extends IButtonProps {
  onClick: (e: any) => any
  text: string
  img?: string
  imgAlt?: string
  disabled?: boolean
}

const BubbleButton: React.FC<IBubbleButtonProps> = ({img, imgAlt, text, ...rest}) => (
  <StyledButton {...rest}>
    <Container color={'clear'} direction={'row'} gap={1}>
      {img && <img src={img} alt={imgAlt} />}
      <CoverText>{text}</CoverText>
    </Container>
  </StyledButton>
)

export default BubbleButton
