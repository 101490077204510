import useStore from 'hooks/useStore'
import React from 'react'
import InnerScroll from '../../../components/InnerScroll'
import Page from '../../../components/Page'
import PortfolioOverviewAside from '../../../components/Platform/Portfolio/Overview/PortfolioOverviewAside'
import Tabs from '../../../components/Tabs'
import Tab from '../../../components/Tabs/Tab'
import useLocalization from '../../../hooks/useLocalization'
import ContractedVolume from './ContractedVolume'
import GreenCertificates from './GreenCertificates'
import CoverageOverview from './CoverageOverview'
import PerformanceOverview from './PerformanceOverview'

const Overview: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()

  return (
    <Page
      title={translate('Portfolio overview')}
      description={translate('Key facts, Aggregated overview of contracts')}
      aside={<PortfolioOverviewAside />}
      asideBorder
    >
      <InnerScroll noRightPad>
        <Tabs name="tab.overview">
          <Tab label={translate('Energy attribute certificates')} slug="">
            <GreenCertificates />
          </Tab>
          <Tab label={translate('Contracted volumes')} slug="contracted-volumes">
            <ContractedVolume />
          </Tab>
          <Tab label={translate('Performance')} slug="performance">
            <PerformanceOverview />
          </Tab>
          {/* <Tab label={translate('Coverage')} slug="coverage">
            <CoverageOverview />
          </Tab> */}
          {/* <Tab label={translate('Power plants')} slug="power-plants">
              <PowerPlantOverview
                contracts={filteredContracts}
                activePeriod={activePeriod}
                loading={contractsIsLoading}
              />
            </Tab> */}
          {/* <Tab label={translate('Expenditure')} slug="expenditure">
              <ExpenditureOverview contracts={filteredContracts} monthData={expenditure} loading={contractsIsLoading} />
            </Tab> */}
        </Tabs>
      </InnerScroll>
    </Page>
  )
}

export default Overview
