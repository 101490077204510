import React, {useEffect} from 'react'
import {IProps as IPageProps} from '../../Page'
import ContractPage from './ContractPage'
import useResource from '../../../hooks/useResource'
import useLocalization from '../../../hooks/useLocalization'
import {generateContractTitleValue} from 'helpers/contract'
import {OfferKind} from 'domain/IOffer'
import CertificateContractPage from './CertificateContractPage/CertificateContractPage'
import useStore from 'hooks/useStore'
import FullscreenLoader from 'components/FullscreenLoader'

interface IProps extends React.PropsWithChildren, IPageProps {
  id: any
  editable?: boolean
}

const ConnectedContractPage: React.FC<IProps> = ({id, editable, ...pageProps}) => {
  const {translate} = useLocalization()
  const {contractStore} = useStore()
  const {contract, loading, partyLoaded} = useResource(store => ({
    contract: store.contractStore.getItem(id),
    loading: store.contractStore.isLoading,
    partyLoaded: store.profileStore.isLoaded,
  }))
  const isLoading = loading || !partyLoaded

  // Get rid of this mobix mess
  useEffect(() => {
    if (partyLoaded) {
      contractStore.loadPartyContracts()
    } else {
      contractStore.isLoading = true
    }
  }, [partyLoaded])

  if (contract?.offer?.kind === OfferKind.CERTIFICATE_TRADE) {
    return <CertificateContractPage offerId={contract.offer.id} />
  }

  if (isLoading) {
    return <FullscreenLoader />
  }

  return (
    <ContractPage
      contract={contract}
      title={translate('Contract %s', contract ? generateContractTitleValue(contract) : '...')}
      {...pageProps}
    />
  )
}

export default ConnectedContractPage
