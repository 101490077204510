import Auth from '@aws-amplify/auth'
import Amplify from '@aws-amplify/core'
import {AuthorityType} from 'domain/AuthorityType'
import IUser from '../domain/IUser'
import UserNotConfirmedError from '../exceptions/UserNotConfirmedError'
import ValidationError from '../exceptions/ValidationError'
import IAuthenticationService from './IAuthenticationService'
import {IAuthOptions} from './IAuthenticationService'

interface CognitoConfig {
  region: string
  userPoolId: string
  userPoolWebClientId: string
}

export default class CognitoAuthenticationService implements IAuthenticationService {
  public constructor({region, userPoolId, userPoolWebClientId}: CognitoConfig, endpoint: string) {
    Amplify.configure({
      Auth: {
        region,
        userPoolId,
        userPoolWebClientId,
        endpoint,
      },
    })
  }

  public async signIn(email: string, password: string): Promise<void> {
    try {
      await Auth.signIn(email, password)
    } catch (e) {
      if (e.code === 'UserNotConfirmedException') {
        throw new UserNotConfirmedError(e.message, email)
      }

      throw e
    }
  }

  public async getAuthenticatedUser(options?: IAuthOptions): Promise<IUser> {
    let user

    try {
      user = await Auth.currentAuthenticatedUser({bypassCache: options?.bypassCache || false})
    } catch (e) {
      return null
    }

    if (!user) {
      return null
    }

    const {
      username: id,
      attributes: {
        email,
        email_verified: emailVerified,
        family_name: lastName,
        given_name: firstName,
        phone_number: phoneNumber,
        phone_number_verified: phoneNumberVerified,
        picture,
      },
    } = user

    const congnitoRoles = (user?.signInUserSession?.accessToken?.payload['cognito:groups'] || []) as string[]
    const authorities = congnitoRoles.map(role => AuthorityType[role]) as AuthorityType[]

    return {
      id,
      email,
      emailVerified,
      phoneNumberVerified: phoneNumberVerified,
      firstName: firstName || '-',
      lastName: lastName || '-',
      phoneNumber: phoneNumber,
      picture,
      authorities,
    }
  }

  public async getToken(): Promise<string> {
    try {
      const session = await Auth.currentSession()

      return session.getIdToken().getJwtToken()
    } catch (err) {
      return null
    }
  }

  public async signOut(): Promise<void> {
    await Auth.signOut()
  }

  public async signUp(email: string, password: string, firstName: string, lastName: string): Promise<void> {
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        given_name: firstName,
        family_name: lastName,
      },
    })
  }

  public async confirmSignUp(email: string, code: string): Promise<void> {
    await Auth.confirmSignUp(email, code)
  }

  public async resendSignUp(email: string): Promise<void> {
    await Auth.resendSignUp(email)
  }

  public async updateInfo(user: IUser): Promise<void> {
    const {firstName, lastName, phoneNumber, picture} = user

    await Auth.updateUserAttributes(await Auth.currentAuthenticatedUser(), {
      given_name: firstName,
      family_name: lastName,
      phone_number: phoneNumber,
      picture: picture || '',
    })

    await Auth.currentAuthenticatedUser({bypassCache: true})
  }

  public async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    try {
      await Auth.changePassword(await Auth.currentAuthenticatedUser(), oldPassword, newPassword)
    } catch (error) {
      if (error.code === 'NotAuthorizedException') {
        throw new ValidationError('Incorrect current password', [
          {
            key: 'oldPassword',
            message: 'Incorrect password',
          },
        ])
      }

      throw error
    }
  }

  public async forgotPassword(email: string): Promise<void> {
    await Auth.forgotPassword(email)
  }

  public async forgotPasswordSubmit(email: string, code: string, password: string): Promise<void> {
    await Auth.forgotPasswordSubmit(email, code, password)
  }
}
