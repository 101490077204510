import React from 'react'
import Table from '../../../Table'
import useFormContext from '../../../../hooks/useFormContext'
import IOfferQuarterlyPriceParameter from '../../../../domain/IOfferQuarterlyPriceParameter'
import useLocalization from '../../../../hooks/useLocalization'
import FormInput from '../../../Form/FormInput'

const OfferPriceParameterFormContent = () => {
  const {watch} = useFormContext()
  const {translate} = useLocalization()

  return (
    <>
      <Table<IOfferQuarterlyPriceParameter>
        data={watch('quarterlyPriceParameters')}
        columns={[
          {
            title: translate('Quarter'),
            accessor: p => p.quarter?.name,
            // eslint-disable-next-line react/display-name
            render: (value, item, rowIndex) => (
              <>
                {value}
                <FormInput type="hidden" name={`quarterlyPriceParameters[${rowIndex}].quarter`} />
              </>
            ),
          },
          {
            title: translate('Load seasonality'),
            accessor: p => p.loadSeasonality,
            // eslint-disable-next-line react/display-name
            render: (value, item, rowIndex) => (
              <FormInput
                type="number"
                name={`quarterlyPriceParameters[${rowIndex}].loadSeasonality`}
                required
                margin={0}
                min={0}
              />
            ),
          },
          {
            title: translate('Load type'),
            accessor: p => p.loadType,
            // eslint-disable-next-line react/display-name
            render: (value, item, rowIndex) => (
              <FormInput
                type="number"
                name={`quarterlyPriceParameters[${rowIndex}].loadType`}
                required
                margin={0}
                min={0}
              />
            ),
          },
        ]}
      />
    </>
  )
}

export default OfferPriceParameterFormContent
