import React from 'react'
import Box from 'components/Box'
import Label from 'components/Label'
import {Item} from 'components/Platform/Portfolio/ContractItem'
import TechnologyIconAndName from 'components/Platform/TechnologyIconAndName'
import ValueWithUnit from 'components/ValueWithUnit'
import {getCurrencySymbol, getPriceUnitSymbol} from 'helpers/price'
import useLocalization from 'hooks/useLocalization'
import useServices from 'hooks/useServices'
import styled from 'styled-components'
import {ICertificateProduct} from 'domain/IProduct'
import {CurrencyCode} from 'domain/IPrice'
import useTheme from 'hooks/useTheme'

interface ISelectedProductProps {
  product: ICertificateProduct
  currency: CurrencyCode
}

const MinWithSpan = styled.span<{width: number}>`
  width: ${props => props.width}px;
`

const Description = styled.div<{limited: boolean}>`
  max-width: 400px;
  ${props =>
    props.limited &&
    `
      max-height: 40px;
      overflow: hidden;
    `}
`

const LastBox = styled(Box)`
  margin-left: auto;
  min-width: 130px;
  margin-right: ${props => props.theme.tileSize * 4}px;
`

const OfferProduct: React.FC<ISelectedProductProps> = ({product, currency}) => {
  const {contentService} = useServices()
  const theme = useTheme()
  const {translate} = useLocalization()

  if (!product) return null

  return (
    <Item
      name={product?.name}
      image={contentService.getAttachmentURL(product?.image)}
      color={theme.colors.technology[product.technology]}
    >
      <Box gap={3} width={'100%'}>
        <MinWithSpan width={125}>
          {product?.technology ? (
            <Label text={translate('Technology')}>
              <TechnologyIconAndName technology={product.technology} semibold />
            </Label>
          ) : (
            product?.description && (
              <Label text={translate('Description')}>
                <Description limited={false}>
                  {(product?.description.substring(0, 45) + '...').split('\n').map((item, key) => {
                    return (
                      <span key={key}>
                        {item}
                        <br />
                      </span>
                    )
                  })}
                </Description>
              </Label>
            )
          )}
        </MinWithSpan>

        <MinWithSpan width={150}>
          <Label text={'Volume'}>
            <ValueWithUnit size="mlarge" value={product.quantity} unit={'CERTIFICATES'} />
          </Label>
        </MinWithSpan>

        <LastBox>
          <Label text={translate('Price')}>
            <ValueWithUnit
              size="mlarge"
              unit="/ CERTIFICATE"
              value={parseFloat(product.certificatePrice).toFixed(1)}
              currency={getCurrencySymbol(currency)}
            />
          </Label>
        </LastBox>
      </Box>
    </Item>
  )
}

export default OfferProduct
