import React from 'react'
import FormInputWrapper, {IProps as IWrapperProps} from './FormInputWrapper'
import MultiSelect, {IProps as ISelectProps} from '../MultiSelect'

export type IProps = IWrapperProps<ISelectProps>

const FormMultiSelect: React.FC<IProps> = props => {
  return (
    <FormInputWrapper {...props}>
      <MultiSelect />
    </FormInputWrapper>
  )
}

export default FormMultiSelect
