import React from 'react'
import Form from 'components/Form'
import Text from 'components/Text'
import {useHistory} from 'react-router-dom'
import useLocalization from 'hooks/useLocalization'
import HorizStack from 'components/HorizStack'
import FormFileDrop from 'components/Form/FormFileDrop'
import DownloadExampleLink from 'components/Platform/Portfolio/Site/Form/DownloadExampleLink'
import Box from 'components/Box'
import {SiteType} from 'domain/ISite'
import useTheme from 'hooks/useTheme'
import {Container, FormContainer} from '../Layout'
import useServices from 'hooks/useServices'
import useStore from 'hooks/useStore'
import {partyAtom} from 'atoms/party'
import {useAtom, useAtomValue} from 'jotai'
import {buyerOnboardingAtom} from 'atoms/onboarding'
import {Heading} from '../Heading'
import {getOnboardSite} from 'domain/IBuyerOnboardState'
import {OnboardingStatus} from 'domain/IParty'
import {ProfileUploadKind} from 'services/V2ApiErrorMapper'
import CenteredLoader from 'components/Platform/ProductDetails/ReservationBox/components/CenteredLoader'
import FormAttachmentUpload from 'components/Platform/Form/FormAttachmentUpload'
import {StyledFormButton} from '../Layout/FormComponents'

interface IProps extends React.PropsWithChildren {
  onSuccess?: () => void
}

const UploadConsumption: React.FC<IProps> = ({...props}) => {
  const theme = useTheme()
  const {translate} = useLocalization()
  const {v2ContentService} = useServices()
  const [party, setParty] = useAtom(partyAtom)
  const {data: onboardResult, loading: onbLoading, error: onbError} = useAtomValue(buyerOnboardingAtom)
  const {alertStore} = useStore()
  const history = useHistory()

  const handleSubmit = async (formData, {setError}) => {
    try {
      const res = await v2ContentService.createBuyerOnbConsumption(party.id, formData.file.id)
      const updated = {
        ...party,
        onboardingStatus: res.onboardingStatus,
      }
      setParty(updated)
      history.push('/consumer/onboarding/review')
    } catch (e) {
      if (e?.error?.kind in ProfileUploadKind) {
        setError('file', 'file', e.message)
      } else {
        alertStore.addError('Failed to upload consumption', e?.correlationId, e?.message)
      }
    }
  }

  if (onbError) {
    return (
      <Container>
        <Heading size="large" color={theme.colors.common.white}>
          {translate('Something went wrong')}
        </Heading>
      </Container>
    )
  }

  if (onbLoading) {
    return (
      <Container>
        <CenteredLoader size="medium" color={theme.colors.common.white} />
      </Container>
    )
  }

  const site = getOnboardSite(onboardResult)
  const isEdit = onboardResult?.onboardingStatus === OnboardingStatus.BUYER_PROFILE_UPLOADED

  return (
    <Box>
      <FormContainer>
        <Form onSubmit={handleSubmit} fillWidth={true} submissionFeedback={null} defaultValues={site}>
          <Text size="xxxlarge" color={theme.colors.common.black} margin={{bottom: 2}}>
            2. {translate('Upload Your Consumption')}
          </Text>
          <FormAttachmentUpload
            useV2
            coloredLink={false}
            label={translate('Upload consumption')}
            name="file"
            removable
            required
          />
          <Box margin={{top: 2.5}}>
            <DownloadExampleLink noColor siteType={SiteType.CONSUMPTION} />
          </Box>
          <Text size="medium" margin={{vertical: 1}} font={theme.font.secondaryFont}>
            {translate(
              'Renewabl supports Hourly or Half-hourly data for an easy upload. You can upload up to 3 years in the past. In other cases please reach out to Renewabl team.',
            )}
          </Text>
          <HorizStack justify="flex-end" margin={{top: 1}}>
            {/* <CancelButton type="button" onClick={() => history.push('/consumer/onboarding/setup-site')}>
              <Text uppercase semibold>
                {translate('Back')}
              </Text>
            </CancelButton> */}
            <StyledFormButton data-cy="continue">
              {isEdit ? translate('Update') : translate('Continue')}
            </StyledFormButton>
          </HorizStack>
        </Form>
      </FormContainer>
    </Box>
  )
}

export default UploadConsumption
