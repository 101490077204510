import ResourceStore, {RelationType} from './ResourceStore'
import ISite, {SiteType} from '../domain/ISite'
import IContentService from '../services/IContentService'
import {computed, observable} from 'mobx'
import RootStore from './index'
import IParty, {PartyRole} from '../domain/IParty'
import {ResourceId} from '../types'
import {IPurchaseGroupBase} from '../domain/IPurchaseGroup'

enum Key {
  CONSUMPTION_TEMPLATES = 'CONSUMPTION_TEMPLATES',
  PRODUCTION_TEMPLATES = 'PRODUCTION_TEMPLATES',
}

export default class SiteStore extends ResourceStore<ISite> {
  private contentService: IContentService
  private rootStore: RootStore
  @observable public siteLoading: boolean = true

  public constructor(rootStore: RootStore, contentService: IContentService) {
    super([
      {
        type: RelationType.OneToMany,
        getStore: () => rootStore.productStore,
        key: 'products',
        inversedBy: 'site',
      },
      {
        type: RelationType.ManyToMany,
        getStore: () => rootStore.purchaseGroupStore,
        key: 'purchaseGroups',
        inversedBy: 'sites',
      },
    ])
    this.rootStore = rootStore
    this.contentService = contentService
    this.isLoading = true
  }

  public async loadSite(id: any) {
    this.loadPartySites() // TODO: load single site instead
  }

  public async loadPartySites() {
    this.siteLoading = true
    try {
      await this.setManyEventually(this.getPartySites())
    } finally {
      this.siteLoading = false
    }
  }

  public async loadPartySitesWithId(id: number) {
    await this.clearItems()
    await this.setManyEventually(this.getPartySites(id))
  }

  public async loadConsumptionTemplates() {
    await this.setManyEventually(
      this.contentService.getConsumptionSiteTemplates(this.party.id),
      Key.CONSUMPTION_TEMPLATES,
    )
  }

  public async loadProductionTemplates() {
    await this.setManyEventually(
      this.contentService.getProductionSiteTemplates(this.party.id),
      Key.PRODUCTION_TEMPLATES,
    )
  }

  private async getPartySites(id?: number) {
    const party = this.party

    // FIXME Is there a better way to do that?
    party?.partyRole === PartyRole.BUYER && (await this.rootStore.purchaseGroupStore.loadCurrentPartyPurchaseGroups())
    const sites = await this.contentService.getSites(id ? id : party.id)

    return sites.map(site => ({...site, party}))
  }

  public async saveSite(site: ISite) {
    await this.setOneEventually(this.storeSite(site))
  }

  public async associatePurchaseGroupToSites(
    purchaseGroup: IPurchaseGroupBase,
    siteIds: number[],
    givenPartyId?: number,
  ) {
    const partyId = this.party.id

    await this.contentService.associatePurchaseGroupToSites(
      givenPartyId ? givenPartyId : partyId,
      purchaseGroup,
      siteIds,
    )

    // FIXME Is there a better way to do that?
    await this.rootStore.purchaseGroupStore.loadCustomerPurchaseGroups(givenPartyId ? givenPartyId : partyId)
    await this.loadPartySitesWithId(givenPartyId ? givenPartyId : partyId)
  }

  public async saveSiteWithoutTimeseries(site: ISite, party?: IParty) {
    if (party) {
      return await this.storeSiteWithPartyId(site, party.id)
    } else {
      return await this.storeSite(site)
    }
  }

  public async saveTimeseries(siteId: number, timeseriesFile?: File, loadType?: string) {
    if (timeseriesFile) {
      try {
        await this.contentService.uploadTimeseriesData(siteId, timeseriesFile)
      } catch (error) {
        throw new Error('Site was saved but failed to upload profile')
      }
    }

    if (loadType) {
      try {
        await this.contentService.cloneTimeseriesData(siteId, loadType)
      } catch (error) {
        throw new Error('Site was saved but failed to build profile')
      }
    }
  }

  public async saveSiteWithTimeseries(site: ISite, timeseriesFile?: File) {
    const savedSite = await this.storeSite(site)

    if (timeseriesFile) {
      try {
        await this.contentService.uploadTimeseriesData(savedSite.id, timeseriesFile)
      } catch (error) {
        throw new Error('Site was saved but failed to upload profile')
      }
    }

    this.loadSite(savedSite.id)
  }

  public async deleteSite(siteId: ResourceId) {
    const partyId = this.party.id

    await this.contentService.softDeleteSite(partyId, siteId)

    this.removeItem(siteId)
  }

  private storeSite(site: ISite): Promise<ISite> {
    const partyId = this.party.id

    return site.id ? this.contentService.updateSite(partyId, site) : this.contentService.createSite(partyId, site)
  }

  private storeSiteWithPartyId(site: ISite, partyId?: number): Promise<ISite> {
    const id = partyId ? partyId : this.party.id

    return site.id ? this.contentService.updateSite(id, site) : this.contentService.createSite(id, site)
  }

  @computed
  public get consumptionTemplates(): ISite[] {
    return this.getItemsByKey(Key.CONSUMPTION_TEMPLATES)
  }

  @computed
  public get productionTemplates(): ISite[] {
    return this.getItemsByKey(Key.PRODUCTION_TEMPLATES)
  }

  @computed
  public get partySites(): ISite[] {
    return this.items.filter(site => site.party?.id === this.party.id)
  }

  @computed
  public get partyProductionSites(): ISite[] {
    return this.partySites.filter(site => site.siteType === SiteType.PRODUCTION)
  }

  @computed
  public get partyConsumptionSites(): ISite[] {
    return this.partySites.filter(site => site.siteType === SiteType.CONSUMPTION)
  }

  @computed
  private get party(): IParty {
    return this.rootStore.profileStore.party
  }
}
