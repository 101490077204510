import React from 'react'
import ResourceGateway from '../../../components/ResourceGateway'
import CustomerListPage from '../../../components/Platform/Portfolio/Customer/CustomerListPage'
import CustomerSitesPage from '../../../components/Platform/Portfolio/Customer/CustomerSitesPage'
import PartyResolver from '../../../components/Platform/Resolver/PartyResolver'

const CustomersSites = () => {
  return (
    <ResourceGateway
      single={routeProps => <PartyResolver component={CustomerSitesPage} id={routeProps.match.params.id} />}
      list={() => <CustomerListPage sitesView />}
    />
  )
}

export default CustomersSites
