import useStore from 'hooks/useStore'
import React, {useState} from 'react'
import InnerScroll from 'components/InnerScroll'
import useLocalization from 'hooks/useLocalization'
import CertificatesChart from 'components/Platform/Portfolio/GreenCertificate/CertificatesChart'
import CertificateSummaryItem from 'components/Platform/Portfolio/GreenCertificate/CertificateSummaryItem'
import Select from 'components/Select'
import moment from 'moment'
import {getChangeEventValue} from 'helpers/misc'
import Box from 'components/Box'
import Checkbox from 'components/Checkbox'
import Heading from 'components/Heading'
import ExcessModal from 'components/Platform/Portfolio/GreenCertificate/ExcessModal'
import {ModalType} from 'components/Modal/IModal'
import {SellExcessPayload} from 'domain/INewTimeseriesItem'
import LoadingSkeleton from 'components/Platform/Portfolio/GreenCertificate/LoadingSkeleton'
import Input from 'components/Input'
import {useAtom, useAtomValue} from 'jotai'
import {greenCertificatesAtom, greenCertsDateFilterAtom} from 'atoms/portfolioOverview'
import {partyAtom} from 'atoms/party'
import ResourceSummary from 'components/Platform/Portfolio/Summary/ResourceSummary'
import ResourceSummaryItem from 'components/Platform/Portfolio/Summary/ResourceSummaryItem'
import useTheme from 'hooks/useTheme'
import {EnergyUnit} from 'helpers/conversion'

const GreenCertificates: React.FC<React.PropsWithChildren> = () => {
  const theme = useTheme()
  const {data: certData, loading, error} = useAtomValue(greenCertificatesAtom)
  const years = Array.from({length: 5}, (v, k) => moment().year() - k)
  const [filter, setFilter] = useState({showExcess: true, showMisisng: true})
  const [dateFilter, setDateFilter] = useAtom(greenCertsDateFilterAtom)
  const party = useAtomValue(partyAtom)
  const months = moment.months()
  const {translate} = useLocalization()
  const {newTimeseriesStore, alertStore} = useStore()
  const {excess, missing} = certData?.summary || {}
  const hasData = certData?.consumption?.some(item => item.value > 0)
  const totalMissing = certData?.missing?.reduce((acc, item) => acc + item.value, 0)
  const totalExcess = certData?.excess?.reduce((acc, item) => acc + item.value, 0)

  const handleDateChange = (event, key) => {
    const value = getChangeEventValue(event)

    setDateFilter({...dateFilter, [key]: value})
  }

  const handleExcessSell = async (payload: SellExcessPayload) => {
    await newTimeseriesStore.sellExcessRequest(party.id, payload)
    alertStore.addSuccess(translate('Successfully sent sell request'))
  }

  if (loading) {
    return <LoadingSkeleton />
  }

  return (
    <Box pad={{bottom: 2}}>
      <ExcessModal onSell={handleExcessSell} excess={excess} />

      <ResourceSummary noPad>
        <ResourceSummaryItem
          title={translate('Total')}
          tooltipText={translate('Sum of total')}
          value={certData.totalProduction.reduce((acc, item) => acc + item.value, 0)}
          color={theme.colors.green7}
          showFilledProgress
          unit={translate('EACs')}
        />
        <ResourceSummaryItem
          title={translate('Consumption')}
          tooltipText={translate('Sum of consumption')}
          value={certData.consumption.reduce((acc, item) => acc + item.value, 0)}
          color={theme.colors.blue7}
          showFilledProgress
          unit={translate(EnergyUnit.MWh)}
        />
        <ResourceSummaryItem
          title={translate('Purchased certificates')}
          tooltipText={translate('Sum of purchased certificates')}
          value={certData.reservedCertProduction.reduce((acc, item) => acc + item.value, 0)}
          color={theme.colors.green1}
          showFilledProgress
          unit={translate('EACs')}
        />
        <ResourceSummaryItem
          title={translate('Contracted')}
          tooltipText={translate('Sum of contracted')}
          value={certData.deliveredPpaProduction.reduce((acc, item) => acc + item.value, 0)}
          color={theme.colors.green2}
          showFilledProgress
          unit={translate('EACs')}
        />
        <ResourceSummaryItem
          title={translate('Shortfall')}
          tooltipText={translate('Sum of shortfall')}
          value={totalMissing}
          unit={translate('EACs')}
          showFilledProgress
          color={theme.colors.grey2}
        />
        <ResourceSummaryItem
          title={translate('Excess')}
          tooltipText={translate('Sum of excess')}
          value={certData.excess.reduce((acc, item) => acc + item.value, 0)}
          unit={translate('EACs')}
          showFilledProgress
          color={theme.colors.yellow1}
        />
      </ResourceSummary>

      <InnerScroll noRightPad>
        <Box gap={2} pad={{top: 2}} justify="space-between">
          <Box direction="row" gap={2}>
            <Input
              onChange={e => handleDateChange(e, 'dayOfMonth')}
              label={translate('Day of month')}
              value={{day: dateFilter.dayOfMonth, month: dateFilter.month, year: dateFilter.year}}
              type="day"
            />
            <Select
              value={dateFilter.month}
              label="Month"
              options={months.map(month => ({value: month, label: month}))}
              onChange={e => handleDateChange(e, 'month')}
            />
            <Select
              value={dateFilter.year}
              label="Year"
              options={years.map(year => ({value: year, label: `${year}`}))}
              onChange={e => handleDateChange(e, 'year')}
            />
          </Box>
          <Box width={35} border round direction="row" margin={{bottom: 2, top: 2}} justify="center">
            <Box gap={3} direction="row">
              <Checkbox
                label="Show Excess"
                checked={filter.showExcess}
                onChange={() => setFilter({...filter, showExcess: !filter.showExcess})}
              />
              <Checkbox
                label="Show Missing"
                checked={filter.showMisisng}
                onChange={() => setFilter({...filter, showMisisng: !filter.showMisisng})}
              />
            </Box>
          </Box>
        </Box>
        {error && (
          <Box justify="center">
            <Heading margin={{top: 4}}>{translate('Failed to load Certificates chart')}</Heading>
          </Box>
        )}
        {!hasData ? (
          <Box justify="center">
            <Heading margin={{top: 4}}>{translate('No data for this interval')}</Heading>
          </Box>
        ) : (
          <>
            <CertificatesChart certData={certData} filter={filter} />
            <Heading margin={2} size="large" style={{textAlign: 'center'}}>
              {translate('Summary')}
            </Heading>
            <Box direction="row" gap={1} justify="center" wrap>
              {excess?.map(({certificateQuantity, technologyKind}, index) => (
                <CertificateSummaryItem
                  title={translate('Excess certificates')}
                  key={index}
                  unit="EAC"
                  action={translate('Offer to market')}
                  quantity={certificateQuantity}
                  tecnology={technologyKind}
                  modal={ModalType.EXCESS_CERTIFICATES}
                  modalValue={technologyKind}
                />
              ))}
              {excess.length === 0 && totalExcess > 0 && (
                <CertificateSummaryItem
                  title={translate('Excess certificates')}
                  unit="EAC"
                  action={translate('Offer to market')}
                  disabled
                  quantity={totalExcess}
                  modal={ModalType.EXCESS_CERTIFICATES}
                />
              )}

              {missing && (
                <CertificateSummaryItem
                  title={translate('Shortfall of energy attribute certificates')}
                  unit="EAC"
                  action={translate('Go to marketplace')}
                  quantity={totalMissing}
                  to={`/consumer/buy/marketplace/products`}
                />
              )}
            </Box>
          </>
        )}
      </InnerScroll>
    </Box>
  )
}

export default GreenCertificates
