import React from 'react'
import useLocalization from '../../../../hooks/useLocalization'
import Filter, {IChoice} from '../../../Filter'
import ISite from '../../../../domain/ISite'

interface IProps {
  sites: ISite[]
  children?: (site: ISite) => void
}

const SiteFilter: React.FC<IProps> = ({sites, children}: IProps) => {
  const {translate} = useLocalization()

  // Do not display filter when only one site exists
  if (sites?.length === 1) {
    return <>{children && children(sites[0])}</>
  }

  const choices: IChoice[] = [
    ...sites.map(site => ({
      name: site.name,
      slug: `${site.id}`,
    })),
  ]

  return (
    <Filter transparent label={translate('Choose site:')} choices={choices} name="site">
      {(choice: IChoice) => {
        const site = sites.find(s => s.id.toString() === choice?.slug)

        return children && site && children(site)
      }}
    </Filter>
  )
}

export default SiteFilter
