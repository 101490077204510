import React, {useState, ReactElement} from 'react'

interface IProps extends React.PropsWithChildren {
  firstOpen?: boolean
}

const Accordion: React.FC<IProps> = ({children, firstOpen}) => {
  const [open, setOpen] = useState<number>(firstOpen ? 0 : -1)

  return (
    <div>
      {React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) {
          return null
        }

        return (
          <React.Fragment key={index}>
            {React.cloneElement(child as ReactElement<any>, {
              open: open === index,
              onHandleClick: () => setOpen(index === open ? -1 : index),
            })}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default Accordion
