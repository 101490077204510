import Loader, {ILoaderProps} from '../../../../Loader'
import Box from '../../../../Box'
import React from 'react'

const CenteredLoader = ({size, color}: ILoaderProps) => (
  <Box justify="center" margin={{vertical: 3}}>
    <Loader size={size} color={color} />
  </Box>
)

export default CenteredLoader
