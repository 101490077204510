import {useRef} from 'react'

interface IOptions {
  margin: number
  filename: string
}

const defaultOptions: IOptions & any = {
  margin: 6,
  filename: 'document.pdf',
  html2canvas: {
    useCORS: true,
    scale: 4,
  },
}

const useDocumentDownload = (options: Partial<IOptions> = {}) => {
  const documentRef = useRef(null)
  const downloadDocument = async () => {
    if (!documentRef.current) {
      throw new Error('documentRef is empty - did you forget to include it?')
    }

    const {default: html2pdf} = await import('html2pdf.js')
    await html2pdf()
      .from(documentRef.current)
      .set({...defaultOptions, ...options})
      .save()
  }

  return {documentRef, downloadDocument}
}

export default useDocumentDownload
