import React from 'react'
import ContractItem, {IContractItemProps} from '../ContractItem'
import IOffer from '../../../../domain/IOffer'
import {getProductMaxReservationVolumeMwhForParty} from '../../../../helpers/product'
import IContractItem from '../../../../domain/IContractItem'
import {VolumeType} from '../../../../domain/IProductType'
import {calculateFirmingOrAutofillVolumeMwh, getAmountOfProduction} from '../../../../helpers/contract'
import useTheme from '../../../../hooks/useTheme'

interface IProps extends React.PropsWithChildren, Omit<IContractItemProps, 'showPercentOf'> {
  offer: IOffer
  contractItem: IContractItem
}

const OfferContractItem: React.FC<IProps> = ({offer, contractItem, ...props}) => {
  const theme = useTheme()
  let maxTotalVolumeMwh
  let autoFillVolumeMwh = 0

  switch (contractItem.product.productType.volumeType) {
    case VolumeType.NONE: // Bundled lgcs
    case VolumeType.AUTO_FILL: // Firming
      maxTotalVolumeMwh = 0
      autoFillVolumeMwh = calculateFirmingOrAutofillVolumeMwh(offer.contract)
      break
    case VolumeType.USER_SELECT: // Default products
      maxTotalVolumeMwh = getProductMaxReservationVolumeMwhForParty(
        contractItem.product,
        offer.contract,
        offer.contract.volumeMwh,
      )
      if (getAmountOfProduction(contractItem) < maxTotalVolumeMwh) {
        maxTotalVolumeMwh = getAmountOfProduction(contractItem)
      }
      break
    case VolumeType.STANDALONE_USER_SELECT: // Unbundled lgcs
      maxTotalVolumeMwh = offer.contract?.volumeMwh
      break
  }

  return (
    <ContractItem
      autoFillVolumeMwh={autoFillVolumeMwh}
      showPercentOf={offer.contract?.volumeMwh}
      maxTotalVolumeMwh={maxTotalVolumeMwh}
      contractItem={contractItem}
      hidePrice={!theme.showPriceInOffer}
      offerState={offer.state}
      hasSlider
      {...props}
    />
  )
}

export default OfferContractItem
