import React from 'react'
import Box from '../../../Box'
import styled from 'styled-components'
import Text from 'components/Text'
import useTheme from 'hooks/useTheme'
import Grid from 'components/Grid'
import EditIcon from '@mui/icons-material/EditOutlined'
import {IconButton, Skeleton} from '@mui/material'
import isNil from 'lodash/isNil'
import CircularProgress, {getColorByValue} from 'components/CircularProgress'
import TrophyIcon from '@mui/icons-material/EmojiEvents'

export const Title = styled(Text)<{columns: number}>`
  color: ${({theme}) => theme.colors.secondary};
  grid-column: span ${({columns}) => columns};
  font-family: ${({theme}) => theme.font.numbers};
  font-size: 11px;
  line-height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-height: 11px;
`

export const Value = styled(Text)`
  color: ${({theme}) => theme.colors.secondary};
  font-family: ${({theme}) => theme.font.numbers};
  font-size: 16px;
  line-height: 21px;
`

export const Description = styled(Text)`
  color: ${({theme}) => theme.colors.accent};
  font-family: ${({theme}) => theme.font.secondaryFont};
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
`

export const StyledRow = styled(Box)`
  border-bottom: 1px solid ${({theme}) => theme.colors.outline};
  &:hover {
    background-color: ${({theme}) => theme.colors.outline};
  }
`

const TextValue = ({value, unit, text, loading}) => {
  return text ? (
    <Box direction="column">
      {loading ? <Skeleton variant="rounded" height={21} /> : <Value>{isNil(value) ? '-' : value + unit}</Value>}
      <Description>{text}</Description>
    </Box>
  ) : null
}

const CircularValue = ({value, text, loading, withIcon}) => {
  const theme = useTheme()
  const parsedValue = isNil(value) ? null : +value
  const showIcon = withIcon && parsedValue >= 100
  return text ? (
    <Box align="center" direction="column">
      <CircularProgress
        trailColor={theme.colors.light1}
        value={loading ? 0 : parsedValue}
        color={getColorByValue(parsedValue, theme)}
        width={6}
        height={6.5}
      >
        {loading ? (
          <Skeleton variant="circular" width={30} height={30} />
        ) : (
          <>
            {showIcon && <TrophyIcon sx={{color: getColorByValue(parsedValue, theme)}} />}
            {!showIcon && (
              <Text
                size="mlarge"
                semibold
                color={getColorByValue(value, theme)}
                font={theme.font.numbers}
                pad={{top: 0.7}}
              >
                {isNil(parsedValue) ? '-' : parsedValue}
              </Text>
            )}
          </>
        )}
      </CircularProgress>
      <Description>{text}</Description>
    </Box>
  ) : null
}

interface IProps {
  editIcon?: boolean
  onEdit?: () => void
  title: string
  firstVal: string
  secondVal?: string
  thirdVal?: string
  firstText: string
  secondText?: string
  thirdText?: string
  unit?: string
  loading?: boolean
  columns?: number
  circularProgress?: boolean
  showSuccessIcon?: boolean
}

const SummaryRow: React.FC<IProps> = ({
  title,
  firstVal,
  secondVal,
  thirdVal,
  firstText,
  secondText,
  thirdText,
  unit,
  editIcon,
  onEdit,
  loading,
  columns = 3,
  circularProgress,
  showSuccessIcon,
}) => {
  const theme = useTheme()

  const ValueComponent = circularProgress ? CircularValue : TextValue

  return (
    <StyledRow>
      <Grid columns={columns} gap={1.5} pad={{horizontal: 2, top: 2, bottom: 1}}>
        <Title columns={columns}>
          <Title columns={columns}>{title}</Title>
          {editIcon && (
            <IconButton size="small" sx={{color: theme.colors.info}} onClick={onEdit} data-cy="kpi-goals">
              <EditIcon fontSize="small" />
            </IconButton>
          )}
        </Title>

        <ValueComponent value={firstVal} unit={unit} text={firstText} loading={loading} withIcon={showSuccessIcon} />
        <ValueComponent value={secondVal} unit={unit} text={secondText} loading={loading} withIcon={showSuccessIcon} />
        <ValueComponent value={thirdVal} unit={unit} text={thirdText} loading={loading} withIcon={showSuccessIcon} />
      </Grid>
    </StyledRow>
  )
}

export default SummaryRow
