import IPurchaseContract from 'domain/IPurchaseContract'
import React from 'react'
import IContract from '../../../../domain/IContract'
import {getSitesOfContracts} from '../../../../helpers/contract'
import SiteFilter from '../Site/SiteFilter'
import SiteRenewableCertificates from './SiteRenewableCertificates'

interface IProps extends React.PropsWithChildren {
  contract: IContract | IPurchaseContract
}

const ContractRenewableCertificates: React.FC<IProps> = ({contract}) => {
  const sites = getSitesOfContracts([contract])

  return (
    <div>
      <SiteFilter sites={sites}>
        {site => {
          return <SiteRenewableCertificates site={site} />
        }}
      </SiteFilter>
    </div>
  )
}

export default ContractRenewableCertificates
