import React from 'react'
import IContract from '../../../domain/IContract'
import ListItem from '../../ListItem'
import useLocalization from '../../../hooks/useLocalization'
import {DateFormat, formatDate} from '../../../helpers/date'
import NoResults from '../../NoResults'
import Grid from '../../Grid'
import {getCurrencySymbolValueSlashEnergyUnit} from '../../../helpers/price'
import useServices from '../../../hooks/useServices'
import {generateContractTitleValue, getContractYearlyVolumeMwh} from '../../../helpers/contract'
import useTheme from 'hooks/useTheme'
import {formatEnergy} from '../../../helpers/format'

interface IProps extends React.PropsWithChildren {
  contracts: IContract[]
}

const ContractList: React.FC<IProps> = ({contracts}) => {
  const {translate} = useLocalization()
  const {contentService} = useServices()
  const theme = useTheme()

  if (contracts.length <= 0) {
    return <NoResults>{translate('No contracts found')}</NoResults>
  }

  return (
    <Grid columns={3} gap={3}>
      {contracts?.map(contract => (
        <ListItem
          to={`/consumer/portfolio/current-contracts/${contract.id}`}
          key={contract.id}
          title={translate('Contract %s', generateContractTitleValue(contract))}
          coverImage={contentService.getAttachmentURL(contract.contractItems[0]?.product?.image)}
          description={formatDate(contract.signingDate)}
          features={[
            {
              name: translate('Price per certificate'),
              value:
                contract.summary &&
                getCurrencySymbolValueSlashEnergyUnit(
                  contract.summary.price.total,
                  theme,
                  contract.summary.price.unit,
                  true,
                ),
            },
            {
              name: translate('Certificate quantity'),
              value: `${formatEnergy(getContractYearlyVolumeMwh(contract))} ${translate('EACs')}`,
            },
            {
              name: translate('Contract term'),
              value: (
                <>
                  {formatDate(contract.validFrom, DateFormat.DAY_MONTH_YEAR)}
                  &nbsp;&nbsp;-&nbsp;&nbsp;
                  {formatDate(contract.validTo, DateFormat.DAY_MONTH_YEAR)}
                </>
              ),
            },
          ]}
        />
      ))}
    </Grid>
  )
}

export default ContractList
