import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 210mm;
  position: fixed;
  left: 200%;
`

const DocumentContainer: React.FC<React.PropsWithChildren<{ref: any}>> = React.forwardRef(
  ({children}, ref: React.Ref<any>) => {
    return (
      <Container>
        <div ref={ref}>{children}</div>
      </Container>
    )
  },
)

export default DocumentContainer
