import React, {useEffect, useState} from 'react'
import useStore from '../../../hooks/useStore'
import IOffer from '../../../domain/IOffer'
import NotFound from '../../NotFound'
import useResource from 'hooks/useResource'
import FullscreenLoader from 'components/FullscreenLoader'

interface IProps extends React.PropsWithChildren {
  id: any
  component: React.FC<{offer: IOffer}>
}

const OfferResolver: React.FC<IProps> = ({id, component: Component, ...props}) => {
  const {offerStore} = useStore()
  const [loading, setLoading] = useState(true)
  const {offer, partyLoaded} = useResource(store => ({
    offer: store.offerStore.getItem(id),
    partyLoaded: store.profileStore.isLoaded,
  }))

  useEffect(() => {
    const getOffer = async () => {
      await offerStore.loadOffer(id)
      setLoading(false)
    }

    if (id && partyLoaded) {
      getOffer()
    }

    // eslint-disable-next-line
  }, [id, partyLoaded])

  if (loading) {
    return <FullscreenLoader height="50vh" />
  }

  if (id && !offer) {
    return <NotFound />
  }

  return <Component offer={offer} {...props} />
}

export default OfferResolver
