import React from 'react'
import IToken from '../../../../domain/IToken'
import useTheme from '../../../../hooks/useTheme'
import Chart, {ChartType, getMaxValue, ILine, StackType} from '../../../Chart'
import {filterByWalletIdRCT, getSmartContractTokenShareRCT} from '../../../../helpers/smartContract'
import {
  dateEndOf,
  DateFormat,
  dateStartOf,
  formatDate,
  getAllMonthsOfYear,
  getMaximumDate,
  isDateBetween,
} from '../../../../helpers/date'
import ISite from '../../../../domain/ISite'
import {convertEnergy, EnergyUnit} from '../../../../helpers/conversion'
import useTimeseries from '../../../../hooks/useTimeseries'
import {Interval} from '../../../../services/IContentService'
import useLocalization from '../../../../hooks/useLocalization'
import {autoCeilNumber} from '../../../../helpers/misc'
import useResource from '../../../../hooks/useResource'
import useProfile from '../../../../hooks/useProfile'
import Loader from '../../../Loader'
import {formatEnergy} from '../../../../helpers/format'

interface IProps extends React.PropsWithChildren {
  tokens: IToken[]
  walletId: string
  site: ISite
  showPredicted: boolean
}

const RenewableCertificatesChart: React.FC<IProps> = ({tokens, walletId, site, showPredicted}) => {
  const theme = useTheme()
  const {translate} = useLocalization()

  const {timeseries} = useTimeseries({
    sites: [site],
    interval: Interval.MONTHLY,
  })

  const maxDate = getMaximumDate(...tokens.map(token => token.deliveryStart))
  const monthlyTokens = tokens.filter(token =>
    isDateBetween(token.deliveryStart, dateStartOf(maxDate, 'year'), dateEndOf(maxDate, 'year')),
  )
  const monthsOfYear = getAllMonthsOfYear().filter(
    month => monthlyTokens.length === 0 || monthlyTokens[0].deliveryStart <= month,
  )
  const labels = monthsOfYear.map(month => formatDate(month, DateFormat.MONTH_SHORT))

  const lines: ILine[] = [
    {
      key: 'tokens',
      label: site.name,
      color: theme.colors.technology[site.productionTechnology],
      data: monthsOfYear.map(date => {
        const token = monthlyTokens.find(token => token.deliveryStart.getUTCMonth() === date.getUTCMonth())

        if (!token) {
          return 0
        }
        return convertEnergy(getSmartContractTokenShareRCT(token, walletId), EnergyUnit.Wh, EnergyUnit.MWh)
      }),
      type: ChartType.BAR,
      stackType: StackType.STACKED,
      order: 1,
    },
    ...(timeseries.sites[0] && showPredicted
      ? [
          /*{
            key: 'predicted-total',
            label: translate('Predicted total volume'),
            color: theme.colors.secondary,
            data: timeseries.sites[0].map(item => item.value),
            type: ChartType.LINE,
            dashed: true,
          },*/
          {
            key: 'predicted',
            label: translate('Predicted volume for you'),
            color: theme.colors.grey1,
            data: monthsOfYear.length
              ? timeseries.sites[0].slice(monthsOfYear[0].getUTCMonth()).map(item => formatEnergy(item.value))
              : [],
            type: ChartType.LINE,
            dashed: true,
          },
        ]
      : []),
  ].filter(Boolean)

  return (
    <Chart
      dark
      toolbar={{vertical: true}}
      lines={lines}
      labels={labels}
      typeLabels={{
        [StackType.DEFAULT]: 'MWh',
      }}
      maxValue={autoCeilNumber(getMaxValue(lines))}
    />
  )
}

export const ConnectedRenewableCertificatesChart: React.FC<{site: ISite}> = ({site}) => {
  const {party} = useProfile()
  const renewableCertificateId = site.meta?.renewableCertificateId
  const walletId = party?.meta?.walletId

  const {renewableCertificate, isLoading} = useResource(
    store => ({
      renewableCertificate: store.smartEnergyContractStore.getItem(renewableCertificateId),
      isLoading: store.smartEnergyContractStore.isLoading,
    }),
    store => renewableCertificateId && store.smartEnergyContractStore.loadById(renewableCertificateId),
    renewableCertificateId,
  )

  if (!renewableCertificate && isLoading) {
    return <Loader />
  }

  const filteredTokens =
    renewableCertificate && walletId ? filterByWalletIdRCT(renewableCertificate.owners, walletId, true) : []

  return <RenewableCertificatesChart tokens={filteredTokens} walletId={walletId} site={site} showPredicted={true} />
}

export default RenewableCertificatesChart
