import {AuthorityType} from 'domain/AuthorityType'
import {IManagedUser, ManagedUserStatus} from 'domain/IManagedUser'
import ITheme from 'theme/ITheme'
import IParty, {PartyRole} from '../domain/IParty'
import IUser from '../domain/IUser'
import ITranslate from '../interfaces/ITranslate'

export function isProfileComplete(user: IUser, party: IParty): boolean {
  return !!(user.emailVerified && party && party.partyRole)
}

export function getAccountType(party: IParty, translate: ITranslate, theme?: ITheme): string {
  switch (party?.partyRole) {
    case PartyRole.BUYER:
      return translate('Buyer')
    //TODO: remove this
    case PartyRole.PRODUCER:
      return translate('Producer')
    case PartyRole.BROKER:
      // Broker role is now considered seller role, and old seller role will be removed
      return translate('Seller')
    default:
      return '-'
  }
}

export function getFullName(user: IUser): string {
  return `${user?.firstName} ${user?.lastName}`
}

export function isAdmin(user: IUser): boolean {
  return user?.authorities?.includes(AuthorityType.ROLE_ADMIN)
}

export function isRenewablEmail(user: IUser): boolean {
  return user?.email?.endsWith('@renewabl.com')
}

export function showAdminMessage(user: IUser): boolean {
  return isAdmin(user) || isRenewablEmail(user)
}

export const getManagedStatus = (user: IUser): ManagedUserStatus => {
  let status: ManagedUserStatus
  if (!user.activated) {
    status = ManagedUserStatus.Invited
  } else {
    status = user.enabled ? ManagedUserStatus.Active : ManagedUserStatus.Suspended
  }

  return status
}

export function toManagedUser(user: IUser): IManagedUser {
  const status = getManagedStatus(user)
  return {...user, status}
}
