import IPurchaseContract from 'domain/IPurchaseContract'
import React from 'react'
import IContract from '../../../../domain/IContract'
import {DateFormat, formatDate} from '../../../../helpers/date'

interface IProps extends React.PropsWithChildren {
  contract: IContract | IPurchaseContract
}

const ContractTerm: React.FC<IProps> = ({contract}) => {
  return (
    <>
      {formatDate(contract.validFrom, DateFormat.DAY_MONTH_YEAR)}
      &nbsp;&nbsp;-&nbsp;&nbsp;
      {formatDate(contract.validTo, DateFormat.DAY_MONTH_YEAR)}
    </>
  )
}

export default ContractTerm
