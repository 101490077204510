import IAuthenticationService from './IAuthenticationService'
import CognitoAuthenticationService from './CognitoAuthenticationService'
import LocalAuthenticationService from './LocalAuthenticationService'
import ApiClient from './ApiClient'
import IContentService from './IContentService'
import LocalContentService from './LocalContentService'
import LederhosenContentService from './LederhosenContentService'
import IConfig from '../config/IConfig'
import IPriceCalculationService from './IPriceCalculationService'
import IHttpClient from './IHttpClient'
import LederhosenPriceCalculationService from './LederhosenPriceCalculationService'
import LocalPriceCalculationService from './LocalPriceCalculationService'
import IConfigurationService from './IConfigurationService'
import RuntimeConfigurationService from './RuntimeConfigurationService'
import LocalConfigurationService from './LocalConfigurationService'
import IOfferPriceParameterService from './IOfferPriceParameterService'
import LocalOfferPriceParameterService from './LocalOfferPriceParameterService'
import ISmartEnergyContractService from './ISmartEnergyContractService'
import axios from 'axios'
import SmartEnergyContractService from './SmartEnergyContractService'
import SimplePriceCalculationService from './SimplePriceCalculationService'
import LederhosenApiErrorMapper from './LederhosenApiErrorMapper'
import IProductFirmingPremiumService from './IProductFirmingPremiumService'
import IV2ContentService from './IV2ContentService'
import V2CoreContentService from './V2CoreContentService'
import MockV2ContentService from './MockV2Service'
import V2ApiError from 'exceptions/V2ApiError'
import V2ApiErrorMapper from './V2ApiErrorMapper'

export default class RootService {
  private static instance: RootService
  public readonly configurationService: IConfigurationService
  public readonly authenticationService: IAuthenticationService
  public readonly contentService: IContentService
  public readonly v2ContentService: IV2ContentService
  public readonly priceCalculationService: IPriceCalculationService
  public readonly offerPriceParameterService: IOfferPriceParameterService
  public readonly productFirmingPremiumService: IProductFirmingPremiumService
  public readonly smartEnergyContractService: ISmartEnergyContractService
  public readonly simplePriceCalculationService: IPriceCalculationService

  constructor() {
    this.configurationService = this.getConfigurationService()
    const config = this.configurationService.getConfiguration()

    this.authenticationService = this.getAuthenticationService(config)
    this.v2ContentService = this.getV2ContentService(config)
    this.contentService = this.getContentService(config)
    this.priceCalculationService = this.getPriceCalculationService(config, this.contentService)
    this.simplePriceCalculationService = this.getSimplePriceCalculationService(this.contentService)
    this.offerPriceParameterService = this.getOfferPriceParameterService(config)
    this.productFirmingPremiumService = this.getProductFirmingPremiumService(config)
    this.smartEnergyContractService = this.getSmartContractService(config)

    if (config.services.exposed) {
      import('./ServiceExposer').then(({default: ServiceExposer}) => ServiceExposer.create(this))
    }
  }

  public static create(): RootService {
    if (RootService.instance) {
      return RootService.instance
    }

    const instance: RootService = new RootService()
    RootService.instance = instance

    return instance
  }

  private getConfigurationService(): IConfigurationService {
    const runtimeConfigurationService = new RuntimeConfigurationService()
    const baseConfig = runtimeConfigurationService.getConfiguration()

    switch (baseConfig.services.configuration) {
      case 'local':
        return new LocalConfigurationService(baseConfig)
      case 'runtime':
        return runtimeConfigurationService
      default:
        throw new Error(`Invalid configuration for services.configuration`)
    }
  }

  private getAuthenticationService(config: IConfig): IAuthenticationService {
    switch (config.services.authentication) {
      case 'cognito':
        return new CognitoAuthenticationService(config.cognito, `${config.coreV2.url}/v2/api/auth`)
      case 'local':
        return new LocalAuthenticationService()
      default:
        throw new Error(`Invalid configuration for services.authentication`)
    }
  }

  private getV2ContentService(config: IConfig): IV2ContentService {
    switch (config.services.v2Content) {
      case 'coreV2':
        return new V2CoreContentService(this.getV2ApiClient(config))
      case 'local':
        return new MockV2ContentService()
      default:
        throw new Error(`Invalid configuration for services.v2Content: ${JSON.stringify(config)}`)
    }
  }

  private getContentService(config: IConfig): IContentService {
    switch (config.services.content) {
      case 'lederhosen':
        return new LederhosenContentService(this.getLederhosenApiClient(config))
      case 'local':
        return new LocalContentService()
      default:
        throw new Error(`Invalid configuration for services.content`)
    }
  }

  private getPriceCalculationService(config: IConfig, contentService: IContentService): IPriceCalculationService {
    switch (config.services.priceCalculation) {
      case 'lederhosen':
        return new LederhosenPriceCalculationService(contentService)
      case 'local':
        return new LocalPriceCalculationService()
      default:
        throw new Error(`Invalid configuration for services.priceCalculation`)
    }
  }

  private getSimplePriceCalculationService(contentService: IContentService): IPriceCalculationService {
    return new SimplePriceCalculationService(contentService)
  }

  private getOfferPriceParameterService(config: IConfig): IOfferPriceParameterService {
    return new LocalOfferPriceParameterService()
  }

  private getProductFirmingPremiumService(config: IConfig): IProductFirmingPremiumService {
    return new LocalOfferPriceParameterService()
  }

  private getSmartContractService(config: IConfig): ISmartEnergyContractService {
    return new SmartEnergyContractService(this.getRenewablTokenApiClient(config))
  }

  private getLederhosenApiClient(config: IConfig): IHttpClient {
    if (!config.lederhosen?.url) {
      throw new Error(`Invalid configuration for lederhosen`)
    }
    return ApiClient.create(this.authenticationService, `${config.lederhosen.url}/api`, {
      errorMappingFn: LederhosenApiErrorMapper.getMessageByErrorType,
    })
  }

  private getV2ApiClient(config: IConfig): IHttpClient {
    if (!config.coreV2?.url) {
      throw new Error(`Invalid configuration for Core V2`)
    }
    // both lederhosen(v1) and v2 are under a reverse proxy(for non-local envs)
    // The load balancer forwards urls that start with `/v2/api/` to v2
    return ApiClient.create(this.authenticationService, `${config.coreV2.url}/v2/api`, {
      //TODO: remove this and add proper error mapping
      errorMappingFn: response => {
        if (!response) {
          return new V2ApiError('Failed to load data. Please check your network connection.', {
            kind: 'CONNECTION_ERROR',
            payload: null,
          })
        }

        const data = response.data
        // UUID v4
        const correlationId = response.headers && response.headers['X-Correlation-Id'.toLowerCase()]
        const parsedMsg = V2ApiErrorMapper.getMessage(data?.message, data?.error)
        const error = data?.error
        return new V2ApiError(parsedMsg, error, correlationId)
      },
    })
  }

  private getRenewablTokenApiClient(config: IConfig): IHttpClient {
    return axios.create({
      baseURL: config.token?.url,
    })
  }
}
