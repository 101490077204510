import React from 'react'
import useLocalization from '../../../hooks/useLocalization'
import {default as BasePage} from '../../Page'
import Box from '../../Box'
import NavigationItem from './NavigationItem'
import HorizStack from 'components/HorizStack'

interface IProps extends React.PropsWithChildren {
  hideNavigation?: boolean
}

const Page: React.FC<IProps> = ({children, hideNavigation}) => {
  const {translate} = useLocalization()

  return (
    <Box fillHeight>
      {!hideNavigation && (
        <HorizStack margin={{bottom: 4}} gap={1}>
          <Box>
            <NavigationItem to="/" exact title={translate('Login')} />
          </Box>
          <Box>
            <NavigationItem to="/register" exact title={translate('Create an account')} />
          </Box>
        </HorizStack>
      )}
      {children}
    </Box>
  )
}

export default Page
