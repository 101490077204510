import React, {useState} from 'react'
import Text from 'components/Text'
import useLocalization from 'hooks/useLocalization'
import DownloadExampleLink from 'components/Platform/Portfolio/Site/Form/DownloadExampleLink'
import Box from 'components/Box'
import {SiteType, TimeseriesKind} from 'domain/ISite'
import {StyledFormSelect} from '../Layout'
import {getCurrentYear, getMonths, getPastYears, getPrevMonth} from 'helpers/date'
import FormProfileBuilder from 'components/Form/FormProfileBuilder'
import {FormControlLabel, RadioGroup} from '@mui/material'
import Radio from 'components/Radio'
import {getChangeEventValue} from 'helpers/misc'
import Label from 'components/Label'
import FormAttachmentUpload from 'components/Platform/Form/FormAttachmentUpload'
import useFormContext from 'hooks/useFormContext'
import FormRange from 'components/Form/FormRange'
import ValueWithUnit from 'components/ValueWithUnit'

const SetupSitesForm = ({uploadType, setUploadType}) => {
  const {translate} = useLocalization()
  const [volume, setVolume] = useState(100)
  const {watch} = useFormContext()
  const interval = watch('interval')

  return (
    <Box pad={{bottom: 1}}>
      <RadioGroup row value={uploadType} onChange={e => setUploadType(getChangeEventValue(e))}>
        <FormControlLabel
          value={TimeseriesKind.BUILDER}
          control={<Radio noColor />}
          label={translate('Build')}
          data-cy="radio-build"
        />
        <FormControlLabel
          value={TimeseriesKind.FILE_UPLOAD}
          control={<Radio noColor />}
          label={translate('Upload')}
          data-cy="radio-upload"
        />
      </RadioGroup>
      <Label text={translate('Interval')}>
        <Box direction="row" gap={1} pad={{bottom: 2}} width="calc(50% - 8px)">
          <StyledFormSelect
            style={{width: '100%'}}
            required
            name="interval.month"
            defaultValue={getPrevMonth()}
            margin={0}
            options={getMonths().map((month, index) => ({value: month, label: month}))}
            placeholder="Month"
          />
          <StyledFormSelect
            style={{width: '100%'}}
            width="100%"
            required
            name="interval.year"
            margin={0}
            options={getPastYears(3).map(year => ({value: year, label: `${year}`}))}
            defaultValue={getCurrentYear()}
            placeholder="Year"
          />
        </Box>
      </Label>
      {uploadType === TimeseriesKind.BUILDER && <FormProfileBuilder production contrast name="builder" />}
      {uploadType === TimeseriesKind.FILE_UPLOAD && (
        <>
          <FormAttachmentUpload
            useV2
            coloredLink={false}
            label={translate('Upload profile')}
            name="file"
            removable
            required
          />

          <Box margin={{vertical: 2}}>
            <DownloadExampleLink noColor interval={interval} siteType={SiteType.CONSUMPTION} />
          </Box>
          <Text semibold size="medium" margin={{vertical: 1}}>
            {translate(
              'Renewabl supports Half-hourly or Quarter-hourly data for an easy upload. The timeseries data must be for the last full calendar month (%s). In other cases please reach out to Renewabl team.',
              getPrevMonth(),
            )}
          </Text>
        </>
      )}
      <Box margin={{vertical: 1}} gap={2} direction="column">
        <FormRange
          label={translate('Generation volume dedicated to the marketplace')}
          name="volumePercent"
          min={1}
          max={100}
          step={1}
          defaultValue={100}
          contrast
          onChange={value => setVolume(+value)}
        />
        <Label text={'Volume'}>
          <ValueWithUnit size="mlarge" value={volume} unit={'%'} />
        </Label>
      </Box>
    </Box>
  )
}

export default SetupSitesForm
