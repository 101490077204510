import React from 'react'
import useLocalization from '../../../../hooks/useLocalization'
import Page from '../../../Page'
import NoContract from './index'
import useStore from '../../../../hooks/useStore'
import {getPartyLink} from '../../../../helpers/party'

const NoDraftContract: React.FC<React.PropsWithChildren> = () => {
  const {profileStore} = useStore()
  const {translate} = useLocalization()
  const {partyRole} = profileStore.party

  return (
    <Page
      title={translate('Review your signed EAC contracts')}
      description={translate('Browse your signed agreements for more details')}
    >
      <NoContract
        buttonLink={`/${getPartyLink(partyRole)}/buy/marketplace/products`}
        buttonText={translate('Go to marketplace')}
      />
    </Page>
  )
}

export default NoDraftContract
