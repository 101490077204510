import React from 'react'
import Chart, {ChartType} from '../../Chart'
import useTheme from '../../../hooks/useTheme'
import useTimeseries from '../../../hooks/useTimeseries'
import IParty from '../../../domain/IParty'

interface IProps extends React.PropsWithChildren {
  party: IParty
  label: string
}

const ConsumptionChart: React.FC<IProps> = ({party, label}) => {
  const theme = useTheme()
  const {timeseries} = useTimeseries({parties: [party], disableYearly: true})
  const data = timeseries.parties[0] || []

  const lines = [
    {
      key: label,
      label: label,
      color: theme.colors.blue7,
      data: data.map(({value}) => value),
      type: ChartType.LINE,
    },
  ]

  return (
    <div>
      <Chart toolbar={{timescale: true}} labels={data.map(({name}) => name)} lines={lines} dark />
    </div>
  )
}

export default ConsumptionChart
