import React from 'react'
import useLocalization from '../../../../hooks/useLocalization'
import IOffer from '../../../../domain/IOffer'
import Heading from '../../../Heading'
import Text from '../../../Text'
import Box from '../../../Box'
import Logo from '../../../Logo'

interface IProps extends React.PropsWithChildren {
  offer: IOffer
}

const OfferDocumentHead: React.FC<IProps> = ({offer}) => {
  const {translate} = useLocalization()

  return (
    <Box justify="space-between" margin={{bottom: 4}}>
      <div>
        <Heading>{translate('Proposal for %s', offer.customer.name)}</Heading>
        <Text margin={{top: 1}} size="small" uppercase>
          {translate('Tailored energy solution')}
        </Text>
      </div>
      <Logo />
    </Box>
  )
}

export default OfferDocumentHead
