import IPurchaseContract, {IPurchaseParty} from 'domain/IPurchaseContract'
import React from 'react'
import IContract from '../../../domain/IContract'
import IParty from '../../../domain/IParty'
import {VolumeType} from '../../../domain/IProductType'
import IPurchaseGroup from '../../../domain/IPurchaseGroup'
import * as contractHelper from '../../../helpers/contract'
import {getChartContractItems} from '../../../helpers/contract'
import {
  getContractItemChartValues,
  getPurchasedEnergyData,
  reduceFirstAndLastYearValues,
} from '../../../helpers/contractsChart'
import {getMonth, getYear} from '../../../helpers/date'
import {autoCeilNumber} from '../../../helpers/misc'
import useLocalization from '../../../hooks/useLocalization'
import useTheme from '../../../hooks/useTheme'
import useTimeseries from '../../../hooks/useTimeseries'
import {Interval} from '../../../services/IContentService'
import Chart, {ChartType, getMaxValue, ILine, StackType} from '../../Chart'
import useNewChartColors from 'hooks/useNewChartColors'

interface IProps extends React.PropsWithChildren {
  contract: IContract | IPurchaseContract
  party?: IParty | IPurchaseParty
  purchaseGroup?: IPurchaseGroup
  consumptionLine?: Partial<ILine>
  type?: ChartType
}

const ContractChart: React.FC<IProps> = ({contract, party, purchaseGroup, consumptionLine, type}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const chartColors = useNewChartColors()

  const contractItemsInChart = getChartContractItems(contract.contractItems)

  const {timeseries, errors, interval} = useTimeseries({
    parties: party ? [party] : [],
    purchaseGroups: purchaseGroup?.party ? [purchaseGroup] : [],
    sites: contractItemsInChart.map(item => item.product?.site),
    yearlyPeriod: [getYear(contract.validFrom), getYear(contract.validTo)],
  })

  const contractLastsOneYearOrLess = getYear(contract.validFrom) === getYear(contract.validTo)

  let labels = ([...timeseries.parties, ...timeseries.sites][0] || []).map(({name}) => name)

  if (contractLastsOneYearOrLess && interval === Interval.MONTHLY) {
    labels = labels.slice(getMonth(contract.validFrom), getMonth(contract.validTo) + 1)
  }

  const getPurchaseGroupConsumption = () => {
    return timeseries.purchaseGroups.map((item, index) => {
      const lineData = {
        key: `consumption`,
        label: purchaseGroup.name,
        color: chartColors.getNext(),
        ...consumptionLine,
        data: item.map(({value}) => value),
        stackType: StackType.DEFAULT,
        type: ChartType.LINE,
      } as ILine

      return lineData
    })
  }

  const getConsumption = () => {
    if (purchaseGroup && timeseries.purchaseGroups[0]) {
      return getPurchaseGroupConsumption()
    }

    return timeseries.parties.map(
      item =>
        item && {
          key: 'consumption',
          label: translate('My consumption'),
          color: theme.colors.blue7,
          ...consumptionLine,
          data: item.map(({value}) => value),
          type: ChartType.LINE,
        },
    )
  }

  const lines: ILine[] = [
    ...getConsumption(),
    ...timeseries.sites.map((item, index) => {
      const contractItem = contractItemsInChart[index]
      const {product} = contractItem

      if (!item || !product || !product.site?.avgYearlyProductionMwh) {
        return null
      }

      if (product.productType.volumeType === VolumeType.AUTO_FILL) {
        return null
      }

      const lineData = {
        key: `product-${product.id}`,
        label: product.name,
        color: chartColors.getNext(),
        data: getContractItemChartValues(item, contractItem),
        stackType: StackType.STACKED,
        stepped: interval === Interval.YEARLY,
        type: type ? type : ChartType.LINE,
      } as ILine

      // Reduce first year and last year values when contract dates are not in start of year or end of year
      if (interval === Interval.YEARLY && lineData.data.length > 0) {
        lineData.data = reduceFirstAndLastYearValues(
          lineData,
          labels,
          product.site.operationsStart,
          product.site.operationsEnd,
          contract.validFrom,
          contract.validTo,
        )
      }

      return lineData
    }),
  ].filter(Boolean)

  const purchasedEnergyLine: ILine = {
    key: 'purchased',
    label: translate('Purchased energy'),
    color: theme.colors.secondary,
    data: getPurchasedEnergyData(contract, lines, party, purchaseGroup),
    type: ChartType.LINE,
  }

  const [autofillItem] = contractHelper.getContractItemsByProductVolumeType(contract, VolumeType.AUTO_FILL)

  const autofillLine: ILine = autofillItem
    ? {
        key: 'autofill',
        label: autofillItem.product?.name,
        color: theme.colors.light1,
        data: purchasedEnergyLine.data,
        fill: true,
        hideTooltip: true,
        type: ChartType.LINE,
      }
    : null

  const allLines = [purchasedEnergyLine, ...lines, autofillLine].filter(Boolean)

  if (contractLastsOneYearOrLess && interval === Interval.MONTHLY) {
    allLines.forEach(
      line => (line.data = line.data.slice(getMonth(contract.validFrom), getMonth(contract.validTo) + 1)),
    )
  }

  return (
    <Chart
      toolbar={{vertical: true, timescale: contractLastsOneYearOrLess ? {hideYearly: true} : true}}
      lines={allLines}
      labels={labels}
      dark
      maxValue={autoCeilNumber(getMaxValue(allLines))}
      errors={errors}
    />
  )
}

export default ContractChart
