import React from 'react'
import {withLabel} from './Label'
import Range from './Range'
import Input, {IProps as IInputProps} from './Input'

export type IProps = Omit<IInputProps, 'type' | 'margin'>

const RangedInput: React.FC<IProps> = ({error, suffix, prefix, ...props}) => {
  return (
    <>
      <Range {...props} />
      <Input margin={{top: 2, bottom: 2}} suffix={suffix} prefix={prefix} error={error} {...props} type="number" />
    </>
  )
}

export default withLabel<IProps>(RangedInput)
