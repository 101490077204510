import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment-timezone'
import config from './config'

import 'style.css'

import App from './App'

moment.locale(config.language || 'en')
moment.updateLocale(config.language || 'en', {
  week: {
    dow: 1,
  },
})
moment.tz.setDefault('UTC')

ReactDOM.render(<App />, document.getElementById('root'))
