import React, {useEffect, useRef, useState} from 'react'
import useLocalization from '../../../hooks/useLocalization'
import IContractItem, {VolumeUnit} from '../../../domain/IContractItem'
import Box from '../../Box'
import useTheme from '../../../hooks/useTheme'
import Heading, {ISize as IHeadingSize} from '../../Heading'
import styled from 'styled-components'
import ITheme from '../../../theme/ITheme'
import {ClearButton} from '../../Button'
import Label from '../../Label'
import TechnologyIconAndName from '../TechnologyIconAndName'
import ValueWithUnit from '../../ValueWithUnit'
import {getPercentageOfProduction, getContractItemVolumeMwh} from '../../../helpers/contract'
import Range from '../../Range'
import Link from '../../Link'
import {
  getCurrencySymbolFromStringifiedUnit,
  getDecimalInputStep,
  getPricePieces,
  getSlashEnergyUnitSymbolFromStringifiedUnit,
} from '../../../helpers/price'
import {getChangeEventValue} from '../../../helpers/misc'
import Thumbnail from '../../Thumbnail'
import useServices from '../../../hooks/useServices'
import SeamlessInput from '../../SeamlessInput'
import Text from '../../Text'
import {isPriceVisibleToCustomer, isProductOnSale, isProductUserSelectable} from '../../../helpers/product'
import {OfferState} from '../../../domain/IOffer'
import {VolumeType} from 'domain/IProductType'
import {formatPercentage} from '../../../helpers/format'

interface IItemProps extends React.PropsWithChildren {
  name: string
  image?: string
  linkTo?: string
  removable?: boolean
  onRemove?: () => void
  noImage?: boolean
  noBorder?: boolean
  headingSize?: IHeadingSize
  height?: number
  noPad?: boolean
  isExpired?: boolean
  style?: React.CSSProperties
  color?: string
}

const Container = styled(Box)`
  position: relative;
`

const LastBox = styled(Box)`
  margin-left: auto;
  min-width: 130px;
  float: left;
`

const Description = styled.div<{limited: boolean}>`
  max-width: 400px;
  ${props =>
    props.limited &&
    `
    max-height: 40px;
    overflow: hidden;
  `}
`

const RemoveButton = styled(ClearButton)<{expired: boolean}>`
  position: absolute;
  top: ${props => (props.theme as ITheme).tileSize}px;
  right: ${props => (props.theme as ITheme).tileSize}px;
  ${props =>
    props.expired &&
    `
    color: red;
  `}
`

const MinWithSpan = styled.span<{width: number}>`
  width: ${props => props.width}px;
`

export const Item: React.FC<IItemProps> = ({
  name,
  image,
  removable,
  onRemove,
  color,
  children,
  linkTo,
  noImage,
  noBorder,
  headingSize,
  height,
  noPad,
  isExpired,
  style,
}) => {
  const {colors} = useTheme()
  const {translate} = useLocalization()

  return (
    <Container
      data-c="Item"
      color={colors.common.white}
      round
      border={noBorder ? false : true}
      align="start"
      margin={{bottom: 2}}
      height={height}
      style={style}
    >
      {!noImage && <Thumbnail linkTo={linkTo} image={image} fallbackColor={color} />}
      <Box
        fillHeight
        pad={noPad ? null : {vertical: 1, horizontal: 2}}
        direction="column"
        justify="space-between"
        width={'100%'}
      >
        <Link to={linkTo}>
          <Heading size={headingSize} lineHeight="small" margin={{vertical: 1}}>
            {name}
          </Heading>
        </Link>
        <Box direction="row" width={'100%'}>
          {children}
        </Box>
      </Box>
      {removable && onRemove && (
        <RemoveButton pad={{horizontal: 1}} onClick={onRemove} expired={isExpired}>
          {isExpired && `${translate('Product is expired!')} `}
          {translate('Remove')}
        </RemoveButton>
      )}
    </Container>
  )
}

Item.defaultProps = {
  height: 16,
}

export interface IContractItemProps extends Omit<IItemProps, 'image' | 'onRemove' | 'name'> {
  contractItem: IContractItem
  editable?: boolean
  compact?: boolean
  removable?: boolean
  onRemove?: () => void
  onAmountChange?: (newAmount: number) => void
  onContractItemChange?: (newItem: IContractItem) => void
  maxTotalVolumeMwh?: number
  autoFillVolumeMwh?: number
  showPercentOf: number
  hidePrice?: boolean
  offerState?: OfferState
  hasSlider?: boolean
}

enum PurchaseVolumeType {
  ENERGY,
  PERCENT,
}

interface IPurchaseVolume {
  type: PurchaseVolumeType
  value: number
  unit: string
  name: string
}

let timeoutRange

const ContractItem: React.FC<IContractItemProps> = ({
  maxTotalVolumeMwh,
  autoFillVolumeMwh,
  onRemove,
  onAmountChange,
  onContractItemChange,
  hidePrice,
  contractItem,
  editable,
  compact,
  showPercentOf,
  removable,
  offerState,
  hasSlider,
  height,
  ...itemProps
}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const {contentService} = useServices()
  const [removed, setRemoved] = useState<boolean>(false)
  const rangeRef = useRef<HTMLInputElement>(null)
  const {volumeUnit} = useTheme()
  const priceVisibleToCustomer = isPriceVisibleToCustomer(contractItem.product)
  const isExpired = !isProductOnSale(contractItem.product)
  const isFirming = contractItem.product.productType.volumeType === VolumeType.AUTO_FILL
  const contractItemVolumeMwh = getContractItemVolumeMwh(contractItem)

  const purchaseVolumeMwh: IPurchaseVolume = {
    value: contractItemVolumeMwh,
    unit: translate(VolumeUnit.MWH_YEAR),
    type: PurchaseVolumeType.ENERGY,
    name: translate('Purchase volume'),
  }

  useEffect(() => {
    if (rangeRef.current && purchaseVolumeMwh) {
      rangeRef.current.valueAsNumber = purchaseVolumeMwh.value
    }
  }, [contractItem, purchaseVolumeMwh])

  const handleAmountChange = (newAmount: number) => {
    const value = Math.min(Math.max(0, newAmount), maxTotalVolumeMwh)
    onAmountChange && onAmountChange(value)

    return value
  }

  const handleContractItemChange = (newItem: IContractItem) => {
    onContractItemChange && onContractItemChange(newItem)
  }

  const [priceValue, priceUnit] = getPricePieces(
    contractItem.product.price ? contractItem.product.price : contractItem.price,
  )
  const handleRemove = () => {
    onRemove()
    setRemoved(true)
  }
  if (removed) {
    return null
  }

  const getPercentage = (volumeMwh: number) => formatPercentage(volumeMwh / showPercentOf)

  const purchaseVolumePercent: IPurchaseVolume = {
    value: getPercentage(isFirming && autoFillVolumeMwh ? autoFillVolumeMwh : contractItemVolumeMwh),
    unit: VolumeUnit.PERCENTAGE,
    type: PurchaseVolumeType.PERCENT,
    name: translate('Of consumption'),
  }

  const purchaseVolume: IPurchaseVolume =
    volumeUnit === VolumeUnit.PERCENTAGE ? purchaseVolumePercent : purchaseVolumeMwh

  const productionVolume: IPurchaseVolume = {
    value: getPercentageOfProduction(contractItem),
    unit: VolumeUnit.PERCENTAGE,
    type: PurchaseVolumeType.PERCENT,
    name: translate('Of production'),
  }

  const showVolume = (contractItemVolumeMwh !== null && isProductUserSelectable(contractItem.product)) || isFirming

  return (
    <Item
      name={contractItem.product.name}
      image={contentService.getAttachmentURL(contractItem.product?.image)}
      onRemove={handleRemove}
      noImage={compact}
      removable={removable}
      isExpired={isExpired}
      height={height}
      {...itemProps}
    >
      <Box gap={3} width={'100%'}>
        {hasSlider && (
          <MinWithSpan width={125}>
            {compact ? (
              <Box margin={{left: -3}} />
            ) : contractItem.product?.site?.productionTechnology ? (
              <Label text={translate('Technology')}>
                <TechnologyIconAndName technology={contractItem.product?.site?.productionTechnology} semibold />
              </Label>
            ) : (
              contractItem.product?.description && (
                <Label text={translate('Description')}>
                  <Description limited={!!height}>
                    {(contractItem.product?.description.substring(0, 45) + '...').split('\n').map((item, key) => {
                      return (
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      )
                    })}
                  </Description>
                </Label>
              )
            )}
          </MinWithSpan>
        )}
        {hasSlider && editable && (
          <MinWithSpan width={150}>
            {editable && maxTotalVolumeMwh > 0 && isProductUserSelectable(contractItem.product) && (
              <Label text={translate('Change volume')}>
                <Range
                  ref={rangeRef}
                  min={0}
                  defaultValue={contractItemVolumeMwh}
                  max={maxTotalVolumeMwh}
                  step={getDecimalInputStep(theme)}
                  onChange={e => {
                    clearTimeout(timeoutRange)
                    const value = +getChangeEventValue(e)
                    handleAmountChange(value)
                  }}
                />
              </Label>
            )}
          </MinWithSpan>
        )}
        <MinWithSpan width={90}>
          {showVolume && (
            <Label text={purchaseVolume.name}>
              {editable ? (
                <Text size="large" semibold>
                  <SeamlessInput
                    name="volume"
                    type="number"
                    lineHeight={'24px'}
                    defaultValue={purchaseVolume.value}
                    suffix={purchaseVolume.unit}
                    onBlur={value => {
                      if (purchaseVolume.type === PurchaseVolumeType.PERCENT) {
                        value = +((showPercentOf / 100) * value).toFixed(2)
                      }

                      if (value > maxTotalVolumeMwh) {
                        value = maxTotalVolumeMwh
                      }

                      if (rangeRef.current) {
                        rangeRef.current.valueAsNumber = value
                      }

                      handleAmountChange(value)
                    }}
                  />
                </Text>
              ) : (
                <ValueWithUnit size="large" value={purchaseVolume.value} unit={purchaseVolume.unit} />
              )}
            </Label>
          )}
        </MinWithSpan>
        <MinWithSpan width={100}>
          {productionVolume?.value >= 0 && (
            <Label text={productionVolume.name}>
              <ValueWithUnit size="mlarge" value={productionVolume.value} unit={productionVolume.unit} />
            </Label>
          )}
        </MinWithSpan>
        {!hidePrice &&
          contractItem.price &&
          priceVisibleToCustomer &&
          offerState &&
          ![OfferState.BINDING_DRAFT, OfferState.BINDING_TO_CUSTOMER].includes(offerState) && (
            <LastBox>
              <Label text={translate('Price')}>
                <ValueWithUnit
                  size="mlarge"
                  currency={getCurrencySymbolFromStringifiedUnit(priceUnit)}
                  value={contractItem.productPriceValue ? contractItem.productPriceValue.toFixed(2) : priceValue}
                  unit={getSlashEnergyUnitSymbolFromStringifiedUnit(priceUnit)}
                />
              </Label>
            </LastBox>
          )}
        {offerState &&
          [OfferState.BINDING_DRAFT, OfferState.BINDING_TO_CUSTOMER].includes(offerState) &&
          contractItem.price && (
            <LastBox>
              <Label text={translate('Price')}>
                <Text size="large" semibold>
                  <SeamlessInput
                    name="price"
                    type="number"
                    defaultValue={
                      contractItem.productPriceValue ? contractItem.productPriceValue.toFixed(2) : priceValue
                    }
                    prefix={getCurrencySymbolFromStringifiedUnit(priceUnit)}
                    suffix={getSlashEnergyUnitSymbolFromStringifiedUnit(priceUnit)}
                    lineHeight={'24px'}
                    onBlur={value => {
                      const newItem = contractItem
                      if (newItem) {
                        newItem.productPriceValue = value
                      }
                      handleContractItemChange(newItem)
                    }}
                  />
                </Text>
              </Label>
            </LastBox>
          )}
      </Box>
    </Item>
  )
}

export default ContractItem
