import {ClearButton, IProps as IButtonProps} from 'components/Button'
import DottedIconButton from 'components/IconButton/DottedIconButton'
import Link, {IProps as ILinkProps} from 'components/Link'
import React, {useState} from 'react'
import styled from 'styled-components'
import ITheme from 'theme/ITheme'
import DropdownList from './DropdownList'

const padding = '12px 12px'
const hoverBgColor = '#DEEBFF'

export const DropdownMenuButton = styled(ClearButton)<IButtonProps>`
  border: none;
  background: none;
  color: ${props => (props.theme as ITheme).colors.secondary};
  padding: ${padding};
  width: 100%;

  &:not(:disabled):hover {
    border: none;
    background: none;

    filter: none;
    background-color: ${hoverBgColor};
  }
`
export const CenteredContentWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  outline: none;
`

export const DropdownMenuLink = styled(Link)<ILinkProps>`
  color: ${props => (props.theme as ITheme).colors.secondary};
  padding: ${padding};
  width: 100%;

  &:not(:disabled):visited,
  &:not(:disabled):hover {
    background-color: ${hoverBgColor};
    color: ${props => (props.theme as ITheme).colors.secondary};
  }
`

export interface IProps extends React.PropsWithChildren {
  menuItems: (typeof DropdownMenuLink | typeof DropdownMenuButton)[]
}

const DotsIconDropdownMenu: React.FC<IProps> = ({menuItems}) => {
  const [isOpen, setIsOpen] = useState(false)

  //https://stackoverflow.com/questions/12092261/prevent-firing-the-blur-event-if-any-one-of-its-children-receives-focus
  const handleBlur = event => {
    const currentTarget = event.currentTarget

    // Check the newly focused element in the next tick of the event loop
    setTimeout(() => {
      // Close the dropdown if the new active element is NOT a child of the dropdown.
      if (!currentTarget.contains(document.activeElement)) {
        setIsOpen(false)
      }
    }, 0)
  }

  return (
    <CenteredContentWrapper tabIndex={0} onClick={() => setIsOpen(!isOpen)} onBlur={event => handleBlur(event)}>
      <DottedIconButton></DottedIconButton>

      {isOpen && <DropdownList menuItems={menuItems}></DropdownList>}
    </CenteredContentWrapper>
  )
}

DotsIconDropdownMenu.displayName = 'DotsIconDropdownMenu'

const Dropdown: React.FC<IProps & {variant: 'dottedIcon'}> = ({variant, ...props}) => {
  // TODO: add more dropdown variants
  return <DotsIconDropdownMenu {...props} />
}

export default Dropdown
