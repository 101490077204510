import React from 'react'
import RouterModal, {RouterModalContext} from '../../../Modal/RouterModal'
import IToken from '../../../../domain/IToken'
import ModalHeader from '../../../Modal/ModalHeader'
import ISite from '../../../../domain/ISite'
import useLocalization from '../../../../hooks/useLocalization'
import TabbedTokenDetails, {TokenType} from './TabbedTokenDetails'
import Error from '../../../Error'
import Box from '../../../Box'
import useTheme from '../../../../hooks/useTheme'
import ISmartEnergyContract from '../../../../domain/ISmartEnergyContract'

interface IProps extends React.PropsWithChildren {
  tokens: IToken[]
  site: ISite
  walletId: string
  type: TokenType
  smartContract: ISmartEnergyContract
}

const TokenDetailsModal: React.FC<IProps> = ({tokens, site, walletId, type, smartContract}) => {
  const {translate} = useLocalization()
  const theme = useTheme()

  return (
    <RouterModal name="modal.token-details" maxSize="medium" full>
      <ModalHeader title={site.name} description={translate('Overview of the ownership token')} />
      <RouterModalContext.Consumer>
        {({value}) => {
          const token = tokens.find(t => t.id === value)

          if (!token) {
            return <Error>{translate('No such token')}</Error>
          }

          return (
            <Box border pad={2}>
              <TabbedTokenDetails
                type={type}
                site={site}
                token={token}
                walletId={walletId}
                smartContract={smartContract}
              />
            </Box>
          )
        }}
      </RouterModalContext.Consumer>
    </RouterModal>
  )
}

export default TokenDetailsModal
