import styled from 'styled-components'

interface IProps extends React.PropsWithChildren {
  type: 'before' | 'after'
  auto?: boolean
  padding?: string
}

const PageBreak = styled.div<IProps>`
  page-break-${props => props.type}: ${props => (props.auto ? 'auto' : 'always')};
  page-break-inside: avoid;

  //workaround for edge browser only to pad top of chart to fit in new pdf page
  @supports (-ms-ime-align: auto) {
    padding-top: ${props => (props.padding ? props.padding : '0px')};
    break-${props => props.type}: ${props => (props.auto ? 'auto' : 'always')};
  }
`

PageBreak.defaultProps = {
  type: 'after',
}

export default PageBreak
