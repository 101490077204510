import React, {useContext} from 'react'
import IContract from '../../../domain/IContract'
import useLocalization from '../../../hooks/useLocalization'
import Text from '../../Text'
import useTheme from '../../../hooks/useTheme'
import {getPriceUnitSymbol, getSlashEnergyUnit} from '../../../helpers/price'
import KeyFeature from '../KeyFeature'
import ContractTerm from './Contract/ContractTerm'
import {isPriceVisibleToCustomer} from '../../../helpers/product'
import {getContractYearlyVolumeMwh} from '../../../helpers/contract'
import Error from '../../Error'
import IPurchaseContract from 'domain/IPurchaseContract'
import {PartyRoleContext} from 'App'
import {PartyRole} from 'domain/IParty'
import {formatEnergy, formatMoney} from '../../../helpers/format'

interface IProps extends React.PropsWithChildren {
  contract: IContract | IPurchaseContract
  unsaved?: boolean
}

const ContractSummaryDetails: React.FC<IProps> = ({contract, unsaved}) => {
  const {translate} = useLocalization()
  const theme = useTheme()

  const partyRole = useContext(PartyRoleContext)

  const price = contract.summary?.price

  if (!price) {
    return <Error>{translate('Invalid contract')}</Error>
  }

  const slashEnergyUnit = getSlashEnergyUnit(price.unit)
  const volumeMwh = getContractYearlyVolumeMwh(contract)
  const priceVisibleToCustomer =
    contract.contractItems.every(item => isPriceVisibleToCustomer(item.product)) && !theme.product.hidePriceInList

  return (
    <div>
      <KeyFeature
        title={translate('Est. contract volume')}
        value={formatEnergy(volumeMwh)}
        unit={translate('MWh/year')}
      />
      <KeyFeature
        title={translate('Total price')}
        value={formatMoney(price.total)}
        currency={getPriceUnitSymbol(contract.summary.price.unit)}
        unit={slashEnergyUnit}
        highlighted
      >
        {unsaved && (
          <Text color={theme.colors.error}>
            {translate('This price is indicative. Update the balancing price below!')}
          </Text>
        )}
      </KeyFeature>
      {contract.summary.pricesByProductType && (partyRole === PartyRole.BROKER || priceVisibleToCustomer) && (
        <div>
          <Text margin={{top: 2}} size="msmall" semibold>
            {translate('Including')}
          </Text>
          {contract.summary.pricesByProductType.map(
            (productTypePrice, index) =>
              productTypePrice.value > 0 && (
                <KeyFeature
                  key={index}
                  title={productTypePrice.name}
                  value={formatMoney(productTypePrice.value)}
                  currency={getPriceUnitSymbol(contract.summary.price.unit)}
                  unit={slashEnergyUnit}
                  small
                />
              ),
          )}
        </div>
      )}
      <KeyFeature title={translate('Contract term')} value={<ContractTerm contract={contract} />} small />
    </div>
  )
}

export default ContractSummaryDetails
