import IContentService from '../services/IContentService'
import {computed, action, observable} from 'mobx'
import RootStore from './index'
import IParty from '../domain/IParty'
import {ResourceId} from 'types'
import ICertificateOffer from 'domain/ICertificateOffer'
import IV2ContentService from 'services/IV2ContentService'

export default class OfferDesignerCertificateStore {
  private readonly rootStore: RootStore
  private readonly contentService: IContentService
  private readonly v2ContentService: IV2ContentService

  @observable public certOffer: ICertificateOffer
  @observable public loading = true

  public constructor(rootStore: RootStore, contentService: IContentService, v2ContentService: IV2ContentService) {
    this.rootStore = rootStore
    this.contentService = contentService
    this.v2ContentService = v2ContentService
  }

  @action
  public async approveRequest(offerId: ResourceId): Promise<void> {
    await this.contentService.approveCertificateOffer(this.party.id, offerId)
  }

  @action
  public async declineRequest(offerId: ResourceId): Promise<void> {
    await this.contentService.declineCertificateOffer(this.party.id, offerId)
  }

  @action
  public async loadCertificateOffer(offerId: ResourceId): Promise<void> {
    try {
      const offer = await this.v2ContentService.getCertificatePurchaseOffer(this.party.id, offerId)

      this.certOffer = offer
    } finally {
      this.loading = false
    }
  }

  @action
  public async cleanCertificateOffer(): Promise<void> {
    this.certOffer = null
  }

  @computed
  private get party(): IParty {
    return this.rootStore.profileStore.party
  }
}
