import IPurchaseContract from 'domain/IPurchaseContract'
import React from 'react'
import IContract from '../../../domain/IContract'
import {
  calculateFirmingOrAutofillVolumeMwh,
  getContractYearlyVolumeMwh,
  isAnyContractProductExpired,
} from '../../../helpers/contract'
import useLocalization from '../../../hooks/useLocalization'
import useScreenSize, {ScreenBreakpoint} from '../../../hooks/useScreenSize'
import useTheme from '../../../hooks/useTheme'
import Box from '../../Box'
import {SecondaryButton} from '../../Button'
import Form from '../../Form'
import FormSubmitButton from '../../Form/FormSubmitButton'
import Heading from '../../Heading'
import ModalHeader from '../../Modal/ModalHeader'
import ResponsiveGrid from '../../ResponsiveGrid'
import ContractDownloadLinks from './ContractDownloadLinks'
import ContractHtmlPreview from './ContractHtmlPreview'
import ContractItem from './ContractItem'
import ContractSummaryDetails from './ContractSummaryDetails'
import ModalCloser from '../../Modal/ModalCloser'
import useProfile from '../../../hooks/useProfile'
import {isPartyAdmin} from '../../../helpers/partyMember'

interface IProps extends React.PropsWithChildren {
  contract: IContract | IPurchaseContract
  onClose?: () => void
  onSubmit?: () => void
  signable?: boolean
}

const ContractModalForm: React.FC<IProps> = ({contract, onClose, onSubmit, signable}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const screenSize = useScreenSize()
  const isExpired = isAnyContractProductExpired(contract?.contractItems)
  const autoFillVolumeMwh = calculateFirmingOrAutofillVolumeMwh(contract)
  const {partyMembership} = useProfile()
  const canSignContract = !isExpired && isPartyAdmin(partyMembership)

  return (
    <>
      <ModalHeader
        title={signable ? translate('Sign the contract') : translate('Contract')}
        description={
          signable
            ? translate('Please review and accept the contract')
            : translate('Please review the details of solution contract')
        }
        error={
          isExpired &&
          translate('Product(s) in your contract are expired. Please close the modal and adjust the contract.')
        }
      >
        <Form
          onSubmit={() => {
            onSubmit && onSubmit()
          }}
          submissionFeedback={null}
        >
          {signable ? (
            <Box gap={2}>
              <SecondaryButton type="button" onClick={onClose}>
                {translate('Cancel')}
              </SecondaryButton>
              <FormSubmitButton disabled={!canSignContract}>
                {translate('I agree with the contract and I sign it')}
              </FormSubmitButton>
            </Box>
          ) : (
            <Box direction="row" gap={2}>
              <ModalCloser>
                <SecondaryButton type="button">{translate('Cancel')}</SecondaryButton>
              </ModalCloser>
            </Box>
          )}
        </Form>
      </ModalHeader>

      <ResponsiveGrid
        gap={3}
        templateColumns={{
          [ScreenBreakpoint.Small]: '1fr',
          [ScreenBreakpoint.Large]: '200px 1fr',
        }}
      >
        <Box pad={2} round border>
          <Heading margin={{bottom: 2}}>{translate('Contract summary')}</Heading>
          <ContractSummaryDetails contract={contract} />
        </Box>

        <Box pad={2} color={theme.colors.light3} round>
          <Heading margin={{bottom: 2}}>{translate('Contract items overview')}</Heading>
          {contract.contractItems.map(contractItem => (
            <ContractItem
              compact={screenSize <= ScreenBreakpoint.Small}
              key={contractItem.id}
              contractItem={contractItem}
              autoFillVolumeMwh={autoFillVolumeMwh}
              showPercentOf={getContractYearlyVolumeMwh(contract)}
            />
          ))}
        </Box>

        <Box pad={2} border round style={screenSize >= ScreenBreakpoint.Large ? {gridColumn: 'span 2'} : null}>
          <Heading margin={{bottom: 2}}>{translate('Agreement')}</Heading>
          <ContractDownloadLinks contract={contract} />
          <ContractHtmlPreview id={contract.id} />
        </Box>
      </ResponsiveGrid>
    </>
  )
}

export default ContractModalForm
