import {action, computed, observable} from 'mobx'
import IOffer, {IOfferMeta, OfferKind} from '../domain/IOffer'
import IPurchaseGroup from '../domain/IPurchaseGroup'
import {ResourceId} from '../types'
import RootStore from './index'
import {ProductTypeBehaviour, VolumeType} from '../domain/IProductType'
import IOfferTextGroup from '../domain/IOfferTextGroup'
import IPriceCalculationService from '../services/IPriceCalculationService'
import RootService from '../services'
import IContentService from '../services/IContentService'
import {getPartySite, isPriceCalculationMethodDefault, isPriceCalculationMethodSimple} from '../helpers/party'
import IParty from '../domain/IParty'
import IContractItem from '../domain/IContractItem'
import {adjustContractVolumes} from '../helpers/contract'

export default class OfferDesignerStore {
  private readonly rootStore: RootStore
  private readonly priceCalculationService: IPriceCalculationService
  private readonly contentService: IContentService
  private readonly simplePriceCalculationService: IPriceCalculationService
  private price: number
  private lgcPrice: number
  private firmingPrice: number
  private managedByParty: IParty

  @observable private baseOffer: IOffer = null
  @observable private productVolumesMwh: {
    [key: string]: {volumeMwh: number; deleted?: boolean}
  } = {}
  @observable public isDirty = false
  @observable public textGroups: IOfferTextGroup[] = null
  @observable public savedContractVolumeMwh = 0

  public constructor(rootService: RootService, rootStore: RootStore) {
    this.rootStore = rootStore
    this.priceCalculationService = rootService.priceCalculationService
    this.contentService = rootService.contentService
    this.simplePriceCalculationService = rootService.simplePriceCalculationService
  }

  @computed
  public get offer(): IOffer {
    const offer = this.baseOffer
    const contract = this.baseOffer?.contract

    const updatedOffer = {
      ...offer,
      contract: {
        ...contract,
        contractItems: Object.keys(this.productVolumesMwh)
          .map(productId => {
            if (this.productVolumesMwh[productId].deleted) {
              return null
            }

            const product = this.rootStore.productStore.getItem(productId)

            if (!product) {
              return null
            }

            return {
              id: contract?.contractItems?.find(item => item.product.id === product.id)?.id,
              volumeMwh: this.productVolumesMwh[productId].volumeMwh,
              contract,
              product,
              price: contract?.contractItems?.find(item => item.product.id === product.id)?.price
                ? contract?.contractItems?.find(item => item.product.id === product.id)?.price
                : product.price,
              productPriceValue: contract?.contractItems?.find(item => item.product.id === product.id)
                ?.productPriceValue
                ? contract?.contractItems?.find(item => item.product.id === product.id)?.productPriceValue
                : null,
            }
          })
          .filter(Boolean),
      },
    }

    return updatedOffer
  }

  @action
  public setManagedParty(party: IParty) {
    this.managedByParty = party
  }

  @action
  public create(offer?: IOffer) {
    this.baseOffer = {
      ...offer,
      meta: {
        ...offer?.meta,
        pricingParameterGroupId:
          offer?.meta?.pricingParameterGroupId ||
          offer?.customer?.meta?.pricingParameterGroupId ||
          getPartySite(offer?.customer)?.meta?.pricingParameterGroupId,
      },
    }

    const productVolumesMwh = {}

    offer?.contract?.contractItems?.forEach(contractItem => {
      productVolumesMwh[contractItem.product?.id] = {
        volumeMwh: contractItem.volumeMwh,
      }
    })

    this.productVolumesMwh = productVolumesMwh
    this.textGroups = null
  }

  @action
  public addProduct(productId: ResourceId) {
    const product = this.rootStore.productStore.getItem(productId)

    let volumeMwh
    // Bundled LGCs should not be split - take 100% of the contract volume
    if (product.productType.volumeType === VolumeType.NONE) {
      volumeMwh = this.baseOffer.contract?.volumeMwh || this.offer.contract?.volumeMwh || null
    } else {
      volumeMwh = [VolumeType.USER_SELECT, VolumeType.STANDALONE_USER_SELECT].includes(product.productType.volumeType)
        ? 0
        : null
    }

    this.productVolumesMwh[productId] = {
      volumeMwh: volumeMwh,
    }

    this.isDirty = true
  }

  @action
  public addProductWithVolume(productId: ResourceId, volume: number) {
    this.rootStore.productStore.getItem(productId)

    this.productVolumesMwh[productId] = {
      volumeMwh: volume,
    }

    this.isDirty = true
  }

  @action
  public removeProduct(productId: ResourceId) {
    this.productVolumesMwh[productId] = {volumeMwh: 0, deleted: true}

    // price calculations are skipped when all products are deleted, so we need to update the price here
    if (Object.keys(this.productVolumesMwh).every(key => this.productVolumesMwh[key].deleted)) {
      this.baseOffer = {
        ...this.baseOffer,
        minPrice: 0,
        maxPrice: 0,
        lgcMinPrice: 0,
        lgcMaxPrice: 0,
      }
    }

    this.isDirty = true
  }

  @action
  public setAmount(productId: ResourceId, volumeMwh: number) {
    this.productVolumesMwh[productId] = {volumeMwh}
    this.isDirty = true
  }

  @action
  public setVolumeMwh(volumeMwh: number) {
    this.baseOffer = {...this.baseOffer, contract: {...this.offer.contract, volumeMwh: volumeMwh}}

    if (this.baseOffer.contract?.contractItems) {
      this.baseOffer.contract = adjustContractVolumes(this.baseOffer.contract)
    }
  }

  @action
  public changeToDirty() {
    this.isDirty = true
  }

  @action
  public async save(offerData: IOffer) {
    // Customer party must be retrieved from party store instead of using offerData.customer since only id is changed
    // in from and therefore could be invalid dataset
    const customerParty: IParty = offerData.customer ? this.rootStore.partyStore.getItem(offerData.customer.id) : null

    this.baseOffer = {
      ...this.baseOffer,
      ...offerData,
      contract: {...this.baseOffer?.contract, ...offerData.contract},
      meta: {
        ...offerData.meta,
        // Stores customer pricing when offer has no pricing set
        pricingParameterGroupId: offerData.meta?.pricingParameterGroupId || customerParty.meta?.pricingParameterGroupId,
      },
    }

    let offer = this.offer

    const purchaseGroup = this.baseOffer?.contract?.purchaseGroup
    offer.contract.purchaseGroup = purchaseGroup?.id ? {...purchaseGroup} : null

    offer.contract = adjustContractVolumes(offer.contract)
    if (isPriceCalculationMethodSimple(offer.managedByParty)) {
      offer = this.changePricesForContractItemsSimple(offer)
    } else {
      offer = this.changePricesForContractItemsDefault(offer)
    }
    this.savedContractVolumeMwh = offer.contract.volumeMwh
    offer.contract.name = offer.name
    const result = await this.rootStore.offerStore.saveOffer(offer)

    this.isDirty = false

    return result
  }

  @action
  public async loadLookups(offerId: any) {
    this.textGroups = await this.contentService.getOfferTextGroups(this.rootStore.profileStore.party.id, offerId)
  }

  @action
  public async calculatePrice(offer: IOffer) {
    const priceCalculationService: IPriceCalculationService = isPriceCalculationMethodSimple(offer.managedByParty)
      ? this.simplePriceCalculationService
      : this.priceCalculationService
    const priceCalculation = await priceCalculationService.calculateOfferPrice(offer)

    this.price = priceCalculation.price
    this.lgcPrice = priceCalculation.lgcPrice
    this.firmingPrice = priceCalculation.firmingPrice

    this.baseOffer = {
      ...this.baseOffer,
      minPrice: priceCalculation.minPrice,
      maxPrice: priceCalculation.maxPrice,
      lgcMinPrice: priceCalculation.lgcMinPrice,
      lgcMaxPrice: priceCalculation.lgcMaxPrice,
    }
  }

  public async calculatePriceOnly(offer: IOffer, managedByParty: IParty) {
    if (offer?.kind === OfferKind.CERTIFICATE_TRADE) {
      return
    }

    const priceCalculationService: IPriceCalculationService = isPriceCalculationMethodSimple(managedByParty)
      ? this.simplePriceCalculationService
      : this.priceCalculationService

    const hasItems = offer?.contract?.contractItems?.length > 0

    if (!managedByParty?.id || !hasItems) {
      return
    }

    const priceCalculation = await priceCalculationService.calculateOfferPrice(offer, managedByParty?.id)

    this.price = priceCalculation.price
    this.lgcPrice = priceCalculation.lgcPrice
    if (priceCalculation.firmingPrice) {
      this.firmingPrice = priceCalculation.firmingPrice
    }

    this.baseOffer = {
      ...this.baseOffer,
      minPrice: priceCalculation.minPrice,
      maxPrice: priceCalculation.maxPrice,
      lgcMinPrice: priceCalculation.lgcMinPrice,
      lgcMaxPrice: priceCalculation.lgcMaxPrice,
    }
  }

  public async returnCalculatePriceOnly(offer: IOffer, managedByParty?: IParty) {
    const priceCalculationService: IPriceCalculationService =
      managedByParty && isPriceCalculationMethodDefault(managedByParty)
        ? this.priceCalculationService
        : this.simplePriceCalculationService

    if (!offer?.managedByParty?.id) return
    const priceCalculation = await priceCalculationService.calculateOfferPrice(offer)

    return {
      minPrice: priceCalculation.minPrice,
      maxPrice: priceCalculation.maxPrice,
      lgcMinPrice: priceCalculation.lgcMinPrice,
      lgcMaxPrice: priceCalculation.lgcMaxPrice,
      lgcPrice: priceCalculation.lgcPrice,
      price: priceCalculation.price,
      yearlyPrices: priceCalculation.yearlyPrices,
    }
  }

  @action
  public async setMinPrice(minPrice: number) {
    this.baseOffer = {
      ...this.baseOffer,
      minPrice,
    }
  }

  @action
  public async setMaxPrice(maxPrice: number) {
    this.baseOffer = {
      ...this.baseOffer,
      maxPrice,
    }
  }

  @action
  public async setLgcMinPrice(lgcMinPrice: number) {
    this.baseOffer = {
      ...this.baseOffer,
      lgcMinPrice,
    }
  }

  @action
  public async setLgcMaxPrice(lgcMaxPrice: number) {
    this.baseOffer = {
      ...this.baseOffer,
      lgcMaxPrice,
    }
  }

  @action
  public setMeta(meta: IOfferMeta) {
    this.baseOffer = {
      ...this.baseOffer,
      meta: {
        ...this.baseOffer?.meta,
        ...meta,
      },
    }
  }

  @action
  public setContractItemPrice(offer: IOffer, contractItem: IContractItem) {
    const contractItems = this.baseOffer?.contract?.contractItems.map(item =>
      item.id === contractItem.id ? contractItem : item,
    )

    this.baseOffer = {
      ...this.baseOffer,
      contract: {
        ...this.baseOffer?.contract,
        contractItems: contractItems,
      },
    }

    this.isDirty = true
  }

  @action
  public setPurchaseGroup(purchaseGroup: IPurchaseGroup) {
    this.baseOffer = {
      ...this.baseOffer,
      contract: {
        ...this.baseOffer?.contract,
        purchaseGroup,
      },
    }
  }

  protected changePricesForContractItemsSimple(offer: IOffer): IOffer {
    offer.contract.contractItems.forEach(item => {
      item.price.value = item.productPriceValue ? item.productPriceValue : item.product.price.value
      item.price.id = null
    })

    return offer
  }

  protected changePricesForContractItemsDefault(offer: IOffer): IOffer {
    offer.contract.contractItems.forEach(item => {
      item.price.value = item.product?.price?.value
      item.price.id = null
    })

    return offer
  }

  protected getTotalPriceByBehaviourType(contractItems: IContractItem[], behaviourType: ProductTypeBehaviour): number {
    return contractItems
      .filter(item => item.product.productType.behaviour === behaviourType)
      .reduce(
        (sum, currentItem) =>
          sum + (currentItem.productPriceValue ? currentItem.productPriceValue : currentItem.product.price.value),
        0,
      )
  }
}
