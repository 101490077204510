import React from 'react'
import Form from '../../Form'
import Label from '../../Label'
import useLocalization from '../../../hooks/useLocalization'
import Text from '../../Text'
import Box from '../../Box'
import useServices from '../../../hooks/useServices'
import IParty from '../../../domain/IParty'
import useStore from '../../../hooks/useStore'
import {RepresentationBasis} from '../../../domain/IPartyMember'
import {PartyMemberRole} from 'domain/PartyMemberRole'
import {StyledFormButton, StyledFormInput, StyledLocationSearch} from './Layout/FormComponents'
import {LocationBox} from '../Portfolio/Site/LocationSearch'

// eslint-disable-next-line
interface IProps extends React.PropsWithChildren {}

const ContactInfoForm: React.FC<IProps> = () => {
  const {translate} = useLocalization()
  const {profileStore} = useStore()
  const {contentService} = useServices()

  const handleSubmit = async (party: IParty) => {
    await contentService.createPartyForCurrentUser({
      representationBasis: RepresentationBasis.CI_CUSTOMER_MANAGER,
      party,
      partyMemberRoles: [PartyMemberRole.PARTY_ADMIN, PartyMemberRole.PARTY_USER],
    })

    profileStore.load()
  }

  return (
    <Form onSubmit={handleSubmit} submissionFeedback={null} fillHeight>
      <Box margin={{bottom: 3}}>
        <Label text={translate('Account type')}>
          <Text size="large" lineHeight="small">
            {translate('Corporate account')}
          </Text>
        </Label>
      </Box>

      <StyledFormInput label={translate('Company name')} name="name" required data-cy="name" />

      <StyledFormInput label={translate('Company reg. number')} name="registrationCode" data-cy="regNumber" required />

      <StyledFormInput label={translate('Company vat. number')} name="vatCode" data-cy="vatCode" required />

      <LocationBox data-cy="location-select">
        <Label text={translate('Company address')}>
          <StyledLocationSearch location={null} variant="contrast" required />
        </Label>
      </LocationBox>

      <Box justify="flex-end">
        <StyledFormButton>{translate('Continue')}</StyledFormButton>
      </Box>
    </Form>
  )
}

export default ContactInfoForm
