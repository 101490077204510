import IOffer, {OfferState} from '../domain/IOffer'
import ITranslate from '../interfaces/ITranslate'
import isNil from 'lodash/isNil'
import meanBy from 'lodash/meanBy'
import mean from 'lodash/mean'
import sum from 'lodash/sum'

export function getPriceRange(minPrice: number, maxPrice: number): string {
  const getPrice = (price: number) => (isNil(price) ? '...' : price.toFixed(2))
  return `${getPrice(minPrice)} - ${getPrice(maxPrice)}`
}

export function getReasonWhyPriceCouldNotBeCalculated(offer: IOffer, translate: ITranslate): string {
  const erroneousFields = []

  if (offer.contract?.volumeMwh <= 0) {
    erroneousFields.push(translate('estimated annual volume'))
  }
  if (!offer.numberOfNmis || offer.numberOfNmis <= 0) {
    erroneousFields.push(translate('number of NMIs'))
  }
  if (!offer.customer?.id) {
    erroneousFields.push(translate('customer'))
  }
  if (!offer.contract?.validFrom) {
    erroneousFields.push(translate('contract start'))
  }
  if (!offer.contract?.validTo) {
    erroneousFields.push(translate('contract end'))
  }
  if (!offer.contract?.contractType?.id) {
    erroneousFields.push(translate('contract type'))
  }
  if (offer.contract?.contractItems?.length <= 0) {
    erroneousFields.push(translate('products'))
  }

  return erroneousFields.length > 0 ? translate('Missing %s', erroneousFields.join(', ')) : null
}

export function getOffersAveragePrice(offers: IOffer[]): number {
  return meanBy(
    offers,
    offer => sum([offer.minPrice, offer.maxPrice, offer.lgcMinPrice, offer.lgcMaxPrice].filter(Boolean)) / 2,
  )
}

export function getOffersAverageElectricityPrice(offers: IOffer[]): number {
  return meanBy(offers, offer => mean([offer.minPrice, offer.maxPrice]))
}

export function brokerOfferStateToReadableName(state: OfferState, translate: ITranslate): string {
  switch (state) {
    case OfferState.INDICATIVE_DRAFT:
      return translate('Indicative draft')

    case OfferState.INDICATIVE_TO_CUSTOMER:
      return translate('Indicative sent')

    case OfferState.CUSTOMER_INTERESTED:
      return translate('Customer interested')

    case OfferState.BINDING_DRAFT:
      return translate('Contract draft')

    case OfferState.BINDING_TO_CUSTOMER:
      return translate('Contract sent')

    case OfferState.ACCEPTED:
      return translate('Contract signed')

    case OfferState.NOT_INTERESTED:
      return translate('Not interested')

    case OfferState.PURCHASE_CONFIG_IN_RETAILER_REVIEW:
      return translate('Purchase in review')

    case OfferState.PURCHASE_CONFIG_DRAFT:
      return translate('Purchase in draft')

    case OfferState.PURCHASE_CONFIG_RETAILER_DECLINED:
      return translate('Purchase declined')
  }

  return null
}

export function customerOfferStateToReadableName(state: OfferState, translate: ITranslate): string {
  switch (state) {
    case OfferState.INDICATIVE_TO_CUSTOMER:
      return translate('Indicative')

    case OfferState.CUSTOMER_INTERESTED:
      return translate('Interested')

    case OfferState.BINDING_DRAFT:
      return translate('Interested')

    case OfferState.BINDING_TO_CUSTOMER:
      return translate('Contract draft')

    case OfferState.ACCEPTED:
      return translate('Contract signed')

    case OfferState.PURCHASE_CONFIG_IN_RETAILER_REVIEW:
      return translate('Purchase in review')

    case OfferState.PURCHASE_CONFIG_DRAFT:
      return translate('Purchase in draft')

    case OfferState.PURCHASE_CONFIG_RETAILER_DECLINED:
      return translate('Purchase declined')
  }

  return null
}
