import React from 'react'
import FormSelect, {IProps as ISelectProps} from '../../../Form/FormSelect'
import useLocalization from '../../../../hooks/useLocalization'
import useResource from '../../../../hooks/useResource'

type IProps = ISelectProps

const ContractTypeSelect: React.FC<IProps> = props => {
  const {translate} = useLocalization()
  const contractTypes = useResource(
    store => store.contractTypeStore.items,
    store => store.contractTypeStore.loadContractTypes(),
  )

  return (
    <FormSelect
      label={translate('Contract type')}
      options={contractTypes.map(type => ({
        value: type.id,
        label: type.name,
      }))}
      {...props}
    />
  )
}

export default ContractTypeSelect
