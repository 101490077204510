import styled from 'styled-components'
import spacing, {ISpacingProps} from './styleProps/spacing'

export type ISize = 'small' | 'msmall' | 'medium' | 'mlarge' | 'large' | 'xlarge' | 'xmlarge' | 'xxlarge' | 'xxxlarge'

export interface IProps extends React.PropsWithChildren, ISpacingProps {
  inline?: boolean
  uppercase?: boolean
  size?: ISize
  lineHeight?: ISize
  bold?: boolean
  light?: boolean
  semibold?: boolean
  color?: string
  align?: 'left' | 'center' | 'right'
  nowrap?: boolean
  overflowEllipsis?: boolean
  font?: string
  capitalize?: boolean
}

const sizes = {
  xxxlarge: 36,
  xxlarge: 28,
  xmlarge: 24,
  xlarge: 20,
  large: 18,
  mlarge: 16,
  medium: 14,
  msmall: 12,
  small: 10,
}

const lineHeights = {
  xxxlarge: 50,
  xxlarge: 40,
  xlarge: 36,
  large: 32,
  mlarge: 28,
  medium: 24,
  msmall: 20,
  small: 16,
}

const Text = styled.div<IProps>`
  font-weight: ${props => (props.bold ? 700 : props.semibold ? 500 : 300)};
  font-weight: ${props => props.light && 200};
  font-size: ${({size}: IProps) => sizes[size || 'medium']}px;
  line-height: ${({lineHeight, size}: IProps) => lineHeights[lineHeight || size || 'medium']}px;
  ${props => (props.inline ? 'display: inline;' : 'display: block;')}
  ${props => props.uppercase && 'text-transform: uppercase;'}
  ${props => props.capitalize && 'text-transform: capitalize;'}
  ${props => props.color && `color: ${props.color};`}
  ${props => props.align && `text-align: ${props.align};`}
  ${props => props.nowrap && `white-space: nowrap;`}
  ${props => props.overflowEllipsis && `overflow: hidden; text-overflow: ellipsis;`}
  ${props => props.font && `font-family: ${props.font};`}
  ${spacing}
  
  p {
    margin: 10px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default Text
