import React from 'react'
import {Controller} from 'react-hook-form'
import useFormContext from '../../hooks/useFormContext'
import useLocalization from '../../hooks/useLocalization'
import ITranslate from '../../interfaces/ITranslate'
import get from 'lodash/get'
import {getChangeEventValue} from '../../helpers/misc'
import {useFormFieldName} from './FormFieldNamePrefixProvider'
import {CertError} from 'helpers/certificates'

export interface IWrapperProps extends React.PropsWithChildren {
  name: string
  required?: boolean | string
  pattern?:
    | RegExp
    | {
        value: RegExp
        message: string
      }
  validate?: (value: any, getValues: () => any) => string | true
  onChange?: (value: string, setValue: (name: string, value: any, shouldValidate?: boolean) => void) => void
  children?: any
  min?: number | string
  max?: number | string
  minLength?: number
  maxLength?: number
  defaultValue?: any
  errorType?: CertError | string
}

export type IProps<T> = IWrapperProps & Omit<T, 'pattern' | 'required' | 'onChange'>

const getErrorMessage = (
  error: {type: string; message?: string},
  translate: ITranslate,
  props: IWrapperProps,
): string => {
  if (!error) {
    return null
  }

  if (error.message) {
    return error.message
  }

  switch (error.type) {
    case 'min':
      return translate('Value is too low. Minimum allowed value is %s', props.min)

    case 'max':
      return translate('Value is too high. Maximum allowed value is %s', props.max)

    case 'minLength':
      return translate('Minimum length is %s', props.minLength)

    case 'maxLength':
      return translate('Maximum length is %s', props.maxLength)
  }

  return translate('Invalid value')
}

const FormInputWrapper = (props: IWrapperProps) => {
  const {errors, getValues, clearError, setValue, control, formState} = useFormContext()
  const {translate} = useLocalization()
  const {
    children,
    name: initialName,
    required,
    pattern,
    validate,
    onChange,
    min,
    max,
    minLength,
    maxLength,
    defaultValue,
    errorType,
    ...rest
  } = props
  const {isSubmitted} = formState
  const name = useFormFieldName(initialName)
  const error = getErrorMessage(get(errors, name) as any, translate, props)

  return (
    <>
      {React.Children.map(children, (child, index) => (
        <Controller
          key={index}
          as={child}
          name={name}
          rules={{
            required: required === true ? translate('This field is required') : required,
            pattern,
            validate: validate && ((value: any) => validate(value, getValues)),
            min,
            max,
            minLength,
            maxLength,
          }}
          control={control}
          defaultValue={defaultValue}
          isSubmitted={isSubmitted}
          onChange={([event]: [React.ChangeEvent<HTMLInputElement> | any]) => {
            const value = getChangeEventValue(event)

            clearError(['generic', name])
            onChange && onChange(value, setValue)

            return value
          }}
          error={error}
          errorType={errorType}
          {...rest}
        />
      ))}
    </>
  )
}

export default FormInputWrapper
