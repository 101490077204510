import styled from 'styled-components'
import ITheme from '../theme/ITheme'

const Truncated = styled.div.attrs(props => ({title: props.children}))<{maxWidth: number}>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${props => (props.theme as ITheme).tileSize * props.maxWidth}px;
`

export default Truncated
