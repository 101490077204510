import React, {useState} from 'react'
import IContract from '../../../domain/IContract'
import useLocalization from '../../../hooks/useLocalization'
import Button from '../../Button'
import ContractModal from './ContractModal'
import {isAnyContractProductExpired} from '../../../helpers/contract'
import IPurchaseContract from 'domain/IPurchaseContract'

interface IProps extends React.PropsWithChildren {
  contract: IContract | IPurchaseContract
}

const ContractPreviewButton: React.FC<IProps> = ({contract}) => {
  const {translate} = useLocalization()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const isExpired = isAnyContractProductExpired(contract.contractItems)

  return (
    <>
      <Button disabled={isExpired} onClick={() => setModalOpen(true)} fullWidth>
        {translate('Preview contract')}
      </Button>
      {modalOpen && <ContractModal contract={contract} onClose={() => setModalOpen(false)} />}
    </>
  )
}

export default ContractPreviewButton
