import React, {useEffect, useRef, useState} from 'react'
import Page from 'components/Page'
import useLocalization from 'hooks/useLocalization'
import {useAtomValue} from 'jotai'
import {partyAtom} from 'atoms/party'
import SellerOnboardingAside from './Seller/SellerOnboardingAside'
import VerticalStack from 'components/VerticalStack'
import {Heading, SubHeading} from './Heading'
import styled from 'styled-components'
import onboardingHeroImg from 'assets/images/onboarding-hero-img.webp'
import {Redirect, Route, Switch} from 'react-router-dom'
import NotFound from 'components/NotFound'
import SetupSitesForm from './Seller/SetupSitesForm'
import CreateProductForm from './Seller/CreateProductForm'
import ReviewProduct from './Seller/ReviewProduct'
import {OnboardingStatus} from 'domain/IParty'
import InnerScroll from 'components/InnerScroll'
import {PADDING_HEIGHT, TOP_NAVBAR_HEIGHT} from 'components/Layout'
import Box from 'components/Box'
import OnboardingWelcome from './OnboardingWelcome'
import Loader from 'components/Loader'
import useTheme from 'hooks/useTheme'

export interface IProps extends React.PropsWithChildren {
  title: string
  description?: string
  disabled?: boolean
  ref: any
}

export const HeroImageContainer = styled.div`
  background-image: url(${onboardingHeroImg});
  background-position: 0 0;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: end;
`
const ROUTE_PREFIX = '/broker/onboarding'

export const OnboardingContainer = styled(Box)`
  width: 50%;
  min-width: 600px;
  max-width: 1150;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  background-image: linear-gradient(rgba(120, 249, 111, 0.77), rgba(120, 249, 111, 0.77));
  padding-top: ${PADDING_HEIGHT / 2}px;
  padding-bottom: ${PADDING_HEIGHT / 2}px;
`

const SellerOnboardingWizard: React.FC<IProps> = ({title, description}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const party = useAtomValue(partyAtom)
  const [contentHeight, setContentHeight] = useState(0)
  const elementRef = useRef(null)

  const updateContentHeight = () => {
    if (elementRef?.current) {
      const {clientHeight} = elementRef.current
      setContentHeight(clientHeight + TOP_NAVBAR_HEIGHT + PADDING_HEIGHT)
    }
  }

  useEffect(() => {
    updateContentHeight()
  }, [party])

  useEffect(() => {
    window.addEventListener('resize', updateContentHeight)
  }, [])

  if (!party) {
    return <Loader />
  }

  return (
    <Page
      title={title}
      description={description}
      aside={<SellerOnboardingAside />}
      asideColor={theme.colors.globalBackground}
      gridTemplateColStyle="auto 325px"
      mainPad={{top: 0, right: 0, bottom: 0, left: 0}}
      noHeader
    >
      <VerticalStack>
        <div ref={elementRef}>
          <VerticalStack style={{padding: 16}}>
            <Heading>{translate('Onboarding wizard')}</Heading>
            <SubHeading>{translate('Follow the steps and get going in no time')}</SubHeading>
          </VerticalStack>
        </div>
        <HeroImageContainer>
          <OnboardingContainer>
            <InnerScroll noRightPad>
              <Box height={`calc(100vh - ${contentHeight}px)`} pad={{horizontal: 6}}>
                <Switch>
                  <Route
                    exact
                    path="/broker/onboarding"
                    component={() => {
                      switch (party.onboardingStatus) {
                        case OnboardingStatus.NOT_STARTED:
                          return <Redirect to={`${ROUTE_PREFIX}/welcome`} />

                        case OnboardingStatus.SELLER_STARTED:
                          return <Redirect to={`${ROUTE_PREFIX}/setup-site`} />

                        case OnboardingStatus.SELLER_SITE_CREATED:
                          return <Redirect to={`${ROUTE_PREFIX}/create-product`} />

                        case OnboardingStatus.SELLER_PRODUCT_CREATED:
                          return <Redirect to={`${ROUTE_PREFIX}/review`} />

                        case OnboardingStatus.COMPLETED:
                        case OnboardingStatus.SELLER_SKIPPED:
                          console.warn('Completed onboarding, but still in onboarding flow')
                          return <Redirect to={`/`} />
                      }
                    }}
                  />
                  <Route
                    exact
                    path={`${ROUTE_PREFIX}/welcome`}
                    component={() => {
                      if (party?.onboardingStatus === OnboardingStatus.NOT_STARTED) {
                        return <OnboardingWelcome />
                      }
                      return <Redirect to={`${ROUTE_PREFIX}`} />
                    }}
                  />
                  <Route
                    exact
                    path={`${ROUTE_PREFIX}/setup-site`}
                    component={() => {
                      if (party?.onboardingStatus === OnboardingStatus.SELLER_STARTED) {
                        return <SetupSitesForm />
                      }
                      return <Redirect to={`${ROUTE_PREFIX}`} />
                    }}
                  />
                  <Route
                    exact
                    path={`${ROUTE_PREFIX}/create-product`}
                    component={() => {
                      if (party?.onboardingStatus === OnboardingStatus.SELLER_SITE_CREATED) {
                        return <CreateProductForm />
                      }
                      return <Redirect to={`${ROUTE_PREFIX}`} />
                    }}
                  />
                  <Route
                    exact
                    path={`${ROUTE_PREFIX}/review`}
                    component={() => {
                      if (party?.onboardingStatus === OnboardingStatus.SELLER_PRODUCT_CREATED) {
                        return <ReviewProduct />
                      }
                      return <Redirect to={`${ROUTE_PREFIX}`} />
                    }}
                  />
                  <Route path="*" component={NotFound} />
                </Switch>
              </Box>
            </InnerScroll>
          </OnboardingContainer>
        </HeroImageContainer>
      </VerticalStack>
    </Page>
  )
}

export default SellerOnboardingWizard
