import IPrice, {CurrencyCode, PriceUnit} from '../domain/IPrice'
import Big from 'big.js'
import isNil from 'lodash/isNil'
import isFinite from 'lodash/isFinite'
import {formatMoney} from './format'
import ITheme from 'theme/ITheme'
import {Country} from 'domain/ILocation'

const DEFAULT_MIN_VALUE = 1

interface IUnitsProperties {
  [name: string]: {
    ratio: number
  }
}

type ValueWithUnit = [number, string]

export enum EnergyUnit {
  e = 'e',
  k = 'k',
  M = 'M',
  G = 'G',
}

export const conversions: {price: IUnitsProperties} = {
  price: {
    e: {
      ratio: 1,
    },
    k: {
      ratio: 1000,
    },
    M: {
      ratio: 1000000,
    },
  },
}

export function convertToUnit(
  value: number,
  sourceUnit: string,
  targetUnit: string,
  unitsProperties: IUnitsProperties,
): ValueWithUnit {
  if (isNil(value) || !isFinite(value)) {
    return null
  }

  return [+Big(unitsProperties[sourceUnit].ratio).div(unitsProperties[targetUnit].ratio).mul(value), targetUnit]
}

function autoConvert(
  value: number,
  unit: string,
  unitsProperties: IUnitsProperties,
  minValue: number = DEFAULT_MIN_VALUE,
): ValueWithUnit {
  if (!value) {
    return [0, unit]
  }

  return Object.keys(unitsProperties)
    .map(targetUnit => {
      if (targetUnit === unit) {
        return [value, unit]
      }

      return convertToUnit(value, unit, targetUnit, unitsProperties)
    })
    .reduce((previous: ValueWithUnit, current: ValueWithUnit) => {
      if (!previous) {
        return current
      }

      return current[0] >= minValue && current[0] - minValue < previous[0] - minValue ? current : previous
    }) as ValueWithUnit
}

export function convertPrice(value: number, sourceUnit: string, targetUnit: string): number {
  if (isNil(value) || !isFinite(value)) {
    return null
  }

  return convertToUnit(value, sourceUnit, targetUnit, conversions.price)[0]
}

export function convertMwh(value: number, targetUnit: string): number {
  return convertPrice(value, EnergyUnit.e, targetUnit)
}

export function autoConvertPrice(
  value: number,
  unit: string = EnergyUnit.e,
  minValue: number = DEFAULT_MIN_VALUE,
): ValueWithUnit {
  return autoConvert(value, unit, conversions.price, minValue)
}

export function detectBestPossiblePriceUnit(values: number[], unit: string = EnergyUnit.e): string {
  const max = Math.max(0, ...values.filter(v => !isNil(v) && isFinite(v)))

  if (!max) {
    return unit
  }

  const [, targetUnit] = autoConvertPrice(max, unit)

  return targetUnit
}

export function autoConvertPrices(values: number[], unit: string = EnergyUnit.e): [number[], string] {
  const targetUnit = detectBestPossiblePriceUnit(values, unit)

  return [values.map(v => convertPrice(v, unit, targetUnit)), targetUnit]
}

export function stringifyPriceWithSuffix(value, suffix) {
  return `${formatMoney(value)} ${suffix === EnergyUnit.e ? '' : suffix}`
}

export function stringifyUnit(unit: PriceUnit): string {
  if (unit === PriceUnit.AUD_MWH) {
    return '$/MWh'
  }

  if (unit === PriceUnit.USD_MWH) {
    return '$/MWh'
  }

  if (unit === PriceUnit.EUR_MWH) {
    return '€/MWh'
  }

  if (unit === PriceUnit.GBP_MWH) {
    return '£/MWh'
  }

  if (unit === PriceUnit.ZAR_MWH) {
    return 'R/MWh'
  }

  if (unit === PriceUnit.AUD_KWH) {
    return 'c/kWh'
  }

  if (unit === PriceUnit.USD_KWH) {
    return 'c/kWh'
  }

  if (unit === PriceUnit.EUR_KWH) {
    return 'c/kWh'
  }

  if (unit === PriceUnit.ZAR_KWH) {
    return 'c/kWh'
  }

  return null
}

export function getCurrencySymbolFromStringifiedUnit(stringifiedUnit: string): string {
  return stringifiedUnit?.split('/')[0]
}

export function getSlashEnergyUnitSymbolFromStringifiedUnit(stringifiedUnit: string): string {
  return stringifiedUnit ? '/' + stringifiedUnit?.split('/')[1] : ''
}

export function getPricePieces(price: IPrice): [string, string] {
  if (!price) {
    return [null, null]
  }

  return [(+price.value).toFixed(2), stringifyUnit(price.unit)]
}

export function stringifyPrice(price: IPrice): string {
  if (!price) {
    return null
  }

  return getPricePieces(price).join(' ')
}

export function getPriceUnitSymbol(unit: PriceUnit): string {
  switch (unit) {
    case PriceUnit.GBP_MWH:
      return '£'
    case PriceUnit.GBP_KWH:
      return '£'
    case PriceUnit.AUD_MWH:
      return '$'
    case PriceUnit.AUD_KWH:
      return 'c'
    case PriceUnit.USD_KWH:
      return 'c'
    case PriceUnit.USD_MWH:
      return '$'
    case PriceUnit.EUR_KWH:
      return 'c'
    case PriceUnit.EUR_MWH:
      return '€'
    case PriceUnit.ZAR_KWH:
      return 'c'
    case PriceUnit.ZAR_MWH:
      return 'R'
    default:
      throw new Error('Invalid price unit')
  }
}

export function getCurrencySymbol(currency: CurrencyCode): string {
  switch (currency) {
    case CurrencyCode.GBP:
      return '£'
    case CurrencyCode.EUR:
      return '€'
    default:
      return '£'
  }
}

export function convertSymbolToCode(currency: string): CurrencyCode {
  switch (currency) {
    case '£':
      return CurrencyCode.GBP
    case '€':
      return CurrencyCode.EUR
    default:
      return CurrencyCode.GBP
  }
}

export function getCurrencyCodeByPriceUnit(priceUnit: PriceUnit): CurrencyCode {
  switch (priceUnit) {
    case PriceUnit.EUR_MWH:
      return CurrencyCode.EUR

    case PriceUnit.EUR_KWH:
      return CurrencyCode.EUR
    case PriceUnit.GBP_MWH:
      return CurrencyCode.GBP
    case PriceUnit.GBP_KWH:
      return CurrencyCode.GBP
    default:
      return CurrencyCode.GBP
  }
}

export function getSlashEnergyUnit(unit: PriceUnit): string {
  if (unit === PriceUnit.GBP_KWH) {
    return '/KWh'
  }

  if (unit === PriceUnit.GBP_MWH) {
    return '/MWh'
  }

  if (unit === PriceUnit.AUD_MWH) {
    return '/MWh'
  }

  if (unit === PriceUnit.USD_MWH) {
    return '/MWh'
  }

  if (unit === PriceUnit.EUR_MWH) {
    return '/MWh'
  }

  if (unit === PriceUnit.ZAR_MWH) {
    return '/MWh'
  }

  if (unit === PriceUnit.AUD_KWH) {
    return '/kWh'
  }

  if (unit === PriceUnit.USD_KWH) {
    return '/kWh'
  }

  if (unit === PriceUnit.EUR_KWH) {
    return '/kWh'
  }

  if (unit === PriceUnit.ZAR_KWH) {
    return '/kWh'
  }

  throw new Error('Invalid price unit')
}

export function getCurrencySymbolValueSlashEnergyUnit(
  value: number | string,
  theme: ITheme,
  unit?: PriceUnit,
  cert?: boolean,
): string {
  if (isNil(value)) {
    return getPriceUnitSymbol(unit || theme.priceUnit) + ' ...' + getSlashEnergyUnit(unit || theme.priceUnit)
  }

  const formattedUnit = cert ? '/EAC' : getSlashEnergyUnit(unit || theme.priceUnit)

  return getPriceUnitSymbol(unit || theme.priceUnit) + formatMoney(Number(value), theme) + formattedUnit
}

export function getCurrencySymbolValueRangeSlashEnergyUnit(
  value1: number | string,
  value2: number | string,
  theme: ITheme,
): string {
  return (
    getPriceUnitSymbol(theme.priceUnit) +
    (isNil(value1) ? ' ...' : formatMoney(Number(value1), theme)) +
    getSlashEnergyUnit(theme.priceUnit) +
    ' - ' +
    getPriceUnitSymbol(theme.priceUnit) +
    (isNil(value2) ? '... ' : formatMoney(Number(value2), theme)) +
    getSlashEnergyUnit(theme.priceUnit)
  )
}

export function getDecimalInputStep(theme: ITheme): number {
  switch (theme.numberDecimals) {
    case 1:
      return 0.1
    case 2:
      return 0.01
    case 3:
      return 0.001

    default:
      return 0.01
  }
}

export const getCurrencyByCountry = (addressCountry: Country, defaultCurrency: CurrencyCode): CurrencyCode => {
  if (addressCountry === null || addressCountry === undefined) {
    console.error(`Missing address country. Returning default(${defaultCurrency})`)
    return defaultCurrency
  }

  switch (addressCountry) {
    case Country.GBR:
      return CurrencyCode.GBP
    case Country.ESP:
    case Country.FRA:
    case Country.ITA:
    case Country.DEU:
    case Country.NLD:
    case Country.BEL:
    case Country.LUX:
    case Country.AUT:
    case Country.IRL:
    case Country.PRT:
    case Country.SVN:
    case Country.EST:
    case Country.LVA:
    case Country.LTU:
    case Country.CYP:
    case Country.GRC:
    case Country.FIN:
    case Country.CZE:
    case Country.HUN:
    case Country.BGR:
    case Country.HRV:
      return CurrencyCode.EUR
    default:
      console.error(`Invalid or unsupported country ${addressCountry}. Returning default currency(${defaultCurrency})`)
      return defaultCurrency
  }
}
