import React from 'react'
import Box from '../Box'
import ModalCloser from './ModalCloser'
import ModalHeader, {IProps as IHeaderProps} from './ModalHeader'
import {PrimaryButton, SecondaryButton} from '../Button'
import FormSubmitButton from '../Form/FormSubmitButton'
import useLocalization from '../../hooks/useLocalization'
import IOffer, {OfferState} from '../../domain/IOffer'
import useStore from '../../hooks/useStore'
import {useHistory} from 'react-router'
import DottedIconButtonSelect from '../IconButton/DottedIconButtonSelect'
import ComponentOption, {IComponentOption} from '../MultiSelect/ComponentOption'
import {OptionsType} from 'react-select'
import useProfile from '../../hooks/useProfile'
import {PartyRole} from '../../domain/IParty'

interface IProps extends React.PropsWithChildren, IHeaderProps {
  offer: IOffer
  buttonText?: string
}

const OfferModalFormHeader: React.FC<IProps> = ({offer, buttonText, ...props}) => {
  const {translate} = useLocalization()
  const {offerStore, alertStore} = useStore()
  const history = useHistory()
  const {party} = useProfile()

  return (
    <ModalHeader {...props}>
      {offer && offer.state === OfferState.INDICATIVE_TO_CUSTOMER && party.partyRole === PartyRole.BUYER && (
        <Box direction="row" gap={2}>
          <PrimaryButton
            type="button"
            onClick={async e => {
              const response = await offerStore.userIsInterested(offer.id)

              if (response.status === 204) {
                alertStore.addSuccess(
                  translate('Thank you for your interest! You will receive an email with detailed proposal!'),
                )
              }
              history.push('/consumer/buy/offers')
            }}
          >
            {translate('Interested')}
          </PrimaryButton>
          <SecondaryButton
            type="button"
            onClick={async e => {
              const response = await offerStore.userNotIsInterested(offer.id)

              if (response.status === 204) {
                alertStore.addSuccess(translate('Thank you! Check our Marketplace for other offerings'))
              }
              history.push('/consumer/buy/offers')
            }}
          >
            {translate('Not Interested')}
          </SecondaryButton>
          <DottedIconButtonSelect
            hideIndicators
            components={{Option: ComponentOption}}
            options={
              [
                {
                  label: translate('Cancel'),
                  value: 'Close modal',
                  cancel: true,
                },
                {
                  label: buttonText || translate('Save'),
                  value: 'Submit',
                  submit: true,
                },
              ] as OptionsType<IComponentOption>
            }
          />
        </Box>
      )}
      {(!offer || offer.state !== OfferState.INDICATIVE_TO_CUSTOMER || party.partyRole !== PartyRole.BUYER) && (
        <Box direction="row" gap={2}>
          <ModalCloser>
            <SecondaryButton type="button">{translate('Cancel')}</SecondaryButton>
          </ModalCloser>
          <FormSubmitButton>{buttonText || translate('Save')}</FormSubmitButton>
        </Box>
      )}
    </ModalHeader>
  )
}

export default OfferModalFormHeader
