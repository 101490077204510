import React, {useEffect, useState} from 'react'
import useLocalization from '../../../../hooks/useLocalization'
import useServices from '../../../../hooks/useServices'
import useResource from '../../../../hooks/useResource'
import config from '../../../../config'
import Loader from '../../../Loader'
import {matchSiteWithParameterGroup} from '../../../../helpers/site'
import FormSelect from '../../../Form/FormSelect'

interface IProps extends React.PropsWithChildren {
  splitSite?: boolean
  setShouldUpdateParameterGroup: any
}

const ProfileSelector: React.FC<IProps> = ({splitSite, setShouldUpdateParameterGroup}) => {
  const {translate} = useLocalization()
  const {offerPriceParameterService} = useServices()
  const sites = useResource(
    store => store.siteStore.consumptionTemplates,
    store => store.siteStore.loadConsumptionTemplates(),
  )
  const [parameterGroup, setParameterGroup] = useState(null)

  const isCleanco = ['cleanco', 'demo'].includes(config.theme)

  useEffect(() => {
    offerPriceParameterService
      .getParameterGroupTemplates()
      .then(value => setParameterGroup(value))
      .catch(() => setParameterGroup(null))
  }, [offerPriceParameterService])

  if (!sites || (isCleanco && !parameterGroup)) {
    return <Loader />
  }
  if (!splitSite && !isCleanco) {
    return null
  }

  if (!splitSite) {
    const groupsWithSites = matchSiteWithParameterGroup(sites, parameterGroup)

    return (
      <FormSelect
        name="newSiteId"
        required
        onChange={() => setShouldUpdateParameterGroup(true)}
        options={[
          {
            label: translate('Select load shape premium profile...'),
            value: null,
          },
        ].concat(
          groupsWithSites.map(item => ({
            label: item.name,
            value: JSON.stringify({meta: item.meta, id: item.site}),
          })),
        )}
      />
    )
  }

  const sitesWithoutCustom = sites.filter(site => site.name !== 'Custom')

  return (
    <>
      <FormSelect
        name="newSiteId"
        required
        options={[
          {
            label: translate('Select load type...'),
            value: null,
          },
        ].concat(
          sitesWithoutCustom.map((site, i) => ({
            key: 100 + i,
            label: site.name,
            value: site.id,
          })),
        )}
      />
      {isCleanco && (
        <FormSelect
          name="pricingParameterGroupId"
          required
          onChange={() => setShouldUpdateParameterGroup(true)}
          options={[
            {
              label: translate('Select load shape premium profile...'),
              value: null,
            },
          ].concat(
            parameterGroup.map((group, i) => ({
              key: i,
              label: group.name,
              value: group.id,
            })),
          )}
        />
      )}
    </>
  )
}

export default ProfileSelector
