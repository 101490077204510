import IContractItem from './IContractItem'
import {CurrencyCode, PriceUnit} from './IPrice'
import IContractType from './IContractType'
import IOffer, {IShortOffer} from './IOffer'
import IPurchaseGroup from './IPurchaseGroup'
import IParty from './IParty'
import {ResourceId} from '../types'
import IContractParty from './IContractParty'
import IOfferPriceCalculationResult from './IOfferPriceCalculationResult'
import {Consumption} from './INewTimeseriesItem'

export enum ContractState {
  DRAFT = 'DRAFT',
  OFFER = 'OFFER',
  SIGNED = 'SIGNED',
}

export enum ContractVolumeProfile {
  LOAD_FOLLOWING = 'LOAD_FOLLOWING',
  GENERATION_FOLLOWING = 'GENERATION_FOLLOWING',
  FLAT = 'FLAT',
}

interface IProductTypePrice {
  name: string
  value: number
  productTypeId: ResourceId
}

export interface IContractSummary {
  volumeMwh: number
  price: {
    average: number
    added: number
    subtracted: number
    total: number
    unit: PriceUnit
  }
  pricesByProductType: IProductTypePrice[]
}

export default interface IContract {
  id: number
  name?: string
  state: ContractState
  signingDate: string
  validFrom: string
  validTo: string
  contractItems: IContractItem[]
  contractType: IContractType
  purchaseGroup?: IPurchaseGroup
  summary: IContractSummary
  offers?: IOffer[]
  parties?: IParty[]
  volumeMwh: number
  volumeProfile: ContractVolumeProfile
  contractParties?: IContractParty[]
  offerPriceCalculationResult?: IOfferPriceCalculationResult
  offer?: IOffer | IShortOffer
  offerId?: number
}

export interface IContractSnapshot {
  contract: IContract
  contractId: number
  productTypeId: ResourceId
}

export interface IPerformanceContract {
  id: number
  name: string
  price: number
  volumeMwh: number
  totalPrice: number
  averageMarketPrice: number
  totalProfit: number
  averageProfit: number
}

export interface IPerformanceContractTimeseries {
  contractId: number
  timeseries: {
    currency: CurrencyCode
    series: Consumption[]
  }
}
