import React from 'react'
import ContractChart from '../ContractChart'
import IOffer from '../../../../domain/IOffer'
import useLocalization from '../../../../hooks/useLocalization'
import useTheme from '../../../../hooks/useTheme'
interface IProps extends React.PropsWithChildren {
  offer: IOffer
}

const OfferChart: React.FC<IProps> = ({offer}) => {
  const {translate} = useLocalization()
  const theme = useTheme()

  return (
    <ContractChart
      contract={offer.contract}
      party={offer.customer}
      purchaseGroup={offer.contract.purchaseGroup}
      consumptionLine={{
        label: offer.contract.purchaseGroup?.name || translate('Load profile'),
        color: theme.colors.primaryDark,
      }}
    />
  )
}

export default OfferChart
