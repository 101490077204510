import React from 'react'
import useLocalization from '../../../../hooks/useLocalization'
import Table, {TableValue} from '../../../Table'
import IOffer, {INewOffer, OfferKind, OfferState} from '../../../../domain/IOffer'
import {DateFormat, dateByMonthAndYear, formatDate, getPeriod} from '../../../../helpers/date'
import {customerOfferStateToReadableName} from '../../../../helpers/offer'
import {ModalType} from '../../../Modal/IModal'
import useStore from '../../../../hooks/useStore'
import {useHistory} from 'react-router'
import {OptionsType} from 'react-select'
import SmartOption, {ISmartOption} from '../../../MultiSelect/SmartOption'
import DottedIconButtonSelect from '../../../IconButton/DottedIconButtonSelect'
import styled from 'styled-components'
import {getPartyLink} from '../../../../helpers/party'
import {LinkButton} from 'components/Button'
import {getCurrencyCodeByPriceUnit} from 'helpers/price'

const DropdownDiv = styled.div`
  width: 60px;
`

interface IProps extends React.PropsWithChildren {
  offers: INewOffer[]
  isLoading?: boolean
}

const CustomerCertOfferTable: React.FC<IProps> = ({offers, isLoading}) => {
  const {translate} = useLocalization()
  const history = useHistory()
  const {offerStore, alertStore, profileStore} = useStore()
  const {partyRole} = profileStore?.party || {}

  const offerLink = (id, state, kind, modal?) => {
    if (kind === OfferKind.CERTIFICATE_TRADE) {
      return `/${getPartyLink(partyRole)}/buy/certificate-designer/offer/${id}/product?${modal || ''}`
    }

    if (state === OfferState.BINDING_TO_CUSTOMER || state === OfferState.BINDING_DRAFT) {
      return `/${getPartyLink(partyRole)}/buy/contract-designer/${id}`
    }

    return `/${getPartyLink(partyRole)}/buy/offers/${id}?${ModalType.OFFER_PDF}`
  }

  const getSelectOptions = (offer: IOffer): OptionsType<ISmartOption> => {
    if (
      [OfferState.PURCHASE_CONFIG_IN_RETAILER_REVIEW, OfferState.PURCHASE_CONFIG_RETAILER_DECLINED].includes(
        offer.state,
      )
    ) {
      return [
        {
          value: 'Preview',
          to: offerLink(offer.id, offer.state, offer.kind),
          label: translate('Preview'),
        },
      ]
    } else if (offer.state === OfferState.PURCHASE_CONFIG_DRAFT) {
      return [
        {
          value: 'Request',
          to: offerLink(offer.id, offer.state, offer.kind, ModalType.CERTIFICATE_REQUEST),
          label: translate('Request'),
        },
        {
          value: 'Preview',
          to: offerLink(offer.id, offer.state, offer.kind, ModalType.CERTIFICATE_PREVIEW),
          label: translate('Preview'),
        },
      ]
    } else {
      return [
        {
          value: 'Sign',
          to: offerLink(offer.id, offer.state, offer.kind),
          label: translate('Sign'),
        },
        {
          value: 'View',
          to: offerLink(offer.id, offer.state, offer.kind),
          label: translate('View'),
        },
        {
          value: 'Not Interested',
          label: translate('Not Interested'),
          onClick: async (e: any) => {
            const response = await offerStore.userNotIsInterested(offer.id)

            if (response.status === 204) {
              alertStore.addSuccess(translate('Thank you! Check our Marketplace for other offerings!'))
            }
            history.push('/consumer/buy/offers')
          },
        },
      ]
    }
  }

  return (
    <Table<INewOffer>
      data={offers}
      isLoading={isLoading}
      linkTo={offer => offerLink(offer.id, offer.state, offer.kind)}
      highlightOnHover
      columns={[
        {
          title: translate('Name'),
          accessor: offer => offer.name,
          render: (name: string) => (
            <LinkButton style={{justifyContent: 'left', whiteSpace: 'nowrap'}} fullWidth>
              {name}
            </LinkButton>
          ),
        },
        {
          title: translate('Broker'),
          accessor: (offer: INewOffer) => offer.seller?.name,
          render: (value: string) => <strong>{value}</strong>,
        },
        {
          title: translate('Certificates'),
          accessor: (offer: INewOffer) => offer.contract?.volumeMwh,
          render: (volume: number) => <TableValue rounded value={volume} />,
        },
        {
          title: translate('Offer expires'),
          accessor: (offer: INewOffer) => formatDate(offer.validTo, DateFormat.MONTH_DAY_YEAR),
          render: (date: string) => <TableValue text={date} />,
        },
        {
          title: translate('Contract Term'),
          accessor: (offer: INewOffer) => getPeriod(offer.contract?.validFrom, offer.contract?.validTo),
          render: (term: string) => <TableValue text={term} />,
        },
        {
          title: translate('Purchase interval'),
          accessor: (offer: INewOffer) => {
            return (
              formatDate(
                dateByMonthAndYear(offer?.purchaseInterval?.month, offer?.purchaseInterval?.year),
                DateFormat.MONTH_YEAR,
              ) || '-'
            )
          },
          render: (interval: string) => <TableValue text={interval} />,
        },
        {
          title: translate('Certificate price'),
          accessor: (offer: INewOffer) => {
            return offer.certPrice
          },
          render: (price: number, offer: INewOffer) => (
            <TableValue value={price} unit={getCurrencyCodeByPriceUnit(offer.priceUnit)} />
          ),
        },
        {
          title: translate('Total price'),
          accessor: (offer: INewOffer) => offer.totalPrice,
          render: (price: number, offer: INewOffer) => (
            <TableValue nowrap value={price} unit={getCurrencyCodeByPriceUnit(offer.priceUnit)} />
          ),
        },
        {
          title: translate('Status'),
          accessor: (offer: INewOffer) => customerOfferStateToReadableName(offer.state, translate),
          render: (status: string) => <TableValue text={status} />,
        },
        {
          title: null,
          disableLink: true,
          accessor: (offer: any) => offer,
          render: (offer: any) => (
            <DropdownDiv>
              <DottedIconButtonSelect
                hideIndicators
                components={{Option: SmartOption}}
                options={getSelectOptions(offer)}
              />
            </DropdownDiv>
          ),
        },
      ]}
    />
  )
}

export default CustomerCertOfferTable
