import React from 'react'
import IParty from '../../../../domain/IParty'
import Page from 'components/Page'
import CustomerSites from './CustomerSites'

interface IProps extends React.PropsWithChildren {
  party?: IParty
}

const CustomerSitesPage: React.FC<IProps> = ({party}) => {
  return (
    <Page title={party?.name}>
      <CustomerSites party={party} />
    </Page>
  )
}

export default CustomerSitesPage
