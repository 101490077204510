import styled from 'styled-components'
import React from 'react'
import Text from '../Text'

const Bubble = styled.div<{color: string}>`
  min-width: 8px;
  height: 8px;
  border-radius: 4px;
  font-size: 32px;
  background-color: ${props => props.color};
  margin-right: 4px;
`

const Container = styled.div<{clickable?: boolean; selected?: boolean}>`
  margin-right: 1px;
  width: 100%;
  font-family: ${props => props.theme.font.secondaryFont};
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 8px;
  padding-right: 8px;
  max-width: 100px;
  min-width: 48px;
  font-size: 12px;
  min-height: 18px;
  border: ${props => `1px solid ${props.theme.colors.lightGrey}`};
  color: ${props => props.theme.colors.accent};
  border-radius: 16px;
  white-space: nowrap;
  border-color: ${props => props.selected && props.theme.colors.secondary};
  cursor: ${props => (props.clickable ? 'pointer' : 'default')};
`

interface IProps extends React.PropsWithChildren {
  color: string
  onClick?: () => void
  selected?: boolean
}

const LegendItem: React.FC<IProps> = ({children, color, onClick, selected}) => {
  return (
    <Container onClick={onClick} clickable={!!onClick} selected={selected}>
      <Bubble color={color} />
      <Text uppercase size="small" overflowEllipsis>
        {children}
      </Text>
    </Container>
  )
}

export default LegendItem
