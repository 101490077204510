export const getBubbleStyles = props => `
  margin-bottom: 0;
  padding: 10px 12px;
  background-color: ${props.theme.colors.primaryDark};
  border-radius: 24px;
  height: 100%;
  min-width: 100px;
  display: flex;
  max-height: 48px;
`
