import React, {useEffect} from 'react'
import useStore from '../../../hooks/useStore'
import useStoreData from '../../../hooks/useStoreData'
import ContractPage from '../../../components/Platform/Portfolio/ContractPage'
import useLocalization from '../../../hooks/useLocalization'
import NoDraftContract from '../../../components/Platform/Portfolio/NoContract/NoDraftContract'

interface IProps extends React.PropsWithChildren {
  id?: any
}

const OfferContractDesigner: React.FC<IProps> = ({id}) => {
  const {translate} = useLocalization()
  const {contractDesignerStore, offerStore} = useStore()
  const {isLoaded, offer} = useStoreData(store => ({
    isLoaded: contractDesignerStore.isLoaded,
    offer: offerStore.getItem(id),
  }))

  useEffect(() => {
    if (offer?.customer) {
      contractDesignerStore.load(offer.customer.id)
    }

    // eslint-disable-next-line
  }, [offer?.id])

  if (id && !offer) {
    return <NoDraftContract />
  }

  return (
    <ContractPage
      editable={!id}
      title={translate('Contract designer')}
      description={translate('Review the projects and confirm the contract')}
      contract={offer.contract}
      loading={!isLoaded}
      offer={offer}
    />
  )
}

export default OfferContractDesigner
