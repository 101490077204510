import React from 'react'
import FormInput from 'components/Form/FormInput'
import useLocalization from 'hooks/useLocalization'
import FormSelect from 'components/Form/FormSelect'
import {CertificateTech} from 'domain/IRegoCertificate'
import {startCase, toLower} from 'lodash'
import {getHumanReadableState} from 'helpers/site'
import {SiteState} from 'domain/ISite'
import LocationSearch, {LocationBox} from '../Site/LocationSearch'
import Label from 'components/Label'

interface IProps extends React.PropsWithChildren {}

const ProductSiteForm: React.FC<IProps> = () => {
  const {translate} = useLocalization()

  return (
    <>
      <FormInput defaultValue="" name="site.name" label={translate('Site name')} required data-cy="site-name" />
      <Label text={translate('Location')}>
        <LocationBox data-cy="site-location">
          <LocationSearch
            location={null}
            variant="primary"
            placeholder={translate('Start typing the address')}
            required
          />
        </LocationBox>
      </Label>
      <FormSelect
        name="site.technology"
        label={translate('Technology')}
        defaultValue=""
        placeholder={translate('Select technology')}
        required
        options={Object.values(CertificateTech).map(tech => ({
          value: tech,
          label: translate(startCase(toLower(tech))),
        }))}
        data-cy="site-technology"
      />
      <FormSelect
        name="site.state"
        label={translate('Status')}
        defaultValue=""
        placeholder={translate('Select status')}
        required
        options={Object.keys(SiteState).map(state => ({
          value: state,
          label: getHumanReadableState(state as SiteState, translate),
        }))}
        data-cy="site-status"
      />
      <FormInput
        defaultValue=""
        name="site.prodCapacityMwac"
        label={translate('Nameplate capacity, MW')}
        placeholder={translate('Insert capacity as number')}
        type="number"
        required
        data-cy="site-capacity"
      />
      <FormInput
        defaultValue=""
        name="site.operationsStart"
        label={translate('Operations start')}
        type="date"
        placeholder={translate('Insert date')}
        required
        data-cy="site-date"
      />
      <FormInput
        defaultValue=""
        name="site.lifetimeYears"
        min={1}
        max={20}
        label={translate('Planned lifetime, in years')}
        type="number"
        placeholder={translate('Insert years as number')}
        required
        data-cy="site-years"
      />
      <FormInput
        defaultValue=""
        name="site.meterReference"
        minLength={6}
        maxLength={11}
        label={translate('Meter reference number')}
        placeholder={translate('Insert meter reference number')}
        required
        data-cy="site-meter"
      />
    </>
  )
}

export default ProductSiteForm
