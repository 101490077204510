import React from 'react'
import ISite from '../../../../domain/ISite'
import useLocalization from '../../../../hooks/useLocalization'
import useResource from '../../../../hooks/useResource'
import Loader from '../../../Loader'
import Error from '../../../Error'
import {filterByWalletIdRCT, getSmartContractTokenShareRCT} from '../../../../helpers/smartContract'
import Box from '../../../Box'
import Table from '../../../Table'
import IToken from '../../../../domain/IToken'
import Truncated from '../../../Truncated'
import {DateFormat, formatDate} from '../../../../helpers/date'
import Link from '../../../Link'
import {DownloadLink1} from '../../../DownloadLink'
import TokenDetailsModal from './TokenDetailsModal'
import RenewableCertificatesChart from './RenewableCertificatesChart'
import {convertEnergy} from '../../../../helpers/conversion'
import useProfile from '../../../../hooks/useProfile'
import {TokenType} from './TabbedTokenDetails'

interface IProps extends React.PropsWithChildren {
  site: ISite
}

const SiteRenewableCertificates: React.FC<IProps> = ({site}) => {
  const {translate} = useLocalization()
  const {party} = useProfile()

  const renewableCertificateId = site.meta?.renewableCertificateId
  const walletId = party?.meta?.walletId

  const {renewableCertificate, isLoading} = useResource(
    store => ({
      renewableCertificate: store.smartEnergyContractStore.getItem(renewableCertificateId),
      isLoading: store.smartEnergyContractStore.isLoading,
    }),
    store => renewableCertificateId && store.smartEnergyContractStore.loadById(renewableCertificateId),
    renewableCertificateId,
  )

  if (!renewableCertificate && isLoading) {
    return <Loader />
  }

  if (!renewableCertificateId) {
    return <Error>{translate('Site is missing RCT token')}</Error>
  }

  if (!renewableCertificate) {
    return <Error>{translate('Unable to load renewable certificates')}</Error>
  }

  const filteredTokens = filterByWalletIdRCT(renewableCertificate.owners, walletId, true)

  return (
    <div>
      <Box margin={{vertical: 4}}>
        <RenewableCertificatesChart tokens={filteredTokens} walletId={walletId} site={site} showPredicted={false} />
      </Box>
      <Table<IToken>
        search={{placeholder: translate('Search certificate')}}
        columns={[
          {
            title: translate('Token Id'),
            accessor: item => item.id,
            render: value => <Truncated maxWidth={10}>{value}</Truncated>,
          },
          {
            title: translate('Token month'),
            accessor: item => item.deliveryStart,
            render: (value: Date) => formatDate(value, DateFormat.MONTH_YEAR),
          },
          {
            title: translate('Volume'),
            accessor: token => `${convertEnergy(getSmartContractTokenShareRCT(token, walletId), 'Wh', 'MWh')} MWh`,
          },
          {
            title: translate('RCT Type'),
            accessor: token => translate('Project linked renewable certificates'),
          },
          {
            title: translate('Origin site'),
            accessor: () => site.name,
          },
          {
            title: translate('Token details'),
            accessor: token => token.id,
            render: id => (
              <Link to={`?tab.contract=renewable-certificates&modal.token-details=${id}`}>
                <DownloadLink1 iconHeight={4} noText />
              </Link>
            ),
          },
        ]}
        data={filteredTokens}
      />
      <TokenDetailsModal
        smartContract={renewableCertificate}
        type={TokenType.RCT}
        tokens={filteredTokens}
        site={site}
        walletId={walletId}
      />
    </div>
  )
}

export default SiteRenewableCertificates
