import React from 'react'
import useLocalization from '../../../hooks/useLocalization'
import ResourceGateway from '../../../components/ResourceGateway'
import ContractListPage from '../../../components/Platform/Portfolio/ContractListPage'
import ConnectedContractPage from '../../../components/Platform/Portfolio/ConnectedContractPage'

const CurrentContract: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()

  return (
    <ResourceGateway
      single={routeProps => (
        <ConnectedContractPage
          title={translate('Current contract')}
          description={translate('The details of currently active contract')}
          id={routeProps.match.params.id}
        />
      )}
      list={() => (
        <ContractListPage
          title={translate('Contracts')}
          description={translate('List of currently active contracts')}
        />
      )}
    />
  )
}

export default CurrentContract
