import {
  ContractSubjectType,
  generalProfileContractSubject,
  IContractSubject,
  purchaseGroupToContractSubject,
} from 'domain/IContractSubject'
import usePrevious from 'hooks/usePrevious'
import useProfile from 'hooks/useProfile'
import isNil from 'lodash/isNil'
import moment from 'moment'
import React, {useEffect} from 'react'
import {useHistory} from 'react-router'
import {ContractVolumeProfile} from '../../../../domain/IContract'
import IContractType from '../../../../domain/IContractType'
import IOffer, {OfferKind, OfferState} from '../../../../domain/IOffer'
import {calculateBundledLgcVolumeMwh, sortContractItems} from '../../../../helpers/contract'
import {brokerOfferStateToReadableName} from '../../../../helpers/offer'
import useFormContext from '../../../../hooks/useFormContext'
import useLocalization from '../../../../hooks/useLocalization'
import useStore from '../../../../hooks/useStore'
import useStoreData from '../../../../hooks/useStoreData'
import useTheme from '../../../../hooks/useTheme'
import Box from '../../../Box'
import {SecondaryButton} from '../../../Button'
import Error from '../../../Error'
import Form from '../../../Form'
import FormInput from '../../../Form/FormInput'
import FormRange from '../../../Form/FormRange'
import FormRangedInput from '../../../Form/FormRangedInput'
import FormSubmitButton from '../../../Form/FormSubmitButton'
import Grid from '../../../Grid'
import Heading from '../../../Heading'
import Input from '../../../Input'
import Label from '../../../Label'
import {ModalType} from '../../../Modal/IModal'
import ModalLink from '../../../Modal/ModalLink'
import PageWithFixedLeftSide from '../../../PageWithFixedLeftSide'
import ActionBox from '../../ActionBox'
import OfferPriceParameterModal from '../OfferPriceParameter/OfferPriceParameterModal'
import ContractSubjectSelect from './ContractSubjectSelect'
import ContractTypeSelect from './ContractTypeSelect'
import OfferChart from './OfferChart'
import OfferContractItem from './OfferContractItem'
import OfferContractVolumeProfileSelect from './OfferContractVolumeProfileSelect'
import OfferDynamicPrice from './OfferDynamicPrice'
import OfferPreviewModal from './OfferPreviewModal'
import PartySelect from './PartySelect'
import ProductAdder from './ProductAdder'
import ContractPreviewModal from '../ContractPreviewModal'
import {formatNumber, formatPercentage} from '../../../../helpers/format'
import {GreenIndicatorWithSubTitle} from '../../../Indicator/GreenIndicator'
import {DownloadLink2} from '../../../DownloadLink'
import useServices from '../../../../hooks/useServices'
import styled from 'styled-components'
import {getDecimalInputStep} from 'helpers/price'
import OfferDesignerCertificatePage from './OfferDesignerCertificatePage'

interface IProps extends React.PropsWithChildren {
  offer?: IOffer
  onSubmit: (values: any) => Promise<void>
  hasRiskAndOperationalFocus: boolean
}

const StyledTerms = styled(Box)`
  grid-column: 4;
`

const FIELD_VOLUME = 'contract.volumeMwh'
const FIELD_CONTRACT_SUBJECT = 'contract.subject'
const FIELD_NUMBER_OF_NMIS = 'numberOfNmis'

const OfferDesignerPage: React.FC<IProps> = ({offer, onSubmit, hasRiskAndOperationalFocus}) => {
  const {translate} = useLocalization()
  const {offerDesignerStore, productStore, offerStore, alertStore, purchaseGroupStore, shoppingCartStore} = useStore()
  const {watch, setValue, handleSubmit, formState} = useFormContext()
  const history = useHistory()
  const theme = useTheme()
  const managerParty = useProfile()
  const applyCart = !!watch('cart')
  const party = useStoreData(store => store.partyStore.getItem(watch('customer.id')))
  const {contentService} = useServices()

  const contractType: IContractType = useStoreData(store =>
    store.contractTypeStore.getItem(watch('contract.contractType.id')),
  )
  const isDirty = useStoreData(store => store.offerDesignerStore.isDirty) || formState.dirty
  const savedContractVolumeMwh = useStoreData(store => store.offerDesignerStore.savedContractVolumeMwh)
  const volumeMwh: number = +(watch(FIELD_VOLUME) || 0)
  const contractValidFrom: string = watch('contract.validFrom')
  const contractValidTo: string = watch('contract.validTo')
  const volumeProfile: ContractVolumeProfile = watch('contract.volumeProfile')
  const numberOfNmis: number = watch(FIELD_NUMBER_OF_NMIS)
  const dynamicOffer: IOffer = {
    ...offer,
    numberOfNmis,
    customer: party,
    contract: {
      ...offer.contract,
      volumeMwh,
      contractType,
      validFrom: contractValidFrom,
      validTo: contractValidTo,
      volumeProfile,
    },
    meta: {
      ...offer.meta,
      pricingParameterGroupId: offer.meta?.pricingParameterGroupId || party?.meta?.pricingParameterGroupId,
    },
    managedByParty: managerParty.party,
  }

  const purchaseGroupConsumption =
    Number(
      dynamicOffer.contract.purchaseGroup?.sites
        ?.map(site => site.avgYearlyConsumptionMwh)
        .reduce((a, b) => a + b, 0)
        .toFixed(2),
    ) || 0

  const maxVolumeMwh = purchaseGroupConsumption || party?.totalAvgYearlyConsumptionMwh

  const partyId = party?.id

  const numberOfPartySites = party?.sites?.length || 0
  const selectedPurchaseGroup = offer.contract?.purchaseGroup
  const numberOfPartyPurchaseGroupSites = selectedPurchaseGroup?.sites?.length

  const contractConsumption = dynamicOffer.contract.volumeMwh || savedContractVolumeMwh
  const totalAvgYearlyConsumptionMwh =
    contractConsumption > maxVolumeMwh || contractConsumption === 0 ? maxVolumeMwh : contractConsumption

  const volumeFieldIsDirty = formState.dirtyFields.has(FIELD_VOLUME)
  const previousPartyId = usePrevious(partyId)
  const isNewOffer = isNil(offer?.id)
  const isContract = [OfferState.BINDING_DRAFT, OfferState.BINDING_TO_CUSTOMER, OfferState.ACCEPTED].includes(
    offer.state,
  )

  useEffect(() => {
    if (!volumeFieldIsDirty && totalAvgYearlyConsumptionMwh) {
      offerDesignerStore.setVolumeMwh(totalAvgYearlyConsumptionMwh)
      setValue(FIELD_VOLUME, totalAvgYearlyConsumptionMwh)
    }
    // eslint-disable-next-line
  }, [
    partyId,
    volumeMwh,
    volumeFieldIsDirty,
    setValue,
    totalAvgYearlyConsumptionMwh,
    dynamicOffer.contract.purchaseGroup,
  ])

  // control NMI value based on customer and purchase group
  useEffect(() => {
    if (partyId && !selectedPurchaseGroup) {
      setValue(FIELD_NUMBER_OF_NMIS, numberOfPartySites || 1)
    }

    if (selectedPurchaseGroup) {
      setValue(FIELD_NUMBER_OF_NMIS, numberOfPartyPurchaseGroupSites || 1)
    }

    // eslint-disable-next-line
  }, [partyId, numberOfPartySites, numberOfPartyPurchaseGroupSites, setValue])

  useEffect(() => {
    //TODO: contractSubject form field is stringified as value and encoded to pass it between the components,
    // should use a store instead
    const contractSubject =
      purchaseGroupToContractSubject(dynamicOffer.contract.purchaseGroup) || generalProfileContractSubject(party?.id)

    // prevent consumption volume exceeding the newly selected subject's max volume
    const consumption =
      !totalAvgYearlyConsumptionMwh || totalAvgYearlyConsumptionMwh > maxVolumeMwh
        ? maxVolumeMwh
        : totalAvgYearlyConsumptionMwh
    setValue(FIELD_VOLUME, consumption)
    if (offerDesignerStore.offer) {
      offerDesignerStore.setVolumeMwh(consumption)
    }

    setValue(FIELD_CONTRACT_SUBJECT, JSON.stringify(contractSubject))
    // eslint-disable-next-line
  }, [dynamicOffer.contract.purchaseGroup])

  useEffect(() => {
    if (partyId && previousPartyId !== party.id) {
      purchaseGroupStore.loadCustomerPurchaseGroups(party.id)
    }

    // eslint-disable-next-line
  }, [party, previousPartyId, purchaseGroupStore])

  useEffect(() => {
    async function addCartItems() {
      await productStore.loadVisibleProducts()
      shoppingCartStore.cart.forEach(async item => {
        await offerDesignerStore.addProductWithVolume(item.product.id, item.volume)
      })
    }
    if (applyCart) {
      shoppingCartStore.setClearCartOnSave(true)
      addCartItems()
    } else {
      shoppingCartStore.setClearCartOnSave(false)
    }

    // eslint-disable-next-line
  }, [])

  const handlePreview = async values => {
    if (isDirty) {
      await onSubmit(values)
    }
    history.push(`?${isContract ? ModalType.CONTRACT : ModalType.OFFER_PDF}`)
  }

  const handleContractSubjectChanged = (subjectStr: string) => {
    const contractSubject = JSON.parse(subjectStr) as IContractSubject

    if (contractSubject.type === ContractSubjectType.PURCHASE_GROUP) {
      const purchaseGroup = purchaseGroupStore.getItem(contractSubject.id)
      offerDesignerStore.setPurchaseGroup(purchaseGroup)
    } else {
      // general profile - fall back party consumption timeseries
      offerDesignerStore.setPurchaseGroup(null)
    }
  }

  const defaultContractStart = moment().startOf('year').add(1, 'year')

  const handelConvertToContract = async (e, offer): Promise<void> => {
    if (window.confirm(translate('Do you want to convert to contract draft?'))) {
      const response = await offerStore.toBindingDraftBroker(offer.id)
      if (response.status === 204) {
        alertStore.addSuccess(translate('Successfully changed to binding draft'))
      }
    }
  }

  const handleSendingToCustomer = async formValues => {
    if (!window.confirm(translate('Do you want to send to customer?'))) {
      return
    }

    if (isNewOffer || isDirty) {
      await onSubmit(formValues)
    }

    // if a new offer, we need to get the id from the store(populated from the response of the API), since offer.id will be null at this point
    const offerId = isNewOffer ? offerDesignerStore.offer.id : offer.id

    if (offer.state === OfferState.BINDING_DRAFT) {
      const response = await offerStore.sendBindingToCustomer(offerId)
      if (response.status === 204) {
        alertStore.addSuccess(translate('A binding contract sent to the customer'))
      }
    } else if (offer.state === OfferState.INDICATIVE_DRAFT || isNewOffer) {
      const response = await offerStore.sendDraftToCustomer(offerId)
      if (response.status === 204) {
        alertStore.addSuccess(translate('Offer successfully sent to the customer'))
      }
    }

    history.push('/broker/sell/offers')
  }

  const totalUnbundledLgcVolume = calculateBundledLgcVolumeMwh(offer.contract)

  const greenDirectIndex =
    totalUnbundledLgcVolume > 0 ? formatPercentage(totalUnbundledLgcVolume / party?.totalAvgYearlyConsumptionMwh) : 0

  return (
    <PageWithFixedLeftSide
      title={translate('Offer designer')}
      description={translate('Review the projects, key terms and confirm the solution')}
      corner={
        <Box gap={2}>
          {offer?.id && offer.state === OfferState.CUSTOMER_INTERESTED && !isDirty && (
            <SecondaryButton type="button" onClick={e => handelConvertToContract(e, offer)}>
              {translate('Convert to contract')}
            </SecondaryButton>
          )}
          {(isNewOffer || [OfferState.INDICATIVE_DRAFT, OfferState.BINDING_DRAFT].includes(offer.state)) && (
            <SecondaryButton type="button" onClick={handleSubmit(handleSendingToCustomer)}>
              {translate('Send To Customer')}
            </SecondaryButton>
          )}
          {offer?.id && (
            <ModalLink
              modal={isContract ? ModalType.CONTRACT : ModalType.OFFER_PDF}
              onClick={handleSubmit(handlePreview)}
            >
              <SecondaryButton type="button">
                {isDirty ? translate('Save and preview') : translate('Preview')}
              </SecondaryButton>
            </ModalLink>
          )}
          <FormSubmitButton>{translate('Save')}</FormSubmitButton>
        </Box>
      }
      aside={
        <ActionBox title={translate('Offer summary')}>
          <Label text={translate('Offer State')}>
            <Input disabled value={brokerOfferStateToReadableName(OfferState.INDICATIVE_DRAFT, translate)} />
          </Label>

          <FormInput label={translate('Offer title')} name="name" required autoComplete="off" />

          <PartySelect
            label={translate('Customer')}
            name="customer.id"
            placeholder={translate('Search customer')}
            required
          />

          <ContractSubjectSelect
            name="contract.subject"
            activePurchaseGroup={selectedPurchaseGroup}
            onChange={handleContractSubjectChanged}
            party={party}
            required
          />

          <OfferContractVolumeProfileSelect name="contract.volumeProfile" />

          <FormRangedInput
            label={translate('Estimated annual volume')}
            name={FIELD_VOLUME}
            required
            suffix={translate('MWh/y')}
            min={1}
            max={formatNumber(maxVolumeMwh)}
            step={getDecimalInputStep(theme)}
            key={maxVolumeMwh}
            pattern={/^\d+(\.\d{1,2})?$/}
          />

          <Label text={translate('Contract start and end date')}>
            <Box direction="row" gap={1}>
              <FormInput
                type="date"
                name="contract.validFrom"
                defaultValue={offer.contract?.validFrom || defaultContractStart.toISOString()}
                validate={(value, getValues) =>
                  Date.parse(value) <= Date.parse(getValues()['contract.validTo']) ||
                  translate('Start date after end date')
                }
              />
              <FormInput
                type="date"
                name="contract.validTo"
                defaultValue={
                  offer.contract?.validTo || defaultContractStart.clone().add(9, 'years').endOf('year').toISOString()
                }
                validate={(value, getValues) =>
                  Date.parse(value) >= Date.parse(getValues()['contract.validFrom']) ||
                  translate('End date before start date')
                }
              />
            </Box>
          </Label>

          {(offer.state === undefined || offer.state === OfferState.INDICATIVE_DRAFT) && (
            <Label text={translate('Proposal valid from and valid until')}>
              <Box direction="row" gap={1}>
                <FormInput
                  type="date"
                  name="validFrom"
                  defaultValue={offer.validFrom || moment().toISOString()}
                  validate={(value, getValues) =>
                    Date.parse(value) <= Date.parse(getValues().validTo) || translate('Start date after end date')
                  }
                />
                <FormInput
                  type="date"
                  name="validTo"
                  defaultValue={offer.validTo || moment().add(72, 'hours').toISOString()}
                  validate={(value, getValues) =>
                    Date.parse(value) >= Date.parse(getValues().validFrom) || translate('End date before start date')
                  }
                />
              </Box>
            </Label>
          )}

          <ContractTypeSelect name="contract.contractType.id" required />
        </ActionBox>
      }
    >
      <Heading>{translate('Offer details')}</Heading>

      <Box pad={{vertical: 1, horizontal: 2}} color="light3" round margin={{bottom: 2}}>
        <Box direction="row" justify="space-between">
          <OfferDynamicPrice offer={dynamicOffer} isDirty={isDirty} />
          <Box gap={2} align="center">
            <GreenIndicatorWithSubTitle
              hoverText="Green Direct Index is a % of consumption covered by bundled renewable energy certificates volume where the original producer is linked to the certificates"
              value={greenDirectIndex || 0}
              title={translate('Hourly matching score')}
              subtitle={translate('Hourly matched renewable index')}
            />
          </Box>
        </Box>
      </Box>

      <Grid columns={4} gap={2}>
        {hasRiskAndOperationalFocus && (
          <>
            <Label text={translate('Level of operational focus on energy')}>
              <FormRange
                margin={{top: 2}}
                name="operationalFocus"
                min={0}
                max={10}
                step={1}
                defaultValue={!isNil(offer.operationalFocus) ? offer.operationalFocus : 5}
              />
            </Label>
            <Label text={translate('Risk appetite')}>
              <FormRange
                margin={{top: 2}}
                name="riskAppetite"
                min={0}
                max={10}
                step={1}
                defaultValue={!isNil(offer.riskAppetite) ? offer.riskAppetite : 5}
              />
            </Label>
          </>
        )}
        <FormInput
          label={translate('Number of NMIs')}
          required
          type="number"
          name={FIELD_NUMBER_OF_NMIS}
          step={1}
          min={1}
        />
        {theme.offer.showPreviewTermsAndConditions && offer?.contract?.contractType?.termsAndConditions && (
          <StyledTerms>
            <DownloadLink2
              title={translate('General terms and conditions')}
              url={contentService.getAttachmentURL(offer?.contract?.contractType?.termsAndConditions)}
              iconHeight={5}
            />
          </StyledTerms>
        )}
      </Grid>
      <Box margin={{top: 6, bottom: 3}}>
        <OfferChart offer={dynamicOffer} />
      </Box>
      <ProductAdder
        excludedIds={offer?.contract?.contractItems?.map(item => item.product?.id)}
        onAdd={productId => {
          productStore.loadVisibleProduct(productId)
          offerDesignerStore.addProduct(productId)
        }}
      />

      <Box margin={{vertical: 4}} style={{minHeight: 200}}>
        {sortContractItems(offer?.contract?.contractItems).map(contractItem => (
          <OfferContractItem
            key={contractItem.product?.id}
            offer={dynamicOffer}
            contractItem={contractItem}
            editable={offer.state !== OfferState.ACCEPTED}
            removable={offer.state !== OfferState.ACCEPTED}
            onContractItemChange={newItem => offerDesignerStore.setContractItemPrice(offer, newItem)}
            onAmountChange={newAmount => {
              return offerDesignerStore.setAmount(contractItem.product?.id, newAmount)
            }}
            onRemove={() => offerDesignerStore.removeProduct(contractItem.product?.id)}
          />
        ))}
        {!offer?.contract?.contractItems?.length && (
          <Box justify="center">
            <Error>{translate('No products added yet')}</Error>
          </Box>
        )}
      </Box>
    </PageWithFixedLeftSide>
  )
}

const OfferDesignerPageWrap: React.FC<{offer?: IOffer}> = ({offer: baseOffer}) => {
  const {translate} = useLocalization()
  const {offerDesignerStore, alertStore, shoppingCartStore} = useStore()
  const history = useHistory()
  const theme = useTheme()
  const managerParty = useProfile()
  offerDesignerStore.setManagedParty(managerParty.party)
  const offer = useStoreData(store => store.offerDesignerStore.offer)

  useEffect(() => {
    offerDesignerStore.calculatePriceOnly(baseOffer, managerParty.party)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    offerDesignerStore.create(baseOffer)
    // eslint-disable-next-line
  }, [baseOffer?.id, baseOffer?.contract?.contractItems?.length, baseOffer?.contract?.contractType, baseOffer?.state])

  const handleSubmit = async (offerData: IOffer) => {
    offerData.operationalFocus = isNil(offerData.operationalFocus) ? 5 : offerData.operationalFocus
    offerData.riskAppetite = isNil(offerData.riskAppetite) ? 5 : offerData.riskAppetite
    offerData.managedByParty = managerParty.party

    const offer = await offerDesignerStore.save(offerData)
    alertStore.addSuccess(translate('Successfully saved %s', offerData.name))

    if (shoppingCartStore.clearCartOnSave) {
      shoppingCartStore.clearCart()
    }

    history.push(`/broker/sell/offers/${offer.id}`)
  }

  if (baseOffer?.kind === OfferKind.CERTIFICATE_TRADE) {
    return <OfferDesignerCertificatePage offer={baseOffer} />
  }

  return (
    <>
      <Form fillHeight onSubmit={handleSubmit} defaultValues={baseOffer} submissionFeedback={null}>
        <OfferDesignerPage
          onSubmit={handleSubmit}
          offer={offer}
          hasRiskAndOperationalFocus={theme.offer.hasRiskAndOperationalFocus}
        />
      </Form>
      <ContractPreviewModal offer={offer} />
      <OfferPreviewModal offer={offer} />
      <OfferPriceParameterModal
        parameterGroupName="Custom parameter group of an offer"
        onCreate={result => {
          offerDesignerStore.setMeta({pricingParameterGroupId: result.id})
        }}
      />
    </>
  )
}

export default OfferDesignerPageWrap
