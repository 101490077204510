import Box from 'components/Box'
import Button from 'components/Button'
import Loader from 'components/Loader'
import Logo from 'components/Logo'
import Modal from 'components/Modal'
import ModalHeader from 'components/Modal/ModalHeader'
import Text from 'components/Text'
import IContract from 'domain/IContract'
import IPurchaseContract from 'domain/IPurchaseContract'
import useLocalization from 'hooks/useLocalization'
import useProfile from 'hooks/useProfile'
import useServices from 'hooks/useServices'
import React, {useState} from 'react'
import {LederhosenApiErrorType} from 'services/LederhosenApiErrorMapper'
import SmsVerificationForm from '../Account/PhoneVerificationForm'
import GreenEnergy from '../ProductDetails/ReservationBox/components/GreenEnergy'
import {ICertOfferContract} from 'domain/ICertificateOffer'

enum ContractVerificationStep {
  CONFIRM_SENDING_SMS = 'CONFIRM_SENDING_SMS',
  SMS_RECEIVED = 'SMS_RECEIVED',
  SEND_SMS_FAILED = 'SEND_SMS_FAILED',
  ATTEMPTS_LIMIT_EXCEEDED = 'ATTEMPTS_LIMIT_EXCEEDED',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  SEND_SMS_LOADING = 'SEND_SMS_LOADING',
  VERIFICATION_ERROR = 'VERIFICATION_ERROR',
}

interface IProps extends React.PropsWithChildren {
  contract: IContract | IPurchaseContract | ICertOfferContract
  onSuccess: () => void
  onClose: () => void
}

const ContractVerificationChallengeModal: React.FC<IProps> = ({onSuccess, onClose, contract}) => {
  const {user} = useProfile()
  const {translate} = useLocalization()
  const [currentStep, setCurrentStep] = useState(ContractVerificationStep.CONFIRM_SENDING_SMS)
  const [errorMsg, setErrorMsg] = useState<string>(null)
  const {contentService} = useServices()

  const handleCancel = () => {
    onClose()
    setCurrentStep(ContractVerificationStep.CONFIRM_SENDING_SMS)
  }

  const handleSendSMS = async () => {
    setCurrentStep(ContractVerificationStep.SEND_SMS_LOADING)

    try {
      await contentService.sendContractVerificationCode(contract.id)
      setCurrentStep(ContractVerificationStep.SMS_RECEIVED)
    } catch (ex) {
      if (ex?.errorType === LederhosenApiErrorType.VERIFICATIONS_LIMIT_EXCEEDED) {
        setCurrentStep(ContractVerificationStep.ATTEMPTS_LIMIT_EXCEEDED)
      } else {
        setCurrentStep(ContractVerificationStep.SEND_SMS_FAILED)
      }
    }
  }

  const handleConfirmVerification = async form => {
    try {
      await contentService.confirmContractVerificationCode(contract.id, form.pin)
      onSuccess()
    } catch (ex) {
      if (ex?.errorType === LederhosenApiErrorType.VERIFICATION_CODE_INVALID) {
        setErrorMsg(ex.message)
      } else {
        setCurrentStep(ContractVerificationStep.VERIFICATION_ERROR)
      }
    }
  }

  if (!user) {
    return <Loader></Loader>
  }

  return (
    <Modal onClose={onClose}>
      <ModalHeader title={translate('Confirm signature')} />
      <Box style={{minWidth: '500px'}}>
        {currentStep === ContractVerificationStep.CONFIRM_SENDING_SMS && (
          <Box>
            <Text size="large" margin={{top: 2, bottom: 5}}>
              We will text you a signature verification code to {user.phoneNumber}
            </Text>

            <Box direction="row" justify="center">
              <Button onClick={handleSendSMS}>Send SMS</Button>
            </Box>
          </Box>
        )}

        {currentStep === ContractVerificationStep.SMS_RECEIVED && (
          <SmsVerificationForm
            remainingMinutes={15}
            phoneNumber={user.phoneNumber}
            handleSubmit={handleConfirmVerification}
            handleCancel={handleCancel}
            onChange={() => setErrorMsg(null)}
            resendCode={handleSendSMS}
            errorMessage={errorMsg}
          ></SmsVerificationForm>
        )}

        {currentStep === ContractVerificationStep.SEND_SMS_FAILED && (
          <Box>
            <GreenEnergy />
            <Text size="xlarge">Failed to send SMS to {user.phoneNumber}.</Text>
            <Button onClick={handleSendSMS}>Try again</Button>
          </Box>
        )}

        {currentStep === ContractVerificationStep.VERIFICATION_FAILED && (
          <Box>
            <GreenEnergy />
            <Text>Verification Failed</Text>
          </Box>
        )}
        {currentStep === ContractVerificationStep.SEND_SMS_LOADING && (
          <Box justify="center" direction="column">
            <Box justify="center">
              <Logo />
            </Box>
            <Text margin={{top: 3, bottom: 3}} size="large">
              Sending your verification code to {user.phoneNumber}, please wait.
            </Text>
            <Box margin={{bottom: 2}} justify="center">
              <Loader size={50}></Loader>
            </Box>
          </Box>
        )}

        {currentStep === ContractVerificationStep.ATTEMPTS_LIMIT_EXCEEDED && (
          <Box justify="center" direction="column">
            <GreenEnergy />
            <Text size="xxlarge" align="center">
              Verification attempts limit exceeded
            </Text>
            <Text>You&apos;ve made too many verification attempts. Please try again later or contact an admin.</Text>
          </Box>
        )}
      </Box>
    </Modal>
  )
}

export default ContractVerificationChallengeModal
