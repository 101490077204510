import React from 'react'
import InnerScroll from 'components/InnerScroll'
import Page from 'components/Page'
import CompanyInfo from 'components/Platform/Portfolio/Customer/CompanyInfo'
import IParty from 'domain/IParty'

interface IProps extends React.PropsWithChildren {
  party?: IParty
  viewType?: ViewType
}

export enum ViewType {
  CREATE,
  EDIT,
}

const CustomerEditPage: React.FC<IProps> = ({party, viewType}) => {
  return (
    <>
      <Page title={party?.name}>
        <InnerScroll>
          <CompanyInfo viewType={viewType} party={party} />
        </InnerScroll>
      </Page>
    </>
  )
}

export default CustomerEditPage
