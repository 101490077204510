import React, {useState} from 'react'
import HorizStack from 'components/HorizStack'
import Text from 'components/Text'
import VerticalStack from 'components/VerticalStack'
import useTheme from 'hooks/useTheme'
import {FormContainer} from './Layout'
import {useAtom, useAtomValue} from 'jotai'
import {partyAtom} from 'atoms/party'
import {useHistory} from 'react-router-dom'
import {rootServiceAtom} from 'atoms/general'
import useLocalization from 'hooks/useLocalization'
import Button from 'components/Button'
import ConfirmationDialog from 'components/ConfirmationDialog'
import useStore from 'hooks/useStore'
import {OnboardingStatus} from 'domain/IParty'

export interface IProps extends React.PropsWithChildren {
  buyer?: boolean
}

const OnboardingWelcome: React.FC<IProps> = ({buyer}) => {
  const [open, setOpen] = useState(false)
  const {alertStore} = useStore()
  const theme = useTheme()
  const {translate} = useLocalization()
  const history = useHistory()
  const [party, setParty] = useAtom(partyAtom)
  const rootService = useAtomValue(rootServiceAtom)
  const {v2ContentService} = rootService

  const handleStart = async e => {
    e.preventDefault()
    const startOnboarding = () =>
      buyer ? v2ContentService.startBuyerOnboarding(party.id) : v2ContentService.startSellerOnboarding(party.id)
    const res = await startOnboarding()
    const updated = {
      ...party,
      onboardingStatus: res.onboardingStatus,
    }
    setParty(updated)
    history.push(`/${buyer ? 'consumer' : 'broker'}/onboarding/setup-site`)
  }

  const onSkip = async () => {
    try {
      await v2ContentService.skipSellerOnboarding(party.id)
      const updated = {
        ...party,
        onboardingStatus: OnboardingStatus.SELLER_SKIPPED,
      }
      setParty(updated)
      history.push(`/broker`)
    } catch (e) {
      alertStore.addError(translate('Failed to skip onboarding'), e?.correlationId, e?.message)
    }
  }

  return (
    <HorizStack justify="end" fillHeight={true}>
      <FormContainer>
        <VerticalStack style={{textAlign: 'right'}}>
          <Text size="xxxlarge" margin={{bottom: 2}} font={theme.font.headings}>
            {translate('Welcome to Renewabl!')}
          </Text>
          <Text size="large" margin={{bottom: 2}} font={theme.font.secondaryFont} light lineHeight="mlarge">
            {translate(`Join the pioneers of the renewable energy industry. The corporate sector, which accounts for 2/3 of the
            energy emissions worldwide will not be able to reach their sustainability targets in 2030 unless we create
            more impactful and achievable solutions.`)}
          </Text>
          <Text size="large" margin={{bottom: 2}} font={theme.font.secondaryFont} light lineHeight="mlarge">
            {translate('You can be a part of the solution!')}
          </Text>
          <Text bold size="xlarge" font={theme.font.secondaryFont} margin={{bottom: 2}}>
            {translate("Let's get started now!")}
          </Text>
          <HorizStack justify={buyer ? 'end' : 'space-between'}>
            {!buyer && (
              <Button large variant="outlined" onClick={() => setOpen(true)} contrast data-cy="skip">
                <Text color={theme.colors.common.black} semibold uppercase font={theme.font.secondaryFont}>
                  {translate('Skip')}
                </Text>
              </Button>
            )}
            <Button onClick={handleStart} variant="outlined" contrast large data-cy="start">
              <Text color={theme.colors.common.black} semibold uppercase font={theme.font.secondaryFont}>
                {translate('Set up your site')}
              </Text>
            </Button>
          </HorizStack>
        </VerticalStack>
      </FormContainer>
      <ConfirmationDialog
        open={open}
        setOpen={setOpen}
        title={translate('Skip onboarding')}
        acceptText={translate('Skip')}
        declineText={translate('Cancel')}
        onAccept={onSkip}
        text={translate(
          'Skip the onboarding sequence if you are interested in tender participation only. By pressing skip, you will skip the guided onboarding sequence, however you will be able to create products later through Marketplace listing menu.',
        )}
      />
    </HorizStack>
  )
}
export default OnboardingWelcome
