import React, {useEffect} from 'react'
import FormInput from 'components/Form/FormInput'
import useLocalization from 'hooks/useLocalization'
import Box from 'components/Box'
import {useAtomValue, useSetAtom} from 'jotai'
import {marketListingAtom, marketListingIdAtom, refetchListingsAtom} from 'atoms/marketListings'
import Form from 'components/Form'
import {rootServiceAtom} from 'atoms/general'
import {partyAtom} from 'atoms/party'
import {DateFormat, formatDate} from 'helpers/date'
import Page from 'components/Page'
import Button from 'components/Button'
import {IMarketListing} from 'domain/IMarketListing'
import useStore from 'hooks/useStore'
import {useHistory} from 'react-router'
import {getCurrencySymbol} from 'helpers/price'

interface IProps extends React.PropsWithChildren {
  id: number
}

const EditMarketListingPage: React.FC<IProps> = ({id}) => {
  const {translate} = useLocalization()
  const history = useHistory()
  const {alertStore} = useStore()
  const rootService = useAtomValue(rootServiceAtom)
  const setMarketListingId = useSetAtom(marketListingIdAtom)
  const refetchListings = useSetAtom(refetchListingsAtom)
  const party = useAtomValue(partyAtom)
  const {data: listing} = useAtomValue(marketListingAtom)

  useEffect(() => {
    if (id) {
      setMarketListingId(id)
    }

    return () => {
      setMarketListingId(null)
    }
  }, [id])

  const handleSubmit = async (data, {setError}) => {
    const {certPrice} = data as IMarketListing

    try {
      await rootService.v2ContentService.updateMarketListing(party.id, id, {certPrice: +certPrice})
      alertStore.addSuccess(translate('Marketplace listing updated'))
      refetchListings()
      history.push('/broker/portfolio/marketplace-listings')
    } catch (error) {
      alertStore.addError(error?.message, error?.correlationId)
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      fillHeight
      submissionFeedback={null}
      defaultValues={{...listing, startDate: formatDate(listing?.startDate, DateFormat.MONTH_YEAR)}}
    >
      <Page
        title={translate('Marketplace listing')}
        corner={
          <Button data-cy="save-button" type="submit">
            {translate('Save')}
          </Button>
        }
      >
        <Box width="50%">
          <FormInput defaultValue="" disabled name="productName" label={translate('Product name')} />
          <FormInput
            defaultValue=""
            type="number"
            name="certPrice"
            label={translate('Price per EAC')}
            prefix={<Box>{getCurrencySymbol(listing?.currency)}</Box>}
            required
            data-cy="cert-price"
          />
          <FormInput defaultValue="" disabled name="startDate" label={translate('Start date')} />
          <FormInput defaultValue="" disabled name="totalQuantity" label={translate('Total EACs')} />
          <FormInput defaultValue="" disabled name="soldQuantity" label={translate('Sold EACs')} />
          <FormInput defaultValue="" disabled name="reservedQuantity" label={translate('Reserved EACs')} />
          <FormInput defaultValue="" disabled name="availableQuantity" label={translate('Available EACs')} />
        </Box>
      </Page>
    </Form>
  )
}

export default EditMarketListingPage
