import {ContractVolumeProfile} from '../../../../domain/IContract'
import FormSelect, {IProps} from '../../../Form/FormSelect'
import React from 'react'
import useLocalization from '../../../../hooks/useLocalization'
import {getHumanReadableContractVolumeProfile} from '../../../../helpers/contract'

const OfferContractVolumeProfileSelect: React.FC<IProps> = props => {
  const {translate} = useLocalization()

  return (
    <FormSelect
      label={translate('Contract volume profile')}
      {...props}
      options={Object.keys(ContractVolumeProfile).map(profile => ({
        value: profile,
        label: getHumanReadableContractVolumeProfile(profile as ContractVolumeProfile, translate),
      }))}
    />
  )
}

export default OfferContractVolumeProfileSelect
