import IWarehouseCertificates from 'domain/IWarehouseCertificates'
import IV2ContentService from './IV2ContentService'
import {IPaginatedExtendedResult} from 'domain/IPaginateResult'
import ISellExcessRequest from 'domain/ISellExcessRequest'
import ICertificateOffer from 'domain/ICertificateOffer'
import {ICreateOnbBuyerSiteReq, ICreateOnbSellerSiteReq} from 'domain/ICreateOnbSiteReq'
import IMatchingScore from 'domain/IMatchingScore'
import ITimeseriesItem from 'domain/INewTimeseriesItem'
import {INewOffer, INewSellerOfferPayload} from 'domain/IOffer'
import {ISellerOnboardState} from 'domain/ISellerOnboardState'
import IProduct, {IBasicProduct, IUpdateProduct} from 'domain/IProduct'
import IProposalDetails, {IProposalDetailsBody} from 'domain/IProposalDetails'
import ISite, {
  IBasicSite,
  ICreateBuyerSite,
  ISiteOnbSummary,
  IUpdateBuyerSiteGeneral,
  IUploadBuyerConsumption,
} from 'domain/ISite'
import ISitesDashboard from 'domain/ISitesDashboard'
import {CreateOnboardProductReq} from 'domain/IUploadProductRequest'
import {IContractedVolume, ICoverageOverview, IFinancialPerformance} from 'domain/Portfolio'
import {Interval} from './IContentService'
import ITenderRequest from 'domain/ITenderRequest'
import IContract from 'domain/IContract'
import {IBuyerOnboardState} from 'domain/IBuyerOnboardState'
import IExternalContract, {IExternalContractPayload, IExternalContractUploadPayload} from 'domain/IExternalContract'
import {
  IGenerateTestCertsPayload,
  IMarketListing,
  IMarketListingReport,
  IMarketListingUpdatePayload,
  INewMarketListing,
} from 'domain/IMarketListing'
import IAttachment from 'domain/IAttachment'
import {IDetailedProfile} from 'domain/IDetailedProfile'
import {CertificateTech, ITestCertificates} from 'domain/IRegoCertificate'
import {
  adminSellRequests,
  adminWarehouse,
  buyerMarketplaceProducts,
  certificateOffer,
  consumption,
  contractedVolumes,
  detailedProfile,
  externalContracts,
  financialPerformance,
  greenCertificates,
  marketListingCertReport,
  marketListings,
  offers,
  portfolioSites,
  portfolioSitesDashboard,
  proposalDetails,
} from './seed/mocksv2'
import LocalStorageDataService from './LocalStorageDataService'
import LocalStorageService from './LocalStorageService'
import {OnboardingStatus} from 'domain/IParty'
import {CurrencyCode, IUnit, PriceUnit} from 'domain/IPrice'
import {Country} from 'domain/ILocation'
import {locations} from './seed/mocks'
import IKPI, {IKPITargets} from 'domain/IKPI'
import {ITenderRespond, ITenderRespondCreatePayload} from 'domain/ITenderRespond'

export default class MockV2ContentService implements IV2ContentService {
  private readonly detailedProfile: LocalStorageService<IDetailedProfile> = new LocalStorageService<IDetailedProfile>(
    'detailedProfile',
  )
  private readonly portfolioSites: LocalStorageDataService<ISite> = new LocalStorageDataService<ISite>('portfolioSites')
  private readonly buyerMarketplaceProducts: LocalStorageDataService<IProduct> = new LocalStorageDataService<IProduct>(
    'buyerMarketplaceProducts',
  )
  private readonly sellerOnboardingState: LocalStorageService<ISellerOnboardState> =
    new LocalStorageService<ISellerOnboardState>('sellerOnboardingState')

  private readonly buyerOnboardingState: LocalStorageService<IBuyerOnboardState> =
    new LocalStorageService<IBuyerOnboardState>('buyerOnboardingState')

  private readonly externalContracts: LocalStorageDataService<IExternalContract> =
    new LocalStorageDataService<IExternalContract>('externalContracts')
  private readonly marketListings: LocalStorageDataService<IMarketListing> =
    new LocalStorageDataService<IMarketListing>('marketListings')

  private readonly offers: LocalStorageDataService<INewOffer> = new LocalStorageDataService<INewOffer>('newOffers')
  private readonly onboardingState: LocalStorageService<OnboardingStatus> = new LocalStorageService<OnboardingStatus>(
    'onboardingState',
  )

  private readonly noPartyRole: LocalStorageService<boolean> = new LocalStorageService<boolean>('noPartyRole')

  public constructor() {
    this.detailedProfile.setItem(detailedProfile)
    this.portfolioSites.setItems(portfolioSites.data)
    this.sellerOnboardingState.setItem({onboardingStatus: OnboardingStatus.NOT_STARTED})
    this.buyerOnboardingState.setItem({onboardingStatus: OnboardingStatus.NOT_STARTED})
    this.buyerMarketplaceProducts.setItems(buyerMarketplaceProducts.data)
    this.externalContracts.setItems(externalContracts.data)
    this.marketListings.setItems(marketListings.data)
    this.offers.setItems(offers.data)
    this.onboardingState.setItem(OnboardingStatus.COMPLETED)
    this.noPartyRole.setItem(false)
  }

  public async getCertificateProposalDetails(
    partyId: any,
    payload: IProposalDetailsBody,
    interval?: Interval,
  ): Promise<IProposalDetails> {
    return proposalDetails
  }

  public async getPorfolioContractedVolumes(partyId: any, interval: Interval): Promise<IContractedVolume> {
    throw contractedVolumes
  }

  public async getCoverageOverview(partyId: any, interval: Interval): Promise<ICoverageOverview> {
    throw contractedVolumes
  }

  public async getPortfolioSites(
    partyId: number,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<ISite>> {
    const sites = this.portfolioSites.getItems()
    return {...portfolioSites, data: sites}
  }

  public async getPortfolioSitesDashboard(partyId: number, interval: Interval): Promise<ISitesDashboard> {
    return portfolioSitesDashboard
  }

  public async getSellerOnboardingState(partyId: number): Promise<ISellerOnboardState> {
    return this.sellerOnboardingState.getItem()
  }

  public async getBuyerOnboardingState(partyId: number): Promise<IBuyerOnboardState> {
    return this.buyerOnboardingState.getItem()
  }

  public async startSellerOnboarding(partyId: number): Promise<ISellerOnboardState> {
    this.sellerOnboardingState.setItem({onboardingStatus: OnboardingStatus.SELLER_STARTED})
    return this.sellerOnboardingState.getItem()
  }

  public async startBuyerOnboarding(partyId: number): Promise<IBuyerOnboardState> {
    this.buyerOnboardingState.setItem({onboardingStatus: OnboardingStatus.BUYER_STARTED})
    return this.buyerOnboardingState.getItem()
  }

  public async createSellerOnbSite(partyId: any, request: ICreateOnbSellerSiteReq): Promise<ISellerOnboardState> {
    const {meterReference} = request

    this.sellerOnboardingState.setItem({
      onboardingStatus: OnboardingStatus.SELLER_SITE_CREATED,
      site: {
        technology: CertificateTech.SOLAR,
        generatedCertificates: 200,
        location: {addressCountry: Country.GBR},
        meterReference,
      },
    })
    return this.sellerOnboardingState.getItem()
  }

  public async createBuyerOnbSite(partyId: any, request: ICreateOnbBuyerSiteReq): Promise<IBuyerOnboardState> {
    this.buyerOnboardingState.setItem({onboardingStatus: OnboardingStatus.BUYER_SITE_CREATED, site: {...request}})
    return this.buyerOnboardingState.getItem()
  }

  public async createSellerOnbProduct(id: number, req: CreateOnboardProductReq): Promise<ISellerOnboardState> {
    this.sellerOnboardingState.setItem({
      ...this.sellerOnboardingState.getItem(),
      onboardingStatus: OnboardingStatus.SELLER_PRODUCT_CREATED,
      product: {
        ...req,
        currency: CurrencyCode.GBP,
      },
    })
    return this.sellerOnboardingState.getItem()
  }

  public async publishOnboardingProduct(id: number): Promise<ISellerOnboardState> {
    this.sellerOnboardingState.setItem({onboardingStatus: OnboardingStatus.COMPLETED})
    return this.sellerOnboardingState.getItem()
  }

  public async createBuyerOnbConsumption(partyId: any, attachmentId: number): Promise<IBuyerOnboardState> {
    this.buyerOnboardingState.setItem({
      ...this.buyerOnboardingState.getItem(),
      onboardingStatus: OnboardingStatus.BUYER_PROFILE_UPLOADED,
      uploadedProfile: {volumeMwh: 99.999},
    })
    return this.buyerOnboardingState.getItem()
  }

  public async completeBuyerOnboarding(partyId: any): Promise<IBuyerOnboardState> {
    this.buyerOnboardingState.setItem({onboardingStatus: OnboardingStatus.COMPLETED})
    return this.buyerOnboardingState.getItem()
  }

  public async getPortfolioMatchingScores(partyId: any): Promise<IMatchingScore> {
    return {hourlyPercent: 80, monthlyPercent: 85, yearlyPercent: 90.5}
  }

  public async getGreenCertificatesData(
    partyId: any,
    year: number,
    month: string,
    dayOfMonth?: number,
  ): Promise<ITimeseriesItem> {
    return greenCertificates
  }

  public async getCertificateProducts(
    partyId: any,
    month: string,
    year: number,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<IProduct>> {
    const products = this.buyerMarketplaceProducts.getItems()
    return {...buyerMarketplaceProducts, data: products}
  }

  public async getCertificateProduct(partyId: any, productId: any): Promise<IProduct> {
    const products = this.buyerMarketplaceProducts.getItems()

    return products.find(p => p.id === +productId)
  }

  public async getCertificatePurchaseOffer(partyId: any, offerId: any): Promise<ICertificateOffer> {
    return certificateOffer
  }

  public async saveCertificateOffer(partyId: any, payload: IProposalDetailsBody): Promise<ICertificateOffer> {
    return certificateOffer
  }

  public async getAdminExcessSellRequests(): Promise<IPaginatedExtendedResult<ISellExcessRequest>> {
    return adminSellRequests
  }

  public async getWarehouseCertificates(): Promise<IWarehouseCertificates> {
    throw adminWarehouse
  }

  public async sendTenderRequest(partyId: any, body: ITenderRequest): Promise<ITenderRequest> {
    return null
  }

  public async getPortfolioProducts(
    partyId: any,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<IProduct>> {
    const products = this.buyerMarketplaceProducts.getItems()

    return {...buyerMarketplaceProducts, data: products}
  }

  public async signContract(partyId: any, contractId: any): Promise<IContract> {
    return null
  }

  public async getExternalContracts(
    partyId: any,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<IExternalContract>> {
    const contracts = this.externalContracts.getItems()

    return {...externalContracts, data: contracts}
  }
  public async getExternalContract(partyId: any, contractId: string): Promise<IExternalContract> {
    return externalContracts.data.find(c => c.id === +contractId)
  }

  public async uploadAttachment(partyId: any, file: File): Promise<IAttachment> {
    return {id: 1, fileName: 'testFile', size: 400, mimeType: 'application/pdf'}
  }

  public async saveExternalContract(partyId: any, contract: IExternalContractPayload): Promise<IExternalContract> {
    this.externalContracts.addItem(contract)

    return contract
  }

  public async getSellerOnbSiteSummary(partyId: any, interval: Interval): Promise<ISiteOnbSummary> {
    return {siteId: '1', siteName: 'test', timeseries: consumption}
  }
  public async getBuyerOnbSiteSummary(partyId: any, interval: Interval): Promise<ISiteOnbSummary> {
    return {siteId: '1', siteName: 'test', timeseries: consumption}
  }

  public async getFinancialPerformance(
    partyId: any,
    startDate: string,
    endDate: string,
    interval: Interval,
  ): Promise<IFinancialPerformance> {
    return financialPerformance
  }

  public async getMarketListings(
    partyId: any,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<IMarketListing>> {
    const listings = this.marketListings.getItems()

    return {...marketListings, data: listings}
  }

  public async getBasicProducts(partyId: any): Promise<IBasicProduct[]> {
    return [
      {
        id: 10004,
        name: "Exeter Solar REGO's",
        description: "Exeter Solar generation following REGO's",
      },
      {
        id: 10002,
        name: "Dinorwig Hydro REGO's",
        description: "Dinorwig Hydro Generation following REGO's",
      },
      {
        id: 10001,
        name: "MAN Solar REGO's",
        description: "Generation Following REGO's Manchester Solar Airport  ",
      },
    ]
  }

  public async getBasicSites(partyId: any): Promise<IBasicSite[]> {
    return [
      {
        id: 100,
        name: 'Site1',
        location: locations[1],
      },
      {
        id: 101,
        name: 'Site2',
        location: locations[2],
      },
    ]
  }

  public async createMarketListing(partyId: any, payload: INewMarketListing): Promise<boolean> {
    this.marketListings.addItem({...this.marketListings.getItems()[0], certPrice: payload.listingDetails.price})

    return true
  }

  public async updateProduct(partyId: any, productId: string, payload: IUpdateProduct): Promise<IProduct> {
    this.buyerMarketplaceProducts.updateItem({id: +productId, ...payload} as unknown as IProduct)

    return this.buyerMarketplaceProducts.getItem(+productId)
  }

  public async getMarketListingsByProduct(
    partyId: any,
    productId: string,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<IMarketListing>> {
    return {
      ...marketListings,
      data: this.marketListings.getItems().filter(l => l.productId === +productId),
    }
  }

  public async getBuyerProposals(
    partyId: any,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<INewOffer>> {
    return {...offers, data: this.offers.getItems()}
  }

  public async getSellerProposals(partyId: any, page: number, size: number): Promise<INewSellerOfferPayload> {
    return {
      ...offers,
      data: this.offers.getItems(),
      summary: {
        totalVolumeMwh: 4073.0,
        avgVolumeMwh: 162.92,
        pricePerMwh: 8.82,
        priceUnit: PriceUnit.GBP_MWH,
      },
    }
  }

  public async getMarketListingById(partyId: any, listingId: number): Promise<IMarketListing> {
    return this.marketListings.getItem(listingId)
  }

  public async getMarketListingCertReport(partyId: any, listingId: number): Promise<IMarketListingReport> {
    return marketListingCertReport
  }

  public async updateMarketListing(
    partyId: any,
    listingId: number,
    payload: IMarketListingUpdatePayload,
  ): Promise<IMarketListing> {
    this.marketListings.updateItem({...this.marketListings.getItem(listingId), ...payload})

    return this.marketListings.getItem(listingId)
  }

  public async createBuyerSite(partyId: any, payload: ICreateBuyerSite): Promise<ISite> {
    const newSite = this.portfolioSites.addItem(payload)

    return newSite
  }

  public async getBuyerSite(partyId: any, siteId: number): Promise<ISite> {
    return this.portfolioSites.getItem(siteId)
  }

  public async updateBuyerSiteGeneral(partyId: any, siteId: number, payload: IUpdateBuyerSiteGeneral): Promise<ISite> {
    const updatedSite = this.portfolioSites.updateItem({...this.portfolioSites.getItem(siteId), ...payload})

    return updatedSite
  }

  public async uploadBuyerSiteConsumption(
    partyId: any,
    siteId: number,
    payload: IUploadBuyerConsumption,
  ): Promise<ISite> {
    return this.portfolioSites.getItem(siteId)
  }

  public async generateOnboardingTestCertificates(partyId: any): Promise<ITestCertificates> {
    return {start: 'G00001PVEN0000009626010624300624TST', end: 'G00001PVEN0000009825010624300624TST'}
  }

  public async getDetailedProfile(): Promise<IDetailedProfile> {
    const {profile, memberships} = this.detailedProfile.getItem()
    const parsedMemberships = memberships.map(m => ({
      ...m,
      party: {
        ...m.party,
        onboardingStatus: this.onboardingState.getItem(),
        partyRole: this.noPartyRole.getItem() ? null : m.party.partyRole,
      },
    }))

    return {profile, memberships: parsedMemberships}
  }

  public async generateMarketListingTestCertificates(
    partyId: any,
    payload: IGenerateTestCertsPayload,
  ): Promise<ITestCertificates> {
    return {start: 'G00001PVEN0000009626010624300624TST', end: 'G00001PVEN0000009825010624300624TST'}
  }

  public async uploadExternalContractProduction(
    partyId: any,
    contractId: number,
    payload: IExternalContractUploadPayload,
  ): Promise<boolean> {
    return true
  }

  public async skipSellerOnboarding(partyId: any): Promise<boolean> {
    this.sellerOnboardingState.setItem({onboardingStatus: OnboardingStatus.SELLER_SKIPPED})

    return true
  }

  public async getBuyerKPIs(partyId: any, month: string, year: number): Promise<IKPI> {
    return {
      coverage: {
        hourlyPercent: 51.9,
        monthlyPercent: null,
        yearlyPercent: 14.42,
      },
      carbon: {
        unit: 'TON',
        yearEmissions: 36.26,
        yearSavedEmissions: 15.0,
        monthEmissions: 36.26,
        monthSavedEmissions: 12.0,
      },
      targetAchievingRates: {
        hourly: 100,
        monthly: 60,
        yearly: 0,
      },
      annualConsumptionMwh: 5500,
    }
  }

  public async getKPITargets(partyId: any): Promise<{targets: IKPITargets}> {
    return {
      targets: {
        hourlyPercent: 50,
        monthlyPercent: 50,
        yearlyPercent: 50,
      },
    }
  }

  public async createKPITargets(partyId: any, payload: IKPITargets): Promise<boolean> {
    return true
  }

  public async updateKPITargets(partyId: any, payload: IKPITargets): Promise<IKPITargets> {
    return {
      hourlyPercent: 50,
      monthlyPercent: 50,
      yearlyPercent: 50,
    }
  }

  getTenderResponds(partyId: any, page: number, size: number): Promise<IPaginatedExtendedResult<ITenderRespond>> {
    throw new Error('Method not implemented.')
  }
  getTenderRespond(partyId: any, id: number): Promise<ITenderRespond> {
    throw new Error('Method not implemented.')
  }
  createTenderRespond(partyId: any, payload: ITenderRespondCreatePayload): Promise<ITenderRespond> {
    throw new Error('Method not implemented.')
  }
  updateTenderRespond(partyId: any, id: number, payload: ITenderRespond): Promise<ITenderRespond> {
    throw new Error('Method not implemented.')
  }
  downloadAttachment(partyId: any, attachmentId: string): Promise<string> {
    return Promise.resolve('Url name')
  }
}
