import React from 'react'
import Box from '../Box'
import Text from '../Text'
import ExpandButton from '../IconButton/ExpandButton'
import styled from 'styled-components'

interface IProps extends React.PropsWithChildren {
  title: string
  open?: boolean
  onHandleClick?: () => void
}

const Container = styled(Box)`
  border-width: 0;
  border-bottom-width: 1px;

  &:last-child {
    border-bottom-width: 0;
  }
`

const Handle = styled(Box)`
  user-select: none;
`

const AccordionItem: React.FC<IProps> = ({title, children, open, onHandleClick}) => {
  return (
    <Container border>
      <Handle
        cursor="pointer"
        hoverColor="light3"
        onClick={onHandleClick}
        justify="space-between"
        pad={{vertical: 2, left: 1, right: 2}}
      >
        <Text>{title}</Text>
        <ExpandButton expanded={open} />
      </Handle>
      {open && <Box pad={2}>{children}</Box>}
    </Container>
  )
}

export default AccordionItem
