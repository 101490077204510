import React, {useCallback, useState} from 'react'
import styled, {keyframes, css} from 'styled-components'
import {useDropzone, FileRejection} from 'react-dropzone'
import ITheme from '../theme/ITheme'
import useLocalization from '../hooks/useLocalization'
import TextLink from './TextLink'
import Text from './Text'
import useTheme from '../hooks/useTheme'
import Label from './Label'
import Loader from './Loader'
import Box from './Box'
import CloseButton from './CloseButton'

const blinkAnimation = keyframes`
    0%		{ background-color: white}
		50%		{ background-color: rgba(0, 0, 0, 0.1)}
		100%	{ background-color: white}
`

const Container = styled.div<{
  asButtonText?: string
  isFileBeingDragged?: boolean
}>`
  min-height: 80px;
  display: flex;
  align-items: center;
  border: 1px ${props => `dashed ${(props.theme as ITheme).colors.accent}`};
  box-sizing: border-box;
  padding: ${props => (props.theme as ITheme).tileSize * 2}px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;

  ${props =>
    props.isFileBeingDragged &&
    css`
       {
        animation: ${blinkAnimation} 1s linear infinite;
      }
    `}
`

interface IFile {
  name: string
}

export interface IProps
  extends React.PropsWithChildren,
    Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onDrop'> {
  accept?: string
  error?: string
  onDrop?: (acceptedFiles: File[], fileRejections: FileRejection[]) => void
  label?: string
  currentFile?: IFile
  loading?: boolean
  removable?: boolean
  coloredLink?: boolean
  asButtonText?: string
  removeAfterDrop?: boolean
  onClear?: () => void
  single?: boolean
}

const FileDrop: React.FC<IProps> = ({
  accept,
  error,
  onDrop: handleDrop,
  label,
  currentFile,
  loading,
  removable,
  children,
  asButtonText,
  removeAfterDrop,
  onClear,
  single,
  coloredLink = true,
  ...inputProps
}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (handleDrop) {
        handleDrop(acceptedFiles, fileRejections)
      }
      // eslint-disable-next-line
    },
    [handleDrop],
  )
  const {getRootProps, getInputProps, acceptedFiles} = useDropzone({
    multiple: inputProps.multiple,
    accept,
    onDrop,
    noClick: !!label,
  })

  const files: IFile[] = removeAfterDrop
    ? []
    : currentFile
    ? [currentFile]
    : acceptedFiles?.length
    ? acceptedFiles
    : null
  const file: IFile = removeAfterDrop ? null : currentFile

  const [isFileBeingDragged, setIsFileBeingDragged] = useState<boolean>(false)

  window.addEventListener('dragenter', ev => {
    setIsFileBeingDragged(ev.dataTransfer.items[0].kind === 'file')
  })

  window.addEventListener('drop', () => {
    setIsFileBeingDragged(false)
  })

  const content = (
    <>
      <Container
        {...getRootProps()}
        asButtonText={asButtonText}
        isFileBeingDragged={isFileBeingDragged}
        data-cy="file-upload"
      >
        <input {...getInputProps()} {...inputProps} />
        {loading && <Loader margin={{right: 1}} />}
        {!single && !loading && (
          <Box grow>
            {files?.length === 1 ? (
              files[0].name
            ) : files?.length > 1 ? (
              files.map(file => <div key={file.name}>{file.name}</div>)
            ) : asButtonText ? (
              asButtonText
            ) : (
              <>
                <span>{translate('Drop your files here or ')}</span>
                <TextLink noColor={!coloredLink}>{translate('select from your computer')}</TextLink>
              </>
            )}
          </Box>
        )}
        {single && !loading && (
          <Box grow>
            {file?.name || (
              <>
                <span>{translate('Drop your file here or ')}</span>
                <TextLink noColor={!coloredLink}>{translate('select from your computer')}</TextLink>
              </>
            )}
          </Box>
        )}
        {children}
        <Box margin={{left: 1}} />
        {/* TODO place it after (every) file name */}
        {currentFile && removable && (
          <CloseButton
            onClick={e => {
              e.preventDefault()
              onDrop([], [])
              onClear && onClear()
            }}
          />
        )}
      </Container>
      {error && (
        <Text color={theme.colors.error} size="medium" semibold data-cy="input-error">
          {error}
        </Text>
      )}
    </>
  )

  if (label) {
    return <Label text={label}>{content}</Label>
  }

  return content
}

export default FileDrop
