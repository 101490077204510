import ILocation from './ILocation'
import IParty from './IParty'
import IProduct from './IProduct'
import ITimeseriesItem from './ITimeseriesItem'
import IPurchaseGroup from './IPurchaseGroup'

export enum SiteModel {
  PHYSICAL = 'PHYSICAL',
  VIRTUAL = 'VIRTUAL',
}

export enum SiteProductionTechnology {
  WIND = 'WIND',
  HYDRO = 'HYDRO',
  SOLAR = 'SOLAR',
  BIOENERGY = 'BIOENERGY',
  HYDROGEN = 'HYDROGEN',
  GEOTHERMAL = 'GEOTHERMAL',
  WAVE = 'WAVE',
  GAS = 'GAS',
  BATTERY = 'BATTERY',
}

export enum SiteState {
  // PLANNING = 'PLANNING',
  IN_CONSTRUCTION = 'IN_CONSTRUCTION',
  IN_OPERATION = 'IN_OPERATION',
  // RETIRED = 'RETIRED',
}

export enum SiteType {
  PRODUCTION = 'PRODUCTION',
  CONSUMPTION = 'CONSUMPTION',
}

export enum SiteShape {
  COMMERCIAL = 'COMMERCIAL',
  MINING = 'MINING',
  INDUSTRIAL = 'INDUSTRIAL',
  DATA_WAREHOUSE = 'DATA_WAREHOUSE',
  PORT = 'PORT',
  REAL_ESTATE = 'REAL_ESTATE',
}

export default interface ISite {
  id: number
  name: string
  state?: SiteState
  siteModel?: SiteModel
  siteType?: SiteType
  siteShape?: SiteShape
  description?: string
  operationsStart?: string
  operationsEnd?: string
  avgYearlyConsumptionMwh?: number
  productionTechnology?: SiteProductionTechnology
  avgYearlyProductionMwh?: number
  productionNameplateCapacityMwac?: number
  mainCircuitBreakerCapacityAmp?: number
  timeseriesId?: string
  location?: ILocation
  party?: IParty
  isTemplate?: boolean
  products?: IProduct[]
  meta?: {
    pricingParameterGroupId?: number
    smartContractId?: string
    renewableCertificateId?: string
  }
  purchaseGroups?: IPurchaseGroup[]
  siteNmi?: string
  meterId?: string
}

export interface ISiteWithTimeseries extends ISite {
  timeseries: ITimeseriesItem[]
}
interface Consumption {
  name: string
  value: number
}

export interface ISiteOnbSummary {
  siteId: string
  siteName: string
  timeseries: Consumption[]
}

export interface IBasicSite {
  id: number
  name: string
  location?: ILocation
}

export enum TimeseriesKind {
  FILE_UPLOAD = 'FILE_UPLOAD',
  BUILDER = 'BUILDER',
}

export interface IUpdateBuyerSiteGeneral {
  name: string
  state: SiteState
  shape: SiteShape
  description?: string
  operationsStart?: string
  operationsEnd?: string
  locationDetails?: string
  placeId: string
  meterReference: string
}

export interface IUploadBuyerConsumption {
  interval: {
    year: number
    month: string
  }
  timeseries: {
    kind: TimeseriesKind
    payload: {
      attachmentId?: number
      hourlyVolumes?: {
        hour: number
        volumeMwh: number
      }[]
    }
  }
}

export interface ICreateBuyerSite extends IUpdateBuyerSiteGeneral, IUploadBuyerConsumption {}
