import useLocalization from '../../../../hooks/useLocalization'
import useStore from '../../../../hooks/useStore'
import useStoreData from '../../../../hooks/useStoreData'
import React, {useEffect} from 'react'
import Page from '../../../Page'
import CustomerTable, {IBuyerParty} from './CustomerTable'
import InnerScroll from '../../../InnerScroll'
import Box from '../../../Box'
import CustomersSummary from './CustomersSummary'

interface IProps extends React.PropsWithChildren {
  sitesView?: boolean
}

const CustomerListPage: React.FC<IProps> = ({sitesView}) => {
  const {translate} = useLocalization()
  const {partyStore} = useStore()
  const {parties, isLoading, partyLoaded} = useStoreData(store => ({
    parties: store.partyStore.buyerParties,
    isLoading: store.partyStore.isLoading,
    partyLoaded: store.profileStore.isLoaded,
  }))

  useEffect(() => {
    if (partyLoaded) {
      partyStore.loadManagedParties()
    }
    // eslint-disable-next-line
  }, [partyLoaded])

  return (
    <Page
      title={sitesView ? translate('Customers sites') : translate('Customers')}
      description={translate(`Browse the list of customers ${sitesView ? 'sites' : ''}`)}
      // corner={
      //   !sitesView && (
      //     <Link to="/broker/portfolio/customers/add">
      //       <Button>{translate('Add customer')}</Button>
      //     </Link>
      //   )
      // }
    >
      <InnerScroll>
        {parties?.length > 0 && sitesView && (
          <Box margin={{bottom: 5}}>
            <CustomersSummary parties={parties} />
          </Box>
        )}
        <CustomerTable parties={parties as IBuyerParty[]} isLoading={isLoading || !partyLoaded} sitesView={sitesView} />
      </InnerScroll>
    </Page>
  )
}

export default CustomerListPage
