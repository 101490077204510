import React from 'react'
import useLocalization from '../../../hooks/useLocalization'
import useStore from '../../../hooks/useStore'
import Form from '../../Form'
import FormSubmitButton from '../../Form/FormSubmitButton'

// eslint-disable-next-line
interface IProps extends React.PropsWithChildren {}

const ContractUpdateButton: React.FC<IProps> = () => {
  const {contractDesignerStore} = useStore()
  const {translate} = useLocalization()

  return (
    <Form onSubmit={() => contractDesignerStore.updateContract()} submissionFeedback={null}>
      <FormSubmitButton fullWidth>{translate('Update prices')}</FormSubmitButton>
    </Form>
  )
}

export default ContractUpdateButton
