import React from 'react'
import useLocalization from '../../../../hooks/useLocalization'
import Table from '../../../Table'
import IParty from '../../../../domain/IParty'
import Link from '../../../Link'
import useStore from '../../../../hooks/useStore'
import {ImageSelect} from '../../../MultiSelect'
import {formatDecimal} from 'helpers/format'

interface IProps extends React.PropsWithChildren {
  parties: IBuyerParty[]
  isLoading: boolean
  sitesView?: boolean
}

export interface IBuyerParty extends IParty {
  contactFirstName: string
  contactLastName: string
}

const CustomerTable: React.FC<IProps> = ({parties, isLoading, sitesView}) => {
  const {translate} = useLocalization()
  const {partyStore} = useStore()
  const subPage = sitesView ? 'customers-sites' : 'customers'

  const handleDelete = id => {
    if (window.confirm(translate('Do you want to delete customer?'))) {
      partyStore.deleteParty(id)
    }
  }

  return (
    <Table<IBuyerParty>
      tableName={translate('Customers')}
      data={parties}
      isLoading={isLoading}
      linkTo={customer => `/broker/portfolio/${subPage}/${customer.id}`}
      columns={[
        {title: translate('Company'), accessor: (party: IBuyerParty) => party.name},
        {
          title: translate('Representative'),
          accessor: (party: IBuyerParty) => party.contactFirstName + ' ' + party.contactLastName,
        },
        {
          title: translate('Contact email'),
          accessor: (party: IBuyerParty) => party.contactEmail,
        },
        sitesView && {
          title: translate('Annual volume, MWh'),
          accessor: (party: IBuyerParty) =>
            party.totalAvgYearlyConsumptionMwh ? formatDecimal(party.totalAvgYearlyConsumptionMwh, 0) : '-',
        },
        !sitesView && {
          title: null,
          disableLink: true,
          accessor: (party: IBuyerParty) => party.id,
          // eslint-disable-next-line react/display-name
          render: id => (
            <ImageSelect
              hideIndicators
              options={[
                // eslint-disable-next-line
                // @ts-ignore
                {value: 'Edit', label: <Link to={`/broker/portfolio/${subPage}/${id}`}>{translate('Edit')}</Link>},
                // eslint-disable-next-line
                // @ts-ignore
                // {
                //   value: 'New Proposal',
                //   label: <Link to={`/broker/sell/offers/add?customer.id=${id}`}>{translate('New proposal')}</Link>,
                // },
                // eslint-disable-next-line
                // @ts-ignore
                {value: 'Delete', label: <span onClick={() => handleDelete(id)}>{translate('Delete')}</span>},
              ]}
            />
          ),
        },
      ].filter(Boolean)}
    />
  )
}

export default CustomerTable
