import React from 'react'
import {IProps as IInputProps} from '../Input'
import FormInputWrapper, {IProps as IWrapperProps} from './FormInputWrapper'
import ProfileBuilder from 'components/ProfileBuilder'

export type IProps = IWrapperProps<IInputProps & {production?: boolean; contrast?: boolean}>

const FormProfileBuilder: React.FC<IProps> = props => {
  return (
    <FormInputWrapper {...props}>
      <ProfileBuilder production={props?.production} contrast={props?.contrast} />
    </FormInputWrapper>
  )
}

export default FormProfileBuilder
