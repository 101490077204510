import ISmartEnergyContract from '../domain/ISmartEnergyContract'
import IToken, {IOfftakerShare, IOwnersShare} from '../domain/IToken'
import ITranslate from '../interfaces/ITranslate'
import {isDateAfter} from './date'

export function getSmartContractTokenOfftaker(token: IToken, walletId?: string): IOfftakerShare {
  let result: IOfftakerShare

  token.states.forEach(state => {
    state.offtakers.forEach(offtaker => {
      if (walletId && offtaker.offtaker?.toLowerCase() !== walletId.toLowerCase()) {
        return
      }

      result = offtaker
    })
  })

  return result
}

export function getSmartContractTokenOwner(token: IToken, walletId?: string): IOwnersShare {
  let result: IOwnersShare

  token.states.forEach(state => {
    state.owners.forEach(owner => {
      if (walletId && owner.owner?.toLowerCase() !== walletId.toLowerCase()) {
        return
      }

      result = owner
    })
  })

  return result
}

export function getSmartContractTokenShare(token: IToken, walletId?: string): number {
  let share: number = 0
  let date: Date

  token.states.forEach(state => {
    state.offtakers.forEach(offtaker => {
      if (walletId && offtaker.offtaker?.toLowerCase() !== walletId.toLowerCase()) {
        return
      }
      if (!date) {
        share = offtaker.share
        date = state.date
      } else if (isDateAfter(state.date, date)) {
        share = offtaker.share
        date = state.date
      }
    })
  })

  return share
}

export function getSmartContractTokenShareRCT(token: IToken, walletId?: string): number {
  let share: number = 0
  let date: Date

  token.states.forEach(state => {
    state.owners.forEach(owner => {
      if (walletId && owner.owner?.toLowerCase() !== walletId.toLowerCase()) {
        return
      }
      if (!date) {
        share = owner.share
        date = state.date
      } else if (isDateAfter(state.date, date)) {
        share = owner.share
        date = state.date
      }
    })
  })

  return share
}

export function getSmartContractTotalShare(smartContract: ISmartEnergyContract): number {
  let share: number = 0

  smartContract.tokens.forEach(token => {
    share += getSmartContractTokenShare(token)
  })

  return share
}

export function getSmartContractTokenSharePercentage(token: IToken, walletId: string): number {
  const share = getSmartContractTokenShare(token, walletId)

  if (share <= 0) {
    return 0
  }

  return share / getSmartContractTokenShare(token)
}

export function getSmartContractTokenSharePercentageRCT(token: IToken, walletId: string): number {
  const share = getSmartContractTokenShareRCT(token, walletId)

  if (share <= 0) {
    return 0
  }

  return share / getSmartContractTokenShareRCT(token)
}

export enum TokenStatus {
  ACTIVE,
  LOCKED_FOR_SETTLEMENT,
  SETTLED,
}

export function getSmartContractTokenStatus(token: IToken): TokenStatus {
  const currentDate = new Date()

  if (token.deliveryEnd < currentDate) {
    return TokenStatus.SETTLED
  } else if (token.deliveryStart < currentDate && token.deliveryEnd > currentDate) {
    return TokenStatus.LOCKED_FOR_SETTLEMENT
  } else if (token.deliveryEnd > currentDate) {
    return TokenStatus.ACTIVE
  }
}

export function getHumanReadableTokenStatus(tokenStatus: TokenStatus, translate: ITranslate): string {
  switch (tokenStatus) {
    case TokenStatus.ACTIVE:
      return translate('Active')
    case TokenStatus.LOCKED_FOR_SETTLEMENT:
      return translate('Locked for settlement')
    case TokenStatus.SETTLED:
      return translate('Settled')
  }
}

export function filterByWalletId(tokens: IToken[], walletId: string, withEmptyShare = false): IToken[] {
  return tokens.filter(token => {
    return !!token.states.find(state => {
      return !!state.offtakers?.find(
        offtaker =>
          offtaker.offtaker?.toLowerCase() === walletId.toLowerCase() && (withEmptyShare || +offtaker.share > 0),
      )
    })
  })
}

export function filterByWalletIdRCT(tokens: IToken[], walletId: string, withEmptyShare = false): IToken[] {
  return tokens.filter(token => {
    return !!token.states.find(state => {
      return !!state.owners?.find(
        owner => owner.owner?.toLowerCase() === walletId.toLowerCase() && (withEmptyShare || +owner.share > 0),
      )
    })
  })
}
