import React from 'react'
import Page, {IProps as IPageProps} from './Page'
import Grid from './Grid'
import styled from 'styled-components'
import InnerScroll from './InnerScroll'

interface IProps extends React.PropsWithChildren, IPageProps {
  aside: React.ReactNode
}

const Aside = styled.aside`
  max-height: 100%;
  overflow: hidden;
`

const StyledGrid = styled(Grid)`
  max-height: 100%;
  overflow: hidden;
`

const PageWithFixedLeftSide: React.FC<IProps> = ({children, aside, ...props}) => {
  return (
    <Page {...props}>
      <StyledGrid columns={2} gap={2} templateColumns="min-content auto">
        <Aside>{aside}</Aside>
        <InnerScroll noRightPad>{children}</InnerScroll>
      </StyledGrid>
    </Page>
  )
}

export default PageWithFixedLeftSide
