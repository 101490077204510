import React, {useEffect} from 'react'
import FileDrop, {IProps as IFileDropProps} from '../FileDrop'
import {FileRejection as IFileRejection} from 'react-dropzone'
import useFormContext from '../../hooks/useFormContext'
import useLocalization from '../../hooks/useLocalization'
import {get} from 'lodash'

export interface IProps extends React.PropsWithChildren, Omit<IFileDropProps, 'required'> {
  name: string
  required?: boolean | string
  removable?: boolean
}

const FormFileDrop: React.FC<IProps> = ({name: initialName, required, onDrop, removable, ...rest}) => {
  const {translate} = useLocalization()
  const {register, setValue, setError, errors, clearError, unregister, watch} = useFormContext()
  const name = initialName
  const errorMessage = get(errors, name)?.message

  useEffect(() => {
    register({name}, {required: required === true ? translate('No file selected') : required})

    return () => unregister(name)
    // eslint-disable-next-line
  }, [register, name])

  return (
    <FileDrop
      onDrop={(acceptedFiles: File[], fileRejections: IFileRejection[]) => {
        if (fileRejections.length) {
          setError(name, 'file', translate(`Invalid file ${fileRejections[0].file.name}`))
        } else {
          clearError(name)
        }

        setValue(name, acceptedFiles[0])
        onDrop && onDrop(acceptedFiles, fileRejections)
      }}
      currentFile={watch(name)}
      error={errorMessage}
      removable={removable || !required}
      onClear={() => setValue(name, null)}
      single
      {...rest}
    />
  )
}

export default FormFileDrop
