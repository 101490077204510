import React, {useState} from 'react'
import {ReactSelect} from '../../../../MultiSelect'
import useLocalization from '../../../../../hooks/useLocalization'
import {ValueType, Props as ReactSelectProps} from 'react-select'
import SmartOption from '../../../../MultiSelect/SmartOption'
import styled from 'styled-components'
import Box, {IProps} from '../../../../Box'
import MicroButton from './MicroButton'
import addSvg from '../../../../../assets/icons/add.svg'
import {getBubbleStyles} from './bubbleStyle'
import BubbleButton from './BubbleButton'
import useOutsideComponentListener from '../../../../../hooks/useOutsideComponentListener'
import {createPortal} from 'react-dom'
import usePortal from '../../../../../hooks/usePortal'

interface IOption {
  label: string
  value: string
}

const Bubble: React.FC<IProps & {ref?: React.Ref<any>}> = styled(Box)<IProps>`
  ${props => getBubbleStyles(props)}
`

const customStyles = {
  indicatorsContainer: provided => ({
    ...provided,
    height: '28px',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '26px',
  }),
  container: provided => ({
    ...provided,
    margin: 0,
    height: '28px',
  }),
  control: provided => ({
    ...provided,
    minHeight: '28px',
    height: '28px',
    width: '100%',
    minWidth: '128px',
    borderRadius: '8px',
    borderWidth: 1,
    backgroundColor: 'hsl(0, 0%, 100%, 0.5)',
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: '12px',
  }),
  input: provided => ({
    ...provided,
    lineHeight: 1.42,
    fontSize: '12px',
    margin: 0,
  }),
}

interface IPurchaseGroupSelect extends ReactSelectProps {
  options?: IOption[]
  onChange?: (value: ValueType<IOption, boolean>) => Promise<void>
  disabled?: boolean
  onSubmit?: (() => any) | ((e: any) => any)
  targetId: string
}

const PurchaseGroupSelect: React.FC<IPurchaseGroupSelect> = ({targetId, disabled, onSubmit, onChange, options}) => {
  const {translate} = useLocalization()
  const [hasCover, setHasCover] = useState(true)
  const [selection, setSelection] = useState(null)
  const [text, setText] = useState(null)

  const {ref} = useOutsideComponentListener(() => {
    setHasCover(true)
    setText(null)
    setSelection(null)
  })

  const target = usePortal(targetId, ref)

  return createPortal(
    <>
      {hasCover ? (
        <BubbleButton
          disabled={disabled}
          img={addSvg}
          imgAlt="+"
          text={translate('Add to new group')}
          onClick={e => {
            e.preventDefault()
            setHasCover(!hasCover)
          }}
        />
      ) : (
        <Bubble gap={1}>
          <ReactSelect
            menuPosition="fixed"
            noOptionsMessage={() => null}
            hideIndicators
            components={{Option: SmartOption}}
            placeholder={translate('New group')}
            styles={customStyles}
            options={options}
            onChange={value => {
              setSelection(value)
            }}
            onInputChange={(value, actionMeta) => {
              if (actionMeta.action === 'input-change') {
                setText(value)
              }
            }}
            menuPortalTarget={target}
            onBlurResetsInput={false}
          />
          <MicroButton
            disabled={!selection && !text}
            onClick={() => {
              onSubmit && onSubmit(selection || {value: {name: text}})
            }}
          >
            {translate('Ok').toUpperCase()}
          </MicroButton>
        </Bubble>
      )}
    </>,
    target,
  )
}

export default PurchaseGroupSelect
