import React, {useContext, useState} from 'react'
import IContract, {ContractState} from '../../../domain/IContract'
import useLocalization from '../../../hooks/useLocalization'
import ContractChart from './ContractChart'
import ContractItem from './ContractItem'
import ContractDownloadLinks from './ContractDownloadLinks'
import ContractAbout from './ContractAbout'
import LegalDisclaimer from '../LegalDisclaimer'
import Box from '../../Box'
import {VolumeType} from '../../../domain/IProductType'
import useStore from '../../../hooks/useStore'
import useProfile from '../../../hooks/useProfile'
import {getProductMaxReservationVolumeMwhForParty, isProductUserSelectable} from '../../../helpers/product'
import useStoreData from '../../../hooks/useStoreData'
import {getContractYearlyVolumeMwh, isRemovable} from '../../../helpers/contract'
import Text from '../../Text'
import Tabs from '../../Tabs'
import Tab from '../../Tabs/Tab'
import ContractProductionOwnership from './Contract/ContractProductionOwnership'
import ContractRenewableCertificates from './Contract/ContractRenewableCertificates'
import IOffer from '../../../domain/IOffer'
import IPurchaseContract, {IPurchaseParty} from 'domain/IPurchaseContract'
import IParty, {PartyRole} from '../../../domain/IParty'
import FileDrop from 'components/FileDrop'
import {FileRejection} from 'react-dropzone'
import useResource from 'hooks/useResource'
import TextLink from 'components/TextLink'
import useServices from 'hooks/useServices'
import styled from 'styled-components'
import {PartyRoleContext} from 'App'
import removeIcon from '../../../../src/assets/icons/remove.svg'
import {ISignedContractFile} from 'domain/ISignedContractFile'

export interface IProps extends React.PropsWithChildren {
  contract: IContract | IPurchaseContract
  editable?: boolean
  chart?: any
  offer?: IOffer
  linkable?: boolean
  passedParty?: IParty | IPurchaseParty
}

const AttachmentsList = styled.div`
  margin-bottom: 16px;
`

const StyledText = styled(Text)`
  margin-bottom: 16px;
`

const SingleFileContainer = styled.div`
  display: flex;
  align-items: center;
`

const RemoveIcon = styled.img`
  margin-right: 4px;
  cursor: pointer;
`

const DownloadLink = styled(TextLink)`
  margin-left: 4px;
`

const ContractDetails: React.FC<IProps> = ({contract, editable, chart, offer, passedParty, linkable = true}) => {
  const {translate} = useLocalization()
  const {contractDesignerStore, contractStore} = useStore()
  const {contentService} = useServices()
  let party: IParty | IPurchaseParty = useProfile().party
  party = offer ? offer.customer : party
  party = passedParty ? passedParty : party
  const mandatoryProducts = useStoreData(store => store.contractDesignerStore.mandatoryProducts)
  const walletId = party?.meta?.walletId
  const partyRole = useContext(PartyRoleContext)

  const [refetchToggle, setRefetchToggle] = useState<boolean>(false)
  const [isUploading, setIsUploading] = useState<boolean>(false)

  const onSignedContractFileDrop = async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setIsUploading(true)
    await contractStore.uploadSignedContractFiles(contract.id, acceptedFiles)
    setRefetchToggle(!refetchToggle)
    setIsUploading(false)
  }

  const {files} = useResource(
    store => ({files: store.contractStore.signedContractFiles}),
    store => store.contractStore.loadSignedContractFiles(contract.id),
    refetchToggle,
  )

  async function removeFileFromContract(contract: IContract | IPurchaseContract, file: ISignedContractFile) {
    if (window.confirm(translate('Do you want to remove "%s"?', file.fileName))) {
      await contractStore.deleteSignedContractFile(contract.id, file.id)
      setRefetchToggle(!refetchToggle)
    }
  }

  return (
    <>
      {editable && contract.state === ContractState.DRAFT && (
        <Text>
          {translate(
            'Please review your aggregated contract details before signing the contract. You can change the power plants and volumes to be bundled in the contract.',
          )}
        </Text>
      )}
      <Tabs name="tab.contract">
        <Tab label={translate('Products & services')} slug="">
          <Box margin={{vertical: 2}}>{chart || <ContractChart party={party} contract={contract} />}</Box>
          <Box margin={{vertical: 4}}>
            {contract.contractItems.map(contractItem => (
              <ContractItem
                maxTotalVolumeMwh={getProductMaxReservationVolumeMwhForParty(contractItem.product, contract, party)}
                key={contractItem.id}
                linkTo={
                  linkable && contractItem.product?.productType?.volumeType === VolumeType.USER_SELECT
                    ? `/consumer/buy/marketplace/products/${contractItem.product?.id}`
                    : null
                }
                contractItem={contractItem}
                editable={editable && isProductUserSelectable(contractItem.product)}
                onAmountChange={(newAmount: number) =>
                  contractDesignerStore.setProductVolumeMwh(contractItem.product.id, newAmount)
                }
                onRemove={() => contractDesignerStore.setProductVolumeMwh(contractItem.product.id, 0)}
                removable={editable && isRemovable(contractItem.product.id, mandatoryProducts)}
                showPercentOf={getContractYearlyVolumeMwh(contract)}
              />
            ))}
          </Box>
          <ContractDownloadLinks contract={contract} />

          {(partyRole === PartyRole.BROKER || files?.length > 0) && (
            <StyledText size="large">{translate('Signed contract attachments')}</StyledText>
          )}

          {files?.length > 0 && (
            <AttachmentsList>
              {files.map(file => {
                return (
                  <SingleFileContainer key={file.id}>
                    {partyRole === PartyRole.BROKER && (
                      <RemoveIcon
                        src={removeIcon}
                        alt="remove"
                        onClick={() => removeFileFromContract(contract, file)}
                      />
                    )}
                    {file.fileName}
                    <DownloadLink download to={contentService.getSignedContractFileURL(file)}>
                      {translate('Download')}
                    </DownloadLink>
                  </SingleFileContainer>
                )
              })}
            </AttachmentsList>
          )}

          {partyRole === PartyRole.BROKER && (
            <FileDrop multiple onDrop={onSignedContractFileDrop} loading={isUploading} removeAfterDrop />
          )}

          <ContractAbout description={contract.contractType?.description} />
          <Box margin={{top: 16}}>
            <LegalDisclaimer />
          </Box>
        </Tab>
        {walletId && (
          <Tab label={translate('Production ownership')} slug="production-ownership">
            <ContractProductionOwnership contract={contract} />
          </Tab>
        )}
        {walletId && (
          <Tab label={translate('Renewable certificates')} slug="renewable-certificates">
            <ContractRenewableCertificates contract={contract} />
          </Tab>
        )}
      </Tabs>
    </>
  )
}

export default ContractDetails
