import React from 'react'
import styled from 'styled-components'
import ITheme from '../theme/ITheme'

interface IItemProps {
  name?: string
  type: string
  onBlur: (value: number) => void
  defaultValue: any
  prefix?: string
  suffix?: string
  lineHeight?: string
}

const StyledSeamlessInput = styled.div<{lineHeight: string}>`
  line-height: ${props => (props.lineHeight ? props.lineHeight : '')};

  span {
    border: 1px dashed;
    border-radius: 4px;
    border-color: ${(props: {theme: ITheme}) => props.theme.colors.light3};
  }
  span:hover {
    background-color: ${(props: {theme: ITheme}) => props.theme.colors.light3};
  }
`

const SeamlessInput: React.FC<IItemProps> = props => {
  const parseInput = initalValue => {
    let value = initalValue

    if (props.type === 'number') {
      const inputValueText = initalValue.replace(/,/g, '.')
      const keepFloatNumbers = (inputValueText.match(/\d+(\.\d+)?/g) || []).join('')
      const split = keepFloatNumbers.split('.')
      value = parseFloat(split.shift() + '.' + split.join(''))
    }

    props.onBlur(value)
  }

  const disableNewlines = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  return (
    <StyledSeamlessInput lineHeight={props.lineHeight}>
      {props.prefix}
      <span
        id={props.name}
        contentEditable
        suppressContentEditableWarning={true}
        onKeyPress={disableNewlines}
        onBlur={e => parseInput(e.target.innerText)}
      >
        {props.defaultValue}
      </span>
      {props.suffix}
    </StyledSeamlessInput>
  )
}

export default SeamlessInput
