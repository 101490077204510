import IPriceCalculationService from './IPriceCalculationService'
import IOffer from '../domain/IOffer'
import IOfferPriceCalculationResult from '../domain/IOfferPriceCalculationResult'
import IContentService from './IContentService'

export default class LederhosenPriceCalculationService implements IPriceCalculationService {
  private readonly contentService: IContentService

  constructor(contentService: IContentService) {
    this.contentService = contentService
  }

  public calculateOfferPrice(offer: IOffer, partyId?: any): Promise<IOfferPriceCalculationResult> {
    return this.contentService.defaultCalculateOfferPrice(offer, partyId)
  }
}
