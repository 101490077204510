import React from 'react'
import IToken, {IOfftakerShare} from '../../../../domain/IToken'
import Tabs from '../../../Tabs'
import useLocalization from '../../../../hooks/useLocalization'
import Tab from '../../../Tabs/Tab'
import Features, {Feature} from '../../ProductDetails/Features'
import {formatPercentage} from '../../../../helpers/format'
import {
  getSmartContractTokenOfftaker,
  getSmartContractTokenOwner,
  getSmartContractTokenShare,
  getSmartContractTokenSharePercentage,
  getSmartContractTokenSharePercentageRCT,
  getSmartContractTokenShareRCT,
} from '../../../../helpers/smartContract'
import {formatDate} from '../../../../helpers/date'
import Grid from '../../../Grid'
import Text from '../../../Text'
import ISite from '../../../../domain/ISite'
import Box from '../../../Box'
import Heading from '../../../Heading'
import Table from '../../../Table'
import Accordion from '../../../Accordion'
import AccordionItem from '../../../Accordion/AccordionItem'
import ISmartEnergyContract from '../../../../domain/ISmartEnergyContract'
import {convertEnergy, EnergyUnit} from '../../../../helpers/conversion'
import ProgressWithLegend from '../../../ProgressWithLegend'
import theme from '../../../../theme'

export enum TokenType {
  SET,
  RCT,
}

interface IProps extends React.PropsWithChildren {
  token: IToken
  walletId: string
  site: ISite
  type: TokenType
  smartContract: ISmartEnergyContract
}

const TabbedTokenDetails: React.FC<IProps> = ({token, walletId, site, type, smartContract}) => {
  const {translate} = useLocalization()

  const userShare =
    type === TokenType.SET
      ? getSmartContractTokenShare(token, walletId)
      : getSmartContractTokenShareRCT(token, walletId)
  const totalShare = type === TokenType.SET ? getSmartContractTokenShare(token) : getSmartContractTokenShareRCT(token)

  const data: Array<{title: string; value: any}> = [
    {
      title: translate('Your share'),
      value: (
        <div>
          {userShare}/{totalShare}
          <Box width={56} margin={{vertical: 2}}>
            <ProgressWithLegend
              unit={type === TokenType.SET ? 'MW' : 'MWh'}
              fills={[
                {
                  color: theme.colors.grey1,
                  text: translate('Your share'),
                  value: userShare,
                },
                {
                  color: theme.colors.grey1,
                  text: translate("Others' share"),
                  value: totalShare - userShare,
                },
              ]}
              max={totalShare}
            />
          </Box>
        </div>
      ),
    },
    {
      title: translate('Holder address'),
      value:
        type === TokenType.SET
          ? getSmartContractTokenOfftaker(token, walletId)?.offtaker
          : getSmartContractTokenOwner(token, walletId)?.owner,
    },
    {
      title: translate('Delivery start'),
      value: formatDate(token.deliveryStart),
    },
    {
      title: translate('Delivery end'),
      value: formatDate(token.deliveryEnd),
    },
    {
      title: translate('Site name'),
      value: site.name,
    },
    {
      title: translate('Blockhash'),
      value: token.minted.blockHash,
    },
    type === TokenType.SET && {
      title: translate('Energy hash'),
      value: getSmartContractTokenOfftaker(token, walletId)?.energyHash,
    },
    type === TokenType.RCT && {
      title: translate('Energy hash'),
      value: getSmartContractTokenOwner(token, walletId)?.energyHash,
    },
  ].filter(Boolean)

  return (
    <Tabs name="tab.token-details">
      <Tab label={translate('Overview')} slug="">
        <Box pad={2}>
          <Box margin={{vertical: 3}}>
            <Features
              features={[
                {
                  title: translate('Share'),
                  value: formatPercentage(
                    type === TokenType.SET
                      ? getSmartContractTokenSharePercentage(token, walletId)
                      : getSmartContractTokenSharePercentageRCT(token, walletId),
                  ).toString(),
                  unit: '%',
                },
                type === TokenType.RCT && {
                  title: translate('Renewable energy volume'),
                  value: convertEnergy(
                    getSmartContractTokenShareRCT(token, walletId),
                    EnergyUnit.Wh,
                    EnergyUnit.MWh,
                  ).toString(),
                  unit: 'MWh',
                },
                {
                  title: translate('Token Id'),
                  smallValue: token.id,
                },
                {
                  title: translate('Minting date'),
                  smallValue: formatDate(token.minted.date),
                },
              ].filter(Boolean)}
            />
          </Box>
          <Grid columns={2} gap={2} templateColumns="auto 1fr">
            {data.map(({title, value}, index) => (
              <React.Fragment key={index}>
                <Text size="small" uppercase semibold>
                  {title}
                </Text>
                <Text size="small" uppercase>
                  {value}
                </Text>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
      </Tab>
      <Tab label={translate('Transactions log')} slug="transactions">
        <Heading margin={{bottom: 4}}>{translate('Token detailed log')}</Heading>
        <Accordion firstOpen>
          {token.states.map(state => {
            return (
              <AccordionItem title={translate('Transaction %s', formatDate(state.date))} key={state.transactionHash}>
                <Grid columns={2} gap={4}>
                  <div>
                    <Heading margin={{bottom: 2}} size="small">
                      {translate('Transaction summary')}
                    </Heading>
                    <Feature title={translate('Date')} smallValue={formatDate(state.date)} />
                    <Feature title={translate('Block hash')} smallValue={state.blockHash} />
                    <Feature title={translate('Block number')} smallValue={state.blockNumber.toString()} />
                    <Feature title={translate('Transaction hash')} smallValue={state.transactionHash} />
                    <Feature title={translate('Gas')} smallValue={state.gas.toString()} />
                  </div>
                  <div>
                    <Heading margin={{bottom: 2}} size="small">
                      {translate('Holders')}
                    </Heading>
                    <Table<IOfftakerShare>
                      sortable={false}
                      columns={[
                        {title: translate('Address'), accessor: v => v.offtaker},
                        {title: translate('Share'), accessor: v => v.share},
                      ]}
                      data={state.offtakers}
                    />
                  </div>
                </Grid>
              </AccordionItem>
            )
          })}
        </Accordion>
      </Tab>
    </Tabs>
  )
}

export default TabbedTokenDetails
