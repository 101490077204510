import axios from 'axios'
import ApiError from 'exceptions/ApiError'
import IAuthenticationService from './IAuthenticationService'
import IHttpClient from './IHttpClient'

export interface IApiClientOptions {
  errorMappingFn?: (response: any) => ApiError
}

export default class ApiClient {
  public static create(
    authenticationService: IAuthenticationService,
    apiURL: string,
    options: IApiClientOptions = {},
  ): IHttpClient {
    const httpClient = axios.create({
      baseURL: apiURL,
      timeout: 10000,
    })

    httpClient.interceptors.request.use(async config => {
      const token = await authenticationService.getToken()

      if (token !== null) {
        config.headers.authorization = `Bearer ${token}`
      }

      if (!config.headers.Accept) {
        config.headers.Accept = 'application/json'
      }

      return config
    })

    httpClient.interceptors.response.use(undefined, error => {
      const {title, detail, message} = error?.response?.data || {}

      if (options?.errorMappingFn) {
        return Promise.reject(options.errorMappingFn(error?.response))
      }

      error.message = title || detail || message || error.message

      error.status = error?.response?.status
      error.errorType = error?.response?.data?.errorType

      return Promise.reject(error)
    })

    return httpClient
  }
}
