import {computed} from 'mobx'
import IParty from '../domain/IParty'
import IPurchaseGroup, {PurchaseGroupState} from '../domain/IPurchaseGroup'
import IContentService from '../services/IContentService'
import RootStore from './index'
import ResourceStore, {RelationType} from './ResourceStore'

export default class PurchaseGroupStore extends ResourceStore<IPurchaseGroup> {
  private readonly rootStore: RootStore
  private readonly contentService: IContentService

  public constructor(rootStore: RootStore, contentService: IContentService) {
    super([
      {
        type: RelationType.ManyToMany,
        key: 'sites',
        getStore: () => rootStore.siteStore,
        inversedBy: 'purchaseGroups',
      },
    ])
    this.rootStore = rootStore
    this.contentService = contentService
  }

  public async loadCurrentPartyPurchaseGroups() {
    await this.setManyEventually(this.getPartyPurchaseGroups())
  }

  public async loadCustomerPurchaseGroups(customerPartyId: number) {
    await this.clearItems()
    await this.setManyEventually(this.getCustomerPurchaseGroups(customerPartyId))
  }

  public async getCustomerPurchaseGroups(customerPartyId: number) {
    const groups = await this.contentService.getPartyPurchaseGroups(customerPartyId)

    return groups
  }

  private async getPartyPurchaseGroups() {
    const groups = await this.contentService.getPartyPurchaseGroups(this.party.id)

    return groups
  }

  @computed
  private get party(): IParty {
    return this.rootStore.profileStore.party
  }

  @computed
  public get purchaseGroups(): IPurchaseGroup[] {
    // https://renewabl.atlassian.net/browse/WP2-747 purch group becomes deleted when its referencing a single site and the site gets deleted
    return this.items.filter(purchGroup => purchGroup.state !== PurchaseGroupState.DELETED)
  }
}
