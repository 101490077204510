import IParty, {PartyType} from './IParty'
import IContract from './IContract'
import IOfferTextGroup from './IOfferTextGroup'
import IUser from './IUser'
import IPurchaseGroup from './IPurchaseGroup'
import {IPaginatedExtendedResult} from './IPaginateResult'
import {PriceUnit} from './IPrice'

export interface IOfferMeta {
  pricingParameterGroupId: number
}

export enum OfferState {
  INDICATIVE_DRAFT = 'INDICATIVE_DRAFT',
  INDICATIVE_TO_CUSTOMER = 'INDICATIVE_TO_CUSTOMER',
  CUSTOMER_INTERESTED = 'CUSTOMER_INTERESTED',
  NOT_INTERESTED = 'NOT_INTERESTED',
  BINDING_DRAFT = 'BINDING_DRAFT',
  BINDING_TO_CUSTOMER = 'BINDING_TO_CUSTOMER',
  ACCEPTED = 'ACCEPTED',
  DELETED = 'DELETED',
  PURCHASE_CONFIG_IN_RETAILER_REVIEW = 'PURCHASE_CONFIG_IN_RETAILER_REVIEW',
  PURCHASE_CONFIG_DRAFT = 'PURCHASE_CONFIG_DRAFT',
  PURCHASE_CONFIG_RETAILER_DECLINED = 'PURCHASE_CONFIG_RETAILER_DECLINED',
}

export enum OfferKind {
  CERTIFICATE_TRADE = 'CERTIFICATE_TRADE',
  ENERGY = 'ENERGY',
}

export interface IShortOffer {
  id: number
  kind: OfferKind
  state: OfferState
  name: string
}

export default interface IOffer {
  id: any
  name: string
  contract: IContract
  customer: IParty
  managedByParty?: IParty
  purchaseGroup?: IPurchaseGroup
  minPrice: number
  maxPrice: number
  lgcMinPrice: number
  lgcMaxPrice: number
  totalPrice: number
  kind: OfferKind
  operationalFocus: number
  riskAppetite: number
  numberOfNmis: number
  textGroups?: IOfferTextGroup[]
  meta?: IOfferMeta
  state?: OfferState
  validFrom?: string
  validTo?: string
  purchaseInterval?: {
    year: number
    month: string
  }
}

export interface IOfferInvite {
  offer: IOffer
  user: IUser
}

export interface INewOffer {
  id: number
  certPrice: number
  contract: Partial<IContract>
  startDate: string
  endDate: string
  kind: OfferKind
  name: string
  priceUnit: PriceUnit
  purchaseInterval: {
    year: number
    month: string
  }
  seller?: {
    id: number
    name: string
    partyType: PartyType
  }
  customer?: {
    id: number
    name: string
    partyType: PartyType
  }
  state: OfferState
  totalPrice: number
  validFrom: string
  validTo: string
}

export interface INewSellerOfferPayload extends IPaginatedExtendedResult<INewOffer> {
  summary?: {
    avgVolumeMwh: number
    pricePerMwh: number
    totalVolumeMwh: number
    priceUnit?: PriceUnit
  }
}
