import React from 'react'
import Chart, {ChartType} from '../../../Chart'
import useTheme from '../../../../hooks/useTheme'
import useTimeseries from '../../../../hooks/useTimeseries'
import ISite from '../../../../domain/ISite'

interface IProps extends React.PropsWithChildren {
  site: ISite
  label: string
}

const SiteChart: React.FC<IProps> = ({site, label}) => {
  const theme = useTheme()
  const {timeseries} = useTimeseries({sites: [site], disableYearly: true})
  const data = timeseries.sites[0] || []

  const lines = [
    {
      key: label,
      label: label,
      color: theme.colors.yellow2,
      data: data.map(({value}) => value),
      type: ChartType.LINE,
      fill: true,
    },
  ]

  return (
    <Chart
      toolbar={{timescale: {hideYearly: true, hideDaily: true}, vertical: true}}
      labels={data.map(({name}) => name)}
      lines={lines}
      dark
    />
  )
}

export default SiteChart
