import ResourceStore, {IRelation, RelationType} from './ResourceStore'
import IOffer, {OfferKind, OfferState} from '../domain/IOffer'
import {computed} from 'mobx'
import RootStore from './index'
import IContentService from '../services/IContentService'
import IParty from '../domain/IParty'
import IContract from '../domain/IContract'
import {ResourceId} from '../types'
import {IPaginateResult} from 'domain/IPaginateResult'

export default class OfferStore extends ResourceStore<IOffer> {
  private readonly rootStore: RootStore
  private readonly contentService: IContentService

  public constructor(rootStore: RootStore, contentService: IContentService) {
    const contractRelation: IRelation<IOffer, IContract> = {
      type: RelationType.ManyToOne,
      key: 'contract',
      getStore: () => rootStore.contractStore,
    }
    const partyRelation: IRelation<IOffer, IParty> = {
      type: RelationType.ManyToOne,
      key: 'customer',
      getStore: () => rootStore.partyStore,
    }

    super([contractRelation, partyRelation])
    this.rootStore = rootStore
    this.contentService = contentService
  }

  public async loadOffers(size?: number, page?: number): Promise<IPaginateResult<IOffer>> {
    await this.clearItems()

    const offerPaginateResult = await this.contentService.getPagedOffers(this.party.id, size, page)

    this.setManyInstantly(offerPaginateResult.content)

    return offerPaginateResult
  }

  public async getAllOffers(): Promise<IOffer[]> {
    const allOffers = await this.contentService.getAllOffers(this.party.id)

    return allOffers
  }

  public async loadCustomerOffers(size?: number, page?: number): Promise<IPaginateResult<IOffer>> {
    await this.clearItems()
    this.isLoading = true

    const offerPaginateResult = await this.contentService.getPagedCustomerOffers(this.party.id, size, page)

    this.setManyInstantly(offerPaginateResult.content)

    return offerPaginateResult
  }

  public async loadOffer(id: any) {
    await this.setOneEventually(this.contentService.getOffer(this.party.id, id))
  }

  public async sendDraftToCustomer(id: any) {
    const data = await this.contentService.sendDraftToCustomer(this.party.id, id)
    this.loadOffers()
    return data
  }

  public async toBindingDraft(id: any) {
    const data = await this.contentService.toBindingDraft(this.party.id, id)
    this.loadOffers()
    return data
  }

  public async toBindingDraftBroker(id: any) {
    const data = await this.contentService.toBindingDraft(this.party.id, id)
    this.loadOffer(id)
    return data
  }

  public async sendBindingToCustomer(id: any) {
    const data = await this.contentService.sendBindingToCustomer(this.party.id, id)
    this.loadOffers()
    return data
  }

  public async userIsInterested(id: any) {
    const data = await this.contentService.userInterestedInOffer(this.party.id, id)
    this.removeItem(id)
    this.loadCustomerOffers()
    return data
  }

  public async userNotIsInterested(id: any) {
    const data = await this.contentService.userNotInterestedInOffer(this.party.id, id)
    this.removeItem(id)
    return data
  }

  @computed
  public get offers(): IOffer[] {
    return this.items.filter(offer => offer.kind === OfferKind.ENERGY)
  }

  @computed
  public get certOffers(): IOffer[] {
    return this.items.filter(offer => offer.kind === OfferKind.CERTIFICATE_TRADE)
  }

  @computed
  public get contracts(): IContract[] {
    const contracts: IContract[] = []
    this.items.forEach(offer => {
      if ([OfferState.BINDING_DRAFT, OfferState.INDICATIVE_TO_CUSTOMER, OfferState.ACCEPTED].includes(offer.state)) {
        contracts.push(offer.contract)
      }
    })
    return contracts
  }

  @computed
  private get party() {
    return this.rootStore.profileStore.party
  }

  public async saveOffer(offer: IOffer): Promise<IOffer> {
    return this.setOneEventually(this.storeOffer(offer))
  }

  public async deleteOffer(offerId: ResourceId): Promise<void> {
    await this.contentService.softDeleteManagedPartyOffer(this.party.id, offerId)

    this.removeItem(offerId)
  }

  private async storeOffer(offer: IOffer): Promise<IOffer> {
    const party = this.party

    return offer.id
      ? await this.contentService.updateOffer(party.id, offer)
      : await this.contentService.createOffer(party.id, offer)
  }
}
