import React, {useEffect, useState} from 'react'
import RouterModal from '../../../Modal/RouterModal'
import {ModalType} from '../../../Modal/IModal'
import useLocalization from '../../../../hooks/useLocalization'
import Form from '../../../Form'
import IOffer from '../../../../domain/IOffer'
import DocumentContainer from '../../../Document/DocumentContainer'
import OfferDocumentContent from './OfferDocumentContent'
import useDocumentDownload from '../../../../hooks/useDocumentDownload'
import OfferDocumentHead from './OfferDocumentHead'
import useResource from '../../../../hooks/useResource'
import OfferModalFormHeader from '../../../Modal/OfferModalHeader'
import useStore from '../../../../hooks/useStore'

interface IProps extends React.PropsWithChildren {
  offer: IOffer
}

const OfferPreviewModalContent: React.FC<IProps> = ({offer}) => {
  const {translate} = useLocalization()
  const {offerDesignerStore} = useStore()
  const {documentRef, downloadDocument} = useDocumentDownload({
    filename: `offer-${offer.id}.pdf`,
  })
  const [yearlyPrices, setYearlyPrices] = useState(null)

  const textGroups = useResource(
    store => store.offerDesignerStore.textGroups,
    store => store.offerDesignerStore.loadLookups(offer.id),
    offer.id,
  )

  const handleSubmit = async () => {
    await downloadDocument()
  }

  useEffect(() => {
    const getYearlyPrices = async () => {
      const offerPrice = await offerDesignerStore.returnCalculatePriceOnly(offer, offer.managedByParty)

      setYearlyPrices(offerPrice?.yearlyPrices ? offerPrice.yearlyPrices : null)
    }

    if (!yearlyPrices) {
      getYearlyPrices()
    }

    // eslint-disable-next-line
  }, [offer])

  return (
    <>
      <Form onSubmit={handleSubmit} submissionFeedback={null}>
        <OfferModalFormHeader
          offer={offer}
          title={translate('Offer')}
          description={translate('Please review the details of solution offer')}
          buttonText={translate('Save as PDF')}
        />
        <DocumentContainer ref={documentRef}>
          <OfferDocumentHead offer={offer} />
          <OfferDocumentContent offer={offer} textGroups={textGroups} yearlyPrices={yearlyPrices} />
        </DocumentContainer>

        <OfferDocumentContent offer={offer} textGroups={textGroups} yearlyPrices={yearlyPrices} />
      </Form>
    </>
  )
}

const OfferPreviewModal: React.FC<IProps> = ({offer}) => {
  if (!offer?.id) {
    return null
  }

  return (
    <RouterModal style={{width: 804}} name={ModalType.OFFER_PDF}>
      <OfferPreviewModalContent offer={offer} />
    </RouterModal>
  )
}

export default OfferPreviewModal
