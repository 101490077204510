import React from 'react'
import useLocalization from '../../../../hooks/useLocalization'
import IOffer from '../../../../domain/IOffer'
import KeyFeature from '../../KeyFeature'
import ContractTerm from '../Contract/ContractTerm'
import {getPriceRange} from '../../../../helpers/offer'
import {getPriceUnitSymbol, getSlashEnergyUnit} from '../../../../helpers/price'
import useTheme from '../../../../hooks/useTheme'
import Grid from '../../../Grid'
import Loader from '../../../Loader'
import Box, {IProps as IBoxProps} from '../../../Box'
import Heading from '../../../Heading'
import PageBreak from '../../../Document/PageBreak'
import IOfferTextGroup from '../../../../domain/IOfferTextGroup'
import OfferContractItem from './OfferContractItem'
import OfferChart from './OfferChart'
import ProductMap from '../../Map/ProductMap'
import {
  calculateBundledLgcVolumeMwh,
  getContractItemsByVolumeType,
  getElectricityItemsAndFirmingItems,
  getWeightAverageForContractItems,
  sortContractItems,
} from '../../../../helpers/contract'
import {PriceUnit} from 'domain/IPrice'
import ITheme, {OfferPriceRange} from 'theme/ITheme'
import {formatEnergy, formatMoney, formatPercentage} from '../../../../helpers/format'
import {ProductTypeBehaviour} from '../../../../domain/IProductType'
import {GreenIndicatorWithSubTitle} from '../../../Indicator/GreenIndicator'
import {DownloadLink2} from '../../../DownloadLink'
import useServices from '../../../../hooks/useServices'
import useStoreData from '../../../../hooks/useStoreData'
import {StyledTable} from '../../../Table'
import IOfferPriceCalculationResult from '../../../../domain/IOfferPriceCalculationResult'

interface ISection extends React.PropsWithChildren {
  title?: string
  highlighted?: boolean
  withBorder?: boolean
}

const DOCUMENT_FONT_SIZE = 11

const SectionHeading: React.FC<React.PropsWithChildren> = ({children}) => (
  <Heading size="small" margin={{bottom: 1, top: 4}}>
    {children}
  </Heading>
)

const YearlyPrices = ({yearlyPrices, isFixed, theme}) => {
  const data = Object.entries(yearlyPrices)

  return (
    <StyledTable>
      <tr>
        {data.map(elem => {
          const year = elem[0]
          return <th key={`tablevalue-${year}`}>{year}</th>
        })}
      </tr>
      <tr>
        {data.map(elem => {
          const price = elem[1] as IOfferPriceCalculationResult

          if (isFixed) {
            return (
              <td key={`tablevalue-${elem[0]}`}>
                {getPriceUnitSymbol(theme.priceUnit)}
                {formatMoney(price.price, theme)}
              </td>
            )
          }

          return (
            <td key={`tablevalue-${elem[0]}`}>{`${getPriceUnitSymbol(theme.priceUnit)}${formatMoney(
              price.minPrice,
              theme,
            )} - ${getPriceUnitSymbol(theme.priceUnit)}${formatMoney(price.maxPrice, theme)}`}</td>
          )
        })}
      </tr>
    </StyledTable>
  )
}

const Section: React.FC<ISection> = ({children, title, highlighted, withBorder}) => {
  const props: IBoxProps = {
    style: {fontSize: DOCUMENT_FONT_SIZE},
    color: highlighted ? 'light1' : 'white',
  }

  if (withBorder) {
    props.round = true
    props.border = true
    props.pad = 2
  }

  return (
    <Box style={{fontSize: DOCUMENT_FONT_SIZE}} {...props}>
      {title && <SectionHeading>{title}</SectionHeading>}
      {children}
    </Box>
  )
}

interface IGroupProps extends Omit<ISection, 'title'> {
  group: IOfferTextGroup
}

function getThemeSpecificPriceUnit(theme: ITheme): PriceUnit {
  if (theme.offer.showPriceInCentsPerKwh) {
    switch (theme.priceUnit) {
      case PriceUnit.AUD_MWH:
        return PriceUnit.AUD_KWH
      case PriceUnit.USD_MWH:
        return PriceUnit.USD_KWH
      case PriceUnit.EUR_MWH:
        return PriceUnit.EUR_KWH
      case PriceUnit.ZAR_MWH:
        return PriceUnit.ZAR_KWH
    }
  }
  return theme.priceUnit
}

function getMWhPriceInCentsPerkWh(price: number): number {
  return price * 0.1
}

function getThemeSpecificPrice(price: number, theme: ITheme): number {
  if (theme.offer.showPriceInCentsPerKwh) {
    return getMWhPriceInCentsPerkWh(price)
  }
  return price
}

const OfferTextGroup: React.FC<IGroupProps> = ({group, ...rest}) => {
  return (
    <Section {...rest} title={group.name}>
      {group.lookups.map(lookup => (
        <Box margin={{vertical: 1}} key={lookup.id}>
          {lookup.text}
        </Box>
      ))}
    </Section>
  )
}

interface IProps extends React.PropsWithChildren {
  offer: IOffer
  textGroups: IOfferTextGroup[]
  yearlyPrices: IOfferPriceCalculationResult
}

const OfferDocumentContent: React.FC<IProps> = ({offer, textGroups, yearlyPrices}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const isOfferPriceRangeFixed = theme.offerPriceRange === OfferPriceRange.FIXED
  const {contentService} = useServices()
  const party = useStoreData(store => store.partyStore.getItem(offer?.customer?.id))

  if (!textGroups) {
    return <Loader />
  }

  const [firstGroup, secondGroup, ...restGroups] = textGroups

  const totalBundledLgcVolume = calculateBundledLgcVolumeMwh(offer.contract)

  const greenDirectIndex =
    totalBundledLgcVolume > 0 ? formatPercentage(totalBundledLgcVolume / party?.totalAvgYearlyConsumptionMwh) : 0

  return (
    <Grid columns={1} gap={2}>
      <Grid columns={2} gap={4}>
        <Box>
          {theme.offer.showPreviewGreenIndex && (
            <GreenIndicatorWithSubTitle
              hoverText="Green Direct Index is a % of consumption covered by bundled renewable energy certificates volume where the original producer is linked to the certificates"
              value={greenDirectIndex || 0}
              title={translate('Hourly matching score')}
              subtitle={translate('Hourly matched renewable index')}
              trailColor={theme.colors.light4}
              noImage
            />
          )}

          <SectionHeading>{translate('Summary')}</SectionHeading>
          <KeyFeature title={translate('Contract term')} value={<ContractTerm contract={offer.contract} />} small />

          <KeyFeature
            title={translate('Est. contract volume')}
            value={formatEnergy(offer.contract?.volumeMwh)}
            unit={translate('MWh/year')}
          />

          <KeyFeature title={translate('Number of NMIs')} value={offer.numberOfNmis} />

          {isOfferPriceRangeFixed ? (
            <>
              <KeyFeature
                title={translate('Electricity price')}
                value={formatMoney(
                  getWeightAverageForContractItems(getElectricityItemsAndFirmingItems(offer.contract.contractItems)),
                )}
                currency={/* todo offer needs to have priceUnit like contract */ getPriceUnitSymbol(theme.priceUnit)}
                unit={getSlashEnergyUnit(getThemeSpecificPriceUnit(theme))}
              />
              <KeyFeature
                title={translate('LGC price')}
                value={formatMoney(
                  getWeightAverageForContractItems(
                    getContractItemsByVolumeType(offer.contract.contractItems, ProductTypeBehaviour.LGC),
                  ),
                )}
                currency={/* todo offer needs to have priceUnit like contract */ getPriceUnitSymbol(theme.priceUnit)}
                unit={getSlashEnergyUnit(getThemeSpecificPriceUnit(theme))}
              />
              {theme.offer.dutchCooAlternative && (
                <KeyFeature
                  title={translate('%s price', theme.offer.dutchCooAlternative)}
                  value={formatMoney(
                    getWeightAverageForContractItems(
                      getContractItemsByVolumeType(offer.contract.contractItems, ProductTypeBehaviour.DUTCH_COO),
                    ),
                  )}
                  currency={/* todo offer needs to have priceUnit like contract */ getPriceUnitSymbol(theme.priceUnit)}
                  unit={getSlashEnergyUnit(getThemeSpecificPriceUnit(theme))}
                />
              )}
            </>
          ) : (
            <>
              <KeyFeature
                title={translate('Electricity price range')}
                value={getPriceRange(
                  getThemeSpecificPrice(offer.minPrice, theme),
                  getThemeSpecificPrice(offer.maxPrice, theme),
                )}
                currency={/* todo offer needs to have priceUnit like contract */ getPriceUnitSymbol(theme.priceUnit)}
                unit={getSlashEnergyUnit(getThemeSpecificPriceUnit(theme))}
              />
              <KeyFeature
                title={translate('LGC price range')}
                value={getPriceRange(
                  getThemeSpecificPrice(offer.lgcMinPrice, theme),
                  getThemeSpecificPrice(offer.lgcMaxPrice, theme),
                )}
                currency={/* todo offer needs to have priceUnit like contract */ getPriceUnitSymbol(theme.priceUnit)}
                unit={getSlashEnergyUnit(getThemeSpecificPriceUnit(theme))}
              />
            </>
          )}

          <KeyFeature title={translate('Contract type')} value={offer.contract?.contractType?.name} small />
        </Box>
        <div>
          <Box margin={{top: 3, bottom: 4}}>{firstGroup && <OfferTextGroup group={firstGroup} />}</Box>
          {theme.offer.showPreviewTermsAndConditions && offer?.contract?.contractType?.termsAndConditions && (
            <DownloadLink2
              title={translate('General terms and conditions')}
              url={contentService.getAttachmentURL(offer?.contract?.contractType?.termsAndConditions)}
              iconHeight={5}
            />
          )}
        </div>
      </Grid>

      <PageBreak type="after" padding="80px">
        <SectionHeading>{translate('Product composition')}</SectionHeading>
        <Box pad={2} style={{marginTop: '24px'}}>
          <OfferChart offer={offer} />
        </Box>
      </PageBreak>

      <Grid columns={2} gap={4} style={{pageBreakInside: 'avoid'}}>
        <Box>{secondGroup && <OfferTextGroup group={secondGroup} />}</Box>

        <Box>
          <SectionHeading>{translate('Offered products and services')}</SectionHeading>
          {sortContractItems(offer?.contract?.contractItems).map(contractItem => (
            <OfferContractItem
              key={contractItem.product?.id}
              contractItem={contractItem}
              offer={offer}
              noImage
              noBorder
              headingSize="xsmall"
              noPad
              style={{fontSize: DOCUMENT_FONT_SIZE}}
            />
          ))}
        </Box>
      </Grid>

      <PageBreak type="before" auto>
        <Grid columns={3} gap={2}>
          {restGroups.slice(0, restGroups.length - 1).map((group, index) => (
            <OfferTextGroup key={index} withBorder group={group} />
          ))}
        </Grid>
      </PageBreak>

      <PageBreak type="before" auto>
        <Box height={31}>
          <ProductMap zoomControl={false} products={offer.contract?.contractItems?.map(c => c.product)} />
        </Box>
      </PageBreak>

      {yearlyPrices && (
        <PageBreak type="after">
          <Box height={31}>
            <SectionHeading>{translate('Yearly prices')}</SectionHeading>
            <YearlyPrices yearlyPrices={yearlyPrices} isFixed={isOfferPriceRangeFixed} theme={theme} />
          </Box>
        </PageBreak>
      )}

      {restGroups[restGroups.length - 1] && (
        <OfferTextGroup withBorder group={restGroups[restGroups.length - 1]} highlighted />
      )}
    </Grid>
  )
}

export default OfferDocumentContent
