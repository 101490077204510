import React from 'react'
import IParty from '../../../../domain/IParty'
import {SiteType} from '../../../../domain/ISite'
import {Route, Switch, useRouteMatch} from 'react-router'
import SiteViewPage from '../Site/SiteViewPage'
import SiteResolver from '../../Resolver/SiteResolver'
import SiteEditPage from '../Site/SiteEditPage'
import SiteListPageNew from '../Site/SiteListPageNew'

interface IProps extends React.PropsWithChildren {
  party?: IParty
}

const CustomerSites: React.FC<IProps> = ({party}) => {
  const {url} = useRouteMatch()

  return (
    <>
      <Switch>
        <Route path={`${url}/add`} render={() => <SiteEditPage siteType={SiteType.CONSUMPTION} siteParty={party} />} />
        <Route
          exact
          path={`${url}/edit/:id`}
          render={routeProps => <SiteResolver id={routeProps.match.params.id} component={SiteEditPage} />}
        />
        <Route
          exact
          path={`${url}/:id`}
          render={routeProps => (
            <SiteResolver id={routeProps.match.params.id} component={SiteViewPage} suffix={'?company=sites'} />
          )}
        />
        <Route exact path={`${url}`} render={() => <SiteListPageNew />} />
      </Switch>
    </>
  )
}

export default CustomerSites
