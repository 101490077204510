import IParty from '../domain/IParty'
import ISite, {SiteType} from '../domain/ISite'
import {useEffect} from 'react'
import {Interval} from '../services/IContentService'
import useStore from './useStore'
import useStoreData from './useStoreData'
import ITimeseriesItem from '../domain/ITimeseriesItem'
import useLocalization from './useLocalization'
import IPurchaseGroup from '../domain/IPurchaseGroup'
import {IPurchaseParty} from 'domain/IPurchaseContract'

interface IArguments {
  parties?: (IParty | IPurchaseParty)[]
  sites?: ISite[]
  partySites?: ISite[]
  purchaseGroups?: IPurchaseGroup[]
  disableYearly?: boolean
  yearlyPeriod?: [number, number]
  interval?: Interval
}

interface IResult {
  timeseries: {
    parties?: ITimeseriesItem[][]
    sites?: ITimeseriesItem[][]
    contractSitesActual?: ITimeseriesItem[][]
    partySitesActual: ITimeseriesItem[][]
    purchaseGroups?: ITimeseriesItem[][]
  }
  errors: string[]
  interval: Interval
}

const useTimeseries = ({
  parties,
  sites,
  partySites,
  purchaseGroups,
  disableYearly,
  interval: suppliedInterval,
  yearlyPeriod: suppliedYearlyPeriod = [null, null],
}: IArguments): IResult => {
  const {translate} = useLocalization()
  const {timeseriesStore} = useStore()
  const errorMessage = (type: string, name: string) => translate('Unable to load %s data of %s', type, name)

  const {interval, timeseries, yearlyPeriod, errors} = useStoreData(store => ({
    interval: store.timeseriesStore.interval,
    timeseries: {
      parties: parties?.map(party => store.timeseriesStore.getPartyConsumption(party.id)),
      sites: sites?.map(site => store.timeseriesStore.getSiteTimeseries(site.id)),
      contractSitesActual: sites?.map(site => store.timeseriesStore.getSiteActualTimeseries(site.id)),
      partySitesActual: partySites?.map(site => store.timeseriesStore.getSiteActualTimeseries(site.id)),
      purchaseGroups: purchaseGroups?.map(purchaseGroup =>
        store.timeseriesStore.getPurchaseGroupConsumption(purchaseGroup.id),
      ),
    },
    errors: [
      ...(parties || []).map(
        party => store.timeseriesStore.getPartyConsumptionError(party.id) && errorMessage('consumption', party.name),
      ),
      ...(purchaseGroups || []).map(
        pg => store.timeseriesStore.getPurchaseGroupConsumptionError(pg.id) && errorMessage('purchase group', pg.name),
      ),
      ...(sites || []).map(
        site =>
          store.timeseriesStore.getSiteTimeseriesError(site.id) &&
          errorMessage(site.siteType === SiteType.PRODUCTION ? 'production' : 'consumption', site.name),
      ),
    ].filter(Boolean),
    yearlyPeriod: store.timeseriesStore.yearlyPeriod,
  }))

  useEffect(() => {
    timeseriesStore.yearlyPeriod = suppliedYearlyPeriod
    // eslint-disable-next-line
  }, [suppliedYearlyPeriod[0], suppliedYearlyPeriod[1]])

  useEffect(() => {
    if (disableYearly && interval === Interval.YEARLY) {
      return
    }

    if (interval === Interval.YEARLY && (!yearlyPeriod[0] || !yearlyPeriod[1])) {
      console.warn('No period set, could not load yearly data')
      return
    }

    parties?.forEach(party => {
      timeseriesStore.loadPredictedPartyConsumption(party?.id)
    })

    purchaseGroups?.forEach(purchaseGroup => {
      timeseriesStore.loadPurchaseGroupConsumption(purchaseGroup?.party?.id, purchaseGroup?.id)
    })

    sites?.forEach(site => {
      timeseriesStore.loadPredictedSiteTimeseries(site.id)
      timeseriesStore.loadActualSiteTimeseries(site.id)
    })

    partySites?.forEach(site => {
      timeseriesStore.loadActualSiteTimeseries(site.id)
    })
    // eslint-disable-next-line
  }, [
    interval,
    (sites || []).map(site => (site.purchaseGroups || []).map(pg => pg.id).join()).join(), // eslint-disable-line
    (sites || []).map(site => site.id).join(), // eslint-disable-line
    (parties || []).map(party => party.id).join(), // eslint-disable-line
    (purchaseGroups || []).map(pg => pg.id).join(), // eslint-disable-line
    interval === Interval.YEARLY ? yearlyPeriod.join() : null, // eslint-disable-line
  ])

  useEffect(() => {
    if (suppliedInterval) {
      timeseriesStore.interval = suppliedInterval
    }
    // eslint-disable-next-line
  }, [suppliedInterval])

  return {timeseries, errors, interval}
}

export default useTimeseries
