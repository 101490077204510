import {action, computed, observable} from 'mobx'
import IContract from '../domain/IContract'
import IContentService from '../services/IContentService'
import DeprecatedContractStore from './ContractStore'
import {VolumeType} from '../domain/IProductType'
import AlertStore from './AlertStore'
import IProduct from '../domain/IProduct'
import {IShoppingCart} from './ShoppingCartStore'

export default class ContractDesignerStore {
  private readonly contentService: IContentService
  private readonly contractStore: DeprecatedContractStore
  private readonly alertStore: AlertStore

  @observable public isLoaded = false
  @observable public partyId: any = null
  @observable private productVolumesMwh: {[key: string]: number} = {}
  @observable public mandatoryProducts: IProduct[] = []

  public constructor(contentService: IContentService, contractStore: DeprecatedContractStore, alertStore: AlertStore) {
    this.contentService = contentService
    this.contractStore = contractStore
    this.alertStore = alertStore
  }

  @action
  public async load(partyId: number) {
    this.partyId = partyId

    await this.contractStore.loadPartyContracts()

    if (this.contract) {
      this.mandatoryProducts = await this.contentService.loadMandatoryProducts(this.contract?.contractType.id, partyId)
    }
    this.isLoaded = true
  }

  @action
  public async loadWithoutOffer(partyId: number) {
    this.partyId = partyId

    await this.contractStore.loadDraftContractsWithoutOffer()

    if (this.contract) {
      this.mandatoryProducts = await this.contentService.loadMandatoryProducts(this.contract?.contractType.id, partyId)
    }
    this.isLoaded = true
  }

  @computed
  public get contract(): IContract {
    return this.contractStore.partyDraftContractsWithoutOffer.length
      ? this.contractStore.partyDraftContractsWithoutOffer
          .sort((a, b) => b.id - a.id)
          .find(contract => contract.contractItems?.length > 0)
      : null
  }

  @computed
  public get unsavedProductVolumes(): Array<{
    productId: any
    volumeMwh: number
  }> {
    return (this.contract?.contractItems || [])
      .map(contractItem => {
        const selectedVolumeMwh = this.productVolumesMwh[contractItem.product.id]

        if (selectedVolumeMwh !== undefined && selectedVolumeMwh !== contractItem.volumeMwh) {
          return {
            productId: contractItem.product.id,
            volumeMwh: selectedVolumeMwh,
          }
        }

        return null
      })
      .filter(Boolean)
  }

  @computed
  public get isUnsaved(): boolean {
    return this.unsavedProductVolumes.length > 0
  }

  @computed
  public get unsavedContract(): IContract {
    const contract: IContract = this.contract

    if (!contract) {
      return null
    }

    return {
      ...contract,
      contractItems: contract.contractItems.map(contractItem => {
        let volumeMwh

        if (contractItem.product?.productType?.volumeType === VolumeType.AUTO_FILL && contractItem.volumeMwh != null) {
          volumeMwh = +(contractItem.volumeMwh - this.getUnsavedVolumeDifference()).toFixed(8)
        } else {
          volumeMwh =
            typeof this.productVolumesMwh[contractItem.product.id] !== 'undefined'
              ? this.productVolumesMwh[contractItem.product.id]
              : contractItem.volumeMwh
        }

        return {
          ...contractItem,
          volumeMwh,
        }
      }),
    }
  }

  @action
  public async updateContract() {
    await this.contentService.updateContract(this.partyId, this.unsavedContract)

    await this.load(this.partyId)
  }

  @action
  public async reserveProduction(partyId: any, reserve: IShoppingCart[]) {
    await this.contentService.reserveProduction(partyId, reserve)
    this.load(this.partyId)
  }

  public getProductVolumeMwh(productId: any): number {
    return this.productVolumesMwh[productId] || 0
  }

  @action
  public setProductVolumeMwh(productId: any, volumeMwh: number) {
    this.productVolumesMwh[productId] = volumeMwh
  }

  @action
  public clearProductVolumeMwh(productId: any) {
    // eslint-disable-next-line
    if (this.productVolumesMwh.hasOwnProperty(productId)) {
      delete this.productVolumesMwh[productId]
    }
  }

  private getUnsavedVolumeDifference(): number {
    let value = 0

    for (const productId in this.productVolumesMwh) {
      const savedValue =
        this.contract?.contractItems?.find(item => item.product?.id.toString() === productId.toString())?.volumeMwh || 0
      const currentValue = this.productVolumesMwh[productId]

      value += currentValue - savedValue
    }

    return value
  }
}
