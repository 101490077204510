import ProfileStore from './ProfileStore'
import RootService from '../services'
import ContractDesignerStore from './ContractDesignerStore'
import TimeseriesStore from './TimeseriesStore'
import NewTimeseriesStore from './NewTimeseriesStore'
import LocalizationStore from './LocalizationStore'
import AlertStore from './AlertStore'
import ProductStore from './ProductStore'
import SiteStore from './SiteStore'
import UIStore from './UIStore'
import ProductTypeStore from './ProductTypeStore'
import PartyStore from './PartyStore'
import OfferStore from './OfferStore'
import ContractStore from './ContractStore'
import OfferDesignerStore from './OfferDesignerStore'
import OfferDesignerCertificateStore from './OfferDesignerCertificateStore'
import ContractItemStore from './ContractItemStore'
import ContractTypeStore from './ContractTypeStore'
import SmartEnergyContractStore from './SmartEnergyContractStore'
import PurchaseGroupStore from './PurchaseGroupStore'
import OfferRequestStore from './OfferRequestStore'
import ShoppingCartStore from './ShoppingCartStore'
import PurchaseContractStore from './PurchaseContractStore'
import PortfolioStore from './PortfolioStore'
import CertificateDesignerStore from './CertificateDesignerStore'
import AdminStore from './AdminStore'

export default class RootStore {
  public adminStore: AdminStore
  public alertStore: AlertStore
  public profileStore: ProfileStore
  public contractDesignerStore: ContractDesignerStore
  public timeseriesStore: TimeseriesStore
  public newTimeseriesStore: NewTimeseriesStore
  public localizationStore: LocalizationStore
  public productStore: ProductStore
  public certificateDesignerStore: CertificateDesignerStore
  public productTypeStore: ProductTypeStore
  public siteStore: SiteStore
  public uiStore: UIStore
  public partyStore: PartyStore
  public offerStore: OfferStore
  public contractStore: ContractStore
  public portfolioStore: PortfolioStore
  public purchaseContractStore: PurchaseContractStore
  public contractItemStore: ContractItemStore
  public contractTypeStore: ContractTypeStore
  public offerDesignerStore: OfferDesignerStore
  public offerDesignerCertificateStore: OfferDesignerCertificateStore
  public smartEnergyContractStore: SmartEnergyContractStore
  public purchaseGroupStore: PurchaseGroupStore
  public offerRequestStore: OfferRequestStore
  public shoppingCartStore: ShoppingCartStore

  public constructor(rootService: RootService) {
    this.adminStore = new AdminStore(this, rootService.contentService, rootService.v2ContentService)
    this.alertStore = new AlertStore()
    this.localizationStore = new LocalizationStore()
    this.profileStore = new ProfileStore(
      rootService.authenticationService,
      rootService.contentService,
      rootService.v2ContentService,
      this.localizationStore,
    )
    this.timeseriesStore = new TimeseriesStore(this, rootService.contentService)
    this.newTimeseriesStore = new NewTimeseriesStore(rootService.contentService, rootService.v2ContentService)
    this.uiStore = new UIStore()
    this.siteStore = new SiteStore(this, rootService.contentService)
    this.productStore = new ProductStore(this, rootService.contentService)
    this.certificateDesignerStore = new CertificateDesignerStore(
      this,
      rootService.contentService,
      rootService.v2ContentService,
    )
    this.productTypeStore = new ProductTypeStore(this, rootService.contentService)
    this.partyStore = new PartyStore(this, rootService.contentService)
    this.offerStore = new OfferStore(this, rootService.contentService)
    this.contractStore = new ContractStore(this, rootService.contentService, rootService.v2ContentService)
    this.purchaseContractStore = new PurchaseContractStore(this, rootService.contentService)
    this.contractItemStore = new ContractItemStore(this, rootService.contentService)
    this.contractTypeStore = new ContractTypeStore(this, rootService.contentService)
    this.contractDesignerStore = new ContractDesignerStore(
      rootService.contentService,
      this.contractStore,
      this.alertStore,
    )
    this.offerDesignerStore = new OfferDesignerStore(rootService, this)
    this.offerDesignerCertificateStore = new OfferDesignerCertificateStore(
      this,
      rootService.contentService,
      rootService.v2ContentService,
    )
    this.smartEnergyContractStore = new SmartEnergyContractStore(this, rootService.smartEnergyContractService)
    this.purchaseGroupStore = new PurchaseGroupStore(this, rootService.contentService)
    this.offerRequestStore = new OfferRequestStore(this, rootService.contentService)
    this.shoppingCartStore = new ShoppingCartStore()
    this.portfolioStore = new PortfolioStore(this, rootService.contentService)
  }
}
