import React from 'react'
import IContract, {ContractState} from '../../../domain/IContract'
import ActionBox from '../ActionBox'
import useLocalization from '../../../hooks/useLocalization'
import Box from '../../Box'
import ContractPreviewButton from './ContractPreviewButton'
import ContractSummaryDetails from './ContractSummaryDetails'
import useStoreData from '../../../hooks/useStoreData'
import ContractUpdateButton from './ContractUpdateButton'
import {PrimaryButton} from '../../Button'
import useStore from '../../../hooks/useStore'
import IOffer, {OfferState} from '../../../domain/IOffer'
import {useHistory} from 'react-router'
import IPurchaseContract from 'domain/IPurchaseContract'

interface IProps extends React.PropsWithChildren {
  contract: IContract | IPurchaseContract
  editable?: boolean
  signable?: boolean
  offer?: IOffer
}

const ContractSummary: React.FC<IProps> = ({contract, editable, signable, offer}) => {
  const {translate} = useLocalization()
  const {offerStore, alertStore} = useStore()
  const history = useHistory()
  const unsaved = useStoreData(store => store.contractDesignerStore.isUnsaved)

  return (
    <ActionBox
      title={translate('Contract summary')}
      description={contract.state === ContractState.SIGNED && translate('Overview of your contract key facts')}
    >
      <ContractSummaryDetails unsaved={editable && unsaved} contract={contract} />
      {(editable || (offer && offer.state) === OfferState.BINDING_TO_CUSTOMER) && (
        <Box margin={{top: 2}}>
          {unsaved ? <ContractUpdateButton /> : <ContractPreviewButton contract={contract} />}
        </Box>
      )}
      {offer && offer?.state === OfferState.CUSTOMER_INTERESTED && (
        <Box margin={{top: 2}}>
          <PrimaryButton
            type="button"
            onClick={async () => {
              const response = await offerStore.sendBindingToCustomer(offer.id)
              if (response.status === 204) {
                alertStore.addSuccess(translate('A binding contract sent to the customer'))
              }
              history.push('/broker/sell/offers')
            }}
          >
            {translate('Send To Customer')}
          </PrimaryButton>
        </Box>
      )}
    </ActionBox>
  )
}

export default ContractSummary
