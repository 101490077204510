import React from 'react'
import ResourceGateway from '../../../components/ResourceGateway'
import ExternalContractsPage from 'components/Platform/Portfolio/ExternalContract/ExternalContractsPage'
import AddExternalContractPage from 'components/Platform/Portfolio/ExternalContract/AddExternalContractPage'
import ExternalContractPage from 'components/Platform/Portfolio/ExternalContract/ExternalContractPage'

interface IProps extends React.PropsWithChildren {}

const ExternalContracts: React.FC<IProps> = () => {
  return (
    <ResourceGateway
      create={() => <AddExternalContractPage />}
      single={routeProps => <ExternalContractPage id={routeProps.match.params.id} />}
      list={() => <ExternalContractsPage />}
    />
  )
}

export default ExternalContracts
