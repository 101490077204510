import IOfferPriceParameterService from './IOfferPriceParameterService'
import IOfferPriceParameterGroup from '../domain/IOfferPriceParameterGroup'
import {ResourceId} from '../types'
import IProductFirmingPremium, {IFirmingPremiumParameter} from '../domain/IProductFirmingPremium'
import IProductFirmingPremiumService from './IProductFirmingPremiumService'

export default class LocalOfferPriceParameterService
  implements IOfferPriceParameterService, IProductFirmingPremiumService
{
  public async createParameterGroup(group: Omit<IOfferPriceParameterGroup, 'id'>): Promise<IOfferPriceParameterGroup> {
    throw new Error('Not implemented')
  }

  public async getParameterGroup(id: ResourceId): Promise<IOfferPriceParameterGroup> {
    throw new Error('Not implemented')
  }

  public async getDefaultParameterGroup(): Promise<IOfferPriceParameterGroup> {
    throw new Error('Not implemented')
  }

  public async getParameterGroupTemplates(): Promise<Array<IOfferPriceParameterGroup>> {
    return [{id: 1, name: 'CONSUMPTION_TEMPLATE_SITE_1', quarterlyPriceParameters: []}]
  }

  public async createFirmingPremium(premiums: IFirmingPremiumParameter[]): Promise<IFirmingPremiumParameter[]> {
    throw new Error('Not implemented')
  }

  public async updateFirmingPremium(premiums: IFirmingPremiumParameter[]): Promise<IFirmingPremiumParameter[]> {
    throw new Error('Not implemented')
  }

  public async getFirmingPremium(id: ResourceId): Promise<IFirmingPremiumParameter[]> {
    throw new Error('Not implemented')
  }

  public async getFirmingPremiumByProduct(): Promise<IProductFirmingPremium> {
    throw new Error('Not implemented')
  }

  getFirmingPremiumDefault(): Promise<IProductFirmingPremium> {
    throw new Error('Not implemented')
  }
}
