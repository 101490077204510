import FormInput from '../../Form/FormInput'
import React from 'react'
import useLocalization from '../../../hooks/useLocalization'
import FormEmail from '../../Form/FormEmail'
import {ViewType} from '../Portfolio/Customer/CustomerEditPage'

interface Props {
  viewType?: ViewType
}

const FormRepresentative: React.FC<Props> = ({viewType}) => {
  const {translate} = useLocalization()

  return (
    <>
      <FormInput label={translate('First name')} name="contactFirstName" required />

      <FormInput label={translate('Last name')} name="contactLastName" required />

      <FormEmail label={translate('Email')} name="contactEmail" required />
    </>
  )
}

export default FormRepresentative
